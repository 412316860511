import {
  READ_SUCCESS,
  RESET_STORE_KEY,
  WRITE_SUCCESS,
} from '../constants/actionConstants';

export const modelReducer =
  ({ NAME, INITIAL_STATE }) =>
  (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case `${NAME}${READ_SUCCESS}`:
      case `${NAME}${WRITE_SUCCESS}`:
      case `${NAME}${RESET_STORE_KEY}`:
        return { ...state, ...payload };
      default:
        return state;
    }
  };
