import moment from 'moment';
import { useEffect, useState } from 'react';
import { getDaysToView } from '../Calendar/helpers';
import Day from '../Day';
import data from '../events.json';

const MonthMode = ({
  referenceDay,
  setReferenceDay,
  selectedDay,
  setSelectedDay,
}) => {
  const [daysOfMonth, setDaysOfMonth] = useState([]);

  const handlePrev = () => {
    const daysOfCurrentMonth = daysOfMonth.filter((day) => day.currentMonth);
    const newReferenceDay = moment(daysOfCurrentMonth[0].isoString).subtract(
      1,
      'days'
    );
    setReferenceDay(newReferenceDay);
  };

  const handleNext = () => {
    const daysOfCurrentMonth = daysOfMonth.filter((day) => day.currentMonth);
    const newReferenceDay = moment(
      daysOfCurrentMonth[daysOfCurrentMonth.length - 1].isoString
    ).add(1, 'days');
    setReferenceDay(newReferenceDay);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setDaysOfMonth(
      daysOfMonth.map((dayOfMonth) =>
        dayOfMonth.isoString === day.isoString
          ? { ...dayOfMonth, selected: true }
          : { ...dayOfMonth, selected: false }
      )
    );
  };

  useEffect(() => {
    setDaysOfMonth(
      getDaysToView(
        'month',
        referenceDay.toISOString(),
        selectedDay,
        data.events
      )
    );
  }, [referenceDay, selectedDay]);

  return (
    <>
      <div className="calendar__buttons">
        <span onClick={handlePrev} variant="contained" size="small">
          {'<'}
        </span>
        <h4 className="calendar__month">
          {`${referenceDay
            .format('MMMM')
            .toUpperCase()} de ${referenceDay.format('Y')}`}
        </h4>
        <span onClick={handleNext} variant="contained" size="small">
          {'>'}
        </span>
      </div>
      <div className="calendar__month-mode">
        <div className="calendar__month-container">
          <div className="calendar__days-labels">
            <div>Lun</div>
            <div>Mar</div>
            <div>Miér</div>
            <div>Jue</div>
            <div>Vie</div>
            <div>Sáb</div>
            <div>Dom</div>
          </div>
          <div className="calendar__days-container">
            <>
              {daysOfMonth.map((day, i) => (
                <Day
                  key={`${day.number}-${i}`}
                  day={day}
                  index={i}
                  handleDayClick={handleDayClick}
                  showEvents={false}
                />
              ))}
            </>
          </div>
        </div>
        {selectedDay && (
          <Day day={selectedDay} handleDayClick={handleDayClick} showEvents />
        )}
      </div>
    </>
  );
};

export default MonthMode;
