import { createTheme, ThemeProvider } from '@mui/material';
import { createContext, useContext, useState, useMemo } from 'react';

export const ThemeContext = createContext({
  toggleColorMode: () => {},
  mode: 'light',
});

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#264068',
          },
          secondary: {
            main: '#36b29a',
          },
          error: {
            main: '#e25a5a',
          },
          success: {
            main: '#39995f',
          },
          info: {
            main: '#FFFFFF',
          },
          sublow: {
            main: '#C26F0B',
          },
          low: {
            main: '#C2A10B',
          },
          medium: {
            main: '#B7C20B',
          },
          high: {
            main: '#6CC20B',
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
