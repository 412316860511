import moment from 'moment';

const getDay = (momentDate, month, selectedDay, referenceDay) => {
  const selectedWeek = referenceDay
    ? momentDate.week() === moment(referenceDay).week()
    : false;

  return {
    isoString: momentDate.toISOString(),
    number: momentDate.date(),
    currentMonth: momentDate.month() === month,
    selectedWeek,
    selected: momentDate.toISOString() === selectedDay.isoString,
    today: moment().format('DD/MM/Y') === momentDate.format('DD/MM/Y'),
    oneDayEvents: [],
    largeEvents: [],
    maxLargeEvents: 0,
  };
};

const isLargeEventInDay = (largeEvent, momentDay) => {
  return (
    momentDay.isSame(largeEvent.dateFrom) ||
    momentDay.isBetween(largeEvent.dateFrom, largeEvent.dateTo) ||
    momentDay.isSame(largeEvent.dateTo)
  );
};

const isLargeEventInWeek = (daysOfWeek, largeEvent) => {
  let found = false;

  for (let i = 0; i < 7; i++) {
    const momentDay = moment(daysOfWeek[i].isoString);
    found = isLargeEventInDay(largeEvent, momentDay);
    if (found) return found;
  }
  return found;
};

const addEvents = (daysOfWeek, events) => {
  let maxLargeEvents = 0;
  const largeEvents = events.filter(
    (event) => !event.oneDay && isLargeEventInWeek(daysOfWeek, event)
  );
  const oneDayEvents = events.filter((event) => event.oneDay);

  const daysWithEvents = daysOfWeek.map((day, i) => {
    const momentDay = moment(day.isoString, 'Y-MM-DD');
    const largeEventsOfDay = largeEvents.map((largeEvent) =>
      isLargeEventInDay(largeEvent, momentDay)
        ? largeEvent
        : { emptySpace: true }
    );
    maxLargeEvents =
      maxLargeEvents < largeEventsOfDay.length
        ? largeEventsOfDay.length
        : maxLargeEvents;

    return {
      ...day,
      oneDayEvents: oneDayEvents.filter((event) =>
        momentDay.isSame(event.date)
      ),
      largeEvents: largeEventsOfDay.map((event) => ({
        ...event,
        starts: momentDay.isSame(event.dateFrom),
        middle: momentDay.isBetween(event.dateFrom, event.dateTo),
        middleStarts:
          momentDay.isBetween(event.dateFrom, event.dateTo) && i === 0,
        ends: momentDay.isSame(event.dateTo),
      })),
    };
  });

  const mappedDaysWithEvents = daysWithEvents.map((day) => ({
    ...day,
    maxLargeEvents,
  }));

  return mappedDaysWithEvents;
};

const getDaysOfWeek = (
  referenceDay,
  selectedDay,
  month,
  events,
  originalReferenceDay
) => {
  const daysOfWeek = [];
  const weekday = moment(referenceDay).weekday();
  const momentDate = moment(referenceDay);

  for (let i = 0; i < weekday; i++) {
    let momentDate = moment(referenceDay).clone();
    momentDate.subtract(weekday - i, 'days');
    daysOfWeek.push(
      getDay(momentDate, month, selectedDay, originalReferenceDay)
    );
  }

  daysOfWeek.push(getDay(momentDate, month, selectedDay, originalReferenceDay));

  for (let i = weekday + 1; i < 7; i++) {
    let momentDate = moment(referenceDay).clone();
    momentDate.add(i - weekday, 'days');
    daysOfWeek.push(
      getDay(momentDate, month, selectedDay, originalReferenceDay)
    );
  }

  return addEvents(daysOfWeek, events);
};

const getDaysToView = (mode, referenceDay, selectedDay, events) => {
  const month = moment(referenceDay).month();

  if (mode === 'week') {
    const daysOfWeek = getDaysOfWeek(
      referenceDay,
      selectedDay,
      month,
      events,
      referenceDay
    );
    return addEvents(daysOfWeek, events);
  }

  if (mode === 'month') {
    const startOfMonthDate = moment(referenceDay).startOf('month');
    let daysOfMonth = [];
    let daysOfWeek = getDaysOfWeek(
      startOfMonthDate.toISOString(),
      selectedDay,
      month,
      events,
      referenceDay
    );
    while (daysOfWeek.some((day) => day.currentMonth)) {
      daysOfMonth = [...daysOfMonth, ...daysOfWeek];
      startOfMonthDate.add(7, 'days');
      daysOfWeek = getDaysOfWeek(
        startOfMonthDate.toISOString(),
        selectedDay,
        month,
        events,
        referenceDay
      );
    }
    return daysOfMonth;
  }
};

export { getDaysToView };
