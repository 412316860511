import { FormControl, TextField } from '@mui/material';
import React from 'react';

const TitleInput = ({ title, handleChange }) => {
  return (
    <>
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <TextField
            label="Título"
            name="title"
            autoComplete="off"
            value={title}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <p className="condition-masssage">
        Máximo 40 caractéres ({title.length}/40)
      </p>
    </>
  );
};

export default TitleInput;
