import { Avatar, Badge, IconButton, Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import Rating from '@mui/material/Rating';
import moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { remainingDates } from '../../../helpers/getRemainingDays';
import { useHistory } from 'react-router-dom';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useSelector } from 'react-redux';

import './Demand.scss';

const Demand = ({ demand, setSelectedDemand }) => {
  const history = useHistory();
  const { userInfo } = useSelector((store) => store.userSignIn);

  const {
    createUserId,
    toUserId,
    title,
    state,
    createdAt,
    updatedAt,
    expiredDate,
    priority,
    comments,
    modifications,
  } = demand;

  const selfAssignment = toUserId._id === createUserId._id;
  const unreadComments = comments.filter(
    (c) => c.userId !== userInfo._id && !c.read
  );
  const edited =
    modifications &&
    modifications.userId !== userInfo._id &&
    !modifications.seeing;

  return (
    <div className={`demand ${state}`}>
      {edited && <div className="demand__edited">Editada</div>}
      <div className="demand_header">
        <div className="demand_header_stars-container">
          <Rating
            name="priority"
            value={priority}
            icon={<CircleIcon fontSize="small" />}
            emptyIcon={<CircleOutlinedIcon fontSize="small" />}
            max={3}
            readOnly={true}
          />
        </div>
        {state !== 'done' && (
          <Tooltip title="Dias restantes" placement="top" arrow>
            <span
              className={`demand_expired-date ${
                remainingDates(expiredDate) < 0 && 'zero'
              }`}
            >
              {remainingDates(expiredDate)}
            </span>
          </Tooltip>
        )}
        <div className="demand_header_avatar-container">
          {selfAssignment ? (
            <Chip label="Auto asignada" />
          ) : (
            <Chip
              avatar={<Avatar src={toUserId.avatar} />}
              label={`${toUserId.name} ${toUserId.lastname}`}
              onClick={() => history.push(`/usuario/${toUserId._id}`)}
            />
          )}
        </div>
      </div>
      <div className="demand_title">{title}</div>
      {!selfAssignment && (
        <div className="demand-creator">
          Asignada por: &nbsp;
          <span onClick={() => history.push(`/usuario/${createUserId._id}`)}>
            {createUserId.name} {createUserId.lastname}
          </span>
        </div>
      )}
      <div className="demand_footer-date">
        <span>
          {moment(createdAt).format('D MMM')} | mod.{' '}
          {moment(updatedAt).format('D MMM')}
        </span>
        <IconButton onClick={() => setSelectedDemand(demand)}>
          <Badge badgeContent={unreadComments?.length} color="primary">
            <MessageOutlinedIcon fontSize="small" />
          </Badge>
        </IconButton>
        <Tooltip title="Ver/editar solicitud" placement="top" arrow>
          <IconButton onClick={() => setSelectedDemand(demand)}>
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default Demand;
