import { booleansEnum } from '../../../helpers/enums';

import './UserPersonalData.scss';

const UserPersonalData = ({ personalData }) => {
  return (
    <div className="user-detail-page__personal-data">
      <div className="user-detail-page__personal-data__title">Hobbies:</div>
      <div className="user-detail-page__personal-data__info">
        {personalData?.hobbies || 'No informado.'}
      </div>
      <div className="user-detail-page__personal-data__title">
        Estado Civil:
      </div>
      <div className="user-detail-page__personal-data__info">
        {personalData?.civilStatus || 'No informado.'}
      </div>
      <div className="user-detail-page__personal-data__children">
        <div>
          <div className="user-detail-page__personal-data__title">Hijos:</div>
          <div className="user-detail-page__personal-data__info">
            {booleansEnum(personalData?.children)}
            {personalData?.children && ', ' + personalData?.childrenQuantity}
          </div>
        </div>
        <div>
          <div className="user-detail-page__personal-data__title">
            Hijos en camino:
          </div>
          <div className="user-detail-page__personal-data__info">
            {booleansEnum(personalData?.childrenComing)}
          </div>
        </div>
      </div>

      <div className="user-detail-page__personal-data__title">Estudios:</div>
      <div className="user-detail-page__personal-data__info">
        {booleansEnum(personalData?.studies)}
      </div>
      <ul className="user-detail-page__personal-data__studies">
        {personalData?.studiesList.map((study) => (
          <li key={study} className="user-detail-page__personal-data__study">
            <span>- {study}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserPersonalData;
