import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError/ServerError';
import { goalsModel } from '../../constants/modelConstants';
import AssignedGoalsUsers from './AssignedGoalsUsers/AssignedGoalsUsers';
import Filters from './Filters/Filters';
import Goals from './Goals/Goals';
import { getFilteredGoals } from './helpers';
import './PreviousGoalsPage.scss';

const defaultFilters = {
  source: 'all',
  selectedUserId: '',
  devolution: 'all',
  evaluation: 'all',
};

const PreviousGoalsPage = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { teamUsers } = useSelector((store) => store.generalData);
  const { goals, previousEvaluations } = useSelector((store) => store.goals);

  const [filters, setFilters] = useState(defaultFilters);
  const [filteredGoals, setFilteredGoals] = useState(goals);

  const { source, selectedUserId } = filters;

  useEffect(() => {
    setFilteredGoals(getFilteredGoals(goals, filters, userInfo._id, userInfo));
  }, [goals, filters, userInfo]);

  useEffect(() => {
    dispatch(getAction(goalsModel.NAME, `${goalsModel.URL}/previous`));
  }, [dispatch]);

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="previous-goals-page">
      <h3 className="goals-page__title"> Objetivos Anteriores</h3>
      <Filters
        filters={filters}
        setFilters={setFilters}
        previousEvaluations={previousEvaluations}
      />
      {source === 'created-by-me' && (
        <AssignedGoalsUsers
          goals={filteredGoals}
          teamUsers={teamUsers}
          selectedUserId={selectedUserId}
          setFilters={setFilters}
        />
      )}
      <Goals goals={filteredGoals} />
    </div>
  ) : null;
};

export default PreviousGoalsPage;
