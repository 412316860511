import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colorHEX } from '../../components/CustomAvatar/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const expectedChartData = [
//   {
//     fullname: 'Martin',
//     evaluations: [
//       { name: '1111', points: 20 },
//       { name: '2222', points: 40 },
//       { name: '3333', points: 0 },
//       { name: '4444', points: 100 },
//     ],
//   },
//   {
//     fullname: 'Francisco',
//     evaluations: [
//       { name: '1111', points: 50 },
//       { name: '2222', points: 60 },
//       { name: '3333', points: 90 },
//       { name: '4444', points: 100 },
//     ],
//   },
// ];

const translatedStats = {
  assistance: 'Asistencia',
  objetives: 'Objetivos',
  competences: 'Competencias',
};

const LineChart = ({ chartData, stats }) => {
  const datasets = chartData.map((user) => ({
    label: user.fullname,
    data: user.evaluations?.map((ev) => ev.points),
    fill: false,
    borderColor: colorHEX(),
    tension: 0.1,
  }));

  const options = {
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Comparación de Puntos de ${translatedStats[stats]}`,
      },
    },
  };

  const data = {
    labels: chartData[0]?.evaluations?.map((ev) => ev.name),
    datasets,
    title: 'Pepe',
  };

  return (
    <div>
      <Line
        data={data}
        options={options}
        style={{ height: '300px', width: '600px' }}
      />
    </div>
  );
};

export default LineChart;
