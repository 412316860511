import { createContext } from 'react';

export const SocketContext = createContext();

const SocketProvider = ({ socket, children }) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
