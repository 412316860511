import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

import './RichText.scss';

const RichText = ({ placeholder, content, setContent }) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      language: 'es',
      placeholder: placeholder || 'Contenido',
    }),
    [placeholder]
  );

  return (
    <div className="rich-text">
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onChange={(newContent) => setContent(newContent)}
      />
    </div>
  );
};

export default RichText;
