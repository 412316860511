import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalDataModel } from '../../../constants/modelConstants';
import { postAction, putAction } from '../../../actions/modelActions';
import CustomModal from '../../../components/CustomModal';
import Ponderations from '../../../components/Ponderations';

import './AreaForm.scss';

const AreaForm = ({ setIsFormOpen, editingArea, selectedParentArea }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { areas, evaluation } = useSelector((store) => store.generalData);

  const [area, setArea] = useState({
    name: editingArea?.name || '',
    management: editingArea?.management || false,
    parentArea: editingArea?.parentArea?._id || selectedParentArea?._id || null,
    withoutBoss: editingArea?.withoutBoss || false,
    withoutEmployees: editingArea?.withoutEmployees || false,
    hasOwnPonderations: editingArea?.hasOwnPonderations || false,
    ponderations: {
      competences: editingArea?.ponderations?.competences || 0,
      assistance: editingArea?.ponderations?.assistance || 0,
      objetives: editingArea?.ponderations?.objetives || 0,
    },
  });

  const {
    name,
    management,
    parentArea,
    withoutBoss,
    withoutEmployees,
    hasOwnPonderations,
    ponderations: { competences, assistance, objetives },
  } = area;

  const handleChange = ({ target: { name, value } }) => {
    setArea({ ...area, [name]: value });
  };

  const handleRadioChange = ({ target: { value } }, name) => {
    const isManagement = value === 'true' ? true : false;
    if (isManagement) {
      setArea({ ...area, [name]: isManagement, parentArea: null });
    } else {
      setArea({ ...area, [name]: isManagement });
    }
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    setArea({ ...area, [name]: checked });
  };

  const handlePercentageChange = ({ target: { name, value } }) => {
    if (value >= 0) {
      setArea({
        ...area,
        ponderations: { ...area.ponderations, [name]: Number(value) },
      });
    }
  };

  const handleSubmit = () => {
    editingArea
      ? dispatch(
          putAction(generalDataModel.NAME, generalDataModel.WRITE_AREA_URL, {
            ...area,
            id: editingArea._id,
          })
        )
      : dispatch(
          postAction(
            generalDataModel.NAME,
            generalDataModel.WRITE_AREA_URL,
            area
          )
        );
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Dar de alta un área" setIsFormOpen={setIsFormOpen}>
      <div classnmae="area-form">
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <TextField
              label="Nombre de área"
              name="name"
              autoComplete="off"
              value={name}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="form-input-wrapper">
          <FormControl
            component="fieldset"
            fullWidth
            disabled={editingArea ? true : false}
          >
            <FormLabel component="legend">Es de 1er nivel?</FormLabel>
            <RadioGroup
              row
              aria-label="children"
              name="children"
              onChange={(e) => handleRadioChange(e, 'management')}
              value={management}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Si"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {!management && (
          <>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <InputLabel id="civil-status">Depende del área</InputLabel>
                <Select
                  value={parentArea || ''}
                  onChange={handleChange}
                  name="parentArea"
                  label="Depende del área"
                >
                  {areas
                    .filter((area) => area._id !== editingArea?._id)
                    .filter((area) => !area.withoutBoss)
                    .map((area) => (
                      <MenuItem value={area._id} key={area._id}>
                        {area.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={withoutBoss}
                      disabled={
                        editingArea
                          ? Boolean(editingArea?.bosses.length) ||
                            withoutEmployees
                          : false
                      }
                    />
                  }
                  label="No posee jefe/s (Responde al nivel superior)"
                  onChange={handleCheckboxChange}
                  name="withoutBoss"
                />
              </FormGroup>
            </div>
          </>
        )}
        <div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={withoutEmployees} />}
              label="No posee empleado/s"
              onChange={handleCheckboxChange}
              disabled={
                editingArea
                  ? Boolean(editingArea?.employees.length) || withoutBoss
                  : false
              }
              name="withoutEmployees"
            />
          </FormGroup>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={hasOwnPonderations} />}
              label="Posee ponderaciones independientes"
              onChange={handleCheckboxChange}
              name="hasOwnPonderations"
            />
          </FormGroup>
        </div>
        {hasOwnPonderations && (
          <div className="area-form__ponderations-container">
            <Ponderations
              competences={competences}
              evaluation={evaluation}
              assistance={assistance}
              objetives={objetives}
              handlePercentageChange={handlePercentageChange}
            />
          </div>
        )}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            sending ||
            !area.name ||
            (!area.management && !area.parentArea) ||
            (area.hasOwnPonderations &&
              competences + objetives + assistance !== 100)
          }
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default AreaForm;
