import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../actions/userActions';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Divider, ListItemIcon, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from 'react-router-dom';

const UserMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    dispatch(signOutAction());
    history.replace('/login');
  };

  return (
    <div className="user-section__user-menu">
      <Button
        id="user-menu"
        size="small"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Avatar src={userInfo?.avatar} />
        <span className="user-section__username">
          {userInfo?.name} {userInfo?.lastname}
        </span>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateY(6px)',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!userInfo?.isNotEvaluable && (
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>

            <Link className="link" to="/datos-personales">
              <ListItemText>Datos personales</ListItemText>
            </Link>
          </MenuItem>
        )}

        {!userInfo?.isNotEvaluable && <Divider />}

        {userInfo?.isAdmin && (
          <div>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <Link className="link" to="/configuraciones">
                <ListItemText>Configuraciones</ListItemText>
              </Link>
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
