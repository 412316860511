import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Tooltip } from '@mui/material';
import BenefitsFilter from '../BenefitsFilter';
import FIleUploadInput from '../../../components/FIleUploadInput';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { benefitModel } from '../../../constants/modelConstants';
import EmptyComponent from '../../../components/EmptyComponent';
import { verifyPermission } from '../../../helpers/verifyPermission';
import { permissions } from '../../../helpers/permissions';
import AddButton from '../../../components/AddButton';
import BenefitsForm from '../BenefitsForm/BenefitsForm';

import './BenefitsTable.scss';

const BenefitsTable = ({ benefits, handleEdit, categories }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { company } = useSelector((store) => store.generalData);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [filteredBenefits, setFilteredBenefits] = useState(benefits);
  const [filteredCategory, setFilteredCategory] = useState('');
  const [index, setIndex] = useState('');

  const getLevel = (boolean) => (boolean ? 'Si' : 'No');

  const handleCategoryChange = ({ target: { value } }) => {
    setFilteredCategory(value);

    if (value) {
      const updatedBenefits = benefits.filter(
        (benefit) => benefit.category.value === value
      );
      setFilteredBenefits(updatedBenefits);
    } else {
      setFilteredBenefits(benefits);
    }
  };

  const handleOnIconClick = (i) => {
    setIndex(i);
  };

  const getFile = (imageSelected) => {
    const benefitId = filteredBenefits[index]._id;
    const newImage = new FormData();
    const extension = imageSelected.name.split('.')[1];
    const file = new File([imageSelected], `${benefitId}.${extension}`, {
      type: imageSelected.type,
      lastModified: imageSelected.lastModified,
    });
    newImage.append('image', file);
    dispatch(
      putAction(
        benefitModel.NAME,
        `${benefitModel.URL_UPDATE_IMAGE}/${benefitId}`,
        newImage
      )
    );
  };

  useEffect(() => {
    setFilteredBenefits(benefits);
    setFilteredCategory('');
  }, [benefits]);

  return (
    <div className="benefits-table">
      <h3 className="benefits-table__title">
        Administrar Beneficios
        {verifyPermission(userInfo, permissions.ADMIN_BENEFITS) && (
          <AddButton
            tooltip="Agregar Beneficio"
            onClick={() => setIsFormOpen(true)}
          />
        )}
      </h3>
      <BenefitsFilter
        handleCategoryChange={handleCategoryChange}
        filteredCategory={filteredCategory}
        categories={categories}
      />
      {!filteredBenefits.length ? (
        <EmptyComponent message="No hay beneficios para esta categoría" />
      ) : (
        <div className="benefits-table__table-container">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className="benefits-table__table-header">
                  <TableCell>Imagen</TableCell>
                  <TableCell>Título</TableCell>
                  <TableCell>Categoría</TableCell>
                  <TableCell>Rubro/Marca</TableCell>
                  <TableCell>Interno</TableCell>
                  <TableCell>Activo</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBenefits?.map((benefit, i) => (
                  <TableRow key={benefit._id}>
                    <TableCell>
                      <div className="image-container">
                        {benefit.image && <Avatar src={benefit.image} />}
                      </div>
                    </TableCell>
                    <TableCell>{benefit.title}</TableCell>
                    <TableCell>
                      {benefit.category?.name || 'Sin categoría'}
                    </TableCell>
                    <TableCell>{benefit.field}</TableCell>
                    <TableCell>{getLevel(benefit.internal)}</TableCell>
                    <TableCell>{getLevel(benefit.isActive)}</TableCell>
                    <TableCell>
                      {
                        <span className="icon-container">
                          <Tooltip title="Editar" placement="top">
                            <EditIcon
                              fontSize="small"
                              onClick={() => handleEdit(benefit)}
                            />
                          </Tooltip>
                        </span>
                      }
                    </TableCell>
                    <TableCell>
                      <div className="icon-container">
                        <FIleUploadInput
                          handleOnIconClick={() => handleOnIconClick(i)}
                          handleChange={(e) => getFile(e.target.files[0])}
                          tooltipTitle="Cambiar Imagen"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {isFormOpen && (
        <BenefitsForm
          setIsFormOpen={setIsFormOpen}
          categories={company.categories}
        />
      )}
    </div>
  );
};

export default BenefitsTable;
