import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';

const EvaluationsSelect = ({
  evaluationsIds,
  stats,
  handleMutipleEvaluationsChange,
  evaluations,
}) => {
  const getEvaluationLabel = (evaluationId) => {
    return evaluations.find((ev) => ev._id === evaluationId).name;
  };

  return (
    <div className="form-input-wrapper">
      <FormControl fullWidth>
        <InputLabel>Evaluaciones a comparar</InputLabel>
        <Select
          multiple
          name="usersIds"
          label="Evaluaciones a comparar"
          value={evaluationsIds}
          onChange={handleMutipleEvaluationsChange}
          disabled={!stats}
          input={<OutlinedInput label="Evaluaciones a comparar" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={getEvaluationLabel(value)} />
              ))}
            </Box>
          )}
        >
          {evaluations.map((evaluation) => (
            <MenuItem value={evaluation._id} key={evaluation._id}>
              {evaluation.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default EvaluationsSelect;
