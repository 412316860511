import React, { useEffect, useState } from 'react';
import { colorHEX, getUserInitials } from './helpers';
import './CustomAvatar.scss';

const CustomAvatar = ({ userFullname }) => {
  const [color, setColor] = useState('#38893f');

  useEffect(() => {
    const randomColor = colorHEX();
    setColor(randomColor);
  }, []);

  return (
    <div
      className="custom-avatar"
      style={{
        backgroundColor: color,
        color: 'white',
      }}
    >
      {getUserInitials(userFullname)}
    </div>
  );
};

export default CustomAvatar;
