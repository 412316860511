import httpClient from '../http-client';
import {
  SEND_MAIL_FAIL,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  USER_SIGN_IN_FAIL,
  USER_SIGN_IN_REQUEST,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_OUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  UPDATE_USER_SUCCESS,
} from '../constants/userConstants';
import {
  WRITE_ERROR,
  WRITE_REQUEST,
  WRITE_RESET,
  WRITE_SUCCESS,
} from '../constants/actionConstants';

export const signInAction =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: USER_SIGN_IN_REQUEST });
    try {
      const { data } = await httpClient.post('user-public/signin', {
        email,
        password,
      });
      localStorage.setItem('token', data.token);
      localStorage.removeItem('area-selected', data.token);
      dispatch({ type: USER_SIGN_IN_SUCCESS, payload: data.userInfo });
    } catch (err) {
      dispatch({
        type: USER_SIGN_IN_FAIL,
        payload: err.response?.data.message || err.message,
      });
    }
  };

export const sendMailAction =
  ({ email }) =>
  async (dispatch) => {
    dispatch({ type: SEND_MAIL_REQUEST });
    try {
      const { data } = await httpClient.post(
        'user-public/sendResetPasswordLink',
        {
          email,
        }
      );
      dispatch({ type: SEND_MAIL_SUCCESS, payload: data.message });
    } catch (err) {
      dispatch({
        type: SEND_MAIL_FAIL,
        payload: err.response?.data.message || err.message,
      });
    }
  };

export const resetPasswordAction = (formData) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  try {
    const { data } = await httpClient.post(
      'user-public/resetPassword',
      formData
    );
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.message });
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: err.response?.data.message || err.message,
    });
  }
};

export const signOutAction = () => async (dispatch) => {
  localStorage.removeItem('token');
  dispatch({ type: USER_SIGN_OUT });
};

export const updateUserAction = (body, url) => async (dispatch) => {
  dispatch({ type: WRITE_REQUEST });

  try {
    const { data } = await httpClient.put(url, body);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.payload,
    });

    dispatch({
      type: WRITE_SUCCESS,
      payload: data.message || 'Edición realizada correctamente',
    });

    setTimeout(() => {
      dispatch({ type: WRITE_RESET });
    }, 3000);
  } catch (err) {
    dispatch({
      type: WRITE_ERROR,
      payload: err.response?.data.message || err.message,
    });
  }
};

export const updateUserCoinsByBenefit = (body, url) => async (dispatch) => {
  dispatch({ type: WRITE_REQUEST });

  try {
    const { data } = await httpClient.put(url, body);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        coins: data.payload.coins,
        coinsMovements: data.payload.coinsMovements,
      },
    });

    dispatch({
      type: 'BENEFIT_WRITE_SUCCESS',
      payload: { benefits: data.payload.benefits },
    });

    dispatch({
      type: WRITE_SUCCESS,
      payload: data.message || 'Edición realizada correctamente',
    });

    setTimeout(() => {
      dispatch({ type: WRITE_RESET });
    }, 3000);
  } catch (err) {
    dispatch({
      type: WRITE_ERROR,
      payload: err.response?.data.message || err.message,
    });
  }
};
