import { Chip, Tooltip } from '@mui/material';

const DependentAreas = ({ areas }) => {
  const moreAreas = [];
  areas.forEach((area, i) => (i > 2 ? moreAreas.push(area) : null));

  let moreAreasString = '';

  if (moreAreas.length) {
    moreAreas.forEach((area, i) => {
      moreAreasString += i === 0 ? area.name : `, ${area.name}`;
    });
  }

  return (
    <span>
      <>
        {areas?.length
          ? areas.map((area, i) =>
              i < 3 ? (
                <Chip
                  key={area._id}
                  label={area.name}
                  className="areas-table__area-chip"
                />
              ) : null
            )
          : 'No tiene areas a cargo'}
        {moreAreas.length > 1 && (
          <Tooltip title={moreAreasString} placement="top" arrow>
            <span className="areas-table__more-areas">
              y {moreAreas.length} más...
            </span>
          </Tooltip>
        )}
      </>
    </span>
  );
};

export default DependentAreas;
