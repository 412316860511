import moment from 'moment';
import './UserDevelopmentHistory.scss';

const UserDevelopmentHistory = ({
  userData: { developmentHistory, entry },
}) => {
  return developmentHistory ? (
    <div className="user-detail-page__development-history">
      <div className="move">
        {entry ? (
          <b>
            Se incorporó a la empresa el {moment(entry).format('DD/MM/YY')}{' '}
          </b>
        ) : (
          <p className="no-entry">Fecha de ingreso no informada.</p>
        )}
      </div>
      {developmentHistory.map((move, i) => (
        <div key={`history-${i}`} className="move">
          {move}
        </div>
      ))}
    </div>
  ) : null;
};

export default UserDevelopmentHistory;
