import { Avatar, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import UserScoresTooltip from './UserScoresTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { subtitles } from './getSubtitle';

const Category = ({ title, filteredUsers, condition, color }) => {
  const history = useHistory();

  return (
    <div className="nine-box__category" style={{ backgroundColor: color }}>
      <div className="nine-box__category-title">
        <span>{title}</span>
      </div>
      <div className="nine-box__category-info">
        <Tooltip title={subtitles[title.toLowerCase()]}>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </div>
      <div className="nine-box__category-users">
        {filteredUsers.map(
          (user) =>
            condition(user) && (
              <UserScoresTooltip user={user} key={user._id}>
                <span onClick={() => history.push(`/usuario/${user._id}`)}>
                  <Avatar
                    src={user.avatar}
                    className={`avatar ${user.role === 'boss' && 'boss'}`}
                  />
                </span>
              </UserScoresTooltip>
            )
        )}
      </div>
    </div>
  );
};

export default Category;
