import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralDataAction } from '../../actions/modelActions';
import { generalDataModel } from '../../constants/modelConstants';
import { buildTabs } from './helpers';

import './ConfigPage.scss';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div style={{ marginTop: '10px' }}>{children}</div>}
    </div>
  );
};

const ConfigPage = () => {
  const dispatch = useDispatch();
  const { evaluation } = useSelector((store) => store.generalData);
  const { userInfo } = useSelector((store) => store.userSignIn);

  const [config, setConfig] = useState(0);
  const [tabsStructure, setTabsStructure] = useState({
    tabs: [],
    tabsPanels: [],
  });

  const handleChange = (_, newConfig) => {
    localStorage.setItem('config', newConfig);
    setConfig(newConfig);
  };

  useEffect(() => {
    const currentTabs = buildTabs(evaluation, userInfo);
    setTabsStructure(currentTabs);

    if (localStorage.getItem('config')) {
      let newConfig = Number(localStorage.getItem('config'));
      if (newConfig >= currentTabs.tabs.length) {
        newConfig = 0;
      }
      setConfig(newConfig);
    }
  }, [evaluation, userInfo]);

  // Get general data
  useEffect(() => {
    dispatch(getGeneralDataAction(generalDataModel.NAME, generalDataModel.URL));
  }, [dispatch]);

  return (
    <div className="configurations-page">
      {tabsStructure.tabs.length && tabsStructure.tabsPanels.length ? (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={config} onChange={handleChange}>
              {tabsStructure.tabs.map((tab, i) => (
                <Tab key={`tab-${i}`} label={tab} />
              ))}
            </Tabs>
          </Box>
          {tabsStructure.tabsPanels.map((tab, i) => (
            <TabPanel key={`tab-panel-${i}`} value={config} index={i}>
              {tab}
            </TabPanel>
          ))}
        </Box>
      ) : (
        <div>
          <h3>No tenés permisos para realizar Acciones administrativas</h3>
        </div>
      )}
    </div>
  );
};

export default ConfigPage;
