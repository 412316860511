const buildInitialState = (competences, affectedUsers) => {
  const initialState = {};
  competences.forEach((competence) => {
    initialState[competence._id] = {};
    affectedUsers.forEach((data) => {
      initialState[competence._id][data.user._id] = {
        evaluationType: data.evaluationType,
        isAddedUser: data.isAddedUser,
        reason: data.reason,
        behaviour: {
          value: null,
          points: 0,
        },
        score: {
          value: null,
          points: 0,
        },
        comments: '',
      };
    });
    initialState[competence._id].bonusPointValue = null;
  });
  return initialState;
};

export default buildInitialState;
