import React, { useEffect, useState } from 'react';
import { /* Avatar, */ Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { userDetailModel, userModel } from '../../../constants/modelConstants';
import CustomModal from '../../CustomModal';
import { updateUserAction } from '../../../actions/userActions';
import './ActionPlanModal.scss';

const ActionPlanModal = ({
  setIsFormOpen,
  toUserId,
  currentActionPlan,
  showButtons = true,
}) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const [actionPlan, setActionPlan] = useState(currentActionPlan);

  const handleChange = (e) =>
    setActionPlan({ ...actionPlan, text: e.target.value });

  const handleCreatorSubmit = () => {
    // esto lo dispara el creador del P.A. y el servicio devuelve toda la info para que actualice la data del usuario. Porque se hace desde la pag de user data, y le pega a esa key del redux
    dispatch(
      putAction(
        userDetailModel.NAME,
        `${userDetailModel.UPDATE_ACTION_PLAN}/${toUserId}/creator`,
        { actionPlan: { ...actionPlan, viewed: false } }
      )
    );
  };

  const handleUserSubmit = () => {
    // esto lo dispara el usuario para marcarlo como leido y el servicio devuelve solo el actionPlan para actualizar mi data. Porque se hace desde el dashboard y le pega al userSignIn del redux
    dispatch(
      updateUserAction(
        { actionPlan: { ...actionPlan, viewed: true } },
        `${userModel.UPDATE_ACTION_PLAN}/${toUserId}/user`
      )
    );
  };

  useEffect(() => {
    if (success) setIsFormOpen(false);
  }, [setIsFormOpen, success]);

  const belongsToMe = userInfo._id === toUserId;

  const titleVariable = () => {
    if (!showButtons) return '';
    if (!belongsToMe) {
      return actionPlan.text ? 'Editar' : 'Crear';
    }
    return 'Ver';
  };
  return (
    <CustomModal
      title={`${titleVariable()} Plan de acción`}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="action-plan__modal">
        {/* {actionPlan.createUserId?.fullname && (
          <div className="action-plan__creator">
            Creado por: <Avatar src={actionPlan.createUserId?.avatar} />{' '}
            {actionPlan.createUserId?.fullname}
          </div>
        )} */}
        <textarea
          className={`action-plan__textarea ${actionPlan.viewed && 'viewed'}`}
          disabled={!showButtons || belongsToMe}
          value={actionPlan.text}
          onChange={handleChange}
          placeholder="Plan de acción..."
        />
        {actionPlan.viewed && (
          <div className="action-plan__read-badge">Leido</div>
        )}
        {showButtons &&
          (belongsToMe ? (
            !actionPlan.viewed && (
              <Button
                variant="contained"
                onClick={handleUserSubmit}
                disabled={sending || actionPlan.viewed}
              >
                {sending ? 'Guardando' : 'Marcar como leído'}
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              onClick={handleCreatorSubmit}
              disabled={sending || !actionPlan}
            >
              {sending ? 'Guardando' : 'Guardar'}
            </Button>
          ))}
      </div>
    </CustomModal>
  );
};

export default ActionPlanModal;
