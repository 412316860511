import { memo, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { allUsersModel } from '../../constants/modelConstants';

const UserAutocomplete = ({
  handleOnChange,
  excludeId = null,
  label = 'Usuario',
  placeholder = '',
  removeValueAfterSearch = false,
}) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { users } = useSelector((store) => store.users);

  const [value, setValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  useEffect(() => {
    if (value.length) {
      if (excludeId) {
        setFilteredUsers(
          users.filter(
            (user) =>
              user.fullname.toLowerCase().includes(value.toLowerCase()) &&
              user.active &&
              user._id !== excludeId
          )
        );
      } else {
        setFilteredUsers(
          users.filter(
            (user) =>
              user.fullname.toLowerCase().includes(value.toLowerCase()) &&
              user.active
          )
        );
      }
    } else {
      setFilteredUsers([]);
    }
  }, [value, users, excludeId]);

  useEffect(() => {
    dispatch(getAction(allUsersModel.NAME, allUsersModel.URL, false));
  }, [dispatch]);

  const customOptions = {};
  if (removeValueAfterSearch) {
    customOptions.value = null;
  }

  return users ? (
    <Autocomplete
      {...customOptions}
      disablePortal
      blurOnSelect={removeValueAfterSearch}
      id="user-autocomplete"
      options={value.length ? filteredUsers : []}
      noOptionsText={
        value.length
          ? 'No se encontraron resultados'
          : 'Escribí para buscar usuarios'
      }
      getOptionLabel={(user) =>
        `${user.name} ${user.lastname} ${
          user._id === userInfo._id ? ' (Yo)' : ''
        }`
      }
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      onChange={(_, selectedValue) => {
        removeValueAfterSearch && setValue('');
        handleOnChange(selectedValue?._id || '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="value"
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
        />
      )}
    />
  ) : null;
};

export default memo(UserAutocomplete);
