import React, { useEffect } from 'react';
import { useState } from 'react';

const Counter = ({ setEndGame }) => {
  const minutes = '00';
  const [seconds, setSeconds] = useState(59);
  const timer = `${minutes}:${seconds}`;

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((sec) => sec - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setEndGame(true);
    }
  }, [seconds, setEndGame]);

  return (
    <div className="counter">
      <h2>{timer}</h2>
    </div>
  );
};

export default Counter;
