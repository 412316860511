import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction } from '../../../actions/userActions';

const LoginForm = ({ setView }) => {
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const { loading, error } = useSelector((store) => store.userSignIn);
  const dispatch = useDispatch();

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signInAction(form));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="login-page_inputs-wrapper">
        <TextField
          type="email"
          name="email"
          label="Email"
          onChange={handleInputChange}
          value={form.email}
          className={`login-page_input ${error && 'error'}`}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          onChange={handleInputChange}
          value={form.password}
          className={`login-page_input ${error && 'error'}`}
        />
        <Button type="submit" variant="contained" disabled={loading}>
          {loading ? 'Ingresando...' : 'Ingresar'}
        </Button>
      </div>
      <p
        className="login-page__forgot-password"
        onClick={() => setView('forgotPassword')}
      >
        Olvidé la contraseña
      </p>
    </form>
  );
};

export default LoginForm;
