import CropImage from '../../CropImage/CropImage';
import CustomModal from '../../CustomModal';

const CropImageModal = ({ setIsFormOpen, userId }) => {
  return (
    <CustomModal
      title="Seleccionar y editar una imágen"
      setIsFormOpen={setIsFormOpen}
    >
      <CropImage userId={userId} aspect={1 / 1} roundedPreview />
    </CustomModal>
  );
};

export default CropImageModal;
