import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import CompetencesTooltip from '../../components/CompetencesTooltip';
import { useSelector } from 'react-redux';

import './ProgressComponent.scss';

const ProgressComponent = ({
  title,
  value,
  widthDetail = false,
  competencesScores = null,
}) => {
  const {
    company: { categories },
  } = useSelector((store) => store.generalData);

  const gold = categories.find((category) => category.value === 'Gold');
  const silver = categories.find((category) => category.value === 'Silver');
  const bronze = categories.find((category) => category.value === 'Bronze');

  const showTootip = () =>
    widthDetail &&
    competencesScores &&
    competencesScores.some((score) => score?.competencePoints);

  const getColor = () => {
    if (value >= gold.minPoints) return 'high';
    if (value >= silver.minPoints) return 'medium';
    if (value >= bronze.minPoints) return 'low';
    return 'sublow';
  };

  return (
    <div className="progress-indicator">
      <div className="progress-indicator__title">{title}</div>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          margin: '20px 0',
        }}
      >
        <CircularProgress
          variant="determinate"
          value={value}
          color={getColor()}
          size={70}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color={getColor()}
            fontSize="medium"
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
      {showTootip() && (
        <CompetencesTooltip competencesScores={competencesScores}>
          <div className="progress-indicator__detail">
            <BarChartIcon />
          </div>
        </CompetencesTooltip>
      )}
    </div>
  );
};

export default ProgressComponent;
