import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

const Input = styled('input')({
  display: 'none',
});

const FIleUploadInput = ({ handleChange, tooltipTitle, handleOnIconClick }) => {
  return (
    <Tooltip title={tooltipTitle || 'Cambiar Avatar'} arrow placement="top">
      <label htmlFor="icon-button-file">
        <Input
          accept="image/*"
          id="icon-button-file"
          type="file"
          onChange={handleChange}
        />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleOnIconClick}
        >
          <PhotoCamera />
        </IconButton>
      </label>
    </Tooltip>
  );
};

export default FIleUploadInput;
