import React from 'react';
import { Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';

const AreaUser = ({ user }) => {
  const history = useHistory();

  return (
    <div
      className="modal-area-detail__user"
      key={user._id}
      onClick={() => history.push(`/usuario/${user._id}`)}
    >
      <Avatar src={user.avatar} />
      <div className="modal-area-detail__user-data">
        <div className="modal-area-detail__user-name">{user.fullname}</div>
        <div className="modal-area-detail__user-role-label">
          {user.roleLabel}
        </div>
      </div>
    </div>
  );
};

export default AreaUser;
