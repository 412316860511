import React, { useEffect, useState } from 'react';
import RichText from '../../components/RichText';
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postAction } from '../../actions/modelActions';
import { publicationsModel } from '../../constants/modelConstants';
import ServerError from '../../components/ServerError';
import { useHistory } from 'react-router-dom';

import './NewPostPage.scss';

const NewPostPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { sending, pageLoading, statusError, success } = useSelector(
    (store) => store.app
  );
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { channels } = useSelector((store) => store.publications);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [channel, setChannel] = useState('');

  const handleChange = ({ target }) => {
    setTitle(target.value);
  };

  const handleSubmit = () => {
    dispatch(
      postAction(publicationsModel.NAME, publicationsModel.URL, {
        title,
        content,
        channel,
        createUserId: userInfo._id,
      })
    );
  };

  useEffect(() => {
    if (success) {
      history.replace('/posts');
    }
  }, [success, history]);

  if (statusError) return <ServerError />;
  if (pageLoading) return null;

  return (
    <div className="new-post-page">
      <h3>
        Nuevo Post
        <span onClick={() => history.push('/posts')}>Ir a los Posts</span>
      </h3>
      <div className="new-post-page__form-content">
        <div className="new-post-page__name">
          <FormControl fullWidth>
            <TextField
              type="text"
              label="Título"
              name="title"
              value={title}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="new-post-page__channel">
          <FormControl fullWidth>
            <InputLabel>Canal</InputLabel>
            <Select
              value={channel}
              label="Canal"
              onChange={(e) => setChannel(e.target.value)}
              defaultValue=""
            >
              {channels.map((channel) => (
                <MenuItem value={channel._id} key={channel._id}>
                  {channel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="new-post-page__content">
          <RichText
            placeholder="Contenido"
            content={content}
            setContent={setContent}
          />
        </div>
        <div className="new-post-page__actions">
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={sending || !title.length || !content.length}
          >
            Guardar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPostPage;
