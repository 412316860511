import './Summary.scss';

const Summary = ({ data, getCompetenceName }) => {
  const {
    user,
    competencesScore,
    competencesScoreDetail,
    comments,
    totalEvaluations,
    totalAnsweredEvaluations,
  } = data;

  return (
    <div className="summary">
      <div className="summary__username">
        <b>Usuario:</b> {user}
      </div>
      <div className="summary__answered-evaluations">
        Eval. respondidas: {totalAnsweredEvaluations}/{totalEvaluations}
      </div>
      <div className="summary__competences-score">
        <b>Score de competencias</b>
        <span className="summary__competences-score-qty">
          {Math.round(competencesScore)}
        </span>
      </div>
      <div className="summary__competences-detail-title">
        Detalle de competencias:
      </div>
      <ul className="summary__competences-detail">
        {competencesScoreDetail.map((competence) => (
          <li
            key={competence.competenceId}
            className="summary__competence-detail"
          >
            <div className="summary__competence-name">
              {getCompetenceName(competence.competenceId)}
            </div>
            <div className="summary__competence-data-container">
              <div className="summary__competence-data">
                <b>Puntos:</b> {Math.round(competence.points)}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <p className="summary__comments-title">Comentarios:</p>

      {comments.length > 0 ? (
        <ul className="summary__comments-container">
          {comments.map((comment, i) => (
            <li key={`comment-${i}`} className="summary__comments">
              {comment}
            </li>
          ))}
        </ul>
      ) : (
        <p className="summary__no-comments">No hay comentarios</p>
      )}
    </div>
  );
};

export default Summary;
