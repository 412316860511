import styled from '@emotion/styled';
import { Avatar, Chip, Tooltip, tooltipClasses } from '@mui/material';
import { useSelector } from 'react-redux';
import './ApplicantsTooltip.scss';

const ApplicantsTooltip = ({ applicants, children }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  if (!userInfo.isSuperAdmin) {
    return <>{children}</>;
  }

  return (
    <CustomTooltip
      title={<ApplicantsTooltipComponent applicants={applicants} />}
      placement="bottom"
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

const ApplicantsTooltipComponent = ({ applicants }) => {
  return applicants.length ? (
    <>
      <p className="applicants__title">Solicitantes</p>
      <ul className="applicants__list">
        {applicants.map((user) => (
          <li key={user.fullname}>
            <Chip avatar={<Avatar src={user.avatar} />} label={user.fullname} />
          </li>
        ))}
      </ul>
    </>
  ) : (
    <p className="applicants__title--empty">No hay solicitantes.</p>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid #cdcdcd',
    borderRadius: '4px',
    padding: 5,
  },
}));

export default ApplicantsTooltip;
