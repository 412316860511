import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { colorHEX } from '../../components/CustomAvatar/helpers';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ chartData }) => {
  const datasets = chartData.map((user) => ({
    label: user.fullname || user.areaName,
    data: [user.totalScore, user.competences, user.assistance, user.objetives],
    backgroundColor: colorHEX().concat('50'),
    borderColor: colorHEX(),
    borderWidth: 1.5,
  }));

  const data = {
    labels: ['Puntos Totales', 'Competenecias', 'Asistencia', 'Objetivos'],
    datasets,
  };

  const options = {
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
  };
  return (
    <div>
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChart;
