import { Link } from 'react-router-dom';
import moment from 'moment';
import './Novelty.scss';

const Novelty = ({ novelty }) => {
  return (
    <div key={novelty._id} className="novelty">
      <div className="novelty__date">
        {moment(novelty.createdAt).format('D MMM')}
      </div>
      <div className="novelty__text">
        {novelty.content} &nbsp;
        {novelty.redirect && (
          <Link to={novelty.redirectUrl} className="novelty__redirect">
            {<div className="novelty__label">{novelty.redirectLabel}</div>}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Novelty;
