import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { useDispatch } from 'react-redux';
import { deleteAction } from '../../../actions/modelActions';
import { generalDataModel } from '../../../constants/modelConstants';
import DependentAreas from '../DependetAreas';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PonderationsTooltip from '../../../components/PonderationsTooltip';
import { useState } from 'react';
import UserForm from '../../ManageUsersPage/UserForm';

const FilteredAreas = ({
  areas,
  setIsAreaFormOpen,
  setEditingArea,
  setSelectedParentArea,
  evaluation,
  companyPonderations,
}) => {
  const dispatch = useDispatch();

  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState(null);

  const getLevelName = (isManagement) => (isManagement ? 'Nivel 1' : 'Nivel 2');
  const getLevelLabel = (isManagement) => (isManagement ? 'N1' : 'N2');

  const getLevelClassName = (area) => {
    let className = 'areas-table__level';
    if (area.management) {
      className += ' areas-table__level--1';
    } else {
      if (area.withoutBoss) {
        className += ' areas-table__level--2-without-boss';
      } else {
        className += ' areas-table__level--2';
      }
    }
    return className;
  };

  const handleAddUserToArealick = (areaId) => {
    setSelectedAreaId(areaId);
    setShowAddUserForm(true);
  };

  const handleEditClick = (area) => {
    setEditingArea(area);
    setIsAreaFormOpen(true);
  };

  const handleDeleteClick = (id) => {
    dispatch(
      deleteAction(
        generalDataModel.NAME,
        `${generalDataModel.WRITE_AREA_URL}/${id}`
      )
    );
  };

  return (
    <div className="areas-table__table-container">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="areas-table__table-header">
              <TableCell>Nombre</TableCell>
              <TableCell></TableCell>
              <TableCell>Areas a cargo</TableCell>
              <TableCell>Depende de</TableCell>
              <TableCell>Ponderación</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areas?.map((area, i) => (
              <TableRow key={area.name}>
                <TableCell>
                  <div className="areas-table__name">
                    <span>{area.name}</span>
                  </div>
                </TableCell>
                <TableCell>
                  <span className="areas-table__level-icon">
                    <Tooltip
                      title={getLevelName(area.management)}
                      placement="top"
                    >
                      <span className={getLevelClassName(area)}>
                        {getLevelLabel(area.management)}
                      </span>
                    </Tooltip>
                    {area.withoutBoss && (
                      <span className="areas-table__without-boss-icon">
                        <Tooltip
                          title="Responde al nivel superior"
                          placement="top"
                        >
                          <SwapVertOutlinedIcon
                            fontSize="small"
                            htmlColor="#36b29a"
                          />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                </TableCell>
                <TableCell>
                  <DependentAreas areas={area.dependentAreas} />
                </TableCell>
                <TableCell>
                  <div className="areas-table__parent-area-name">
                    <span>{area.management ? '-' : area.parentArea?.name}</span>
                  </div>
                </TableCell>
                <TableCell>
                  <PonderationsTooltip
                    ponderations={
                      area.hasOwnPonderations
                        ? area.ponderations
                        : companyPonderations
                    }
                  >
                    <PercentOutlinedIcon fontSize="small" />
                  </PonderationsTooltip>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="high"
                    onClick={() => handleAddUserToArealick(area._id)}
                    disabled={!!evaluation}
                  >
                    <PersonAddAlt1OutlinedIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(area)}
                    disabled={!!evaluation}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteClick(area._id)}
                    disabled={!!evaluation}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showAddUserForm && (
        <UserForm
          selectedAreaId={selectedAreaId}
          setIsFormOpen={setShowAddUserForm}
        />
      )}
    </div>
  );
};

export default FilteredAreas;
