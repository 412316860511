import menu from './menu';
import { Link, NavLink } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import './SubHeader.scss';

const SubHeader = ({ location: { pathname }, stickNavbar }) => {
  const { pageLoading, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const {
    config: {
      viewDemands,
      viewSearches,
      viewBenefits,
      viewNineBox,
      viewStatistics,
    },
  } = useSelector((store) => store.generalData);

  const configValidations = {
    '/solicitudes': viewDemands,
    '/empresa/busquedas': viewSearches,
    '/empresa/area': viewNineBox,
    '/empresa/estadisticas': viewStatistics,
    '/beneficios': viewBenefits,
  };

  const isSectionActive = (section) => {
    return pathname.includes(section);
  };
  const sections = document.getElementsByClassName('subheader__section-name');
  const stickNavbarClass = stickNavbar ? 'subheader--fixed' : '';

  const showSection = (section) =>
    (!section.userInfoValidation || section.userInfoValidation(userInfo)) &&
    (!section.configValidation ||
      section.configValidation(configValidations[section.url]));

  return (
    <div className={`subheader ${stickNavbarClass}`}>
      {pageLoading && !statusError && (
        <div className="subheader__loading">
          <LinearProgress color="primary" />
        </div>
      )}
      {menu.map((section, i) =>
        !section.children
          ? showSection(section) && (
              <NavLink
                key={`${section.url}-${i}`}
                exact
                className="subheader__section"
                to={section.url}
              >
                {section.icon}
                <div className="subheader__section-name">{section.name}</div>
              </NavLink>
            )
          : showSection(section) && (
              <div
                key={`${section.url}-${i}`}
                className={`subheader__section company ${
                  isSectionActive(section.url) && 'active'
                }`}
              >
                {section.icon}
                <div className="subheader__section-name">{section.name}</div>
                <ul
                  id="company-list"
                  style={{ width: `calc(100vw / ${sections.length})` }}
                >
                  {section.children.map(
                    (child, j) =>
                      showSection(child) && (
                        <Link key={`${child.url}-${j}`} to={child.url}>
                          {child.name}
                        </Link>
                      )
                  )}
                </ul>
              </div>
            )
      )}
    </div>
  );
};

export default SubHeader;
