import { Link } from 'react-router-dom';
import moment from 'moment';
import './Congratulation.scss';

const Congratulation = ({ congratulation }) => {
  return (
    <div key={congratulation._id} className="congratulation">
      <div className="congratulation__date">
        {moment(congratulation.createdAt).format('D MMM')}
      </div>
      <div className="congratulation__text">
        {congratulation.content} &nbsp;
        {congratulation.redirect && (
          <Link
            to={congratulation.redirectUrl}
            className="congratulation__redirect"
          >
            {
              <div className="congratulation__label">
                {congratulation.redirectLabel}
              </div>
            }
          </Link>
        )}
      </div>
    </div>
  );
};

export default Congratulation;
