import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import UserAutocomplete from '../../../components/UserAutocomplete/UserAutocomplete';

import './UsersSearch.scss';

const UsersSearch = () => {
  const history = useHistory();

  const handleOnChange = (userId) =>
    userId?.length ? history.push(`/usuario/${userId}`) : null;

  return (
    <div className="users-search">
      <UserAutocomplete
        handleOnChange={handleOnChange}
        label=""
        placeholder="Buscar usuario"
        removeValueAfterSearch
      />
    </div>
  );
};

export default memo(UsersSearch);
