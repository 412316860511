import { Avatar, FormControl, TextField } from '@mui/material';
import scores from './scores';
import getAnswerLetter from '../EvaluationForm/helpers';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DirectionIcon from '../../../components/DirectionIcon';

import './CompetenceResponse.scss';

const CompetenceResponse = ({
  competence,
  isSelfEvaluation,
  data,
  answers,
  setValue,
  showComments,
  done,
}) => {
  const { user } = data;
  const isOptionSelected = (value) =>
    value === answers[competence._id][user._id].behaviour.value
      ? 'option-selected'
      : '';

  const isScoreSelected = (value) =>
    value === answers[competence._id][user._id].score.value
      ? 'score-selected'
      : '';

  const isOptionDisabled = () =>
    done ? 'competence-response__option--disabled' : '';

  const isScoreDisabled = () =>
    done ? 'competence-response__score--disabled' : '';

  return user ? (
    <div className="competence-response">
      <div className="competence-response__container">
        <div className="competence-response__user">
          <Avatar src={user.avatar} sx={{ width: 90, height: 90 }} />
          <p className="competence-response__user__name">{user.fullname}</p>
          <div className="competence-response__user__direction">
            <DirectionIcon direction={data.evaluationType} />
          </div>
        </div>
        {!showComments ? (
          <div className="competence-response__questions">
            <div className="competence-response__label">
              1 -
              {isSelfEvaluation
                ? 'Elige la opción que más te identifique:'
                : 'Elige la opción que más lo identifique:'}
            </div>
            {competence.behaviourQuestionOptions.map((option, i) => (
              <div
                key={`${option.value}-${i}`}
                className={`competence-response__option ${isOptionDisabled()}`}
                onClick={() =>
                  setValue(competence._id, user._id, 'behaviour', option)
                }
              >
                <div
                  className={`competence-response__option__circle ${isOptionSelected(
                    option.value
                  )}`}
                ></div>
                <div className="competence-response__option__label">
                  {getAnswerLetter(i)} -
                  {isSelfEvaluation
                    ? ` ${option.label}`
                    : ` ${option.labelOthers}`}
                </div>
              </div>
            ))}

            <div className="competence-response__label">
              2 -
              {isSelfEvaluation
                ? 'Tenés un máximo de 5 puntos y un mínimo de 1 punto para asignarte en esta competencia.'
                : 'Tenés un máximo de 5 puntos y un mínimo de 1 punto para asignarle en esta competencia.'}
            </div>
            <div className="competence-response__score">
              {scores.map((score) => (
                <div
                  key={score.value}
                  className={`competence-response__score__points ${isScoreSelected(
                    score.value
                  )} ${isScoreDisabled()}`}
                  onClick={() =>
                    setValue(competence._id, user._id, 'score', score)
                  }
                >
                  {score.value}
                </div>
              ))}
            </div>
            <div className="competence-response__score-max-min">
              <span className="competence-response__score-max-min__label">
                <span className="max-icon">
                  <ArrowBackOutlinedIcon color="silver" />
                </span>
                <span>Mínimo</span>
              </span>
              <span className="score-line"></span>
              <span className="competence-response__score-max-min__label">
                <span>Máximo</span>
                <span className="min-icon">
                  <ArrowForwardOutlinedIcon color="silver" />
                </span>
              </span>
            </div>
          </div>
        ) : (
          <div className="competence-response__comments">
            <FormControl fullWidth>
              <TextField
                label="Comentarios"
                name="comments"
                autoComplete="off"
                value={answers[competence._id][user._id].comments}
                onChange={(e) =>
                  setValue(competence._id, user._id, 'comments', e.target)
                }
                fullWidth
                multiline
                rows="4"
                disabled={done}
              />
            </FormControl>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="evaluation-form__empty">
      No hay roles asociados para realizar esta evaluación
    </div>
  );
};

export default CompetenceResponse;
