import { useSelector } from 'react-redux';
import ServerError from '../../components/ServerError';
import UserInfo from '../../components/UserInfo';
import PersonalData from './PersonalData';

import './PersonalDataPage.scss';

const PersonalDataPage = () => {
  const { statusError, pageLoading } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);

  if (statusError) return <ServerError />;
  if (pageLoading) return null;

  return (
    <div className="personal-data-page">
      <div className="dashboard-page_user-info-container">
        <UserInfo userInfo={userInfo} />
      </div>
      <PersonalData data={userInfo.personalData} />
    </div>
  );
};

export default PersonalDataPage;
