import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../actions/modelActions';
import { dashboardModel } from '../constants/modelConstants';
import { SocketContext } from '../context/socketContext';
import confetti from 'canvas-confetti';
import { NOTIFICATIONS_ADD_NEW } from '../constants/notificationsConstants';
import { WRITE_SUCCESS } from '../constants/actionConstants';

const useListenSocketActions = () => {
  const { socket } = useContext(SocketContext);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('comunication-created', () => {
      if (pathname === '/') {
        dispatch(getAction(dashboardModel.NAME, dashboardModel.URL, false));
      }
    });

    socket.on(`user-notification-${userInfo?._id}`, (newNotification) => {
      if (newNotification.type === 'RECOGNITION') {
        confetti({ spread: 360 });
      }
      dispatch({ type: NOTIFICATIONS_ADD_NEW, payload: newNotification });
    });

    if (userInfo?.isSuperAdmin) {
      socket.on(`pending-task-finished-${userInfo?._id}`, (data) => {
        const { config, model, payload } = data;

        dispatch({
          type: 'GENERAL_DATA_WRITE_SUCCESS',
          payload: { config },
        });

        dispatch({
          type: `${model}${WRITE_SUCCESS}`,
          payload: payload,
        });
      });
    }
  }, [dispatch, pathname, socket, userInfo]);
};

export default useListenSocketActions;
