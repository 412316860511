import { Chip, Switch, Tooltip } from '@mui/material';
import { formatDate, rolesEnum } from '../../../helpers/enums';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motives } from '../../../constants/inactivateMotives';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UserTableData = ({
  user,
  handleOnChange,
  selectedUser,
  handleSwitchChange,
}) => {
  const { evaluation } = useSelector((store) => store.generalData);

  return (
    <div
      className={`filtered-users__table__data ${
        selectedUser?._id === user._id
          ? 'filtered-users__table__data--selected'
          : ''
      } ${
        user.active && !user.area ? 'filtered-users__table__data--no-area' : ''
      }`}
    >
      <div>
        <Link to={`/usuario/${user._id}`}>{user.fullname}</Link>
        {user.inactiveMotive && (
          <span className="inactive-motive">
            <Tooltip
              title={
                motives.find((m) => m.value === user.inactiveMotive)?.label
              }
              arrow
              placement="top"
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </span>
        )}
      </div>
      <div>{user.email}</div>
      <div className="area">
        {user.area
          ? `${user.area?.name}`
          : user.active && <span className="no-area">Sin área</span>}
        {user.area && user.area?.management && (
          <Tooltip title="1er nivel" placement="top">
            <span className="flag-management">1N</span>
          </Tooltip>
        )}
      </div>
      <div className="role">{user.role ? rolesEnum[user.role] : '-'}</div>
      <div className="roleLabel">{user.roleLabel ? user.roleLabel : '-'}</div>
      <div className="entry">{user.entry ? formatDate(user.entry) : '-'}</div>
      <div className="is-admin">
        {user.isAdmin && <Chip label="admin" color="default" size="small" />}
      </div>
      <div className="is-active">
        <Switch
          onChange={() => handleSwitchChange(user)}
          checked={user.active}
          disabled={!!evaluation}
        />
      </div>
      <span className="filtered-users__table__actions">
        <span className="icon-container">
          <Tooltip title="Editar" placement="top">
            <EditIcon fontSize="small" onClick={() => handleOnChange(user)} />
          </Tooltip>
        </span>
      </span>
    </div>
  );
};

export default UserTableData;
