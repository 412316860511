import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import './ReportPage.scss';

const ReportPage = () => {
  const params = useParams();
  const { previousScores } = useSelector((store) => store.userSignIn.userInfo);

  const [currentEvaluationData, setCurrentEvaluationData] = useState({});

  useEffect(() => {
    const currentData = previousScores.find(
      (prevScore) => prevScore.evaluationName === params.evaluation
    );
    setCurrentEvaluationData(currentData);
  }, [params.evaluation, previousScores]);

  return currentEvaluationData ? (
    <div>
      ReportPage
      <div>{currentEvaluationData.evaluationName}</div>
      <div>{currentEvaluationData.assistance}</div>
      <div>{currentEvaluationData.date}</div>
      <div>{currentEvaluationData.objetives}</div>
      <div>
        <Tooltip title="Descargar Informe" placement="right" arrow>
          <IconButton
            className="download_button"
            onClick={() => window.print()}
          >
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  ) : null;
};

export default ReportPage;
