import { Button, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import { dashboardModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import './ReminderForm.scss';

const ReminderForm = ({ setIsFormOpen }) => {
  const [reminder, setReminder] = useState({ date: null, content: '' });

  const { sending, success } = useSelector((store) => store.app);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      postAction(dashboardModel.NAME, dashboardModel.REMINDER_URL, reminder)
    );
  };

  const handleContentChange = ({ target: { value } }) =>
    setReminder((prevState) => ({ ...prevState, content: value }));

  const handleDateChange = (momentDate) => {
    setReminder((prevState) => ({ ...prevState, date: momentDate }));
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Crear Recordatorio" setIsFormOpen={setIsFormOpen}>
      <div className="form-input-wrapper">
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Fecha.."
              inputFormat="DD/MM/YYYY"
              value={reminder.date}
              onChange={(momentDate) => handleDateChange(momentDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <div>
        <textarea
          className="reminder-form-textarea"
          name="reminder"
          value={reminder.content}
          onChange={handleContentChange}
          placeholder="Recordatorio..."
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={sending || !reminder.date || !reminder.content}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default ReminderForm;
