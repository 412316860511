import './Feedback.scss';

const Feedback = ({ feedback }) => {
  return (
    <div className="feedback">
      <span className="feedback__date">{feedback.date}</span>
      <p className="feedback__text">{feedback.text}</p>
    </div>
  );
};

export default Feedback;
