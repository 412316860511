import Astronauta from '../../assets/astronauta.png';

import './EmptyComponentImage.scss';

const EmptyComponentImage = ({ text }) => {
  return (
    <div className="empty-component-img">
      <div className="img-container">
        <img src={Astronauta} alt="logo" />
      </div>
      <div className="dialog">{text}</div>
    </div>
  );
};

export default EmptyComponentImage;
