import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { devolutionOptions, sourceOptions } from './options';
import { useSelector } from 'react-redux';
import './Filters.scss';

const Filters = ({
  filters,
  setFilters,
  setShowUsersWithoutGoals,
  current,
  previousEvaluations,
  usersWithoutGoals,
}) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { source, devolution, evaluation } = filters;

  const handleChange = ({ target: { name, value } }) => {
    current && setShowUsersWithoutGoals(false);
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleRemoveFilters = () => {
    current && setShowUsersWithoutGoals(false);
    setFilters({ source: 'all', devolution: 'all', evaluation: 'all' });
  };

  const isOptionDisabled = usersWithoutGoals?.length === 0;

  return (
    <div className="goals-filters">
      {userInfo.role === 'boss' && (
        <div className="goals-filters__select">
          <FormControl fullWidth size="small">
            <InputLabel>Origen</InputLabel>
            <Select
              value={source}
              label="Origen"
              name="source"
              onChange={handleChange}
              defaultValue="all"
            >
              <MenuItem value="all">Todos</MenuItem>
              {sourceOptions.map((option) => (
                <MenuItem value={option._id} key={option._id}>
                  {option.label}
                </MenuItem>
              ))}
              {current && usersWithoutGoals?.length > 0 && (
                <MenuItem
                  value="users-without-goals"
                  disabled={isOptionDisabled}
                >
                  Usuarios sin objetivos asignados
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      )}
      {current ? (
        <div className="goals-filters__select">
          <FormControl fullWidth size="small">
            <InputLabel>Devolución</InputLabel>
            <Select
              value={devolution}
              label="Devolución"
              name="devolution"
              onChange={handleChange}
              defaultValue="all"
              disabled={source === 'users-without-goals'}
            >
              <MenuItem value="all">Todos</MenuItem>
              {devolutionOptions.map((option) => (
                <MenuItem value={option._id} key={option._id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : (
        <div className="goals-filters__select">
          <FormControl fullWidth size="small">
            <InputLabel>Evaluación</InputLabel>
            <Select
              value={evaluation}
              label="Evaluación"
              name="evaluation"
              onChange={handleChange}
              defaultValue="all"
            >
              <MenuItem value="all">Todas</MenuItem>
              {previousEvaluations.map((option) => (
                <MenuItem value={option.name} key={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="goals-filters__remove-filters">
        <Button
          onClick={handleRemoveFilters}
          variant="contained"
          disabled={
            source === 'all' && devolution === 'all' && evaluation === 'all'
          }
        >
          Quitar Filtros
        </Button>
      </div>
    </div>
  );
};

export default Filters;
