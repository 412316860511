import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { goalsModel } from '../../../constants/modelConstants';
import { putAction } from '../../../actions/modelActions';
import { doneNotes, failedNotes } from '../../../helpers/goalsNotes';
import GoalDetails from '../GoalDetails/GoalDetails';

import './GoalDevolutionForm.scss';

const devolutionMaxLength = 300;

const GoalDevolutionForm = ({ setIsDevolutionFormOpen, editingGoal }) => {
  const initialForm = {
    note: '',
    state: '',
    devolution: '',
    toUserId: editingGoal.toUserId?._id,
  };

  const [formData, setFormData] = useState(initialForm);
  const { note, state, devolution } = formData;

  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'devolution' && value.length > devolutionMaxLength) return;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(
      putAction(goalsModel.NAME, `${goalsModel.URL}/${editingGoal._id}`, {
        ...formData,
      })
    );
  };

  useEffect(() => {
    if (success) {
      setIsDevolutionFormOpen(false);
    }
  }, [success, setIsDevolutionFormOpen]);

  return (
    <CustomModal
      title={`Generar Devolución`}
      setIsFormOpen={setIsDevolutionFormOpen}
    >
      <div>
        <GoalDetails goal={editingGoal} />
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <InputLabel>Estado</InputLabel>
            <Select
              value={state}
              label="Estado"
              name="state"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="done">Cumplido</MenuItem>
              <MenuItem value="failed">No Cumplido</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <InputLabel>Desempeño</InputLabel>
            <Select
              value={note}
              label="Desempeño"
              name="note"
              onChange={handleChange}
              defaultValue=""
            >
              {[...failedNotes, ...doneNotes].map((n) => (
                <MenuItem value={n.points} key={n.points}>
                  {n.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <label> Devolución final del objetivo asignado:</label>
          <textarea
            className="goal-devolution-form-textarea"
            placeholder="Devolución..."
            name="devolution"
            onChange={handleChange}
            value={devolution}
          />
          <span className="goal-devolution-form-textarea__characters">
            {devolution.length}/{devolutionMaxLength}
          </span>
        </div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={sending || !note || !state || !devolution}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default GoalDevolutionForm;
