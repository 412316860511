import Coins from '../../assets/coins.png';
import { Avatar } from '@mui/material';
import Astronauta from '../../assets/astronauta.png';
import Volver1 from '../../assets/volver1.png';
import { Link } from 'react-router-dom';
import CustomModal from '../../components/CustomModal/CustomModal';
import './GameModal.scss';

const GameModal = ({ win }) => {
  return (
    <CustomModal>
      <div className="game-modal">
        <div className="game-modal__img-container">
          <img src={Astronauta} alt="" />
        </div>
        <div className="game-modal__title">
          {win ? (
            <h1>¡¡FELICITACIONES!!</h1>
          ) : (
            <h1>MEJOR SUERTE PARA LA PRÓXIMA</h1>
          )}
        </div>

        <div className="game-modal__text">
          {win ? (
            <>
              <h3>
                Pudiste resolver juego, esperamos que te hayas divertido!!
              </h3>
              <h2>
                Ganaste <Avatar src={Coins} /> 10 monedas
              </h2>
              <h4>
                * Recordá que podrás canjearlas por beneficios
                <Link to="/beneficios">en esta sección </Link>
              </h4>
            </>
          ) : (
            <>Texto de que perdió</>
          )}
        </div>
        <div className="game-modal__back-button">
          <img
            src={Volver1}
            alt=""
            onClick={() => (window.location.href = '/')}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default GameModal;
