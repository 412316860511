import { Button, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAction, putAction } from '../../../actions/modelActions';
import {
  evaluationProcess,
  generalDataModel,
} from '../../../constants/modelConstants';
import EvaluationPending from '../EvaluationPending';

import './CloseEvaluationProcess.scss';

const CloseEvaluationProcess = ({ evaluation, hasPendingTask }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { sending } = useSelector((store) => store.app);
  const { pendingTasksToClose } = useSelector(
    (store) => store.evaluationProcess
  );

  const closeEvaluationProcess = () => {
    if (sending) return;
    dispatch(
      putAction(
        generalDataModel.NAME,
        generalDataModel.CLOSE_EVALUATION_PROCESS
      )
    );
  };

  useEffect(() => {
    dispatch(
      getAction(
        evaluationProcess.NAME,
        `${evaluationProcess.PENDING_TASKS_TO_CLOSE}/${evaluation._id}`
      )
    );
  }, [dispatch, evaluation]);

  return pendingTasksToClose ? (
    <>
      <div className="create-evaluation-process">
        <div className="create-evaluation__evaluation-exists">
          <p>Ya existe un proceso de evaluación abierto para este período</p>
          {sending && (
            <div className="sending-message__wrapper">
              <div className="sending-message">
                <p>Aguarde. Este proceso puede demorar ...</p>
                <LinearProgress />
              </div>
            </div>
          )}
          <Button
            onClick={closeEvaluationProcess}
            variant="contained"
            size="large"
            disabled={
              /* !pendingTasksToClose.isCloseActionEnabled || */
              sending || hasPendingTask
            }
          >
            {sending
              ? 'Cerrando el proceso de evaluaciones'
              : 'Cerrar proceso de evaluaciones'}
          </Button>
        </div>
        {evaluation?.isCreationEnabled && (
          <div className="create-evaluation__boss-evaluations">
            <Button
              className="create-evaluation__create-button"
              onClick={() => history.push('/crear-evaluacion')}
              variant="contained"
              size="large"
            >
              Crear evaluaciones para Jefes
            </Button>
          </div>
        )}
      </div>
      <EvaluationPending
        openProcess={false}
        pendingTasks={pendingTasksToClose}
      />
    </>
  ) : null;
};

export default CloseEvaluationProcess;
