import { Avatar, FormControl, TextField } from '@mui/material';
import DirectionIcon from '../../../components/DirectionIcon';

import './UserComents.scss';

const UserComments = ({
  data,
  answers,
  competence,
  setValue,
  done,
  reOpen,
}) => {
  const { user, postEvaluation } = data;

  return answers ? (
    <div className="competence-response__container">
      <div className="competence-response__user">
        <Avatar src={user.avatar} sx={{ width: 90, height: 90 }} />
        <p className="competence-response__user__name">{user.fullname}</p>
        <div className="competence-response__user__direction">
          <DirectionIcon direction={data.evaluationType} />
        </div>
      </div>
      <div className="competence-response__comments">
        <FormControl fullWidth>
          <TextField
            label="Comentarios"
            name="comments"
            autoComplete="off"
            value={answers[competence._id][user._id].comments}
            onChange={(e) =>
              setValue(competence._id, user._id, 'comments', e.target)
            }
            fullWidth
            multiline
            rows="4"
            disabled={done || (reOpen && !postEvaluation)}
          />
        </FormControl>
      </div>
    </div>
  ) : null;
};

export default UserComments;
