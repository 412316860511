import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { getFilteredUsers } from '../../actions/innerStateActions';
import { useSelector } from 'react-redux';

const UsersAutocomplete = ({
  handleAutocompleteChange,
  selectedUser = null,
  toUsersIds,
  toUserId,
  group,
  editingGoal,
}) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { teamUsers } = useSelector((store) => store.generalData);

  const [value, setValue] = useState('');
  const [users, setUsers] = useState(selectedUser ? [selectedUser] : []);

  const getData = useCallback(async () => {
    if (userInfo.isSuperAdmin) {
      try {
        const resp = await getFilteredUsers({ searchValue: value }, 1);
        if (userInfo.isSuperAdmin) {
          setUsers(resp.data.filter((user) => user._id !== userInfo._id));
        } else {
          setUsers(resp.data);
        }
      } catch (err) {}
    } else {
      setUsers(
        teamUsers.filter((user) =>
          (user.name.toLowerCase() || user.lastname.toLowerCase()).includes(
            value.toLowerCase()
          )
        )
      );
    }
  }, [userInfo, value, teamUsers]);

  useEffect(() => {
    if (!selectedUser) {
      getData();
    }
  }, [getData, selectedUser]);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const handleOnChange = (_, values) => {
    if (group) {
      handleAutocompleteChange(
        values.map(({ _id, name, lastname }) => ({ _id, name, lastname })),
        'toUsersIds'
      );
    } else {
      const { _id, name, lastname } = values;
      handleAutocompleteChange({ _id, name, lastname }, 'toUserId');
    }
    setValue('');
  };

  return (
    <Autocomplete
      disablePortal
      id="user-autocomplete"
      options={users}
      multiple={group}
      disabled={editingGoal && !group}
      noOptionsText="No se encontraron resultados"
      getOptionLabel={(user) => `${user.name} ${user.lastname}`}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      onChange={handleOnChange}
      value={group ? toUsersIds : toUserId}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar Usuarios..."
          name="value"
          onChange={handleChange}
          value={value}
        />
      )}
    />
  );
};

export default UsersAutocomplete;
