import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = (props) => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  return userInfo ? <Route {...props} /> : <Redirect to="/login" />;
};

export default PrivateRoute;
