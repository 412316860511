export const getFilteredAreas = (areas, filter, openProcess) => {
  const { searchValue, pendings, userInCharge } = filter;
  let newAreas = areas || [];

  if (searchValue !== '') {
    newAreas = newAreas.filter((area) =>
      area.name.toUpperCase().includes(searchValue.toUpperCase())
    );
  }

  if (pendings !== '') {
    if (openProcess) {
      if (pendings === 'with') {
        newAreas = newAreas.filter(
          (area) =>
            area.hasGoalsPendingToAssign ||
            area.hasMissingUsers ||
            area.hasRelationshipsPendings
        );
      }

      if (pendings === 'without') {
        newAreas = newAreas.filter((area) => {
          return (
            !area.hasGoalsPendingToAssign &&
            !area.hasMissingUsers &&
            !area.hasRelationshipsPendings
          );
        });
      }
    } else {
      if (pendings === 'with') {
        newAreas = newAreas.filter(
          (area) =>
            area.hasEvaluationsPendingToCreate ||
            area.hasEvaluationsPendingToDo ||
            area.hasGoalsPendingToGiveFeedback
        );
      }

      if (pendings === 'without') {
        newAreas = newAreas.filter(
          (area) =>
            !area.hasEvaluationsPendingToCreate &&
            !area.hasEvaluationsPendingToDo &&
            !area.hasGoalsPendingToGiveFeedback
        );
      }
    }
  }

  if (userInCharge !== '') {
    newAreas = newAreas.filter((area) =>
      area.usersInCharge.some((user) => user._id === userInCharge)
    );
  }

  return newAreas;
};

export const getUsersInCharge = (areas) => {
  const usersInCharge = [];
  areas.forEach((area) => {
    area.usersInCharge.forEach((user) => {
      const addedUser = usersInCharge.find(
        (userInCharge) => userInCharge._id === user._id
      );
      if (!addedUser) usersInCharge.push(user);
    });
  });
  return usersInCharge;
};
