import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import './BenefitsFilter.scss';

const BenefitsFilter = ({
  handleCategoryChange,
  filteredCategory,
  categories,
}) => {
  return (
    <div className="benefits-filter">
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <InputLabel id="category">Categoría</InputLabel>
          <Select
            labelId="category"
            id="category"
            value={filteredCategory}
            label="Categoría"
            name="category"
            onChange={handleCategoryChange}
            defaultValue=""
          >
            <MenuItem value="">Todas</MenuItem>
            {categories.map((category) => (
              <MenuItem value={category.value} key={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
export default BenefitsFilter;
