/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getAction } from '../../actions/modelActions';
import { evaluationModel } from '../../constants/modelConstants';
import EmptyComponentImage from '../../components/EmptyComponentImage';
import Evaluation from './Evaluation';
import TeamEvaluations from './TeamEvaluations';
import AddButton from '../../components/AddButton/AddButton';
import ServerError from '../../components/ServerError/ServerError';

import './UserEvaluationsPage.scss';

const UserEvaluationsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { userEvaluations, teamUsersEvaluations } = useSelector(
    (store) => store.evaluations
  );
  const { evaluation, teamUsers, floatingReminders } = useSelector(
    (store) => store.generalData
  );

  const teamBossesEvaluations = teamUsersEvaluations.filter(
    (tue) => tue.user.role === 'boss'
  );

  const teamEmployeesEvaluations = teamUsersEvaluations.filter(
    (tue) => tue.user.role === 'employee'
  );

  useEffect(() => {
    dispatch(
      getAction(
        evaluationModel.NAME,
        `${evaluationModel.USER_EVALUATION}/${userInfo._id}`
      )
    );
  }, [dispatch, userInfo]);

  // Al cargar esta pagina, dsp de completar una eval, se fija. Si ya no quedan evaluaciones por hacer, setea la prop en false en gral data
  // para que se muestre el otro mensaje en el dashboard
  useEffect(() => {
    if (userEvaluations.length > 0) {
      dispatch({
        type: 'GENERAL_DATA_WRITE_SUCCESS',
        payload: {
          floatingReminders: {
            ...floatingReminders,
            pendingEvaluations: !userEvaluations.every((ev) => ev.done),
          },
        },
      });
    }
  }, [userEvaluations]);

  // El boton se muestra si esta habilitada la creacion y soy jefe
  // Las validaciones mas detalladas se hacen en el servidor
  const evaluationCreationEnabled =
    userInfo.role === 'boss' &&
    evaluation?.isCreationEnabled &&
    teamUsers.some((user) => user.role === 'employee');

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="user-evaluations-page">
      <h3 className="user-evaluations-page__title">
        Evaluación actual
        {evaluationCreationEnabled && (
          <AddButton
            tooltip="Crear Evaluación"
            onClick={() => history.push('/crear-evaluacion')}
          />
        )}
      </h3>
      {evaluationCreationEnabled && (
        <div className="user-evaluations-page__creation-warning">
          Tenés evaluaciones pendientes de crear. Podés hacerlo haciendo click
          el botón <b>"+"</b> de arriba a la derecha o haciendo click{' '}
          <b>
            <Link to="/crear-evaluacion">acá</Link>
          </b>
          .
        </div>
      )}
      {evaluation ? (
        <>
          <Evaluation
            evaluation={evaluation}
            userEvaluations={userEvaluations}
          />

          {userInfo.role === 'boss' && (
            <>
              <h4 className="user-evaluations-page__team-evaluations-status">
                Estado de evaluaciones de tu equipo
              </h4>

              {teamBossesEvaluations.length > 0 && (
                <>
                  <h5>Jefes</h5>
                  <TeamEvaluations teamEvaluations={teamBossesEvaluations} />
                </>
              )}
              {teamEmployeesEvaluations.length > 0 && (
                <>
                  <h5>Empleados</h5>
                  <TeamEvaluations teamEvaluations={teamEmployeesEvaluations} />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <EmptyComponentImage text="Acá vas a poder ver las evaluaciones que te sean asignadas cuando el período de evaluaciones esté vigente." />
      )}
    </div>
  ) : null;
};

export default UserEvaluationsPage;
