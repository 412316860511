import { Avatar, Chip, IconButton, Rating, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAction,
  postAction,
  putAction,
} from '../../../actions/modelActions';
import { useEffect } from 'react';
import { demandModel } from '../../../constants/modelConstants';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DemandComments from './DemandComments';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { remainingDates } from '../../../helpers/getRemainingDays';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DemandEditMode from './DemandEditMode';

import './DemandModal.scss';
import ConfirmActionModal from '../../../components/ConfirmActionModal';

const DemandModal = ({ demand, setSelectedDemand }) => {
  const {
    _id,
    createUserId,
    toUserId,
    priority,
    title,
    description,
    comments,
    state,
    createdAt,
    updatedAt,
    expiredDate,
    modifications,
  } = demand;

  const dispatch = useDispatch();
  const history = useHistory();

  const { userInfo } = useSelector((store) => store.userSignIn);
  const { success } = useSelector((store) => store.app);

  const initialFormData = {
    priority,
    title,
    description,
    expiredDate,
    modifications: { userId: userInfo._id, seeing: false },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [editMode, setEditMode] = useState(false);
  const [demandDeleted, setDemandDeleted] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);

  const isMyDemand = createUserId._id === userInfo._id;
  const selfAssignment = toUserId._id === createUserId._id;

  const handlePriorityChange = (priority) => {
    setFormData({ ...formData, priority });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (_, momentDate) => {
    const formatedDate = momentDate.format('YYYY-MM-DD');
    setFormData({ ...formData, expiredDate: formatedDate });
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setFormData(initialFormData);
  };

  const handleSaveClick = () => {
    dispatch(
      putAction(demandModel.NAME, `${demandModel.URL}/${demand._id}`, {
        ...formData,
      })
    );
  };

  const handleNewCommentChange = ({ target }) => setNewComment(target.value);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendNewComment();
    }
  };

  const handleCloseClick = () => {
    setSelectedDemand(null);
  };

  const handleSendNewComment = () => {
    if (newComment.trim().length === 0) return;

    dispatch(
      postAction(
        demandModel.NAME,
        `${demandModel.NEW_COMMENT_URL}/${demand._id}`,
        {
          userId: userInfo._id,
          content: newComment,
          fullname: userInfo.fullname,
          date: moment().format('yyyy-M-D HH:mm'),
        }
      )
    );
  };

  const handleDelete = () => {
    setDemandDeleted(true);
    dispatch(deleteAction(demandModel.NAME, `${demandModel.URL}/${_id}`));
  };

  useEffect(() => {
    if (success) {
      if (demandDeleted) {
        setSelectedDemand(null);
      } else {
        setNewComment('');
        setEditMode(false);
      }
    }
  }, [demandDeleted, setSelectedDemand, success]);

  useEffect(() => {
    const unreadComments = comments.filter(
      (c) => c.userId !== userInfo._id && !c.read
    );
    const edited =
      modifications &&
      modifications.userId !== userInfo._id &&
      !modifications.seeing;

    if (unreadComments.length || edited) {
      const toUserIdNotification =
        createUserId === userInfo._id ? toUserId : createUserId;

      dispatch(
        putAction(
          demandModel.NAME,
          `${demandModel.DEMAND_SEEN}`,
          {
            demandId: demand._id,
            toUserId: toUserIdNotification,
          },
          false
        )
      );
    }
  }, [
    comments,
    createUserId,
    demand._id,
    dispatch,
    modifications,
    toUserId,
    userInfo._id,
  ]);

  return (
    <>
      <div className="demand-modal">
        <div className="demand-modal__container">
          <span className="demand-modal__close-button">
            <IconButton color="inherit" onClick={handleCloseClick}>
              <Close />
            </IconButton>
          </span>
          <div className="demand-modal__content">
            <h2>Detalle de solicitud</h2>
            <div className="demand-modal__static-data">
              {selfAssignment ? (
                <div>
                  <Chip label="Auto asignada" />
                </div>
              ) : (
                <div>
                  <b>Asignado a:</b>
                  <Chip
                    avatar={<Avatar src={toUserId.avatar} />}
                    label={`${toUserId.name} ${toUserId.lastname}`}
                    onClick={() => history.push(`/usuario/${toUserId._id}`)}
                  />
                </div>
              )}
              <div>
                <b>Creada el:</b> {moment(createdAt).format('D [de] MMMM')}
              </div>
              <div>
                <b>Ultima modificación:</b>
                {moment(updatedAt).format('D [de] MMMM')}
              </div>
              <div>
                <b>Días restantes:</b> {remainingDates(expiredDate)}
              </div>
              {state !== 'done' && (
                <div className="demand-modal__actions">
                  {!editMode && isMyDemand && state !== 'done' && (
                    <Tooltip title="Editar" placement="top" arrow>
                      <IconButton onClick={() => setEditMode(true)}>
                        <EditNoteOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!editMode && isMyDemand && (
                    <Tooltip title="Eliminar" placement="top" arrow>
                      <IconButton
                        onClick={() => setShowConfirmActionModal(true)}
                        color="error"
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {editMode && (
                    <>
                      <Tooltip title="Cancelar" placement="top" arrow>
                        <IconButton onClick={handleCancelClick}>
                          <CancelOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Guardar" placement="top" arrow>
                        <IconButton onClick={handleSaveClick}>
                          <SaveOutlinedIcon variant="contained" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="demand-modal__editable-data">
              {!editMode && (
                <>
                  <div>
                    <b>Prioridad:</b>
                    <Rating
                      name="priority"
                      value={priority}
                      icon={<CircleIcon fontSize="small" />}
                      emptyIcon={<CircleOutlinedIcon fontSize="small" />}
                      max={3}
                      readOnly
                    />
                  </div>
                  <div>
                    <b>Fecha vencimiento:</b>
                    {moment(expiredDate).format('D [de] MMMM')}
                  </div>
                  <div className="demand-modal__title">{title}</div>
                  <div className="demand-modal__description">{description}</div>
                </>
              )}
              {editMode && (
                <DemandEditMode
                  handlePriorityChange={handlePriorityChange}
                  formData={formData}
                  handleDateChange={handleDateChange}
                  handleChange={handleChange}
                />
              )}
            </div>
          </div>
          <DemandComments
            comments={comments}
            newComment={newComment}
            handleChange={handleNewCommentChange}
            handleKeyDown={handleKeyDown}
            handleSendNewComment={handleSendNewComment}
            userInfo={userInfo}
            state={state}
          />
        </div>
      </div>
      {showConfirmActionModal && (
        <ConfirmActionModal
          title="Eliminar Solicitud"
          message="Estás seguro de eliminar la solicitud?"
          handleConfirmAction={handleDelete}
          setIsFormOpen={setShowConfirmActionModal}
        />
      )}
    </>
  );
};

export default DemandModal;
