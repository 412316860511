import React from 'react';
import { permissions } from '../../helpers/permissions';
import { verifyPermission } from '../../helpers/verifyPermission';
import AssistanceForm from '../../components/AssistanceForm';
import ManageAreasPage from '../ManageAreasPage';
import ManageCompanyPage from '../ManageCompanyPage';
import ManageCompetencesPage from '../ManageCompetencesPage';
import ManageGlobalEvaluationPage from '../ManageGlobalEvaluationPage';
import ManageUsersPage from '../ManageUsersPage';

export const buildTabs = (evaluation, userInfo) => {
  const tabs = [];
  const tabsPanels = [];

  if (
    verifyPermission(userInfo, permissions.ADMIN_ASSISTANCE) ||
    userInfo.isSuperAdmin
  ) {
    tabs.push('Usuarios');
    tabsPanels.push(React.createElement(ManageUsersPage));
    tabs.push('Areas');
    tabsPanels.push(React.createElement(ManageAreasPage));
  }

  if (userInfo.isSuperAdmin) {
    tabs.push('Datos de la Empresa');
    tabsPanels.push(React.createElement(ManageCompanyPage));
    tabs.push('Proceso de Evaluaciones');
    tabsPanels.push(React.createElement(ManageGlobalEvaluationPage));
    tabs.push('Competencias');
    tabsPanels.push(React.createElement(ManageCompetencesPage));
  }

  if (evaluation) {
    if (verifyPermission(userInfo, permissions.ADMIN_ASSISTANCE)) {
      tabs.push('Presentismo');
      tabsPanels.push(React.createElement(AssistanceForm));
    }
  }

  return { tabs, tabsPanels };
};
