import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { getFilteredUsers } from './helpers';
import { InnerStateNoData } from '../../../components/InnerStateStatus';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import UserTableData from '../UserTableData';
import UserForm from '../UserForm';
import AddButton from '../../../components/AddButton';
import { useDispatch } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import LoadingModal from '../../../components/LoadingModal';
import ConfirmModal from '../../../components/ConfirmModal';

import './FilteredUsers.scss';
import { allUsersModel } from '../../../constants/modelConstants';

const defaultFilter = {
  searchValue: '',
  area: '',
  role: '',
  type: '',
  active: true,
  toAssign: false,
};

const FilteredUsers = ({
  handleOnChange,
  setIsLoadUsersFormOpen,
  selectedUser,
  areas,
  users,
}) => {
  const dispatch = useDispatch();
  const { sending } = useSelector((store) => store.app);
  const { evaluation } = useSelector((store) => store.generalData);

  const [filter, setFilter] = useState(defaultFilter);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const [usersToAssignArea, setUsersToAssignArea] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const { searchValue, area, role, type, active } = filter;

  const options = [
    {
      label: 'Agregar un nuevo Usuario',
      action: () => {
        setIsRegisterFormOpen(true);
      },
    },
    {
      label: 'Cargar Usuarios desde una planilla',
      action: () => {
        setIsLoadUsersFormOpen(true);
      },
    },
  ];

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const handleWithoutAreaFilter = () => {
    setFilter({ ...filter, toAssign: true });
  };

  const handleSwitchChange = (user) => {
    setShowConfirmModal(true);
    setCurrentUser(user);
  };

  const handleModalClose = () => {
    setShowConfirmModal(false);
    setCurrentUser({});
  };

  const handleConfirmClick = (motive) => {
    const { _id, area, role, active } = currentUser;
    setShowConfirmModal(false);
    if (active) {
      dispatch(
        putAction(allUsersModel.NAME, allUsersModel.DEACTIVATE_USER, {
          userId: _id,
          areaId: area?._id || null,
          role,
          motive,
        })
      );
    } else {
      dispatch(
        putAction(allUsersModel.NAME, allUsersModel.ACTIVATE_USER, {
          userId: _id,
        })
      );
    }
  };

  useEffect(() => {
    setFilteredUsers(getFilteredUsers(users, filter));
  }, [filter, users]);

  useEffect(() => {
    setUsersToAssignArea(
      filteredUsers?.some(
        (user) => !user.isNotEvaluable && user.active && !user.area
      )
    );
  }, [filteredUsers, setUsersToAssignArea]);

  return (
    <div className="filtered-users__table-container">
      {sending && <LoadingModal />}
      {showConfirmModal && (
        <ConfirmModal
          setIsFormOpen={handleModalClose}
          handleConfirm={handleConfirmClick}
          username={currentUser.fullname}
          action={currentUser.active}
        />
      )}
      <h3>
        Administrar Usuarios
        {!evaluation && (
          <AddButton tooltip="Agregar Usuario" withOptions options={options} />
        )}
      </h3>

      <div className="filtered-users__filters">
        <div className="filtered-users__filters__textfield">
          <FormControl fullWidth>
            <TextField
              label="Nombre, Apellido o Email"
              value={searchValue}
              name="searchValue"
              onChange={handleChange}
              size="small"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </FormControl>
        </div>
        <div className="filtered-users__filters__select">
          <FormControl fullWidth size="small">
            <InputLabel id="area">Area</InputLabel>
            <Select
              labelId="area"
              id="area"
              value={area}
              label="Area"
              name="area"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todas</MenuItem>
              {areas.map((area) => (
                <MenuItem value={area._id} key={area._id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="filtered-users__filters__select">
          <FormControl fullWidth size="small">
            <InputLabel id="role">Rol</InputLabel>
            <Select
              labelId="role"
              id="role"
              value={role}
              label="role"
              name="role"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="boss">Jefes</MenuItem>
              <MenuItem value="employee">Empleados</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filtered-users__filters__select">
          <FormControl fullWidth size="small">
            <InputLabel id="role">Tipo</InputLabel>
            <Select
              labelId="type"
              id="type"
              value={type}
              label="Tipo"
              name="type"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="true">Administradores</MenuItem>
              <MenuItem value="false">No administradores</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filtered-users__filters__select">
          <FormControl fullWidth size="small">
            <InputLabel id="active">Estado</InputLabel>
            <Select
              labelId="active"
              id="active"
              value={active.toString()}
              label="active"
              name="active"
              onChange={handleChange}
              defaultValue="true"
            >
              <MenuItem value="true">Activos</MenuItem>
              <MenuItem value="false">Inactivos</MenuItem>
            </Select>
          </FormControl>
        </div>
        {usersToAssignArea && (
          <div className="filtered-users__filters__no-area-msg">
            <div>
              Existen Usuarios sin área asignada.
              {filter.toAssign ? (
                <Button onClick={() => setFilter(defaultFilter)}>Quitar</Button>
              ) : (
                <Button onClick={handleWithoutAreaFilter}>Filtrar</Button>
              )}
            </div>
          </div>
        )}
      </div>
      {(area || searchValue || role || type) && (
        <div className="remove-filters">
          <Button onClick={() => setFilter(defaultFilter)}>
            <FilterAltOffOutlinedIcon fontSize="small" />
            <span> Quitar Filtros </span>
          </Button>
        </div>
      )}
      <div className="filtered-users__table">
        <div className="filtered-users__table__header">
          <h4>Nombre y Apellido</h4>
          <h4>Email</h4>
          <h4>Área</h4>
          <h4 className="role">Rol</h4>
          <h4 className="roleLabel">Puesto</h4>
          <h4 className="entry">Fecha de ing.</h4>
          <h4 className="is-admin">{''}</h4>
          <h4 className="is-active">Activo</h4>
          <span className="filtered-users__table__actions"></span>
        </div>
        {filteredUsers?.length > 0 &&
          filteredUsers.map((user) => (
            <UserTableData
              key={user._id}
              user={user}
              selectedUser={selectedUser}
              handleOnChange={handleOnChange}
              handleSwitchChange={handleSwitchChange}
            />
          ))}
        {!users?.length && <InnerStateNoData />}
      </div>
      {isRegisterFormOpen && (
        <UserForm
          selectedUser={selectedUser}
          setIsFormOpen={setIsRegisterFormOpen}
        />
      )}
    </div>
  );
};

export default FilteredUsers;
