import { useSelector } from 'react-redux';
import Evaluation from '../Evaluation';
import './PreviousEvaluations.scss';

const PreviousEvaluations = ({ previousScores, userId }) => {
  const { company, evaluation } = useSelector((store) => store.generalData);
  const { userData } = useSelector((store) => store.userDetail);

  return (
    <div className="previous-evaluations">
      {evaluation && (
        <div className="previous-evaluations__evaluation">
          <div className="previous-evaluations__evaluation__data">
            <div className="previous-evaluations__evaluation__name">
              Evaluación en curso
            </div>
            <p className="previous-evaluations__evaluation-in-progress">
              Una vez finalizada ser podrán ver los resultados y acceder al
              reporte.
            </p>
          </div>
        </div>
      )}
      {!company.isFirstEvaluation && !evaluation && (
        <Evaluation
          key={`evaluation-last`}
          prevScore={userData.score}
          userId={userId}
        />
      )}
      {previousScores &&
        previousScores.map((prevScore, i) => (
          <Evaluation
            key={`evaluation-${i}`}
            prevScore={prevScore}
            userId={userId}
          />
        ))}
    </div>
  );
};

export default PreviousEvaluations;
