import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import './UsersInChargeTooltip.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const UsersInChargeTooltip = ({ users, children }) => {
  const className =
    users?.length === 0 ? 'users-in-charge-tooltip__warning' : '';

  return (
    <CustomTooltip
      title={<UsersInChargeTooltipComponent users={users} />}
      arrow
      placement="right"
    >
      <span className={className}>{children}</span>
    </CustomTooltip>
  );
};

const UsersInChargeTooltipComponent = ({ users }) => {
  const history = useHistory();
  return (
    <div className="users-in-charge-tooltip">
      <p className="users-in-charge-tooltip__title">Usuarios a cargo:</p>
      {users?.length > 0 ? (
        users.map((user) => (
          <div
            key={user._id}
            className="users-in-charge-tooltip__user"
            onClick={() => history.push(`/usuario/${user._id}`)}
          >
            <p className="users-in-charge-tooltip__fullname">{user.fullname}</p>
            <p className="users-in-charge-tooltip__email">{user.email}</p>
          </div>
        ))
      ) : (
        <p className="users-in-charge-tooltip__warning">
          No hay usuarios a cargo.
        </p>
      )}
    </div>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid gray',
    borderRadius: '4px',
    padding: 0,
  },
}));

export default UsersInChargeTooltip;
