import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import { recognitionModel } from '../../../constants/modelConstants';
import UserAutocomplete from '../../../components/UserAutocomplete/UserAutocomplete';
import CustomModal from '../../../components/CustomModal';
import { UPDATE_USER_SUCCESS } from '../../../constants/userConstants';

import './RecognitionForm.scss';

const reasons = [
  'Su trabajo en equipo',
  'Su actitud positiva y buena predisposición',
  'Su compromiso y responsabilidad',
  'Su colaboración con otros sectores',
  'Su creatividad',
  'Su disposición a aprender y adquirir nuevas habilidades',
];

const RecognitionForm = ({ setIsFormOpen }) => {
  const dispatch = useDispatch();

  const initialFormState = {
    recognizedUserId: '',
    standardReasons: [],
  };

  const { sending, success } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const [formData, setFormData] = useState(initialFormState);

  const handleReasonChange = ({ target: { value, checked } }) => {
    const { standardReasons } = formData;
    let updatedStandardReasons;

    if (checked) {
      updatedStandardReasons = [...standardReasons, value.toLowerCase()];
    } else {
      updatedStandardReasons = standardReasons.filter(
        (reason) => reason !== value.toLowerCase()
      );
    }

    setFormData({
      ...formData,
      standardReasons: updatedStandardReasons,
    });
  };

  const handleRecognizedUserChange = (userId) => {
    setFormData({ ...formData, recognizedUserId: userId });
  };

  const handleSubmit = () => {
    formData.standardReasons = formData.standardReasons
      .toString()
      .replaceAll(',', ', ')
      .replace(/,(?=[^,]*$)/, ' y');

    const updateUser = () =>
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { recognitionsMade: userInfo.recognitionsMade + 1 },
      });

    dispatch(
      postAction(
        recognitionModel.NAME,
        recognitionModel.URL,
        formData,
        updateUser
      )
    );
  };

  const isChecked = (reason) => {
    formData.standardReasons.includes(reason.toLowerCase());
  };

  const recognitionsAvailable = userInfo.role === 'boss' ? 5 : 3;

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal
      title={`Realizar un Reconocimiento (${
        userInfo.recognitionsMade + 1
      }/${recognitionsAvailable})`}
      setIsFormOpen={setIsFormOpen}
    >
      <div>
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <UserAutocomplete
              handleOnChange={handleRecognizedUserChange}
              excludeId={userInfo._id}
            />
          </FormControl>
        </div>
        <div className="radio-button-group">
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Motivo
            </FormLabel>
            {reasons.map((reason) => (
              <FormControlLabel
                key={reason}
                label={reason}
                value={reason}
                control={
                  <Checkbox
                    checked={isChecked(reason)}
                    onChange={handleReasonChange}
                    label={reason}
                  />
                }
              />
            ))}
          </FormControl>
        </div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            sending ||
            !formData.recognizedUserId ||
            !formData.standardReasons.length
          }
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default RecognitionForm;
