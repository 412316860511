import { Avatar, Tooltip } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { useSelector } from 'react-redux';
import { getPopulatedUsers } from '../../../helpers/evaluationRelationships';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import './SugestedUsers.scss';

const SugestedUsers = ({ affectedUsers, setAffectedUsers, setHasChanges }) => {
  const { selectedArea } = useSelector((store) => store.areaRelationships);
  const { evaluation } = useSelector((store) => store.generalData);
  const { users } = useSelector((store) => store.users);

  const populatedUsers = getPopulatedUsers(users, affectedUsers);

  const isUserSelected = (userId) => {
    return (
      affectedUsers?.find((affected) => affected.user === userId)?.selected ||
      false
    );
  };

  const isPostEvaluation = (userId) => {
    return (
      affectedUsers?.find((affected) => affected.user === userId)
        ?.postEvaluation || false
    );
  };

  const getSugestedUserClasses = (userId) => {
    const currentUser = affectedUsers.find(
      (affected) => affected.user === userId
    );
    const classes = [];
    const baseClass = 'sugested-users__user';
    classes.push(baseClass);
    if (isUserSelected(userId)) classes.push(`${baseClass}--selected`);
    if (currentUser.isAddedUser) classes.push(`${baseClass}--added`);
    return classes.join(' ');
  };

  const handleClick = (user) => {
    const currentUser = affectedUsers.find(
      (affUser) => affUser.user === user._id
    );

    if (evaluation && currentUser.selected) {
      return;
    }

    setAffectedUsers((prev) =>
      prev.map((affectedUser) =>
        affectedUser.user === user._id
          ? {
              ...affectedUser,
              selected: !affectedUser.selected,
              postEvaluation: !!evaluation,
            }
          : affectedUser
      )
    );
    setHasChanges(true);
  };

  return (
    <div className="sugested-users">
      {populatedUsers.length > 0 ? (
        populatedUsers.map(({ user }) => (
          <div
            className={`${getSugestedUserClasses(user._id)}`}
            key={user._id}
            onClick={() => handleClick(user)}
          >
            <Avatar src={user.avatar} />
            <div className="sugested-users__user-data">
              <div className="sugested-users__user-name">{user.fullname}</div>
              <div className="sugested-users__user-role-label">
                {user.roleLabel}
              </div>
            </div>
            <div className="sugested-users__user-area">
              {user?.area?._id && user.area._id !== selectedArea._id && (
                <Tooltip title={user.area.name} placement="top" arrow>
                  <BusinessIcon />
                </Tooltip>
              )}
            </div>
            {user.isAddedUser && (
              <div className="sugested-users__added-user">
                <Tooltip title="Usuario agregado" placement="top" arrow>
                  <PersonAddAltOutlinedIcon />
                </Tooltip>
              </div>
            )}
            {isPostEvaluation(user._id) && (
              <div className="sugested-users__user--post-evaluation">
                <Tooltip
                  title="Usuario agregado post evaluación"
                  placement="top"
                  arrow
                >
                  <ErrorOutlinedIcon />
                </Tooltip>
              </div>
            )}
            {isUserSelected(user._id) && (
              <div className="sugested-users__check">
                <CheckIcon />
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="sugested-users__empty">No hay usuarios sugeridos.</p>
      )}
    </div>
  );
};

export default SugestedUsers;
