import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoginForm from './LoginForm/LoginForm';
import ForgotPasswordForm from './ForgotPassrword';
import ResetPasswordForm from './ResetPasswordForm';
import { useSelector } from 'react-redux';
import Message from '../../components/SnackBar';
import Logo from '../../assets/logo-vertical.png';
import './LoginPage.scss';

const LoginPage = (props) => {
  const [view, setView] = useState('login');
  const [rpat, setRpat] = useState(null);
  const { search } = useLocation();

  const { userInfo, message, error } = useSelector((store) => store.userSignIn);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const queryRpat = queryParams.get('rpat');
    if (queryRpat) {
      setRpat(queryRpat);
      setView('resetPassword');
    }
  }, [search]);

  useEffect(() => {
    if (userInfo) {
      const pathName = localStorage.getItem('path-name');
      if (pathName) {
        localStorage.removeItem('path-name');
        props.history.push(pathName);
      } else {
        props.history.push('/');
      }
    }
  }, [userInfo, props.history]);

  return (
    <div className="login-page">
      <div className="login-page_form-wrapper">
        <div className="login-page_form-container">
          <div className="login-page__logo">
            <img src={Logo} alt="logo" />
            <h1>Iniciar sesión</h1>
          </div>
          {view === 'login' && <LoginForm {...props} setView={setView} />}
          {view === 'forgotPassword' && (
            <ForgotPasswordForm {...props} setView={setView} />
          )}
          {view === 'resetPassword' && (
            <ResetPasswordForm {...props} rpat={rpat} setView={setView} />
          )}
        </div>
      </div>
      {error && <Message msg={error} severity="error" />}
      {message && <Message msg={message} severity="success" />}
    </div>
  );
};

export default LoginPage;
