import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { allUsersModel } from '../../../constants/modelConstants';

import './LoadUsersForm.scss';

const LoadUsersForm = ({ setIsFormOpen }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);

  const [file, setFile] = useState(null);

  const handleChange = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleClick = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/excel/download/users`);
  };

  const handleSubmit = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    dispatch(
      postAction(allUsersModel.NAME, allUsersModel.LOAD_USERS_URL, formData)
    );
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal
      title="Cargar usuarios desde planilla Excel"
      setIsFormOpen={setIsFormOpen}
    >
      <div className="load-user-form__content">
        <p>
          Para poder cargar usuarios desde una planilla Excel es necesario
          hacerlo con un formato específico.
        </p>
        <p>
          Descargá una planilla de ejemplo para cargar usuarios haciendo click
          <span className="load-user-form__link" onClick={handleClick}>
            acá
          </span>
          .
        </p>
        <div>
          <input
            type="file"
            name="excel"
            accept=".xlsx"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="load-user-form__actions">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={sending || !file}
        >
          {sending ? 'Cargando' : 'Cargar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default LoadUsersForm;
