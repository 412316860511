import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError/ServerError';
import { demandModel } from '../../constants/modelConstants';
import Demands from './Demands';

import './DemandsPage.scss';

const DemandsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const {
    config: { viewDemands },
  } = useSelector((store) => store.generalData);
  const { demands } = useSelector((store) => store.demands);

  if (!viewDemands) history.replace('/');

  useEffect(() => {
    dispatch(getAction(demandModel.NAME, demandModel.URL));
  }, [dispatch]);

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="demands-page">
      <Demands demands={demands} />
    </div>
  ) : null;
};

export default DemandsPage;
