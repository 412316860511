// READ ACTION CONSTANTS
export const READ_REQUEST = 'READ_REQUEST';
export const READ_SUCCESS = 'READ_SUCCESS';
export const READ_ERROR = 'READ_ERROR';
// WRITE ACTION CONSTANTS
export const WRITE_REQUEST = 'WRITE_REQUEST';
export const WRITE_SUCCESS = 'WRITE_SUCCESS';
export const WRITE_RESET = 'WRITE_RESET';
export const WRITE_ERROR = 'WRITE_ERROR';
// RESET STORE KEY ACTION CONSTANT
export const RESET_STORE_KEY = 'RESET_STORE_KEY';
