import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/system';
import { alphabeticOrder } from './helpers';

const AreasSelect = ({
  areasIds,
  handleAreasChange,
  stats,
  areas,
  usersIds,
}) => {
  const getAreaLabel = (areaId) => {
    return areas.find((c) => c._id === areaId).name;
  };

  const itemStyles = (currentUserId) => {
    const isAddedUser = usersIds.includes(currentUserId);
    return isAddedUser ? { color: 'silver' } : { color: 'inherit' };
  };

  return (
    <div className="form-input-wrapper">
      <FormControl fullWidth>
        <InputLabel>Areas a Evaluar</InputLabel>
        <Select
          multiple
          label="Areas a Evaluar"
          name="areasIds"
          value={areasIds}
          onChange={handleAreasChange}
          disabled={!stats}
          input={<OutlinedInput label="Areas a evaluar" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={getAreaLabel(value)} />
              ))}
            </Box>
          )}
        >
          {areas.sort(alphabeticOrder).map((area) => (
            <MenuItem key={area._id} value={area._id} style={itemStyles(area)}>
              <Checkbox checked={areasIds.includes(area._id)} />
              <ListItemText primary={area.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AreasSelect;
