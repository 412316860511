import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RelatedEvaluation from '../RelatedEvaluation';
// import { Button, Checkbox } from '@mui/material';
// import { competencesScoreDetailModel } from '../../../constants/modelConstants';
// import { postAction } from '../../../actions/modelActions';

import './RelatedEvaluationsDetail.scss';

const RelatedEvaluationsDetail = ({
  evaluation,
  userId,
  data,
  getCompetenceName,
}) => {
  // const dispatch = useDispatch();

  const { relatedEvaluationsDetail } = data;

  const { success } = useSelector((store) => store.app);

  const [selectedEvaluations, setSelectedEvaluations] = useState([]);
  // const [isAllChecked, setIsAllChecked] = useState(false);

  /* const handleClick = () => {
    dispatch(
      postAction(
        competencesScoreDetailModel.NAME,
        `${competencesScoreDetailModel.URL}/${evaluation._id}/${userId}`,
        { evaluationIds: selectedEvaluations }
      )
    );
  }; */

  /* const handleAllCheck = (e) => {
    if (!isAllChecked) {
      setSelectedEvaluations(
        relatedEvaluationsDetail.map((evaluation) => evaluation._id)
      );
    } else {
      setSelectedEvaluations([]);
    }
  }; */

  /* useEffect(() => {
    if (relatedEvaluationsDetail) {
      setIsAllChecked(
        selectedEvaluations.length === relatedEvaluationsDetail.length
      );
    } else {
      setIsAllChecked(false);
    }
  }, [relatedEvaluationsDetail, selectedEvaluations]); */

  useEffect(() => {
    if (success) {
      setSelectedEvaluations([]);
    }
  }, [success]);

  return (
    <div className="related-evaluations-detail">
      <div className="related-evaluations-detail__header">
        <span className="related-evaluations-detail__title">
          Detalle de evaluaciones:
        </span>
      </div>
      {relatedEvaluationsDetail.length > 0 ? (
        <div className="related-evaluations-detail__container">
          {relatedEvaluationsDetail.map((evaluation) => (
            <RelatedEvaluation
              key={evaluation._id}
              evaluation={evaluation}
              getCompetenceName={getCompetenceName}
              selectedEvaluations={selectedEvaluations}
              setSelectedEvaluations={setSelectedEvaluations}
            />
          ))}
        </div>
      ) : (
        <div className="related-evaluations-detail__empty">
          No se han creado evaluaciones que afecten a este usuario.
        </div>
      )}
    </div>
  );
};

export default RelatedEvaluationsDetail;
