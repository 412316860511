import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { searchModel } from '../../constants/modelConstants';
import Search from './Search';
import SearchForm from './SearchForm/SearchForm';
import AddButton from '../../components/AddButton/AddButton';
import EmptyComponentImage from '../../components/EmptyComponentImage';
import { verifyPermission } from '../../helpers/verifyPermission';
import { permissions } from '../../helpers/permissions';
import { useHistory } from 'react-router-dom';
import ServerError from '../../components/ServerError';
import ExcelIcon from '../../components/ExcelIcon';

import './SearchesPage.scss';

const CompanyPage = () => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const {
    config: { viewSearches },
  } = useSelector((store) => store.generalData);
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { searches } = useSelector((store) => store.searches);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    if (viewSearches) dispatch(getAction(searchModel.NAME, searchModel.URL));
  }, [dispatch, viewSearches]);

  if (!viewSearches) history.replace('/');

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="searches-page">
      <div className="searches-page_container">
        <h3>
          <span>Busquedas Internas</span>
          {userInfo.isSuperAdmin && searches.length > 0 && (
            <ExcelIcon entity="searches" title="postulantes" />
          )}
          {verifyPermission(userInfo, permissions.ADMIN_SEARCHES) && (
            <AddButton
              tooltip="Crear una nueva búsqueda"
              onClick={() => setIsFormOpen(true)}
            />
          )}
        </h3>
        <div className="searches-page_searches-container">
          {searches.length ? (
            searches.map((search) => (
              <Search key={search._id} search={search} />
            ))
          ) : (
            <EmptyComponentImage text="Acá vas a poder ver las búsquedas en curso dentro de tu organización." />
          )}
        </div>
      </div>
      {isFormOpen && <SearchForm setIsFormOpen={setIsFormOpen} />}
    </div>
  ) : null;
};

export default CompanyPage;
