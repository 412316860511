import CupIcon from '@mui/icons-material/EmojiEvents';
import { categoriesEnum } from '../../../helpers/enums';
import { verifyCurrentCategory } from '../../BenefitsPage/Benefits/verifyCurrentCategory';

const TotalScore = ({ prevScore, company }) => {
  const category = verifyCurrentCategory(
    { score: { totalScore: prevScore?.totalScore } },
    company?.categories
  );

  const categoryColor =
    category?.value === 'Gold'
      ? '#eac102'
      : category?.value === 'Silver'
      ? '#b8d6f1'
      : '#909ca7';

  return prevScore ? (
    <div className="previous-evaluations__evaluation__total-score">
      <p>Puntaje Total</p>
      <div className="previous-evaluations__evaluation__points">
        <span>{Math.round(prevScore?.totalScore)}</span>
      </div>
      <div className="previous-evaluations__category-wrapper">
        {category && categoriesEnum[category.value] ? (
          <>
            <CupIcon htmlColor={categoryColor} fontSize="large" />
            <span
              className="previous-evaluations__category"
              style={{
                border: `1px solid ${categoryColor}`,
                color: categoryColor,
              }}
            >
              {categoriesEnum[category.value]}
            </span>
          </>
        ) : (
          <span className="previous-evaluations__no-category">
            Sin categoría
          </span>
        )}
      </div>
    </div>
  ) : null;
};

export default TotalScore;
