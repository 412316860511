import { useState, useEffect } from 'react';
import UserAutocomplete from '../../../components/UserAutocomplete/UserAutocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import { getFilteredGoals } from '../../../actions/innerStateActions';
import GoalsTable from './GoalsTable';
import './SearchGoalsModal.scss';

const SearchGoalsModal = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [filteredGoals, setFilteredGoals] = useState([]);

  const handleOnChangeInput = (id) => setUserId(id);

  useEffect(() => {
    const getGoals = async () => {
      setLoading(true);
      const { goals } = await getFilteredGoals(userId);
      setFilteredGoals(goals);
      setLoading(false);
    };
    if (userId) {
      getGoals();
    }
  }, [userId]);

  return (
    <div>
      <div className="users-filter">
        <UserAutocomplete handleOnChange={handleOnChangeInput} />
      </div>
      {loading ? (
        <LoadingButton loading />
      ) : filteredGoals.length > 0 ? (
        <GoalsTable goals={filteredGoals} />
      ) : (
        <div className="empty-state">
          El usuario no posee objetivos asignados
        </div>
      )}
    </div>
  );
};

export default SearchGoalsModal;
