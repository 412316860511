import {
  READ_ERROR,
  READ_REQUEST,
  READ_SUCCESS,
  RESET_STORE_KEY,
  WRITE_ERROR,
  WRITE_REQUEST,
  WRITE_RESET,
  WRITE_SUCCESS,
} from '../constants/actionConstants';
import httpClient from '../http-client';

export const getGeneralDataAction = (model, url) => async (dispatch) => {
  try {
    const { data } = await httpClient.get(url);
    const { userInfo, ...rest } = data;

    dispatch({
      type: `${model}${READ_SUCCESS}`,
      payload: { ...rest },
    });

    dispatch({ type: READ_SUCCESS });

    dispatch({
      type: 'USER_SIGN_IN_SUCCESS',
      payload: { ...userInfo },
    });
  } catch (err) {
    dispatch({
      type: READ_ERROR,
      payload: err.response?.status || 500,
    });
  }
};

export const getAction =
  (model, url, showLoading = true) =>
  async (dispatch) => {
    if (showLoading) {
      dispatch({ type: READ_REQUEST });
    }

    try {
      const { data } = await httpClient.get(url);

      dispatch({ type: `${model}${READ_SUCCESS}`, payload: data });

      dispatch({ type: READ_SUCCESS });
    } catch (err) {
      dispatch({
        type: READ_ERROR,
        payload: err.response?.status || 500,
      });
    }
  };

export const postAction =
  (model, url, body, aditionalFc) => async (dispatch) => {
    dispatch({ type: WRITE_REQUEST });

    try {
      const { data } = await httpClient.post(url, body);

      dispatch({
        type: `${model}${WRITE_SUCCESS}`,
        payload: data.payload,
      });

      if (data && aditionalFc) {
        aditionalFc(data.payload);
      }

      dispatch({
        type: WRITE_SUCCESS,
        payload: data.message || 'Acción realizada correctamente',
      });

      setTimeout(() => {
        dispatch({ type: WRITE_RESET });
      }, 3000);
    } catch (err) {
      dispatch({
        type: WRITE_ERROR,
        payload: err.response?.data.message || err.message,
      });
    }
  };

export const putAction =
  (model, url, body, showMessages = true, aditionalFc) =>
  async (dispatch) => {
    if (showMessages) {
      dispatch({ type: WRITE_REQUEST });
    }

    try {
      const { data } = await httpClient.put(url, body);

      dispatch({
        type: `${model}${WRITE_SUCCESS}`,
        payload: data.payload,
      });

      if (showMessages) {
        dispatch({
          type: WRITE_SUCCESS,
          payload: data.message || 'Edición realizada correctamente',
        });
      }

      if (aditionalFc) {
        aditionalFc();
      }

      setTimeout(() => {
        dispatch({ type: WRITE_RESET });
      }, 3000);
    } catch (err) {
      if (showMessages) {
        dispatch({
          type: WRITE_ERROR,
          payload: err.response?.data.message || err.message,
        });
      }
    }
  };

export const deleteAction = (model, url) => async (dispatch) => {
  dispatch({ type: WRITE_REQUEST });

  try {
    const { data } = await httpClient.delete(url);

    dispatch({
      type: `${model}${WRITE_SUCCESS}`,
      payload: data.payload,
    });

    dispatch({
      type: WRITE_SUCCESS,
      payload: data.message || 'Eliminación realizada correctamente',
    });

    setTimeout(() => {
      dispatch({ type: WRITE_RESET });
    }, 3000);
  } catch (err) {
    dispatch({
      type: WRITE_ERROR,
      payload: err.response?.data.message || err.message,
    });
  }
};

export const postGlobalEvaluationAction =
  (model, url, body) => async (dispatch) => {
    dispatch({ type: WRITE_REQUEST });

    try {
      const { data } = await httpClient.post(url, body);

      dispatch({
        type: `${model}${WRITE_SUCCESS}`,
        payload: data.payload,
      });

      dispatch({
        type: WRITE_SUCCESS,
        payload: data.message || 'Acción realizada correctamente',
      });

      setTimeout(() => {
        dispatch({ type: WRITE_RESET });
      }, 3000);
    } catch (err) {
      dispatch({
        type: WRITE_ERROR,
        payload: err.response?.data.message || err.message,
      });
    }
  };

export const resetAction = (model, defaultData) => (dispatch) => {
  dispatch({
    type: `${model}${RESET_STORE_KEY}`,
    payload: defaultData,
  });
};

export const postUsersEvaluationAction =
  (model, url, body) => async (dispatch) => {
    dispatch({ type: WRITE_REQUEST });

    try {
      const { data } = await httpClient.post(url, body);

      dispatch({
        type: `${model}${WRITE_SUCCESS}`,
        payload: data.payload,
      });

      dispatch({
        type: WRITE_SUCCESS,
        payload: data.message || 'Acción realizada correctamente',
      });

      setTimeout(() => {
        dispatch({ type: WRITE_RESET });
      }, 3000);
    } catch (err) {
      dispatch({
        type: WRITE_ERROR,
        payload: err.response?.data.message || err.message,
      });
    }
  };
