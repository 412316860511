import NoveltyForm from '../NoveltyForm';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import Novelty from '../Novelty';
import './Novelties.scss';

const Novelties = ({ novelties, isFormOpen, setIsFormOpen }) => {
  return (
    <div className="novelties">
      {novelties.length ? (
        novelties.map((novelty) => (
          <Novelty novelty={novelty} key={novelty._id} />
        ))
      ) : (
        <EmptyComponentImage text="Acá vas a poder estar al tanto de todas las novedades de la empresa." />
      )}
      {isFormOpen && <NoveltyForm setIsFormOpen={setIsFormOpen} />}
    </div>
  );
};

export default Novelties;
