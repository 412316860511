import { Button, FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import Ponderations from '../../../components/Ponderations';
import { generalDataModel } from '../../../constants/modelConstants';
import RichText from '../../../components/RichText';

const CompanyForm = ({ company }) => {
  const { sending } = useSelector((store) => store.app);
  const { evaluation } = useSelector((store) => store.generalData);

  const dispatch = useDispatch();

  const initialForm = {
    _id: company._id,
    name: company.name,
    description: company.description,
    competences: company.competences,
    categories: company.categories,
    objetives: company.objetives,
    assistance: company.assistance,
    logo: company.logo,
    impacts: company.impacts,
  };

  const [formData, setFormData] = useState(initialForm);
  const [description, setDescription] = useState(company.description);
  const [warningMessage, setWarningMessage] = useState(null);
  const {
    name,
    competences,
    objetives,
    assistance,
    categories,
    impacts,
    // logo,
  } = formData;

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePercentageChange = ({ target: { name, value } }) => {
    if (value >= 0) {
      setFormData({ ...formData, [name]: Number(value) });
    }
  };

  const handleCategoryChange = ({ target: { name, value } }) => {
    if (value >= 0) {
      const updateCategories = categories.map((category) => {
        if (category._id === name) {
          category.minPoints = value;
        }
        return category;
      });

      setFormData({
        ...formData,
        categories: updateCategories,
      });
    }
  };

  const handleImpactChange = ({ target: { name, value } }) => {
    let totalImpacts = 0;

    Object.keys({ ...impacts, [name]: value }).forEach(
      (key) => (totalImpacts += Number(impacts[key]))
    );

    if (totalImpacts < 100 || value < impacts[name]) {
      setFormData({ ...formData, impacts: { ...impacts, [name]: value } });
    }
  };

  const handleSubmit = () => {
    let [bronzeCategory, silverCategory, goldCategory, coinsCategory] =
      categories;

    bronzeCategory.maxPoints = silverCategory.minPoints - 1;
    silverCategory.maxPoints = goldCategory.minPoints - 1;

    let totalImpacts = 0;

    Object.keys(impacts).forEach(
      (key) => (totalImpacts += Number(impacts[key]))
    );

    if (totalImpacts === 100) {
      setWarningMessage(null);
      dispatch(
        putAction(generalDataModel.NAME, generalDataModel.UPDATE_COMPANY_INFO, {
          ...formData,
          description,
          categories: [
            bronzeCategory,
            silverCategory,
            goldCategory,
            coinsCategory,
          ],
        })
      );
    } else {
      setWarningMessage('Los porcentajes de impacto deben sumar 100 %');
    }
  };

  return (
    <div className="mange-company-page__form">
      <div className="mange-company-page__form__container">
        <div className="mange-company-page__form__container__data">
          <h3>Información de la Empresa</h3>
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                name="name"
                autoComplete="off"
                value={name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="form-multiline-wrapper">
            <RichText
              placeholder="Descripción"
              content={description}
              setContent={setDescription}
            />
          </div>
        </div>
        <div className="mange-company-page__form__container__side">
          <div className="mange-company-page__flex">
            <div className="mange-company-page__form__container__ponderations">
              <Ponderations
                competences={competences}
                evaluation={evaluation}
                assistance={assistance}
                objetives={objetives}
                handlePercentageChange={handlePercentageChange}
                showtitle
              />
            </div>
            <div className="mange-company-page__form__container__categories">
              <h3>Categorías</h3>
              {categories
                .filter((category) => category.exchangeType === 'points')
                .map((category, i) => (
                  <div key={category._id} className="form-input-wrapper">
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        label={`Pts. mín. ${category.name}`}
                        name={category._id}
                        value={categories[i].minPoints}
                        onChange={handleCategoryChange}
                      />
                    </FormControl>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <h3>Impactos por tipo de evaluación</h3>

            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="Desde arriba"
                  name="DOWN"
                  value={impacts.DOWN}
                  onChange={handleImpactChange}
                  disabled={!!evaluation}
                />
              </FormControl>
            </div>

            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="Desde abajo"
                  name="UP"
                  value={impacts.UP}
                  onChange={handleImpactChange}
                  disabled={!!evaluation}
                />
              </FormControl>
            </div>

            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="Pares"
                  name="PAIR"
                  value={impacts.PAIR}
                  onChange={handleImpactChange}
                  disabled={!!evaluation}
                />
              </FormControl>
            </div>

            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="Autoevaluación"
                  name="SELF"
                  value={impacts.SELF}
                  onChange={handleImpactChange}
                  disabled={!!evaluation}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      {warningMessage && (
        <div className="mange-company-page__form__warning-message">
          {warningMessage}
        </div>
      )}
      <Button
        variant="contained"
        className="mange-company-page__form__button"
        onClick={handleSubmit}
        disabled={sending || competences + objetives + assistance !== 100}
      >
        {sending ? 'Guardando' : 'Guardar'}
      </Button>
    </div>
  );
};

export default CompanyForm;
