import Category from './Category';
import {
  conditionRiesgo,
  conditionDilema,
  conditionClave,
  conditionConfiable,
  conditionDesarrollar,
  conditionEfectivo,
  conditionEnigma,
  conditionEstrella,
  conditionImpacto,
} from './getConditions';
import { Avatar } from '@mui/material';
// import DefaultAvatar from '../../../images/default-avatar.jpg';
import UserScoresTooltip from './UserScoresTooltip';
import CustomAvatar from '../../../components/CustomAvatar';
import { useHistory } from 'react-router-dom';

import './NineBox.scss';

const NineBox = ({ evaluation, filteredUsers }) => {
  // const usersWithZeroPoints = filteredUsers.filter(() => evaluation);
  // const usersWithPoints = filteredUsers.filter(() => !evaluation);

  const usersWithZeroPoints = filteredUsers?.filter(
    (user) => user.score.competences === 0 && user.score.objetives === 0
  );
  const usersWithPoints = filteredUsers?.filter(
    (user) => user.score.competences !== 0 && user.score.objetives !== 0
  );
  const history = useHistory();

  return (
    <>
      {usersWithZeroPoints.length > 0 && (
        <div className="pointless-users-wrapper">
          <p>Usuarios sin puntos asignados por el momento:</p>
          <div className="pointless-users">
            {usersWithZeroPoints.map((user) => (
              <UserScoresTooltip user={user} key={user._id}>
                <div onClick={() => history.push(`/usuario/${user._id}`)}>
                  {user.avatar ? (
                    <Avatar src={user.avatar} className="avatar" />
                  ) : (
                    <CustomAvatar userFullname={user.fullname} />
                  )}
                </div>
              </UserScoresTooltip>
            ))}
          </div>
        </div>
      )}
      {filteredUsers.length === 0 && (
        <p className="pointless-users__empty">
          No hay usuarios asignados al área, o no hay un área seleccionada
        </p>
      )}
      <div className="nine-box">
        <div className="nine-box__y">
          <span>&uarr;</span>
          <span>OBJETIVOS</span>
          <span>&darr;</span>
        </div>
        <div className="nine-box__categories-container">
          <Category
            title="Confiable"
            filteredUsers={usersWithPoints}
            condition={conditionConfiable}
            color="#edeab4"
          />
          <Category
            title="Impacto"
            filteredUsers={usersWithPoints}
            condition={conditionImpacto}
            color="#c5d19e"
          />
          <Category
            title="Estrella"
            filteredUsers={usersWithPoints}
            condition={conditionEstrella}
            color="#96bd91"
          />
          <Category
            title="Efectivo"
            filteredUsers={usersWithPoints}
            condition={conditionEfectivo}
            color="#e5cc9f"
          />
          <Category
            title="Clave"
            filteredUsers={usersWithPoints}
            condition={conditionClave}
            color="#ecdc8f"
          />
          <Category
            title="Desarrollar"
            filteredUsers={usersWithPoints}
            condition={conditionDesarrollar}
            color="#c5d19e"
          />
          <Category
            title="Riesgo"
            filteredUsers={usersWithPoints}
            condition={conditionRiesgo}
            color="#dda495"
          />
          <Category
            title="Dilema"
            filteredUsers={usersWithPoints}
            condition={conditionDilema}
            color="#e5cc9f"
          />
          <Category
            title="Enigma"
            filteredUsers={usersWithPoints}
            condition={conditionEnigma}
            color="#edeab4"
          />
          <p className="nine-box__x">
            <span>&larr;</span>
            <span>COMPETENCIAS</span>
            <span>&rarr;</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default NineBox;
