import moment from 'moment';
import EvaluationProgress from '../EvaluationProgress/EvaluationProgress';
import CheckIcon from '@mui/icons-material/Check';
import { formatDate } from '../../../helpers/enums';
import UserEvaluation from '../UserEvaluation';
import MultipleEvaluations from '../MultipleEvaluations';

import './Evaluation.scss';

const Evaluation = ({ evaluation, userEvaluations }) => {
  const commonEvaluations = userEvaluations.filter(
    (evaluation) => !evaluation.isMultiple
  );

  const multipleEvaluations = userEvaluations.filter(
    (evaluation) => evaluation.isMultiple
  );

  const { createdAt, dateTo } = evaluation;
  const timeElapsed = moment().diff(moment(createdAt), 'days') - 1;
  const timeToExpire = moment(dateTo).diff(moment(), 'days') + 1;

  const done =
    userEvaluations.length > 1 &&
    userEvaluations.every((userEvaluation) => userEvaluation.done);

  return (
    <div className={`evaluation ${done && 'evaluation--done'}`}>
      <h2>{evaluation.name}</h2>
      <div className="evaluation__header">
        {commonEvaluations.length === 0 && multipleEvaluations.length === 0 && (
          <div>
            Comenzó un nuevo proceso de evaluación. Acá vas a ver las
            evaluaciones que te sean asignadas.
          </div>
        )}
        {commonEvaluations.length > 0 &&
          commonEvaluations.map((userEvaluation) => (
            <UserEvaluation
              key={userEvaluation._id}
              userEvaluation={userEvaluation}
            />
          ))}
        {multipleEvaluations.length > 0 && (
          <MultipleEvaluations evaluations={multipleEvaluations} />
        )}
      </div>

      <div className="evaluation__body">
        {done && userEvaluations[userEvaluations.length - 1] ? (
          <p>
            Completaste esta evaluación el
            <b style={{ marginLeft: '10px' }}>
              {formatDate(
                userEvaluations[userEvaluations.length - 1]?.dateCompleted
              )}
            </b>
          </p>
        ) : (
          <p>
            Disponible hasta el <b>{formatDate(evaluation?.dateTo)}</b>
          </p>
        )}
        <EvaluationProgress
          range={timeElapsed + timeToExpire}
          done={done}
          timeToExpire={timeToExpire}
          timeElapsed={timeElapsed}
        />
      </div>
      {done && (
        <div className="evaluation__check">
          <CheckIcon />
        </div>
      )}
    </div>
  );
};

export default Evaluation;
