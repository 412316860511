import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import './CustomModal.scss';

const CustomModal = ({ title, children, setIsFormOpen, fullWidth = false }) => {
  return (
    <div className="modal">
      <div
        className="modal__container"
        style={fullWidth ? { width: '90vw', height: '90vh' } : {}}
      >
        {title && (
          <div className="modal__header">
            <h2>{title}</h2>
            <span className="modal__header__close-button">
              <IconButton color="inherit" onClick={() => setIsFormOpen(false)}>
                <Close />
              </IconButton>
            </span>
          </div>
        )}
        <div
          className="modal__body"
          style={
            fullWidth ? { maxHeight: '100%', padding: '5px 15px 10px' } : {}
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
