import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { userModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { updateUserAction } from '../../../actions/userActions';
import { useEffect } from 'react';
import Coins from '../../../assets/coins.png';
import LoadingModal from '../../../components/LoadingModal';

import './Survey.scss';

const Survey = ({ setIsFormOpen }) => {
  const dispatch = useDispatch();

  const { success, sending } = useSelector((store) => store.app);
  const coins = 2;

  const handleClick = (emotion) => {
    dispatch(updateUserAction({ emotion, coins }, userModel.UPDATE_SURVEY));
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <>
      {sending ? (
        <LoadingModal />
      ) : (
        <CustomModal
          setIsFormOpen={setIsFormOpen}
          title="Como calificarías tu semana?"
        >
          <div className="survey">
            <div className="survey__emotions">
              <span onClick={() => handleClick(1)}>&#128532;</span>
              <span onClick={() => handleClick(2)}>&#128528;</span>
              <span onClick={() => handleClick(3)}>&#128512;</span>
              <span onClick={() => handleClick(4)}>&#128513;</span>
            </div>
            <div className="survey__footer">
              <h3>
                Respondé y ganá <Avatar src={Coins} /> {coins} monedas
              </h3>
              <h4>* Recordá que las respuestas son anónimas</h4>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default Survey;
