import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilteredUsers from './FilteredUsers/FilteredUsers';
import { getAction } from '../../actions/modelActions';
import { allUsersModel } from '../../constants/modelConstants';
import UserForm from './UserForm';
import ServerError from '../../components/ServerError';
import LoadUsersForm from './LoadUsersForm/LoadUsersForm';

import './ManageUsersPage.scss';

const ManageUsersPage = ({ history }) => {
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { areas } = useSelector((store) => store.generalData);
  const { users } = useSelector((store) => store.users);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoadUsersFormOpen, setIsLoadUsersFormOpen] = useState(false);

  const handleOnChange = (user) => {
    if (!user) {
      setSelectedUser(null);
      return;
    }
    setSelectedUser(null);
    const timeout = setTimeout(() => {
      setSelectedUser(user);
      clearTimeout(timeout);
    }, 1);
  };

  useEffect(() => {
    dispatch(getAction(allUsersModel.NAME, allUsersModel.URL));
  }, [dispatch]);

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="manage-users">
      <FilteredUsers
        history={history}
        handleOnChange={handleOnChange}
        setIsLoadUsersFormOpen={setIsLoadUsersFormOpen}
        selectedUser={selectedUser}
        areas={areas}
        users={users}
      />
      {selectedUser && (
        <UserForm selectedUser={selectedUser} setIsFormOpen={handleOnChange} />
      )}

      {isLoadUsersFormOpen && (
        <LoadUsersForm
          selectedUser={selectedUser}
          setIsFormOpen={setIsLoadUsersFormOpen}
        />
      )}
    </div>
  ) : null;
};

export default ManageUsersPage;
