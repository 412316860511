import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError/ServerError';
import UserInfo from '../../components/UserInfo';
import { userDetailModel } from '../../constants/modelConstants';
import UserDevelopmentHistory from './UserDevelopmentHistory';
import UserPersonalData from './UserPersonalData';
import Progress from '../DashboardPage/Progress';
import { getUsersTree } from '../../helpers/getAreaTree';

import './UserDetailPage.scss';
import UserGoalStatus from './UserGoalStatus';

const UserDetailPage = () => {
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { userData, userGoalStatus } = useSelector((store) => store.userDetail);
  const { areas } = useSelector((store) => store.generalData);

  const { userId } = useParams();

  console.log(userGoalStatus);

  const myArea = areas.find((area) => area._id === userInfo?.area?._id);

  const showProgress =
    (userData && userInfo.isSuperAdmin) ||
    (userData?.score &&
      getUsersTree(areas, myArea).some((user) => user._id === userData._id));

  useEffect(() => {
    dispatch(
      getAction(userDetailModel.NAME, `${userDetailModel.URL}/${userId}`)
    );
  }, [dispatch, userId]);

  if (statusError) return <ServerError />;

  return pageLoaded && userData ? (
    <div className="user-detail-page">
      <UserInfo
        userInfo={userData}
        allowUpdate={false}
        showViewActionPlanButton={userInfo._id === userId}
        showDetailButtons={userInfo._id !== userId}
      />
      <div className="user-detail-page__left-column">
        {showProgress && (
          <>
            <Progress
              user={userData}
              score={userData?.score}
              isMyProgress={userInfo._id === userData._id}
            />
            <UserGoalStatus userGoalStatus={userGoalStatus} />
          </>
        )}
      </div>
      <div className="user-detail-page__right-column">
        <div className="user-detail-page__personal-data-container">
          <h3 className="user-detail-page__title">Datos Personales</h3>
          <UserPersonalData personalData={userData.personalData} />
        </div>
        <div className="user-detail-page__development-history-container">
          <h3 className="user-detail-page__title">Historial de Desarrollo</h3>
          <UserDevelopmentHistory userData={userData} />
        </div>
      </div>
    </div>
  ) : null;
};

export default UserDetailPage;
