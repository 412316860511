import { Avatar, Button } from '@mui/material';
import { useState } from 'react';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import GoalForm from '../GoalForm/GoalForm';
import './UsersWithoutGoals.scss';

const UsersWithousGoals = ({ usersWithoutGoals }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleCreateGoalClick = (user) => {
    setSelectedUser(user);
    setIsFormOpen(true);
  };

  return usersWithoutGoals.length > 0 ? (
    <div className="user-without-goals">
      {usersWithoutGoals.map((user) => (
        <div className="user-without-goals__user" key={user._id}>
          <div>
            <Avatar src={user.avatar} />
          </div>
          <span className="name">
            {user.name} {user.lastname}
          </span>
          <Button
            variant="contained"
            onClick={() => handleCreateGoalClick(user)}
          >
            Asignar
          </Button>
        </div>
      ))}
      {isFormOpen && (
        <GoalForm setIsFormOpen={setIsFormOpen} selectedUser={selectedUser} />
      )}
    </div>
  ) : (
    <EmptyComponentImage text="Ya todos tienen objetivos asignados" />
  );
};

export default UsersWithousGoals;
