import React from 'react';
import { useSelector } from 'react-redux';
import AddButton from '../../../components/AddButton';
import './GoalsPageHeader.scss';

const GoalsPageHeader = ({ setIsFormOpen }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { evaluation } = useSelector((store) => store.generalData);

  return (
    <h3 className="goals-page__title">
      Objetivos Actuales
      {!evaluation && (userInfo.role === 'boss' || userInfo.isSuperAdmin) && (
        <AddButton
          tooltip="Crear un objetivo"
          onClick={() => setIsFormOpen(true)}
        />
      )}
    </h3>
  );
};

export default GoalsPageHeader;
