import React from 'react';
import UsersAutocomplete from '../../../components/UserAutocomplete/UsersAutocomplete';

const UsersInput = ({
  handleAutocompleteChange,
  selectedUser,
  toUserId,
  toUsersIds,
  group,
  editingGoal,
}) => {
  return (
    <div className="form-input-wrapper autocomplete">
      <UsersAutocomplete
        handleAutocompleteChange={handleAutocompleteChange}
        selectedUser={selectedUser}
        toUsersIds={toUsersIds}
        toUserId={toUserId}
        group={group}
        editingGoal={editingGoal}
      />
    </div>
  );
};

export default UsersInput;
