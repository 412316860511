import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import Benefit from '../Benefit';
import { verifyCurrentCategory } from './verifyCurrentCategory';
import CupIcon from '@mui/icons-material/EmojiEvents';
import Category from '../Category/Category';
import { categoriesEnum } from '../../../helpers/enums';
import BenefitsImage from '../../../assets/benefits.png';
// import BenefitsCredential from '../BenefitsCredential';
import NoExchangeFilter from '../NoExchangeFilter';

import './Benefits.scss';

const Benefits = ({ categories, benefits }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { evaluation } = useSelector((store) => store.generalData);

  const {
    score: { totalScore },
  } = userInfo;

  const currentCategory = verifyCurrentCategory(userInfo, categories);

  const [category, setCategory] = useState(currentCategory);

  const [filteredBenefits, setFilteredBenefits] = useState([]);

  useEffect(() => {
    if (category) {
      if (category === 'no-exchange') {
        setFilteredBenefits(
          benefits.filter(
            (benefit) =>
              benefit.isActive && benefit.exchangeType === 'no-exchange'
          )
        );
      } else {
        setFilteredBenefits(
          benefits.filter(
            (benefit) =>
              benefit.isActive && benefit.category?.value === category?.value
          )
        );
      }
    }
  }, [benefits, category, setFilteredBenefits]);

  const isCurrent = (value) => (userInfo.category === value ? 'current' : '');

  const isSelected = (value) => (category?.value === value ? 'selected' : '');

  const categoryColor =
    currentCategory?.value === 'Gold'
      ? '#ddad00'
      : currentCategory?.value === 'Silver'
      ? '#909ca7'
      : '#cd7f32';

  return (
    <div className="benefits">
      <div className="benefits__categories-container">
        {!userInfo?.isNotEvaluable && (
          <div className="benefits__user-category">
            <span className="benefits__user-category__points">
              Puntaje Total: {evaluation ? 0 : Math.round(totalScore)}
            </span>

            {categoriesEnum[currentCategory?.value] && !evaluation ? (
              <span className="benefits__user-category__category-container">
                <CupIcon htmlColor={categoryColor} fontSize="large" />

                <span
                  className="benefits__user-category__category"
                  style={{
                    border: `1px solid ${categoryColor}`,
                    color: categoryColor,
                  }}
                >
                  {categoriesEnum[currentCategory?.value]}
                </span>
              </span>
            ) : (
              <span className="progress-total-score__no-category">
                Sin categoría
              </span>
            )}
          </div>
        )}
        <div className="benefits__categories">
          <NoExchangeFilter setCategory={setCategory} />
          {categories.map((category) => (
            <Category
              key={category._id}
              category={category}
              isCurrent={isCurrent}
              isSelected={isSelected}
              setCategory={setCategory}
            />
          ))}
        </div>
      </div>

      {!userInfo?.isNotEvaluable &&
        category?.exchangeType === 'points' &&
        Math.round(totalScore) < category?.minPoints && (
          <div className="benefits__not-allowed">
            No tenés los puntos suficientes para acceder a estos beneficios.
          </div>
        )}
      {category?.exchangeType === 'points' &&
        Math.round(totalScore) >= category?.minPoints && (
          <div className="benefits__allowed">
            Podés acceder a los beneficios de esta categoría. Elegí el que más
            te guste.
          </div>
        )}

      {!userInfo?.isNotEvaluable && category?.exchangeType === 'coins' && (
        <div className="benefits__allowed">
          Acá vas a poder canjear tus monedas. Elegí el beneficio que más te
          guste.
        </div>
      )}

      <div className="benefits-container">
        {benefits.length ? (
          filteredBenefits.length ? (
            filteredBenefits.map((benefit) => (
              <Benefit
                benefit={benefit}
                key={benefit._id}
                minPoints={category?.minPoints}
              />
            ))
          ) : category ? (
            <h4 className="benefits__empty-title">
              No hay beneficios para esta categoría.
            </h4>
          ) : (
            <div className="benefits__cover-container">
              <div className="benefits__cover">
                <div className="benefits__image">
                  <img src={BenefitsImage} alt="Beneficios" />
                </div>
                <div className="benefits__text">
                  <h3>¡Descubrí tus beneficios!</h3>
                  <p>¡Bienvenido al portal de beneficios para empleados!</p>
                  <p>
                    Acá vas a encontrar una amplia gama de beneficios diseñados
                    especialmente para vos, basados en tu desempeño y logros.
                  </p>
                  <p>
                    Estamos comprometidos en brindarte un entorno laboral
                    enriquecedor donde tu bienestar y desarrollo sean nuestra
                    prioridad.
                  </p>
                  <p>
                    Explorá todas las opciones disponibles y descubrí cómo podés
                    aprovechar al máximo tu experiencia en nuestra empresa.
                  </p>
                  <p>Seleccioná una categoría para ver sus beneficios.</p>
                </div>
              </div>
            </div>
          )
        ) : (
          <EmptyComponentImage text="Acá vas a poder ver los beneficios disponibles para cada categoría." />
        )}
      </div>

      <div className="benefits__credential-examples">
        {/* {userInfo?.score?.totalScore >= 40 && (
        <BenefitsCredential user={userInfo} currentCategory={currentCategory} />
      )} */}

        {/* <BenefitsCredential
          user={userInfo}
          currentCategory={{ name: 'Bronce', value: 'Bronze' }}
        />
        <BenefitsCredential
          user={userInfo}
          currentCategory={{ name: 'Plata', value: 'Silver' }}
        />
        <BenefitsCredential
          user={userInfo}
          currentCategory={{ name: 'Oro', value: 'Gold' }}
        /> */}
      </div>
    </div>
  );
};

export default Benefits;
