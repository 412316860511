import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import CustomModal from '../CustomModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { putAction } from '../../actions/modelActions';
import { userModel } from '../../constants/modelConstants';

const PasswordModal = ({ setIsFormOpen }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
  });

  const [show, setShow] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordRepeated: false,
  });

  const [error, setError] = useState(null);

  const { oldPassword, newPassword, newPasswordRepeated } = formData;

  const handleChange = ({ target: { name, value } }) => {
    setError(null);
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = (name) => {
    setShow({ ...show, [name]: !show[name] });
  };

  const handleSubmit = () => {
    if (newPassword !== newPasswordRepeated) {
      setError('No estás repitiendo la nueva contraseña');
      return;
    }
    setError(null);

    dispatch(
      putAction(userModel.NAME, userModel.UPDATE_PASSWORD, { ...formData })
    );
  };

  const missingData =
    !oldPassword.length || !newPassword.length || !newPasswordRepeated.length;

  useEffect(() => {
    if (success) setIsFormOpen(false);
  }, [setIsFormOpen, success]);

  return (
    <CustomModal title="Cambiar contraseña" setIsFormOpen={setIsFormOpen}>
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            Contraseña anterior
          </InputLabel>
          <OutlinedInput
            label="Contraseña anterior"
            name="oldPassword"
            autoComplete="off"
            value={oldPassword}
            onChange={handleChange}
            required
            type={show.oldPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('oldPassword')}
                  edge="end"
                >
                  {show.oldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className="form-input-wrapper">
        <FormControl fullWidth error={!!error}>
          <InputLabel htmlFor="outlined-adornment-password">
            Nueva contraseña
          </InputLabel>
          <OutlinedInput
            error={!!error}
            label="Nueva contraseña"
            name="newPassword"
            autoComplete="off"
            value={newPassword}
            onChange={handleChange}
            required
            type={show.newPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('newPassword')}
                  edge="end"
                >
                  {show.newPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className="form-input-wrapper">
        <FormControl fullWidth error={!!error}>
          <InputLabel htmlFor="outlined-adornment-password">
            Repetir nueva contraseña
          </InputLabel>
          <OutlinedInput
            label="Repetir nueva contraseña"
            name="newPasswordRepeated"
            autoComplete="off"
            value={newPasswordRepeated}
            onChange={handleChange}
            required
            type={show.newPasswordRepeated ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('newPasswordRepeated')}
                  edge="end"
                >
                  {show.newPasswordRepeated ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      {error && (
        <div style={{ color: '#c47424', marginBottom: '20px' }}>{error}</div>
      )}

      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={missingData || sending}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default PasswordModal;
