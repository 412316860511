import './NoExchangeFilter.scss';

const NoExchangeFilter = ({ setCategory }) => {
  return (
    <div
      className="no-exchange-filter"
      onClick={() => setCategory('no-exchange')}
    >
      Beneficios sin categoría
    </div>
  );
};

export default NoExchangeFilter;
