import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const BackButton = ({ label = null }) => {
  const history = useHistory();

  if (label) {
    return (
      <Button
        variant="text"
        size="small"
        color="info"
        sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
        onClick={() => history.goBack()}
      >
        <KeyboardBackspaceOutlinedIcon /> <span>{label} </span>
      </Button>
    );
  }
  return (
    <IconButton
      aria-label="back"
      sx={{ color: 'white' }}
      onClick={() => history.goBack()}
    >
      <KeyboardBackspaceOutlinedIcon />
    </IconButton>
  );
};

export default BackButton;
