import styled from '@emotion/styled';
import { Tooltip, tooltipClasses } from '@mui/material';
import './PonderationsTooltip.scss';

const PonderationsTooltip = ({ ponderations, children }) => {
  return (
    <CustomTooltip
      title={<PonderationsTooltipComponent ponderations={ponderations} />}
      placement="right"
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

const PonderationsTooltipComponent = ({
  ponderations: { competences, objetives, assistance },
}) => {
  return (
    <div className="ponderations">
      <p className="ponderations__title">Ponderaciones:</p>
      <ul className="ponderations__percentages">
        <li>Competencias: {competences}% </li>
        <li>Objetivos: {objetives}%</li>
        <li>Asistencia: {assistance}% </li>
      </ul>
    </div>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1.5px solid #cdcdcd',
    borderRadius: '4px',
  },
}));

export default PonderationsTooltip;
