import Demand from '../Demand';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DemandsForm from '../DemandsForm';
import AddButton from '../../../components/AddButton/AddButton';
import useUpdateFilteredDemands from '../Hooks/useUpdateFilteredDemands';
import Filter from '../Filter/Filter';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { putAction } from '../../../actions/modelActions';
import { demandModel } from '../../../constants/modelConstants';
// import CongratsModal from '../../../components/CongratsModal';
// import { updateUserAction } from '../../../actions/userActions';
// import { userModel } from '../../../constants/modelConstants';
// import { remainingDates } from '../../../helpers/getRemainingDays';
import LoadingModal from '../../../components/LoadingModal';

import './Demands.scss';
import DemandModal from '../DemandModal';

const states = ['pending', 'wip', 'blocked', 'done'];
const translations = {
  pending: 'Pendientes',
  wip: 'En proceso',
  blocked: 'Bloqueadas',
  done: 'Finalizadas',
};

const Demands = ({ demands }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { sending, success } = useSelector((store) => store.app);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [filteredDemands, setFilteredDemands] = useState(demands);
  const [filter, setFilter] = useState('all');
  const [isDropDisabled, setIsDropDisabled] = useState(false);
  const [selectedDemand, setSelectedDemand] = useState(null);
  // const [isDoneState, setIsDoneState] = useState(false);
  // const [showCongratsModal, setShowCongratsModal] = useState(false);
  // const [coinsEarned, setCoinsEarned] = useState(0);

  const demandsStates = {
    pending: filteredDemands.filter((d) => d.state === states[0]),
    wip: filteredDemands.filter((d) => d.state === states[1]),
    blocked: filteredDemands.filter((d) => d.state === states[2]),
    done: filteredDemands.filter((d) => d.state === states[3]),
  };

  const handleClick = () => setIsFormOpen(!isFormOpen);

  useUpdateFilteredDemands(
    filter,
    demands,
    userInfo,
    setFilteredDemands,
    setIsDropDisabled
    // isDoneState,
    // setIsDoneState
    // setShowCongratsModal
  );

  const handleDragEnd = ({ source, destination }) => {
    if (!destination /*  || source.droppableId === 'done' */) return;

    if (source.droppableId !== destination.droppableId) {
      setIsDropDisabled(true);
      const state = destination.droppableId;
      const movedDemand = demandsStates[source.droppableId][source.index];
      movedDemand.state = state;
      demandsStates[state].unshift(movedDemand);

      // if (state === 'done') {
      //   setIsDoneState(true);
      //   const coins = remainingDates(movedDemand.expiredDate);

      //   dispatch(
      //     updateUserAction(
      //       { coins },
      //       `${userModel.UPDATE_COINS}/demand/${movedDemand._id}`
      //     )
      //   );

      //   setCoinsEarned(coins);
      // } else {
      dispatch(
        putAction(demandModel.NAME, `${demandModel.URL}/${movedDemand._id}`, {
          state,
          createUserId: movedDemand?.createUserId?._id,
        })
      );
      // }
    }

    setFilteredDemands((prevDemands) => [...prevDemands]);
  };

  const isNotDemandForMe = (demand) => demand.toUserId._id !== userInfo._id;

  useEffect(() => {
    if (success && selectedDemand) {
      const updatedDemand = demands.find(
        (demand) => demand._id === selectedDemand._id
      );
      setSelectedDemand(updatedDemand);
    }
  }, [selectedDemand, demands, success]);

  return (
    <div className="demands">
      {sending && <LoadingModal />}
      <div style={{ display: 'flex' }}>
        <Filter setFilter={setFilter} filter={filter} />
        <div>
          <AddButton tooltip="Crear una solicitud" onClick={handleClick} />
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="demands__columns-container">
          {states.map((state) => (
            <div
              key={state}
              className={`demands__column demands__column-${state}`}
            >
              <h3>{translations[state]}</h3>
              <Droppable droppableId={state} isDropDisabled={isDropDisabled}>
                {(droppableProvided) => (
                  <div
                    className={`demands__column__cards`}
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    {demandsStates[state].map((demand, i) => (
                      <Draggable
                        key={demand._id}
                        draggableId={demand._id}
                        index={i}
                        isDragDisabled={
                          isDropDisabled || isNotDemandForMe(demand)
                        }
                      >
                        {(draggableProvided) => (
                          <div
                            className="demands__column__demand"
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Demand
                              demand={demand}
                              setSelectedDemand={setSelectedDemand}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
      {isFormOpen && <DemandsForm setIsFormOpen={setIsFormOpen} />}
      {selectedDemand && (
        <DemandModal
          demand={selectedDemand}
          setSelectedDemand={setSelectedDemand}
        />
      )}
      {/* {showCongratsModal && (
        <CongratsModal
          setIsFormOpen={setShowCongratsModal}
          coinsEarned={coinsEarned}
        />
      )} */}
    </div>
  );
};

export default Demands;
