// Pages
import DashboardPage from './DashboardPage';
import UserEvaluationsPage from './UserEvaluationsPage';
import EvaluationResponsePage from './EvaluationResponsePage';
import CurrentGoalsPage from './GoalsPage/CurrentGoalsPage';
import PreviousGoalsPage from './GoalsPage/PreviousGoalsPage';
import RecognitionsPage from './RecognitionsPage';
import BenefitsPage from './BenefitsPage';
import DemandsPage from './DemandsPage';
import SearchesPage from './SearchesPage';
import PersonalDataPage from './PersonalDataPage';
import CompanyPage from './CompanyPage';
import UserDetailPage from './UserDetailPage';
import AreaPage from './AreaPage';
import BenefitsAdminPage from './BenefitsAdminPage';
import ConfigPage from './ConfigPage';
import CompanyChartPage from './CompanyChartPage';
import EvaluationsHistoryPage from './EvaluationsHistoryPage';
import ReportPage from './ReportPage';
import ManageEvaluationsPage from './ManageEvaluationsPage';
import CompanyStatsPage from './CompanyStatsPage';
import StatusPage from './StatusPage';
import CalendarPage from './CalendarPage';
import AreaRelationships from './AreaRelationships';
import CompetencesScoreDetail from './CompetencesScoreDetail';
import EvaluationsPage from './EvaluationsPage';
import NewPostPage from './NewPostPage/NewPostPage';
// import PostsPage from './PostsPage';
// import QuizPage from './QuizPage/QuizPage';

const routes = [
  {
    path: '/evaluaciones/:userEvaluationId',
    component: EvaluationResponsePage,
    exact: false,
  },
  {
    path: '/evaluaciones',
    component: UserEvaluationsPage,
    exact: false,
  },
  {
    path: '/crear-evaluacion',
    component: ManageEvaluationsPage,
    exact: false,
  },
  {
    path: '/informes/:evaluation',
    component: ReportPage,
    exact: false,
  },
  {
    path: '/objetivos/actuales',
    component: CurrentGoalsPage,
    exact: true,
  },
  {
    path: '/objetivos/anteriores',
    component: PreviousGoalsPage,
    exact: true,
  },
  {
    path: '/solicitudes',
    component: DemandsPage,
    exact: false,
  },
  {
    path: '/reconocimientos',
    component: RecognitionsPage,
    exact: false,
  },
  {
    path: '/beneficios',
    component: BenefitsPage,
    exact: true,
  },
  {
    path: '/calendario',
    component: CalendarPage,
    exact: true,
  },
  {
    path: '/beneficios/adm',
    component: BenefitsAdminPage,
    exact: true,
  },
  {
    path: '/empresa/conocenos',
    component: CompanyPage,
    exact: false,
  },
  {
    path: '/empresa/busquedas',
    component: SearchesPage,
    exact: false,
  },
  {
    path: '/empresa/area',
    component: AreaPage,
    exact: false,
  },
  {
    path: '/empresa/mapa-de-areas',
    component: CompanyChartPage,
    exact: false,
  },
  {
    path: '/empresa/estadisticas',
    component: CompanyStatsPage,
    exact: false,
  },
  {
    path: '/empresa/status',
    component: StatusPage,
    exact: false,
  },
  {
    path: '/configuraciones',
    component: ConfigPage,
    exact: false,
  },
  {
    path: '/datos-personales',
    component: PersonalDataPage,
    exact: false,
  },
  {
    path: '/usuario/:userId',
    component: UserDetailPage,
    exact: false,
  },
  {
    path: '/historial-evaluaciones/:userId',
    component: EvaluationsHistoryPage,
    exact: false,
  },
  {
    path: '/posts/nuevo',
    component: NewPostPage,
    exact: false,
  },
  // {
  //   path: '/posts',
  //   component: PostsPage,
  //   exact: false,
  // },
  {
    path: '/area/relaciones/:areaId',
    component: AreaRelationships,
    exact: false,
  },
  {
    path: '/evaluaciones-actuales/:userId',
    component: EvaluationsPage,
    exact: false,
  },
  {
    path: '/impacto360/:evaluationId/:userId',
    component: CompetencesScoreDetail,
    exact: false,
  },
  // {
  //   path: '/quizgame',
  //   component: QuizPage,
  //   exact: false,
  // },
  {
    path: '/',
    component: DashboardPage,
    exact: true,
  },
];

export default routes;
