import { useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import CanvasPreview from './CanvasPreview';
import useDebounceEffect from './useDebounceEffect';
import { Button } from '@mui/material';
import { userModel } from '../../constants/modelConstants';
import { updateUserAction } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import base64ToFile from './base64ToFile';

import 'react-image-crop/dist/ReactCrop.css';
import './CropImage.scss';

const CropImage = ({ userId, aspect, roundedPreview }) => {
  const dispatch = useDispatch();
  const { sending } = useSelector((store) => store.app);
  const [imgSrc, setImgSrc] = useState('');
  const [imgOriginalName, setImgOriginalName] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [error, setError] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || '')
      );
      reader.readAsDataURL(e.target.files[0]);
      setImgOriginalName(e.target.files[0].name);
    }
  };

  const onImageLoad = (e) => {
    imgRef.current = e.currentTarget;

    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const handleSubmit = async () => {
    setError(null);
    const imageBase64 = previewCanvasRef.current.toDataURL();
    let y = 1;
    if (imageBase64.endsWith('==')) {
      y = 2;
    }
    const xSize = imageBase64.length * (3 / 4) - y;
    const finalSize = Math.round(xSize / 1024);
    const extension = imgOriginalName.split('.')[1];

    if (finalSize > 50000) {
      setError('Tamaño de imágen excedido.');
      return;
    }

    const blob = base64ToFile(
      previewCanvasRef.current.toDataURL(),
      `${userId}.${extension}`
    );
    const newAvatar = new FormData();
    newAvatar.append('image', blob);

    dispatch(updateUserAction(newAvatar, userModel.UPDATE_AVATAR));
  };

  return (
    <div className="crop-image">
      <div className="crop-image__input-file">
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <p className="crop-image__message">Máximo tamaño permitido: 2MB</p>
      <div className="crop-image__images-container">
        <div>
          {Boolean(imgSrc) && (
            <ReactCrop
              className="crop-image__original-image"
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img alt="Crop" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
          {error && <p className="crop-image__error">{error}</p>}
        </div>
        <div className="crop-image__croped-image">
          {completedCrop && <h3>Vista previa</h3>}
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid silver',
              objectFit: 'contain',
              width: completedCrop ? 220 : 0,
              height: completedCrop ? 220 : 0,
              borderRadius: roundedPreview ? '50%' : '0',
            }}
          />
        </div>
      </div>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={sending || !completedCrop}
      >
        {sending ? 'Guardando...' : 'Guardar'}
      </Button>
    </div>
  );
};

export default CropImage;
