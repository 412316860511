import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const EvaluationSelect = ({
  evaluationId,
  handleEvaluationChange,
  stats,
  evaluations,
}) => {
  return (
    <div className="form-input-wrapper">
      <FormControl fullWidth>
        <InputLabel>Evaluación</InputLabel>
        <Select
          value={evaluationId}
          label="Evaluación"
          name="evaluationId"
          onChange={handleEvaluationChange}
          disabled={!stats}
        >
          {evaluations.map((evaluation) => (
            <MenuItem value={evaluation._id} key={evaluation._id}>
              {evaluation.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default EvaluationSelect;
