import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import CalendarPicker from '../CalendarPicker';
import MonthMode from '../MonthMode';
import WeekMode from '../WeekMode';

import './Calendar.scss';

const initialSelectedDay = {
  isoString: moment().toISOString(),
  number: moment().date(),
  currentMonth: true,
  selected: true,
  today: true,
  events: [],
};

const Calendar = () => {
  const [mode, setMode] = useState('week');
  const [referenceDay, setReferenceDay] = useState(moment());
  const [selectedDay, setSelectedDay] = useState(initialSelectedDay);

  const handleModeChange = ({ target }) => setMode(target.value);

  return (
    <div className="calendar">
      <div className="calendar__select-mode">
        <FormControl>
          <InputLabel>Vista</InputLabel>
          <Select
            value={mode}
            label="Vista"
            name="area"
            onChange={handleModeChange}
            size="small"
          >
            <MenuItem value="week">Semana </MenuItem>
            <MenuItem value="month">Mes </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="calendar__container">
        {mode === 'week' && (
          <div className="calendar__picker-container">
            <CalendarPicker
              setReferenceDay={setReferenceDay}
              referenceDay={referenceDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
          </div>
        )}
        <div className="calendar__mode-container">
          {mode === 'week' && (
            <WeekMode
              setReferenceDay={setReferenceDay}
              referenceDay={referenceDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
          )}
          {mode === 'month' && (
            <MonthMode
              setReferenceDay={setReferenceDay}
              referenceDay={referenceDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
