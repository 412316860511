import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { recognitionModel } from '../../../constants/modelConstants';
import ReactionsQtyTooltip from './ReactionsQtyTooltip';
import { WRITE_SUCCESS } from '../../../constants/actionConstants';
import { useEffect, useRef, useState } from 'react';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import { Tooltip } from '@mui/material';

import './RecognitionReactions.scss';

const reactions = [
  {
    type: 'love',
    img: '❤️',
  },
  {
    type: 'clap',
    img: '👏',
  },
  {
    type: 'like',
    img: '👍',
  },
  {
    type: 'party',
    img: '🎉',
  },
];

const RecognitionReactions = ({ recognition, recognitions }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);

  const { userInfo } = useSelector((store) => store.userSignIn);
  const [showIcons, setShowIcons] = useState(false);

  const existsReaction = (reactionType) =>
    recognition.reactions.some(
      (r) => r.userId === userInfo._id && r.type === reactionType
    );

  const handleReactionClick = (reactionType) => {
    if (existsReaction(reactionType)) return;

    const updatedRecognition = {
      ...recognition,
      reactions: [
        ...recognition.reactions,
        {
          userId: userInfo._id,
          type: reactionType,
          fullname: userInfo.fullname,
        },
      ],
    };
    const updatedRecognitions = recognitions.map((r) =>
      r._id === recognition._id ? updatedRecognition : r
    );
    dispatch({
      type: `${recognitionModel.NAME}${WRITE_SUCCESS}`,
      payload: { recognitions: updatedRecognitions },
    });
    dispatch(
      putAction(
        recognitionModel.NAME,
        `${recognitionModel.URL}/${recognition._id}`,
        { reactionType },
        false
      )
    );
    setShowIcons(false);
  };

  const getRelatedReactions = (reactionType) => {
    return recognition.reactions?.filter(
      (reaction) => reaction.type === reactionType
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        setShowIcons(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef]);

  return (
    <div ref={elementRef}>
      <div className="recognition__reactions">
        <div className="recognition__reactions--list">
          {reactions.map(
            (r) =>
              recognition.reactions.some(({ type }) => type === r.type) && (
                <ReactionsQtyTooltip
                  reactions={getRelatedReactions(r.type)}
                  userId={userInfo._id}
                  key={r.type}
                >
                  <div
                    className={`recognition__reactions--reaction ${
                      existsReaction(r.type) ? 'reacted' : ''
                    }`}
                    onClick={() => handleReactionClick(r.type)}
                  >
                    <span className="recognition__reactions--reaction-icon">
                      {r.img}
                    </span>
                    <span className="recognition__reactions--reaction-qty">
                      {getRelatedReactions(r.type).length}
                    </span>
                  </div>
                </ReactionsQtyTooltip>
              )
          )}
        </div>
        <Tooltip title="Reaccionar" placement="bottom" arrow>
          <div
            className="add-reaction-icon"
            onClick={() => setShowIcons((prev) => !prev)}
          >
            <AddReactionOutlinedIcon fontSize="small" />
          </div>
        </Tooltip>
      </div>
      <div className={`select-reaction-icons ${showIcons && 'show'}`}>
        {reactions.map((r, i) => (
          <span
            key={`${r.type}-${i}`}
            onClick={() => handleReactionClick(r.type)}
            className={`icon ${existsReaction(r.type) ? 'disabled' : ''}`}
          >
            {r.img}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RecognitionReactions;
