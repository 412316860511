import { Avatar, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CompetenceResponse from '../CompetenceResponse';
import buildInitialState from './buildInitialState';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import formValidation from './formValidation';
import CompetenceMultipleResponse from '../CompetenceMultipleResponse';

import './EvaluationForm.scss';
import buildNewInitialState from './buildNewInitialState';

const EvaluationForm = ({
  competences,
  answers: prevAnswers,
  isSelfEvaluation,
  withBonusPoint,
  affectedUsers = [],
  sendResponse,
  done,
  reOpen,
}) => {
  const { sending, success } = useSelector((store) => store.app);
  const history = useHistory();
  const topRef = useRef(null);

  const [page, setPage] = useState(1);
  const [currentCompetence, setCurrentCompetence] = useState(
    competences[page - 1]
  );
  const [answers, setAnswers] = useState(null);
  const [showComments, setShowComments] = useState(false);

  const setValue = (competenceId, userId, key, target) => {
    if (!done) {
      const newAnswersState = { ...answers };
      if (key === 'comments') {
        newAnswersState[competenceId][userId][key] = target.value;
      } else {
        newAnswersState[competenceId][userId][key].value = target.value;
        newAnswersState[competenceId][userId][key].points = target.points;
      }
      setAnswers(newAnswersState);
    }
  };

  const setBonusPointValue = (competenceId, value) => {
    if (!done) {
      const newAnswersState = { ...answers };
      newAnswersState[competenceId].bonusPointValue = value;
      setAnswers(newAnswersState);
    }
  };

  const isUserSelected = (userId) =>
    userId === answers[currentCompetence._id].bonusPointValue
      ? 'user-selected'
      : '';

  const isSomeUserSelected = () =>
    !!answers[currentCompetence._id].bonusPointValue;

  const isUserDisabled = () =>
    done ? 'evaluation-form__bonus__user--disabled' : '';

  const isCurrentPage = (i) =>
    i === page - 1 ? 'evaluation-form__dot--current' : '';

  const changePage = (direction) => {
    if (direction === 'next') {
      setCurrentCompetence(competences[page]);
      setPage(page + 1);
    } else {
      setCurrentCompetence(competences[page - 2]);
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (!answers) {
      if (!prevAnswers) {
        const initialState = buildInitialState(competences, affectedUsers);
        setAnswers(initialState);
      } else {
        let newState = prevAnswers;
        const postEvaluationUsers = affectedUsers.filter(
          (affUser) => affUser.postEvaluation
        );
        if (postEvaluationUsers.length && reOpen) {
          newState = buildNewInitialState(prevAnswers, postEvaluationUsers);
        }
        setAnswers(newState);
      }
    }
  }, [answers, prevAnswers, competences, affectedUsers, reOpen]);

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        history.push('/evaluaciones');
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [success, history]);

  useEffect(() => {
    window.scrollTo({ top: 180, left: 0, behavior: 'smooth' });
  }, [page]);

  return affectedUsers?.length ? (
    <div className="evaluation-form" ref={topRef}>
      {!showComments ? (
        <>
          <h3 className="evaluation-form__competence-name" id="competence-name">
            <span>{currentCompetence.name}</span>
            <span className="evaluation-form__page">{`Competencia ${page} de ${competences.length}`}</span>
          </h3>
          <p className="evaluation-form__competence-description">
            {currentCompetence.description}
          </p>
        </>
      ) : (
        <>
          <h3 className="evaluation-form__comments-title">Comentarios</h3>
          <p className="evaluation-form__comments-description">
            Los comentarios son opcionales y te permiten agregar el feedback
            adicional que consideres necesario.
          </p>
        </>
      )}

      {answers && affectedUsers.length === 1 ? (
        <CompetenceResponse
          competence={currentCompetence}
          isSelfEvaluation={isSelfEvaluation}
          data={affectedUsers[0]}
          answers={answers}
          showComments={showComments}
          setValue={setValue}
          done={done}
          reOpen={reOpen}
        />
      ) : (
        <CompetenceMultipleResponse
          competence={currentCompetence}
          affectedUsers={affectedUsers}
          answers={answers}
          showComments={showComments}
          setValue={setValue}
          done={done}
          reOpen={reOpen}
        />
      )}
      {answers && withBonusPoint && !showComments && (
        <div className="evaluation-form__bonus">
          <div className="evaluation-form__bonus__title">
            Punto Bonus
            {isSomeUserSelected() && !done && (
              <span
                className="evaluation-form__bonus__unselect"
                onClick={() => setBonusPointValue(currentCompetence._id, null)}
              >
                Deshacer selección
              </span>
            )}
          </div>
          <div className="evaluation-form__bonus__label">
            <p>{currentCompetence.bonusQuestionLabel.split(':')[0]}:</p>
          </div>
          <div className="evaluation-form__bonus__users">
            {affectedUsers.map((data) => (
              <div
                key={`bonus-${data.user._id}`}
                className={`evaluation-form__bonus__user ${isUserSelected(
                  data.user._id
                )} ${isUserDisabled()}`}
                onClick={() =>
                  setBonusPointValue(currentCompetence._id, data.user._id)
                }
              >
                <Avatar src={data.user.avatar} sx={{ width: 90, height: 90 }} />
                <p className="evaluation-form__bonus__user__name">
                  {data.user.fullname}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="evaluation-form__footer">
        {page - 1 > 0 ? (
          <Button
            onClick={() =>
              !showComments ? changePage('prev') : setShowComments(false)
            }
          >
            Anterior
          </Button>
        ) : (
          <div></div>
        )}

        {!showComments && (
          <div className="evaluation-form__dots">
            {competences.map((c, i) => (
              <div
                key={c._id}
                className={`evaluation-form__dot ${isCurrentPage(i)}`}
              ></div>
            ))}
          </div>
        )}

        {(!isSelfEvaluation && !showComments) ||
        (isSelfEvaluation && page < competences.length) ? (
          <Button
            onClick={() =>
              page < competences.length
                ? changePage('next')
                : !isSelfEvaluation && setShowComments(true)
            }
            disabled={formValidation(currentCompetence._id, answers)}
          >
            Siguiente
          </Button>
        ) : (
          !done && (
            <Button
              onClick={() => sendResponse(answers)}
              disabled={
                sending ||
                success ||
                formValidation(currentCompetence._id, answers)
              }
              className="evaluation-form__submit-button"
            >
              {sending ? 'Guardando' : 'Guardar'}
            </Button>
          )
        )}
      </div>
    </div>
  ) : (
    <div className="evaluation-form__empty">
      No es posible realizar la evaluación por falta de datos
    </div>
  );
};

export default EvaluationForm;
