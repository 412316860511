export const getFilteredUsers = (users, filter) => {
  const { searchValue, area, role, type, active, toAssign } = filter;
  let newUsers = users || [];

  if (searchValue !== '') {
    newUsers = newUsers.filter(
      (user) =>
        user.fullname.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.email.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  if (area !== '') {
    newUsers = newUsers.filter((user) => user.area?._id === area);
  }

  if (role !== '') {
    newUsers = newUsers.filter((user) => user.role === role);
  }

  if (type !== '') {
    newUsers = newUsers.filter((user) => user.isAdmin.toString() === type);
  }

  if (toAssign) {
    newUsers = newUsers.filter(
      (user) => !user.isNotEvaluable && user.active && !user.area
    );
  }

  newUsers = newUsers.filter(
    (user) => user.active.toString() === active.toString()
  );

  return newUsers;
};
