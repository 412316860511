import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EmptyComponentImage from '../../components/EmptyComponentImage';
import PreviousEvaluations from './PreviousEvaluations/PreviousEvaluations';
import { getAction } from '../../actions/modelActions';
import { userDetailModel } from '../../constants/modelConstants';
import ServerError from '../../components/ServerError/ServerError';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getUsersTree } from '../../helpers/getAreaTree';

import './EvaluationsHistoryPage.scss';

const EvaluationsHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { userData } = useSelector((store) => store.userDetail);
  const { company, teamUsers, areas } = useSelector(
    (store) => store.generalData
  );

  const { userId } = useParams();

  const myArea = areas.find((area) => area._id === userInfo?.area?._id);

  useEffect(() => {
    if (
      userInfo.isSuperAdmin ||
      userId === userInfo._id ||
      getUsersTree(areas, myArea).some((user) => user._id === userId)
    ) {
      dispatch(
        getAction(userDetailModel.NAME, `${userDetailModel.URL}/${userId}`)
      );
    } else {
      return history.replace('/');
    }
  }, [dispatch, history, teamUsers, userId, userInfo, areas, myArea]);

  if (statusError) return <ServerError />;

  return userData && pageLoaded ? (
    <div className="evaluations-history-page">
      <h3 className="evaluations-history-page__title">
        Historial de Evaluaciones - {userData.fullname}
      </h3>
      <div>
        {userData.previousScores?.length || !company.isFirstEvaluation ? (
          <PreviousEvaluations
            previousScores={userData.previousScores}
            userId={userId}
          />
        ) : (
          <EmptyComponentImage text="Acá vas a poder ver Tu historial de resultados de Evaluaciones. " />
        )}
      </div>
    </div>
  ) : null;
};

export default EvaluationsHistory;
