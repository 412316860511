import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@mui/material';
import './CompanyStatsPage.scss';
import RadarChart from './RadarChart';
import { useDispatch } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { chartsModel } from '../../constants/modelConstants';
import { useSelector } from 'react-redux';
import LineChart from './LineChart';
import EvaluationsSelect from './EvaluationsSelect';
import EvaluationSelect from './EvaluationSelect';
import UsersSelect from './UsersSelect';
import AreasSelect from './AreasSelect';
import ServerError from '../../components/ServerError/ServerError';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getUsersTree } from '../../helpers/getAreaTree';

const CompanyStatsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    config: { viewStatistics },
    areas: gralDataAreas,
  } = useSelector((store) => store.generalData);
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { evaluations, users, areas, data } = useSelector(
    (store) => store.chartsData
  );
  const { pageLoading } = useSelector((store) => store.app);

  const myArea = gralDataAreas?.find(
    (area) => area._id === userInfo?.area?._id
  );

  const [stats, setStats] = useState('');
  const [availableUsers, setAvailableUsers] = useState([]);
  const [evaluationId, setEvaluationId] = useState('');
  const [evaluationsIds, setEvaluationsIds] = useState([]);
  const [usersIds, setUsersIds] = useState([]);
  const [areasIds, setAreasIds] = useState([]);

  const handleStatsChange = ({ target }) => {
    dispatch({ type: 'CHART_READ_SUCCESS', payload: { data: null } });
    setStats(target.value);
  };
  const handleUsersChange = ({ target }) => setUsersIds(target.value);
  const handleAreasChange = ({ target }) => setAreasIds(target.value);
  const handleEvaluationChange = ({ target }) => setEvaluationId(target.value);
  const handleMutipleEvaluationsChange = ({ target }) =>
    setEvaluationsIds(target.value);
  const pointsParams = ['assistance', 'objetives', 'competences'];

  const handleClick = () => {
    const ids =
      stats === 'user-points' || pointsParams.includes(stats)
        ? usersIds
        : areasIds;

    dispatch(
      getAction(
        chartsModel.NAME,
        `${chartsModel.URL}/${stats}?ids=${ids}&evaluation=${evaluationId}&evaluations=${evaluationsIds}`
      )
    );
  };

  useEffect(() => {
    dispatch(getAction(chartsModel.NAME, chartsModel.URL));
  }, [dispatch]);

  useEffect(() => {
    setAvailableUsers(
      userInfo.isSuperAdmin ? users : getUsersTree(gralDataAreas, myArea)
    );
  }, [gralDataAreas, myArea, userInfo.isSuperAdmin, users]);

  const isButtonDisabled =
    pageLoading ||
    !stats ||
    (stats === 'user-points' && (!evaluationId || !usersIds.length)) ||
    (stats === 'area-points' && (!evaluationId || !areasIds.length)) ||
    (pointsParams.includes(stats) &&
      (!usersIds.length || !evaluationsIds.length));

  const showRadarChart =
    data &&
    !pointsParams.includes(stats) &&
    (usersIds.length > 0 || areasIds.length > 0);

  const showLineChart =
    data &&
    pointsParams.includes(stats) &&
    usersIds.length > 0 &&
    evaluationsIds.length > 0;

  if (!viewStatistics) history.replace('/');
  if (statusError) return <ServerError />;

  return pageLoaded || !statusError ? (
    <div className="stats-page">
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <InputLabel>Estadística a evaluar</InputLabel>
          <Select
            value={stats}
            label="Estadística a evaluar"
            name="stats"
            onChange={handleStatsChange}
          >
            <MenuItem value="user-points">
              Puntos de Competencias, Objetivos y Asistencia ( POR PERSONA )
            </MenuItem>
            <MenuItem value="area-points">
              Puntos de Competencias, Objetivos y Asistencia ( POR AREA )
            </MenuItem>
            <MenuItem value="assistance">
              Comparar Puntaje de Asistencia ( POR PERSONA )
            </MenuItem>
            <MenuItem value="objetives">
              Comparar Puntaje de Objetivos ( POR PERSONA )
            </MenuItem>
            <MenuItem value="competences">
              Comparar Puntaje de Competencias ( POR PERSONA )
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {stats && !pointsParams.includes(stats) && (
        <EvaluationSelect
          evaluationId={evaluationId}
          handleEvaluationChange={handleEvaluationChange}
          stats={stats}
          evaluations={evaluations}
        />
      )}
      {pointsParams.includes(stats) && (
        <EvaluationsSelect
          evaluationsIds={evaluationsIds}
          stats={stats}
          handleMutipleEvaluationsChange={handleMutipleEvaluationsChange}
          evaluations={evaluations}
        />
      )}
      {(stats === 'user-points' || pointsParams.includes(stats)) && (
        <UsersSelect
          usersIds={usersIds}
          handleUsersChange={handleUsersChange}
          stats={stats}
          users={availableUsers}
        />
      )}
      {stats === 'area-points' && (
        <AreasSelect
          areasIds={areasIds}
          handleAreasChange={handleAreasChange}
          stats={stats}
          areas={areas}
          usersIds={usersIds}
        />
      )}
      <div className="form-input-wrapper">
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={isButtonDisabled}
        >
          {pageLoading ? 'Consultando' : 'Consultar'}
        </Button>
      </div>
      {showRadarChart && (
        <div className="stats-page__chart">
          <RadarChart chartData={data} />
        </div>
      )}
      {showLineChart && (
        <div className="stats-page__chart--line">
          <LineChart chartData={data} stats={stats} />
        </div>
      )}
    </div>
  ) : null;
};

export default CompanyStatsPage;
