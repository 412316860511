import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const DirectionIcon = ({ direction }) => {
  const directionIcons = {
    UP: <ArrowUpwardIcon />,
    BOSS_UP: <ArrowUpwardIcon />,
    EMPLOYEE_BOSS: <ArrowUpwardIcon />,
    DOWN: <ArrowDownwardIcon />,
    BOSS_DOWN: <ArrowDownwardIcon />,
    BOSS_EMPLOYEE: <ArrowDownwardIcon />,
    PAIR: <SyncAltIcon />,
    BOSS_PAIR: <SyncAltIcon />,
    EMPLOYEE_PAIR: <SyncAltIcon />,
  };
  return directionIcons[direction] || null;
};

export default DirectionIcon;
