import {
  NOTIFICATIONS_ADD_NEW,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_DELETE_SUCCESS,
} from '../constants/notificationsConstants';
import { emitSound } from '../helpers/emitSound';

export const notificationsReducer = (state = { data: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATIONS_SUCCESS:
    case NOTIFICATIONS_DELETE_SUCCESS:
      return { data: payload };

    case NOTIFICATIONS_ADD_NEW:
      if (
        !state.data.some((notification) => notification._id === payload._id)
      ) {
        emitSound();
        return { data: [payload, ...state.data] };
      }
      return state;
    default:
      return state;
  }
};
