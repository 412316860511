export const generalDataModel = {
  NAME: 'GENERAL_DATA_',
  URL: 'generalData',
  CREATE_EVALUATION_URL: 'evaluation',
  CREATE_USER_EVALUATION_URL: 'evaluation/user',
  WRITE_AREA_URL: 'area',
  WRITE_AREAS_URL: 'area/loadAreas',
  UPDATE_COMPANY_INFO: 'company',
  CLOSE_EVALUATION_PROCESS: 'evaluation/close-evaluation-process',
  UPDATE_COMPETENCE: 'competence',
  CREATE_COMPETENCE_TEMPLATE: 'competence/template',
  INITIAL_STATE: {
    applicationName: null,
    areas: [],
    userScore: {},
    company: {},
    competences: [],
    competencesProfiles: [],
    userProfiles: [],
    teamUsers: [],
    evaluation: null,
    config: {},
    floatingReminders: {},
  },
};

export const userDetailModel = {
  NAME: 'USER_DETAIL_',
  URL: 'user',
  UPDATE_ACTION_PLAN: 'user/action-plan',
  INITIAL_STATE: {
    userData: {},
    userGoalStatus: [],
    managementArea: null,
    ponderations: null,
  },
};

export const userModel = {
  NAME: 'USER-DATA_',
  UPDATE_PASSWORD: 'user/updatePassword',
  UPDATE_AVATAR: 'user/uploadAvatarToSpaces',
  UPDATE_PERSONAL_DATA: 'user/personal-data',
  UPDATE_SURVEY: 'survey',
  UPDATE_COINS: 'user/coins',
  UPDATE_ACTION_PLAN: 'user/action-plan',
};

export const dashboardModel = {
  NAME: 'DASHBOARD_',
  URL: 'dashboard',
  NOVELTY_URL: 'novelty',
  REMINDER_URL: 'reminder',
  CONGRATULATION_URL: 'congratulation',
  INITIAL_STATE: {
    novelties: [],
    reminders: [],
    congratulations: [],
    categoriesRanking: [],
  },
};

export const evaluationModel = {
  NAME: 'EVALUATION_',
  URL: 'evaluation',
  USER_EVALUATION: 'evaluation/user',
  EVALUATION_RESPONSE: 'evaluation/response',
  INITIAL_STATE: {
    userEvaluations: [],
    teamUsersEvaluations: [],
    evaluationResponse: null,
  },
};

export const evaluationProcess = {
  NAME: 'EVALUATION_PROCESS_',
  PENDING_TASKS_TO_OPEN: 'evaluation/pending/open',
  PENDING_TASKS_TO_CLOSE: 'evaluation/pending/close',
  INITIAL_STATE: {
    pendingTasksToOpen: null,
    pendingTasksToClose: null,
  },
};

export const recognitionModel = {
  NAME: 'RECOGNITION_',
  URL: 'recognition',
  INITIAL_STATE: {
    recognitions: [],
    ranking: [],
  },
};

export const benefitModel = {
  NAME: 'BENEFIT_',
  URL: 'benefit',
  URL_UPDATE_IMAGE: 'benefit/updateImage',
  INITIAL_STATE: { benefits: [] },
};

export const demandModel = {
  NAME: 'DEMAND_',
  URL: 'demand',
  NEW_COMMENT_URL: 'demand/comment',
  DEMAND_SEEN: 'demand/seen',
  INITIAL_STATE: { demands: [] },
};

export const searchModel = {
  NAME: 'SEARCH_',
  URL: 'search',
  POSTULATE_URL: 'search/postulate',
  INITIAL_STATE: { searches: [] },
};

export const goalsModel = {
  NAME: 'GOALS_',
  URL: 'goal',
  INITIAL_STATE: { goals: [], previousEvaluations: [] },
};

export const allUsersModel = {
  NAME: 'USERS_',
  URL: 'user',
  DEACTIVATE_USER: 'user/deactivate',
  ACTIVATE_USER: 'user/activate',
  REGISTER_URL: 'user/register',
  LOAD_USERS_URL: 'user/loadUsers',
  POST_ASSISTANCE_SCORES: 'user/assistance-score',
  INITIAL_STATE: [],
};

export const chartsModel = {
  NAME: 'CHART_',
  URL: 'charts',
  INITIAL_STATE: {
    users: [],
    areas: [],
    evaluations: [],
    data: null,
  },
};

export const areaRelationshipsModel = {
  NAME: 'AREA_RELATIONSHIPS_',
  URL: 'area/relationships',
  CONFIRM: 'area/relationships/confirm',
  POST_EVALUATION_CONFIRM: 'area/relationships/post-evaluation/confirm',
  INITIAL_STATE: {
    selectedArea: null,
    evaluations: [],
  },
};

export const competencesScoreDetailModel = {
  NAME: 'COMPETENCES_SCORE_DETAIL_',
  URL: 'evaluation/calculate360',
  INITIAL_STATE: null,
};

export const currentEvaluationsModel = {
  NAME: 'CURRENT_EVALUATIONS_',
  URL: 'evaluation/current',
  INITIAL_STATE: { user: null, currentEvaluations: null },
};

export const publicationsModel = {
  NAME: 'POSTS_',
  URL: 'post',
  CHANNELS_URL: 'post/channel',
  NEW_COMMENT_URL: 'post/comment',
  LIKE_URL: 'post/like',
  REACTION_URL: 'post/reaction',
  INITIAL_STATE: {
    post: null,
    posts: [],
    channels: [],
  },
};

export const statusModel = {
  NAME: 'STATUS_',
  URL: 'company/status',
  INITIAL_STATE: {
    status: [],
  },
};
