import { useEffect, useState } from 'react';
import { getAction, putAction } from '../../actions/modelActions';
import { useDispatch, useSelector } from 'react-redux';
import { allUsersModel } from '../../constants/modelConstants';

const useAssistanceForm = () => {
  const { sending } = useSelector((store) => store.app);
  const { users } = useSelector((store) => store.users);
  const { areas, evaluation } = useSelector((store) => store.generalData);
  const dispatch = useDispatch();

  const [assistanceScores, setAssistanceScores] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [areaSelected, setAreaSelected] = useState('');

  const handleInputChange = ({ target: { value } }, user) => {
    if (value < 0 || value > 100) return;

    const updatedScores = assistanceScores.map((userData) =>
      userData.id === user._id
        ? { ...userData, score: Number(value) }
        : userData
    );
    setAssistanceScores(updatedScores);
  };

  const enableInput = (user, value) => {
    const updatedScores = assistanceScores.map((userData) =>
      userData.id === user._id ? { ...userData, disabled: value } : userData
    );
    setAssistanceScores(updatedScores);
  };

  const handleAreaFilterChange = ({ target: { value } }) =>
    setAreaSelected(value);

  const handleSubmit = () => {
    const assistanceScoresPointed = assistanceScores.filter(
      (asistance) => asistance.score !== 0 && !asistance.disabled
    );
    dispatch(
      putAction(allUsersModel.NAME, allUsersModel.POST_ASSISTANCE_SCORES, {
        scores: assistanceScoresPointed,
        evaluation,
      })
    );
  };

  useEffect(() => {
    dispatch(getAction(allUsersModel.NAME, allUsersModel.URL));
  }, [dispatch]);

  useEffect(() => {
    const initialScores = users
      ? users.map((user) => ({
          id: user._id,
          score: user.score.assistance || 0,
          updated: !!user.score.assistance,
          disabled: !!user.score.assistance,
          active: user.active,
        }))
      : [];
    setAssistanceScores(initialScores);
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (areaSelected === '') {
      setFilteredUsers(users);
    } else {
      const areaSelectedData = areas.find((area) => area._id === areaSelected);
      let updateFilteredUsers;
      if (areaSelectedData.management) {
        const dependentAreasIds = areaSelectedData.dependentAreas.map(
          (dependentArea) => dependentArea._id
        );

        updateFilteredUsers = users?.filter(
          (user) =>
            user.area._id === areaSelected ||
            dependentAreasIds.includes(user.area._id)
        );
      } else {
        updateFilteredUsers = users?.filter(
          (user) => user.area._id === areaSelected
        );
      }

      setFilteredUsers(updateFilteredUsers);
    }
  }, [areaSelected, users, areas]);

  return {
    areas,
    areaSelected,
    handleAreaFilterChange,
    filteredUsers,
    handleInputChange,
    assistanceScores,
    handleSubmit,
    sending,
    enableInput,
  };
};

export default useAssistanceForm;
