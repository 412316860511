import { useEffect, useState } from 'react';
import LogoVertical from '../../assets/logo-vertical.png';

import './InitialLoading.scss';

const InitialLoading = ({ userInfo, pageLoaded, statusError }) => {
  const [show, setshow] = useState(true);

  useEffect(() => {
    if (userInfo || pageLoaded || statusError) {
      setTimeout(() => {
        setshow(false);
      }, 1000);
    }
  }, [userInfo, pageLoaded, statusError]);

  return show ? (
    <div
      className={`initial-loading-container ${
        userInfo ? 'initial-loading-container--hide' : ''
      }`}
    >
      <div className="initial-loading">
        <div className="initial-loading__logo">
          <img src={LogoVertical} alt="logo" />
          <div className="initial-loading__logo__progress"></div>
        </div>
      </div>
    </div>
  ) : null;
};

export default InitialLoading;
