import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from '@mui/material';
import EmptyComponentImage from '../../components/EmptyComponentImage';
import CompanyChart from './CompanyChart';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ServerError from '../../components/ServerError/ServerError';
import buildAreaTree, { buildChildren } from './helpers';

import './CompanyChartPage.scss';

const CompanyChartPage = () => {
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { areas } = useSelector((store) => store.generalData);
  const [areaTree] = useState(buildAreaTree(areas));
  const [filteredAreas, setFilteredAreas] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [selectedArea, setSelectedArea] = useState('Todas');
  const [areasExpanded, setAreasExpanded] = useState(true);

  const handleChange = (e) => {
    setSelectedArea(e.target.value);
  };

  const handleExpandedAreasChange = (e) => setAreasExpanded(e.target.checked);

  // se fija si hay una gerencia guardada en el LS, y si esta, hace scroll hasta esa posicion
  useEffect(() => {
    const lastManagementViewedId = localStorage.getItem('managementArea');
    if (lastManagementViewedId) {
      const element = document.getElementById(lastManagementViewedId);
      if (element) {
        const rect = element.getBoundingClientRect();
        setTimeout(() => {
          window.scrollTo({ top: rect.y - 60, behavior: 'smooth' });
          localStorage.removeItem('managementArea');
        }, 1500);
      }
    }
  }, []);

  // efecto para escuchar el scroll y mostrar en base a la posicion Y, un boton para subir
  useEffect(() => {
    const listenerStickNavbar = () => {
      if (window !== undefined) {
        window.scrollY > 250 ? setShowButton(true) : setShowButton(false);
      }
    };

    window.addEventListener('scroll', listenerStickNavbar, { pasive: true });

    return () => {
      window.removeEventListener('scroll', listenerStickNavbar);
    };
  }, []);

  useEffect(() => {
    if (selectedArea === 'Todas') {
      setFilteredAreas(areaTree);
    } else {
      // setFilteredAreas(
      //   areaTree.filter((managementArea) => managementArea._id === selectedArea)
      // );
      const currentArea = areas.find((area) => area._id === selectedArea);
      setFilteredAreas([buildChildren(currentArea, areas, 'children')]);
    }
  }, [areaTree, areas, selectedArea]);

  if (statusError) return <ServerError />;

  return pageLoaded && filteredAreas ? (
    <div className="organization-chart-page">
      <h3>Mapa de Areas</h3>
      {filteredAreas.length > 0 && (
        <div className="organization-chart-page__header">
          <div className="organization-chart-page__filter">
            <FormControl size="small" fullWidth>
              <InputLabel id="area">Seleccionar Area N1</InputLabel>
              <Select
                labelId="area"
                id="area"
                value={selectedArea}
                label="Seleccionar Area N1"
                name="area"
                onChange={handleChange}
              >
                <MenuItem value="Todas">Todas</MenuItem>
                {/* {areaTree.map((management) => (
                  <MenuItem value={management._id} key={management._id}>
                    {management.name}
                  </MenuItem>
                ))} */}
                {areas.map((area) => (
                  <MenuItem value={area._id} key={area._id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="form-select-wrapper">
            Mostrar / Ocultar Areas dependientes
            <Switch
              onChange={handleExpandedAreasChange}
              checked={areasExpanded}
            />
          </div>
        </div>
      )}
      {areaTree.length ? (
        <CompanyChart
          managements={filteredAreas}
          areasExpanded={areasExpanded}
          areas={areas}
        />
      ) : (
        <EmptyComponentImage text="Acá vas a poder conocer el Mapa de Areas del sistema." />
      )}
      {showButton && (
        <div
          className="scroll-button"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <ArrowCircleUpOutlinedIcon fontSize="large" htmlColor="#36b29a" />
        </div>
      )}
    </div>
  ) : null;
};

export default CompanyChartPage;
