import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMailAction } from '../../../actions/userActions';

const ForgotPasswordForm = ({ setView }) => {
  const [form, setForm] = useState({
    email: '',
  });
  const { loading, error } = useSelector((store) => store.userSignIn);
  const dispatch = useDispatch();

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendMailAction(form));
  };

  return (
    <>
      <p className="login-page__description">
        Ingresá tu email y te enviaremos un link para que resetees tu
        contraseña.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="login-page_inputs-wrapper">
          <TextField
            type="email"
            name="email"
            label="Email"
            onChange={handleInputChange}
            value={form.email}
            required
            className={`login-page_input ${error && 'error'}`}
          />
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar link'}
          </Button>
        </div>
        <p
          className="login-page__forgot-password"
          onClick={() => setView('login')}
        >
          Tengo la contraseña
        </p>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
