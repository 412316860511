import './UsersWithoutGoalsWarning.scss';

const UsersWithoutGoalsWarning = ({
  setFilters,
  setShowUsersWithoutGoals,
  showUsersWithoutGoals,
}) => {
  const handleShowButton = () => {
    if (showUsersWithoutGoals) {
      setFilters({
        source: 'all',
        selectedUserId: '',
        devolution: 'all',
      });
    } else {
      setFilters({
        source: 'users-without-goals',
        selectedUserId: '',
        devolution: 'all',
      });
    }
    setShowUsersWithoutGoals(!showUsersWithoutGoals);
  };

  return (
    <span className="without-goals-warning">
      Existen usuarios sin objetivos asignados.
      <button onClick={handleShowButton}>
        {showUsersWithoutGoals ? 'Ocultar' : 'Ver'}
      </button>
    </span>
  );
};

export default UsersWithoutGoalsWarning;
