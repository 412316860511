import axios from 'axios';
// import store from '../store';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 90000,
  timeoutErrorMessage: 'Ha surgido un error en el servidor - Time Out',
});

httpClient.interceptors.request.use((request) => {
  const token = localStorage.getItem('token');
  if (token) {
    request.headers.common.Authorization = token;
  }
  return request;
});

export default httpClient;
