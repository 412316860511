export const evaluationTypes = {
  BOSS_UP: 'BOSS_UP',
  BOSS_PAIR: 'BOSS_PAIR',
  BOSS_DOWN: 'BOSS_DOWN',
  BOSS_EMPLOYEE: 'BOSS_EMPLOYEE',
  EMPLOYEE_BOSS: 'EMPLOYEE_BOSS',
  EMPLOYEE_PAIR: 'EMPLOYEE_PAIR',
  BOSS_SELF: 'BOSS_SELF',
  EMPLOYEE_SELF: 'EMPLOYEE_SELF',
};

export const finalEvaluationTypes = [
  {
    key: 'DOWN',
    relatedTo: [evaluationTypes.BOSS_DOWN, evaluationTypes.BOSS_EMPLOYEE],
    label: 'Desde arriba',
  },
  {
    key: 'PAIR',
    relatedTo: [evaluationTypes.BOSS_PAIR, evaluationTypes.EMPLOYEE_PAIR],
    label: 'Pares',
  },
  {
    key: 'UP',
    relatedTo: [evaluationTypes.BOSS_UP, evaluationTypes.EMPLOYEE_BOSS],
    label: 'Desde abajo',
  },
  {
    key: 'SELF',
    relatedTo: [evaluationTypes.BOSS_SELF, evaluationTypes.EMPLOYEE_SELF],
    label: 'Autoevaluación',
  },
];

export const getSugestedUsers = (areas, selectedUser) => {
  const suggestedUsers = [];
  const userArea = areas.find((area) => area._id === selectedUser.area);

  const isSelected = (id) => {
    const currentRelation =
      selectedUser.evaluationRelationships.affectedUsers.find(
        (relation) => relation.user === id
      );
    return currentRelation?.selected || false;
  };

  const isAdded = (id) => {
    const currentRelation =
      selectedUser.evaluationRelationships.affectedUsers.find(
        (relation) => relation.user === id
      );
    return currentRelation?.isAddedUser || false;
  };

  const isPostEvaluation = (id) => {
    const currentRelation =
      selectedUser.evaluationRelationships.affectedUsers.find(
        (relation) => relation.user === id
      );
    return currentRelation?.postEvaluation || false;
  };

  if (selectedUser.role === 'employee') {
    const suggestedBosses = userArea.withoutBoss
      ? userArea.parentArea.bosses
      : userArea.bosses.map((b) => b._id);
    const suggestedEmployees = userArea.withoutEmployess
      ? []
      : userArea.employees
          .filter((e) => e._id !== selectedUser._id)
          .map((e) => e._id);

    suggestedBosses.forEach((bossId) => {
      suggestedUsers.push({
        forRole: 'boss',
        userArea: selectedUser.area,
        evaluationType: evaluationTypes.EMPLOYEE_BOSS,
        user: bossId,
        isAddedUser: isAdded(bossId),
        reason: '',
        selected: isSelected(bossId),
        postEvaluation: isPostEvaluation(bossId),
      });
    });
    suggestedEmployees.forEach((employeeId) => {
      suggestedUsers.push({
        forRole: 'employee',
        userArea: selectedUser.area,
        evaluationType: evaluationTypes.EMPLOYEE_PAIR,
        user: employeeId,
        isAddedUser: isAdded(employeeId),
        reason: '',
        selected: isSelected(employeeId),
        postEvaluation: isPostEvaluation(employeeId),
      });
    });
  } else {
    const parentAreaBosses = userArea.parentArea?.bosses || [];
    const dependentAreaBosses = [];
    const pairBosses = userArea.bosses
      .filter((b) => b._id !== selectedUser._id)
      .map((b) => b._id);
    const areaEmployees = userArea.employees?.map((e) => e._id);

    userArea.dependentAreas?.forEach((dep) => {
      if (dep.withoutBoss) {
        dep.employees.forEach((employeeId) => {
          suggestedUsers.push({
            forRole: 'employee',
            userArea: dep._id,
            evaluationType: evaluationTypes.BOSS_EMPLOYEE,
            user: employeeId,
            isAddedUser: isAdded(employeeId),
            reason: '',
            selected: isSelected(employeeId),
            postEvaluation: isPostEvaluation(employeeId),
          });
        });
      } else {
        dependentAreaBosses.push(...dep.bosses);
      }
    });

    parentAreaBosses.forEach((bossId) => {
      suggestedUsers.push({
        forRole: 'boss',
        userArea: userArea.parentArea._id,
        evaluationType: evaluationTypes.BOSS_UP,
        user: bossId,
        isAddedUser: isAdded(bossId),
        reason: '',
        selected: isSelected(bossId),
        postEvaluation: isPostEvaluation(bossId),
      });
    });
    dependentAreaBosses.forEach((bossId) => {
      suggestedUsers.push({
        forRole: 'boss',
        userArea: selectedUser.area,
        evaluationType: evaluationTypes.BOSS_DOWN,
        user: bossId,
        isAddedUser: isAdded(bossId),
        reason: '',
        selected: isSelected(bossId),
        postEvaluation: isPostEvaluation(bossId),
      });
    });
    pairBosses.forEach((bossId) => {
      suggestedUsers.push({
        forRole: 'boss',
        userArea: selectedUser.area,
        evaluationType: evaluationTypes.BOSS_PAIR,
        user: bossId,
        isAddedUser: isAdded(bossId),
        reason: '',
        selected: isSelected(bossId),
        postEvaluation: isPostEvaluation(bossId),
      });
    });
    areaEmployees.forEach((employeeId) => {
      suggestedUsers.push({
        forRole: 'employee',
        userArea: selectedUser.area,
        evaluationType: evaluationTypes.BOSS_EMPLOYEE,
        user: employeeId,
        isAddedUser: isAdded(employeeId),
        reason: '',
        selected: isSelected(employeeId),
        postEvaluation: isPostEvaluation(employeeId),
      });
    });
  }
  return suggestedUsers;
};

export const getPopulatedUsers = (users, affectedUsers) => {
  const populatedUsers = [];
  affectedUsers.forEach((affectedUser) => {
    const currentUser = users.find(
      (user) => user._id === affectedUser.user && user.active
    );

    // Validamos si hay current user porque en el find se agrega que el usuario esté activo
    if (currentUser) {
      populatedUsers.push({
        ...affectedUser,
        user: { ...currentUser, isAddedUser: affectedUser.isAddedUser },
      });
    }
  });
  return populatedUsers;
};
