import { Button } from '@mui/material';
import { useState } from 'react';
import CustomModal from '../../../components/CustomModal';
import Feedback from '../Feedback';
import FeedbackForm from '../FeedbackForm';
import GoalDetails from '../GoalDetails/GoalDetails';
import './Feedbacks.scss';

const Feedbacks = ({ goal, setShowFeedback, belongsToOneOfMyEmployees }) => {
  const [createFeedback, setCreateFeedback] = useState(false);

  return (
    <CustomModal setIsFormOpen={setShowFeedback} title="Feedbacks">
      <GoalDetails goal={goal} />
      {createFeedback ? (
        <FeedbackForm
          goal={goal}
          setCreateFeedback={setCreateFeedback}
          setShowFeedback={setShowFeedback}
        />
      ) : (
        <>
          <div className="feedback-list">
            {goal.feedbacks?.length ? (
              goal.feedbacks.map((feedback) => (
                <Feedback feedback={feedback} key={feedback.text} />
              ))
            ) : (
              <p className="feedback-list__empty">
                No posee feedback realizados
              </p>
            )}
          </div>
          {!goal.processed && belongsToOneOfMyEmployees && goal.note === 0 && (
            <div>
              <Button
                variant="contained"
                onClick={() => setCreateFeedback(true)}
              >
                Crear Nuevo Feedback
              </Button>
            </div>
          )}
        </>
      )}
    </CustomModal>
  );
};

export default Feedbacks;
