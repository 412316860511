import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import scores from '../CompetenceResponse/scores';
import DirectionIcon from '../../../components/DirectionIcon';
import getAnswerLetter from '../EvaluationForm/helpers';

import './CompetenceUsrResponse.scss';

const CompetenceUserResponse = ({
  competence,
  data,
  answers,
  setValue,
  done,
  reOpen,
}) => {
  const { user, postEvaluation } = data;

  const isUserEvaluated =
    answers &&
    answers[competence._id][user._id].behaviour.value &&
    answers[competence._id][user._id].score.value;

  const isOptionSelected = (key, value) =>
    value === answers[competence._id][user._id][key].value;

  return answers ? (
    <div
      className={`competence-user-response ${
        isUserEvaluated ? 'competence-user-response--evaluated' : ''
      }`}
    >
      <div className="competence-user-response__avatar">
        <Avatar src={user.avatar} sx={{ width: 150, height: 150 }} />
      </div>
      <p className="competence-user-response__name">{user.fullname}</p>
      <div className="competence-response__user__direction">
        <DirectionIcon direction={data.evaluationType} />
      </div>
      <div className="competence-user-response__form">
        <FormControl className="competence-user-response__control">
          <InputLabel>Rta. 1</InputLabel>
          <Select
            value={answers[competence._id][user._id].behaviour.value || ''}
            label="Rta. 1"
            name="behaviour"
            disabled={done || (reOpen && !postEvaluation)}
          >
            {competence.behaviourQuestionOptions.map((option, i) => (
              <MenuItem
                key={`${i}-${user._id}`}
                value={option.value}
                selected={isOptionSelected('behaviour', option.value)}
                onClick={() =>
                  setValue(competence._id, user._id, 'behaviour', option)
                }
              >
                Opc. {getAnswerLetter(i)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="competence-user-response__control">
          <InputLabel>Rta. 2</InputLabel>
          <Select
            value={answers[competence._id][user._id].score.value || ''}
            label="Rta. 2"
            name="score"
            disabled={done || (reOpen && !postEvaluation)}
          >
            {scores.map((score, i) => (
              <MenuItem
                key={`${i}-${competence._id}-${user._id}`}
                value={score.value}
                selected={isOptionSelected('score', score.value)}
                onClick={() =>
                  setValue(competence._id, user._id, 'score', score)
                }
              >
                {score.value} pts.
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  ) : null;
};

export default CompetenceUserResponse;
