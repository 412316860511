// import { Checkbox } from '@mui/material';
import DirectionIcon from '../../../components/DirectionIcon';

const RelatedEvaluation = ({
  evaluation,
  getCompetenceName,
  selectedEvaluations,
  setSelectedEvaluations,
}) => {
  const getBonusPointsLabel = (hasBonusPoints) => {
    return hasBonusPoints ? 'Si' : 'No';
  };

  /* const isChecked = (evaluationId) => {
    return !!selectedEvaluations.find((evalId) => evalId === evaluationId);
  };

  const handleEvaluationCheck = (e, evaluationId) => {
    if (e.target.checked) {
      setSelectedEvaluations((selectedEvaluations) => [
        ...selectedEvaluations,
        evaluationId,
      ]);
    } else {
      setSelectedEvaluations((selectedEvaluations) =>
        selectedEvaluations.filter((evalId) => evalId !== evaluationId)
      );
    }
  }; */

  return (
    <div
      key={evaluation._id}
      className="related-evaluations-detail__evaluation"
    >
      <div className="related-evaluations-detail__evaluation-data-container">
        <div className="related-evaluations-detail__evaluation-data">
          {evaluation.evaluationType === 'SELF' ? (
            <div>
              <b>Autoevaluación</b>
            </div>
          ) : (
            <div>
              <b>Usuario:</b> {evaluation.user}
            </div>
          )}

          <div className="related-evaluations-detail__icon">
            <DirectionIcon direction={evaluation.evaluationType} />
          </div>
        </div>

        <div className="related-evaluations-detail__evaluation-data">
          <b>Evaluación ID:</b> {evaluation._id}
        </div>
        {/* <div className="related-evaluations-detail__evaluation-checkbox">
          <Checkbox
            onChange={(e) => handleEvaluationCheck(e, evaluation._id)}
            checked={isChecked(evaluation._id)}
          />
        </div> */}
      </div>
      {evaluation.answers ? (
        <div className="related-evaluations-detail__answers">
          <ul className="related-evaluations-detail__answers-container">
            {Object.keys(evaluation.answers).map((competenceId) => (
              <li
                key={competenceId}
                className="related-evaluations-detail__answer"
              >
                <div className="related-evaluations-detail__answer-competence-name">
                  {getCompetenceName(competenceId)}
                </div>
                <div className="related-evaluations-detail__answer-data-container">
                  <div className="related-evaluations-detail__answer-data">
                    <b>Comportamiento:</b>
                    {evaluation.answers[competenceId].behaviourPoints / 10}
                  </div>
                  <div className="related-evaluations-detail__answer-data">
                    <b>Puntaje:</b>
                    {evaluation.answers[competenceId].scorePoints / 10}
                  </div>
                  <div className="related-evaluations-detail__answer-data">
                    <b>Puntos Totales:</b>
                    {evaluation.answers[competenceId].totalCompetencePoints /
                      10}
                  </div>
                  <div className="related-evaluations-detail__answer-data">
                    <b>Punto bonus:</b>
                    {getBonusPointsLabel(
                      evaluation.answers[competenceId].bonusPoint
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="related-evaluations-detail__answers-empty">
          Aún no fué respondida
        </p>
      )}
    </div>
  );
};

export default RelatedEvaluation;
