import Button from '@mui/material/Button';
import { Avatar, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { benefitModel } from '../../../constants/modelConstants';
import { useSelector } from 'react-redux';
import LoadingModal from '../../../components/LoadingModal';
import ApplicantsTooltip from '../../../components/ApplicantsTooltip/ApplicantsTooltip';
import Coins from '../../../assets/coins.png';
import { updateUserCoinsByBenefit } from '../../../actions/userActions';
import './Benefit.scss';

const Benefit = ({ benefit, minPoints }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { sending } = useSelector((store) => store.app);

  const {
    score: { totalScore },
    coins,
  } = userInfo;

  const { title, field, image, description } = benefit;

  const frontBackgroundImageStyles = {
    backgroundImage: `linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ), url(${image})`,
  };

  const backBackgroundImageStyles = {
    backgroundImage: `linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ), url(${image})`,
  };

  const isRequestedByMe = benefit.applicants.some(
    (applicant) => applicant._id === userInfo._id
  );

  const shouldButtonBeRender = () => {
    return (
      !isRequestedByMe &&
      ((benefit?.exchangeType === 'points' &&
        minPoints <= Math.round(totalScore)) ||
        (benefit?.exchangeType === 'coins' && benefit?.coins <= coins))
    );
  };

  const handleButtonClick = () => {
    if (benefit.exchangeType === 'coins') {
      dispatch(
        updateUserCoinsByBenefit(
          {
            applicant: userInfo._id,
            id: benefit._id,
            coins: benefit.coins,
          },
          `user/coins/benefit/${benefit._id}`
        )
      );
    } else {
      dispatch(
        putAction(benefitModel.NAME, `${benefitModel.URL}/apply`, {
          applicant: userInfo._id,
          id: benefit._id,
        })
      );
    }
  };

  return (
    <div className="benefit">
      {sending && <LoadingModal />}
      <div
        className="benefit__side benefit__side--front"
        style={frontBackgroundImageStyles}
      >
        {isRequestedByMe && (
          <div className="benefit-requested">
            <CheckCircleIcon htmlColor="#36b29a" fontSize="large" />
          </div>
        )}
        <div className="benefit__title-container">
          <h3> {field} </h3>
        </div>
        <div className="benefit__discount-container">
          <h3> {title} </h3>
        </div>

        {benefit.exchangeType !== 'no-exchange' && (
          <div className="benefit-applicants__quantity">
            {benefit.applicants.length}
          </div>
        )}
        {benefit.exchangeType === 'coins' && (
          <div className="benefit__coins">
            <Avatar src={Coins} sx={{ width: 50, height: 50 }} />
            <span className="benefit__coins__quantity">{benefit.coins}</span>
          </div>
        )}
      </div>
      <div
        className="benefit__side benefit__side--back"
        style={backBackgroundImageStyles}
      >
        {isRequestedByMe && (
          <Tooltip title="Solicitado">
            <div className="benefit-requested">
              <CheckCircleIcon htmlColor="#36b29a" fontSize="large" />
            </div>
          </Tooltip>
        )}
        <div className="benefit__description">{description}</div>
        {shouldButtonBeRender() && (
          <div className="benefit__actions">
            <Button
              size="small"
              variant="contained"
              onClick={handleButtonClick}
            >
              Solicitar
            </Button>
          </div>
        )}
        {benefit.exchangeType !== 'no-exchange' && (
          <ApplicantsTooltip applicants={benefit.applicants}>
            <div className="benefit-applicants__quantity">
              {benefit.applicants.length}
            </div>
          </ApplicantsTooltip>
        )}

        {benefit.exchangeType === 'coins' && (
          <div className="benefit__coins">
            <Avatar src={Coins} sx={{ width: 50, height: 50 }} />
            <span className="benefit__coins__quantity">{benefit.coins}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Benefit;
