import { Button, TextField, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useAssistanceForm from './useAssistanceForm';
import LoadingModal from '../LoadingModal';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CustomAvatar from '../CustomAvatar/CustomAvatar';
import { useHistory } from 'react-router-dom';

import './AssistanceForm.scss';

const AssistanceForm = () => {
  const {
    areas,
    areaSelected,
    handleAreaFilterChange,
    filteredUsers,
    handleInputChange,
    assistanceScores,
    handleSubmit,
    sending,
    enableInput,
  } = useAssistanceForm();

  const history = useHistory();

  return (
    <div className="assistance-form">
      {sending && <LoadingModal />}
      <h3 className="assistance-form__title">Administrar Presentismo</h3>
      <FormControl className="assistance-form__search-container">
        <InputLabel>Filtrar por Area</InputLabel>
        <Select
          value={areaSelected}
          label="Filtrar por Area"
          name="area"
          onChange={handleAreaFilterChange}
        >
          <MenuItem value=""> Todas </MenuItem>
          {areas.map((area) => (
            <MenuItem value={area._id} key={area._id}>
              {area.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="assistance-form__table-header">
              <TableCell>Imagen</TableCell>
              <TableCell>Nombre Completo</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Presentismo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers?.map(
              (user, i) =>
                user.active && (
                  <TableRow key={user.fullname}>
                    <TableCell>
                      <div className="image-container">
                        {user.avatar ? (
                          <Avatar
                            src={user.avatar}
                            onClick={() => history.push(`/usuario/${user._id}`)}
                          />
                        ) : (
                          <CustomAvatar
                            userFullname={user.fullname}
                            onClick={() => history.push(`/usuario/${user._id}`)}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{user.fullname}</TableCell>
                    <TableCell>{user.area?.name}</TableCell>
                    <TableCell className="assistance-form__actions">
                      <TextField
                        type="number"
                        className={
                          !assistanceScores.find((u) => u.id === user._id)
                            .updated
                            ? 'input-new'
                            : ''
                        }
                        onChange={(e) => handleInputChange(e, user)}
                        value={
                          assistanceScores.find((u) => u.id === user._id)
                            .score || ''
                        }
                        disabled={
                          assistanceScores.find((u) => u.id === user._id)
                            .disabled
                        }
                      />

                      {assistanceScores.find((u) => u.id === user._id)
                        .updated && (
                        <span>
                          {assistanceScores.find((u) => u.id === user._id)
                            .disabled ? (
                            <Tooltip title="Editar" placement="top" arrow>
                              <EditIcon
                                color="primary"
                                onClick={() => enableInput(user, false)}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Cancelar edición"
                              placement="top"
                              arrow
                            >
                              <EditOffIcon
                                color="disabled"
                                onClick={() => enableInput(user, true)}
                              />
                            </Tooltip>
                          )}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="assistance-form__button">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={assistanceScores
            .filter((as) => as.active)
            .every((a) => a.disabled)}
        >
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default AssistanceForm;
