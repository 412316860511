import ReminderForm from '../ReminderForm';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import Reminder from '../Reminder';
import './Reminders.scss';

const Reminders = ({ reminders, isFormOpen, setIsFormOpen }) => {
  return (
    <div className="reminders">
      {reminders.length ? (
        reminders.map((reminder) => (
          <Reminder reminder={reminder} key={reminder._id} />
        ))
      ) : (
        <EmptyComponentImage text="Acá vas a poder estar al tanto de todas los recordatorios." />
      )}
      {isFormOpen && <ReminderForm setIsFormOpen={setIsFormOpen} />}
    </div>
  );
};

export default Reminders;
