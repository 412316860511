import './SurveyAverage.scss';

const SurveyAverage = ({ surveyAverage }) => {
  const icons = [
    <span>&#128532;</span>,
    <span>&#128528;</span>,
    <span>&#128512;</span>,
    <span>&#128513;</span>,
  ];

  const Icon = () => {
    return icons[Math.round(surveyAverage) - 1] || null;
  };

  const getAverageClassName = () => {
    if (surveyAverage >= 4) return 'survey-average-wrapper--4';
    if (surveyAverage >= 3) return 'survey-average-wrapper--3';
    if (surveyAverage >= 2) return 'survey-average-wrapper--2';
    return 'survey-average-wrapper--1';
  };

  return (
    <div className={`survey-average-wrapper ${getAverageClassName()}`}>
      <div className="survey-average">
        Promedio de Satisfacción del área:
        <span className="survey-average__average">
          {surveyAverage.toFixed(2)}
        </span>
        <div className="survey-average__icon">
          <Icon />
        </div>
      </div>
    </div>
  );
};

export default SurveyAverage;
