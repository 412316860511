import { useEffect, useState } from 'react';
import { getUsersByArea } from '../../../actions/innerStateActions';
import CustomModal from '../../../components/CustomModal';
import AreaUser from '../AreaUser';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';

import './ModalAreaDetail.scss';

const ModalAreaDetail = ({ setIsFormOpen, area }) => {
  const [bosses, setBosses] = useState([]);
  const [bossesSecondLevel, setBossesSecondLevel] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAreaUsers = async () => {
      setLoading(true);
      try {
        const response = await getUsersByArea(area._id);
        setBosses(response.area.bosses.filter((boss) => boss.range === 1));
        setBossesSecondLevel(
          response.area.bosses.filter((boss) => boss.range > 1)
        );
        setEmployees(response.area.employees);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //TODO manejar el error
      }
    };
    getAreaUsers();
  }, [area._id]);

  return (
    <CustomModal
      title={`Detalle de ${area.name}`}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="modal-area-detail">
        {loading && (
          <div className="modal-area-detail__loading">
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <div className="modal-area-detail__content">
            <div className="modal-area-detail__users">
              {area.withoutBoss ? (
                <div className="modal-area-detail__without-boss">
                  <div className="modal-area-detail__without-boss-icon">
                    <SwapVertOutlinedIcon
                      fontSize="small"
                      htmlColor="#36b29a"
                    />
                  </div>
                  <div>Depende directamente del área superior</div>
                </div>
              ) : (
                area.bosses.length < 1 && (
                  <div className="modal-area-detail__no-boss">
                    No hay Jefe asignado
                  </div>
                )
              )}
              {bosses.map((boss) => (
                <AreaUser key={boss._id} user={boss} />
              ))}
            </div>
            {bosses.length > 0 && !area.withoutEmployees && (
              <div className="modal-area-detail__connector">
                <ArrowDownwardIcon />
              </div>
            )}
            {bossesSecondLevel.length > 0 && (
              <>
                <div className="modal-area-detail__users">
                  {bossesSecondLevel.map((boss) => (
                    <AreaUser key={boss._id} user={boss} />
                  ))}
                </div>
                <div className="modal-area-detail__connector">
                  <ArrowDownwardIcon />
                </div>
              </>
            )}
            <div className="modal-area-detail__users">
              {employees.map((employee) => (
                <AreaUser key={employee._id} user={employee} />
              ))}
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default ModalAreaDetail;
