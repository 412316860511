import CustomModal from '../../../components/CustomModal';

import './CommentsModal.scss';

const CommentsModal = ({ setIsFormOpen, comments }) => {
  return (
    <CustomModal title="Comentarios" setIsFormOpen={setIsFormOpen}>
      <div className="comments__modal">
        {comments?.length === 0 && (
          <p>
            No hay comentarios relacionados al usuario para esta evaluación.
          </p>
        )}
        <ul>
          {comments?.map((comment) => (
            <li className="comments__modal__comment">{comment}</li>
          ))}
        </ul>
      </div>
    </CustomModal>
  );
};

export default CommentsModal;
