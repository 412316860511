import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAction } from '../../actions/modelActions';
import { competencesScoreDetailModel } from '../../constants/modelConstants';
import Summary from './Summary';
import RelatedEvaluationsDetail from './RelatedEvaluationsDetail';
import BackButton from '../../components/BackButton';

import './CompetencesScoreDetail.scss';

const CompetencesScoreDetail = () => {
  const dispatch = useDispatch();
  const data = useSelector((store) => store.competencesScoreDetail);
  const { competences } = useSelector((store) => store.generalData);

  const { evaluationId, userId } = useParams();

  const getCompetenceName = (competenceId) => {
    const currentCompetence = competences.find(
      (competence) => competence._id === competenceId
    );
    return currentCompetence.name;
  };

  useEffect(() => {
    if (evaluationId && userId) {
      dispatch(
        getAction(
          competencesScoreDetailModel.NAME,
          `${competencesScoreDetailModel.URL}/${evaluationId}/${userId}`
        )
      );
    }
  }, [dispatch, userId, evaluationId]);

  return data ? (
    <div className="competences-score-detail">
      <h3 className="competences-score-detail__title">
        Impacto 360 - {data.evaluation.name}
        <BackButton />
      </h3>
      <div className="competences-score-detail__content">
        <Summary data={data} getCompetenceName={getCompetenceName} />
        <RelatedEvaluationsDetail
          evaluation={data.evaluation}
          userId={userId}
          data={data}
          getCompetenceName={getCompetenceName}
        />
      </div>
    </div>
  ) : null;
};

export default CompetencesScoreDetail;
