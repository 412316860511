import { useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal';
import { getEvaluationsImpacts } from '../../../actions/innerStateActions';
import { CircularProgress } from '@mui/material';
import { calculateImpacts } from '../../../helpers/calculateImpacts';
import DirectionIcon from '../../../components/DirectionIcon';
import { useSelector } from 'react-redux';

import './ShowImpactsModal.scss';

const ShowImpactsModal = ({ setIsFormOpen, user }) => {
  const { company } = useSelector((store) => store.generalData);
  const [loading, setLoading] = useState(false);
  const [evaluationImpacts, setEvaluationImpacts] = useState([]);
  const [currentPonderations, setCurrentPonderations] = useState([]);

  useEffect(() => {
    const getUserImpacts = async () => {
      setLoading(true);
      try {
        const response = await getEvaluationsImpacts(user._id);
        setEvaluationImpacts(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        //TODO manejar el error
      }
    };
    getUserImpacts();
  }, [user._id]);

  useEffect(() => {
    setCurrentPonderations(
      calculateImpacts(evaluationImpacts, company.impacts)
    );
  }, [evaluationImpacts, company]);

  return (
    <CustomModal
      title={`Afectaciones a ${user.fullname}`}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="show-impacts-modal">
        <p>
          Las afectaciones son los tipos de evaluaciones que afectan al
          resultado total de la evaluación de desempeño de un determinado
          usuario.
        </p>
        <p>
          Cada tipo de evaluación tiene un porcentaje de impacto definido por la
          empresa, pero puede variar de acuerdo a los tipos de evaluaciones que
          afectan a un usuario.
        </p>
        <p>
          Estas afectaciones darán siempre como resultado de sumarlas un 100% de
          impacto.
        </p>
        <div className="show-impacts-modal__content">
          {loading && (
            <div className="show-impacts-modal__loading">
              <CircularProgress />
            </div>
          )}
          {!loading && (
            <div className="show-impacts-modal__impacts">
              {currentPonderations.map((ponderation) => (
                <div
                  key={ponderation.evaluationType}
                  className="show-impacts-modal__impact"
                >
                  <div className="show-impacts-modal__evaluation-type">
                    <b>{ponderation.label}</b>
                    <DirectionIcon direction={ponderation.evaluationType} />
                  </div>
                  {ponderation.qty > 0 && (
                    <div>
                      <b>Evaluaciones: </b>
                      {ponderation.qty}
                    </div>
                  )}
                  <div>
                    <b>{ponderation.impact.toFixed(2)} %</b>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default ShowImpactsModal;
