import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAreaTree } from '../../../helpers/getAreaTree';
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import './AreaUsersData.scss';
import { orderUsersByPoints } from '../../../helpers/orderUsersByPoint';
import { alphabeticOrder } from '../../CompanyStatsPage/helpers';

const AreaUsersData = () => {
  const history = useHistory();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { areas } = useSelector((store) => store.generalData);
  const [selectedArea, setSelectedArea] = useState({});
  const [areaFilteredUsers, setAreaFilteredUsers] = useState([]);

  const myArea = areas?.find((area) => area._id === userInfo.area?._id);
  const availableAreas = getAreaTree(areas, myArea);

  const handleOnChangeArea = ({ target }) =>
    setSelectedArea(areas.find((area) => area._id === target.value));

  const handleAvatarClick = (userId) => {
    localStorage.setItem('area-selected', JSON.stringify(selectedArea));
    history.push(`/usuario/${userId}`);
  };
  useEffect(() => {
    const previousAreaSelected = JSON.parse(
      localStorage.getItem('area-selected')
    );
    setSelectedArea(previousAreaSelected || myArea);
  }, [myArea]);

  useEffect(() => {
    if (selectedArea?.bosses?.length || selectedArea?.employees?.length) {
      setAreaFilteredUsers(
        [...selectedArea?.bosses, ...selectedArea?.employees].filter(
          (user) => user._id !== userInfo._id
        )
      );
    }
  }, [
    selectedArea?._id,
    selectedArea?.bosses,
    selectedArea?.employees,
    userInfo._id,
  ]);

  return (
    <div className="area-users-data">
      <h3>Información de empleados de mi área y dependientes</h3>
      <div className="area-users-data__select">
        <FormControl fullWidth>
          <InputLabel>Area</InputLabel>
          <Select
            value={selectedArea?._id || myArea?._id}
            label="Area"
            name="selectedArea"
            onChange={handleOnChangeArea}
          >
            {availableAreas.sort(alphabeticOrder).map((area) => (
              <MenuItem value={area._id} key={area._id}>
                {area.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="area-users-data__users">
        {areaFilteredUsers.length === 0
          ? 'no hay usuarios en el área'
          : areaFilteredUsers.sort(orderUsersByPoints).map((user) => (
              <div className="area-users-data__user" key={user._id}>
                <Avatar
                  src={user.avatar}
                  className="area-users-data__user-avatar"
                  onClick={() => handleAvatarClick(user._id)}
                />
                <span>{user.fullname}</span>
                <span className="area-users-data__user-score">
                  {user.score?.totalScore} pts
                </span>
              </div>
            ))}
      </div>
    </div>
  );
};

export default AreaUsersData;
