import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getNoteText, getGoalState } from '../../../helpers/goalsNotes';
import { useSelector } from 'react-redux';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import CustomModal from '../../../components/CustomModal';
import ConfirmDeleteGoalModal from '../../../components/ConfirmDeleteGoalModal';
import TodoForm from '../../GoalsPage/TodoForm';
import Feedbacks from '../../GoalsPage/Feedbacks';
import GoalDevolutionForm from '../../GoalsPage/GoalDevolutionForm/GoalDevolutionForm';
import GoalForm from '../../GoalsPage/GoalForm/GoalForm';
import './GoalsTable.scss';

const GoalsTable = ({ goals }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  const [showTodos, setShowTodos] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showGoalForm, setShowGoalForm] = useState(false);
  const [isDevolutionFormOpen, setIsDevolutionFormOpen] = useState(false);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [showDevolutionText, setShowDevolutionText] = useState(false);

  const handleTodoIconClick = (goal) => {
    setCurrentGoal(goal);
    setShowTodos(true);
  };

  /* const handleFeedbackIconClick = (goal) => {
    setCurrentGoal(goal);
    setShowFeedback(true);
  }; */

  /* const handleDevolutionIconClick = (goal) => {
    setCurrentGoal(goal);
    setIsDevolutionFormOpen(true);
  }; */

  const handleDetailIconClick = (goal) => {
    setCurrentGoal(goal);
    setShowDetail(true);
  };

  /* const handleSeeDevolutionText = (goal) => {
    setShowDevolutionText(true);
    setCurrentGoal(goal);
  }; */

  const usersIdsArray = currentGoal?.toUserId?._id
    ? [currentGoal?.toUserId?._id]
    : currentGoal?.toUsersIds.map((u) => u._id);

  const isAnEmployeeOfMyArea = usersIdsArray?.some((userId) =>
    userInfo.area?.employees.includes(userId)
  );

  const isABossOfMyDependentAreas = usersIdsArray?.some((userId) =>
    userInfo.area?.dependentAreas.some((area) => area.bosses.includes(userId))
  );

  const answersDirectlyToManagement = usersIdsArray?.some((userId) =>
    userInfo.area?.dependentAreas.some(
      (area) => area.withoutBoss && area.employees.includes(userId)
    )
  );

  const belongsToOneOfMyEmployees =
    userInfo.isSuperAdmin ||
    (userInfo.role === 'boss' &&
      (isAnEmployeeOfMyArea ||
        isABossOfMyDependentAreas ||
        answersDirectlyToManagement));

  /* const showDevolutionIcon = (goal) =>
    goal.createUserId._id === userInfo._id &&
    goal.note === 0; */

  /* const seeDevolution = (goal) =>
    goal.createUserId._id === userInfo._id ||
    goal.toUserId?._id === userInfo._id ||
    goal.toUsersIds.some((user) => user._id === userInfo._id); */

  return (
    <>
      {goals.length > 0 ? (
        <TableContainer component={Paper} className="goals-table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="goals-table__table-header">
                <TableCell>De</TableCell>
                <TableCell></TableCell>
                <TableCell>Para</TableCell>
                <TableCell className="title-column">Título</TableCell>
                <TableCell>Detalle</TableCell>
                <TableCell>Tareas</TableCell>
                {/* <TableCell>Feedbacks</TableCell>
                <TableCell>Devolución</TableCell> */}
                <TableCell>Estado</TableCell>
                <TableCell>Nota</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {goals?.map((goal, i) => (
                <TableRow key={goal._id}>
                  <TableCell>
                    <div className="avatar-cell">
                      <Avatar src={goal.createUserId.avatar} />
                      <span>
                        {goal.createUserId.name} &nbsp;
                        {goal.createUserId.lastname}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <TrendingFlatOutlinedIcon />
                  </TableCell>
                  <TableCell>
                    {goal.group ? (
                      <div className="avatars-container">
                        {goal.toUsersIds.map((user) => (
                          <div className="avatar-cell" key={user._id}>
                            <Avatar src={user.avatar} />
                            <span>{user.name}</span>
                            <span>{user.lastname}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="avatar-cell">
                        <Avatar src={goal.toUserId?.avatar} />
                        <span>{goal.toUserId?.name}</span>
                        <span>{goal.toUserId?.lastname}</span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="text-cell__title">{goal.title}</div>
                  </TableCell>
                  <TableCell>
                    <div className="icon-cell">
                      <Tooltip title="Ver Detalle" placement="top" arrow>
                        <IconButton onClick={() => handleDetailIconClick(goal)}>
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="icon-cell">
                      <Tooltip
                        title="Ver Listado de tareas"
                        placement="top"
                        arrow
                      >
                        <IconButton onClick={() => handleTodoIconClick(goal)}>
                          <ListAltOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                  {/* <TableCell>
                    <div className="icon-cell feedback-icon">
                      <Tooltip title="Ver Feedbacks" placement="top" arrow>
                        <IconButton
                          onClick={() => handleFeedbackIconClick(goal)}
                        >
                          <TextsmsOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <span className="feedbacks-qty">
                        {goal.feedbacks.length}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="icon-cell">
                      {goal.devolution ? (
                        <Tooltip
                          title="Ver devolución final"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={() => handleSeeDevolutionText(goal)}
                            disabled={!seeDevolution(goal)}
                          >
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Dar devolución" placement="top" arrow>
                          <span>
                            <IconButton
                              onClick={() => handleDevolutionIconClick(goal)}
                              disabled={!showDevolutionIcon(goal)}
                            >
                              <ThumbsUpDownOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell> */}
                  <TableCell>
                    <div className="text-cell__state">
                      {getGoalState(goal.state)}
                    </div>
                  </TableCell>
                  <TableCell>
                    {goal.state !== 'wip' ? (
                      <div
                        className={`text-cell__note ${
                          goal.note > 5 ? 'approbed' : 'failed'
                        }`}
                      >
                        {getNoteText(goal.note)}
                      </div>
                    ) : (
                      <div className="text-cell__note"> ? </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyComponentImage text="Sin datos para mostrar" />
      )}
      {showTodos && (
        <TodoForm
          goal={currentGoal}
          todos={currentGoal.todos}
          setShowTodos={setShowTodos}
        />
      )}
      {showFeedback && (
        <Feedbacks
          goal={currentGoal}
          setShowFeedback={setShowFeedback}
          belongsToOneOfMyEmployees={belongsToOneOfMyEmployees}
        />
      )}
      {isDevolutionFormOpen && (
        <GoalDevolutionForm
          setIsDevolutionFormOpen={setIsDevolutionFormOpen}
          editingGoal={currentGoal}
        />
      )}
      {showDetail && (
        <CustomModal title="Detalle del objetivo" setIsFormOpen={setShowDetail}>
          {currentGoal.detail}
        </CustomModal>
      )}
      {showGoalForm && (
        <GoalForm setIsFormOpen={setShowGoalForm} editingGoal={currentGoal} />
      )}
      {showConfirmModal && (
        <ConfirmDeleteGoalModal
          setIsFormOpen={setShowConfirmModal}
          currentGoal={currentGoal}
        />
      )}
      {showDevolutionText && (
        <CustomModal
          title="Devolución del objetivo"
          setIsFormOpen={setShowDevolutionText}
        >
          {currentGoal.devolution}
        </CustomModal>
      )}
    </>
  );
};

export default GoalsTable;
