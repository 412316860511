import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AddButton from '../../components/AddButton';
import { getFilteredAreas } from './helpers';
import FilteredAreas from './FilteredAreas';
import AreaForm from './AreaForm';
import ServerError from '../../components/ServerError/ServerError';

import './ManageAreasPage.scss';
import LoadAreasForm from './LoadAreasForm';

const defaultFilter = {
  searchValue: '',
  level: '',
  parentArea: '',
};

const ManageAreasPage = () => {
  const { areas, evaluation, company } = useSelector(
    (store) => store.generalData
  );
  const { statusError } = useSelector((store) => store.app);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAreaFormOpen, setIsAreaFormOpen] = useState(false);
  const [isLoadAreasFormOpen, setIsLoadAreasFormOpen] = useState(false);
  const [filteredAreas, setFilteredAreas] = useState(areas);
  const [editingArea, setEditingArea] = useState(null);
  const [selectedParentArea, setSelectedParentArea] = useState(null);

  const { searchValue, level, parentArea } = filter;

  const options = [
    {
      label: 'Agregar una nueva Area',
      action: () => {
        setEditingArea(null);
        setIsAreaFormOpen(true);
      },
    },
    {
      label: 'Cargar Areas desde una planilla',
      action: () => {
        setIsLoadAreasFormOpen(true);
      },
    },
  ];

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const companyPonderations = {
    competences: company.competences,
    objetives: company.objetives,
    assistance: company.assistance,
  };

  useEffect(() => {
    setFilteredAreas(getFilteredAreas(areas, filter));
  }, [filter, areas]);

  useEffect(() => {
    if (!isAreaFormOpen) setSelectedParentArea(null);
  }, [isAreaFormOpen]);

  if (statusError) return <ServerError />;

  return (
    <div className="areas-table">
      <h3 className="areas-table__title">
        Administrar Areas
        {!evaluation && (
          <AddButton tooltip="Agregar un área" withOptions options={options} />
        )}
      </h3>
      <div className="areas-table__filters">
        <div className="areas-table__input-filter">
          <FormControl fullWidth>
            <TextField
              label="Nombre"
              autoComplete="off"
              value={searchValue}
              name="searchValue"
              onChange={handleChange}
              size="small"
            />
          </FormControl>
        </div>
        <div className="areas-table__select-filter">
          <FormControl fullWidth size="small">
            <InputLabel id="level">Nivel</InputLabel>
            <Select
              labelId="level"
              id="level"
              value={level}
              label="Nivel"
              name="level"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="l1">Primer Nivel</MenuItem>
              <MenuItem value="l2">Segundo Nivel</MenuItem>
              <MenuItem value="l2-wb">
                Segundo Nivel (depend. del Primer Nivel)
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="areas-table__select-filter">
          <FormControl fullWidth size="small">
            <InputLabel id="parentArea">Dependen de</InputLabel>
            <Select
              labelId="parentArea"
              id="parentArea"
              value={parentArea}
              label="Dependen de"
              name="parentArea"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todas</MenuItem>
              {areas
                .filter((area) => area.management)
                .map((area) => (
                  <MenuItem key={area._id} value={area._id}>
                    {area.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <FilteredAreas
        areas={filteredAreas}
        setIsAreaFormOpen={setIsAreaFormOpen}
        setEditingArea={setEditingArea}
        setSelectedParentArea={setSelectedParentArea}
        evaluation={evaluation}
        companyPonderations={companyPonderations}
      />
      {isAreaFormOpen && (
        <AreaForm
          setIsFormOpen={setIsAreaFormOpen}
          editingArea={editingArea}
          selectedParentArea={selectedParentArea}
        />
      )}
      {isLoadAreasFormOpen && (
        <LoadAreasForm setIsFormOpen={setIsLoadAreasFormOpen} />
      )}
    </div>
  );
};

export default ManageAreasPage;
