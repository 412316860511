import httpClient from '../http-client/httpClient';

export const createReport = async (data) => {
  try {
    const response = await httpClient.post('evaluation/report', data, {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
    });
    return response;
  } catch (err) {}
};
