import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { useHistory } from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ModalAreaDetail from '../ModalAreaDetail';
import { useDispatch, useSelector } from 'react-redux';
import { resetAction } from '../../../actions/modelActions';
import { areaRelationshipsModel } from '../../../constants/modelConstants';
import { getIsAreaInPath } from '../helpers';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';

const RecursiveArea = ({ areasExpanded, area, totalAreas, index, areas }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((store) => store.userSignIn);
  // const { teamUsers } = useSelector((store) => store.generalData);

  const [showChildren, setShowChildren] = useState(areasExpanded);
  const [areaDetail, setAreaDetail] = useState(null);
  const [isModalAreaDetailOpen, setIsModalAreaDetailOpen] = useState(false);

  const members =
    area.bosses.filter((boss) => boss.active).length +
    area.employees.filter((employee) => employee.active).length;

  const ownArea = userInfo.area?._id === area._id;

  const showRealtionShipsButton = userInfo.isSuperAdmin; /*  ||
    (userInfo.role === 'boss' &&
      (ownArea ||
        (area.withoutBoss &&
          area.employees.some((employee) =>
            teamUsers.find((teamUser) => teamUser._id === employee._id)
          )))) */

  const isAreaInMyAreaPathY =
    !userInfo.isSuperAdmin &&
    getIsAreaInPath(areas, area._id, userInfo.area?._id, true);

  const isAreaInMyAreaPathX =
    !userInfo.isSuperAdmin &&
    getIsAreaInPath(areas, area._id, userInfo.area?._id, false);

  const connectorClass = () => {
    if (totalAreas !== 0 && index === 0) {
      return 'connector-x--left';
      /* return isAreaInMyAreaPathX
        ? 'connector-x--left green-line'
        : 'connector-x--left'; */
    }
    if (totalAreas !== 0 && index === totalAreas - 1) {
      return 'connector-x--right';
      /* return isAreaInMyAreaPathX
        ? 'connector-x--right green-line'
        : 'connector-x--right'; */
    }
  };

  const viewDetail = (area) => {
    setAreaDetail(area);
    setIsModalAreaDetailOpen(true);
  };

  const goToRelationships = (areaId) => {
    dispatch(
      resetAction(
        areaRelationshipsModel.NAME,
        areaRelationshipsModel.INITIAL_STATE
      )
    );
    history.push(`/area/relaciones/${areaId}`);
  };

  useEffect(() => {
    setShowChildren(areasExpanded);
  }, [areasExpanded]);

  return (
    <div className="chart">
      {!area.management && totalAreas > 1 && (
        <div className={`connector-x ${connectorClass()}`}></div>
      )}
      {!area.management && (
        <div className={`connector-y ${isAreaInMyAreaPathX ? '' : ''}`}></div>
      )}
      {area.management && <div className="space-y"></div>}

      <div
        className={`area-box ${isAreaInMyAreaPathX ? 'green-line' : ''} ${
          area?._id === userInfo?.area?._id ? 'area-box--own-area' : ''
        }`}
      >
        {area.withoutBoss && (
          <span className="area-box__without-boss-icon">
            <Tooltip title="Responde al nivel superior" placement="top" arrow>
              <SwapVertOutlinedIcon fontSize="small" htmlColor="#36b29a" />
            </Tooltip>
          </span>
        )}
        <div className="area-box__name">{area.name}</div>
        <div className="area-box__bosses">
          {!area.withoutBoss && area.bosses.length === 0 && (
            <div className="area-box__no-boss">No hay Jefe asignado</div>
          )}
          {area.bosses
            .filter((boss) => boss.range === 1)
            .map((boss) => (
              <div key={boss._id} className="area-box__boss-name">
                {boss.fullname}
              </div>
            ))}
          {!area.withoutEmployees && area.employees.length === 0 && (
            <div className="area-box__no-employees">No posee empleados</div>
          )}
        </div>
        {members > 0 && (
          <div className="area-box__footer">
            <div
              className="area-box__view-detail"
              onClick={() => viewDetail(area)}
            >
              <Tooltip title="Ver integrantes del Area" placement="top" arrow>
                <span>
                  <PersonSearchOutlinedIcon />
                  <span>({members})</span>
                </span>
              </Tooltip>
            </div>
            {showRealtionShipsButton && (
              <div
                className="area-box__relationships"
                onClick={() => goToRelationships(area._id)}
              >
                <Tooltip
                  title="Ver relaciones de Evaluaciones"
                  placement="top"
                  arrow
                >
                  <InventoryOutlinedIcon fontSize="small" />
                </Tooltip>
              </div>
            )}
          </div>
        )}

        {isModalAreaDetailOpen && (
          <ModalAreaDetail
            area={areaDetail}
            setIsFormOpen={setIsModalAreaDetailOpen}
          />
        )}
      </div>

      {area.children?.length > 0 && showChildren && (
        <>
          <div
            className="toogle-button toogle-button--open"
            onClick={() => setShowChildren(false)}
          >
            <Tooltip title="Ocultar Areas" placement="bottom" arrow>
              <ArrowDropUpIcon fontSize="inherit" color="inherit" />
            </Tooltip>
          </div>
          <div
            className={`connector-y ${
              !ownArea && isAreaInMyAreaPathY ? '' : ''
            }`}
          ></div>
        </>
      )}
      {area.children?.length > 0 && showChildren && (
        <div className="children">
          {showChildren &&
            area.children.map((dependentArea, i) => (
              <RecursiveArea
                key={dependentArea._id}
                areasExpanded={areasExpanded}
                area={dependentArea}
                totalAreas={area.children.length}
                index={i}
                areas={areas}
              />
            ))}
        </div>
      )}
      {area.children?.length > 0 && !showChildren && (
        <div className="toogle-button" onClick={() => setShowChildren(true)}>
          <Tooltip
            title={`Mostrar Areas (${area.children?.length})`}
            placement="bottom"
            arrow
          >
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default RecursiveArea;
