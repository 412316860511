const Day = ({
  day,
  index,
  handleDayClick,
  showLargeEvents,
  showEvents,
  weekMode,
  highlightWeek,
}) => {
  const getDayClassNames = (day) => {
    const baseClassName = 'calendar__day';
    const classNames = [baseClassName];

    if (weekMode) classNames.push(`${baseClassName}--week-mode`);
    if (highlightWeek && day.selectedWeek)
      classNames.push(`${baseClassName}--selected-week`);
    if (day.today) classNames.push(`${baseClassName}--today`);
    if (day.selected) classNames.push(`${baseClassName}--selected`);
    if (day.currentMonth) classNames.push(`${baseClassName}--active`);

    return classNames.join(' ');
  };

  const getLargeEventClassNames = (event) => {
    const baseClassName = 'calendar__day__large-event';
    const classNames = [baseClassName];

    if (event.emptySpace) {
      classNames.push(`${baseClassName}--empty-space`);
      return classNames.join(' ');
    }

    if (event.starts) classNames.push(`${baseClassName}--starts`);
    if (event.middle) classNames.push(`${baseClassName}--middle`);
    if (event.ends) classNames.push(`${baseClassName}--ends`);

    return classNames.join(' ');
  };

  return (
    <div className={getDayClassNames(day)} onClick={() => handleDayClick(day)}>
      <div className="calendar__day__number">{day.number}</div>
      {showLargeEvents && (
        <div
          className="calendar__day__large-events"
          style={{ height: `${34 * day.maxLargeEvents}px` }}
        >
          {day.largeEvents.map((event, i) =>
            event.emptySpace ? (
              <div
                key={`empty-space-${i}`}
                className={`${getLargeEventClassNames(event)}`}
              ></div>
            ) : (
              <div
                key={event.id}
                className={`${getLargeEventClassNames(event)}`}
                style={{ paddingLeft: `${event.middleStarts ? '6px' : 0}` }}
              >
                {index === 0 || event.starts || event.middleStarts
                  ? event.title
                  : ''}
              </div>
            )
          )}
        </div>
      )}
      {showEvents && (
        <div className="calendar__day__events">
          {day.oneDayEvents?.map((event) => (
            <div key={event.id} className="calendar__day__event">
              {event.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Day;
