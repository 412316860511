import { useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressComponent from '../../../components/ProgressComponent';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { formatDate } from '../../../helpers/enums';
import TotalScore from '../TotalScore';
import { createReport } from '../../../actions/reportActions';
import ActionPlanModal from '../../../components/UserInfo/ActionPlanModal/ActionPlanModal';
import CommentsModal from '../CommentsModal';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

const Evaluation = ({ prevScore, userId }) => {
  const {
    company,
    config: { viewReport },
  } = useSelector((store) => store.generalData);

  const { userData } = useSelector((store) => store.userDetail);

  const [generatingReport, setGeneratingReport] = useState(false);
  const [error, setError] = useState(null);
  const [showCommentsnModal, setShowCommentsnModal] = useState(false);
  const [showActionPlanModal, setShowActionPlanModal] = useState(false);

  /* const hasDataForReport = () =>
    !!(
      prevScore?.evaluationName &&
      prevScore?.competences &&
      prevScore?.assistance &&
      prevScore?.objetives
    ); */

  const hasDataForReport = () => true;

  const generateReport = async () => {
    setError(null);
    setGeneratingReport(true);
    try {
      const response = await createReport({ score: prevScore, userId });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      setGeneratingReport(false);
    } catch (err) {
      setError('No se pudo generar el reporte');
      setGeneratingReport(false);
    }
  };

  const getRelatedActionPlan = userData?.previousActionPlans?.find(
    (ap) => ap.evaluationId === prevScore.evaluationId
  );

  return (
    <div className="previous-evaluations__evaluation">
      {showCommentsnModal && (
        <CommentsModal
          setIsFormOpen={setShowCommentsnModal}
          comments={prevScore?.comments}
        />
      )}
      {showActionPlanModal && (
        <ActionPlanModal
          setIsFormOpen={setShowActionPlanModal}
          currentActionPlan={getRelatedActionPlan}
          showButtons={false}
        />
      )}
      {hasDataForReport() ? (
        <>
          <div className="previous-evaluations__evaluation__data">
            <div className="previous-evaluations__evaluation__name">
              {prevScore?.evaluationName}
            </div>
            <div className="previous-evaluations__evaluation__date">
              {prevScore?.date &&
                `Finalizada el ${formatDate(prevScore?.date)}`}
            </div>
            <div className="previous-evaluations__report">
              {prevScore?.comments && (
                <Tooltip title="Ver comentarios" placement="top" arrow>
                  <IconButton onClick={() => setShowCommentsnModal(true)}>
                    <CommentOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Ver plan de acción" placement="top" arrow>
                <span>
                  <IconButton
                    onClick={() => setShowActionPlanModal(true)}
                    disabled={!getRelatedActionPlan?.text.length}
                  >
                    <AutoGraphOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>

              {viewReport && (
                <Tooltip title="Generar informe" placement="top" arrow>
                  <IconButton onClick={generateReport}>
                    <AssignmentIndOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}

              {generatingReport && (
                <span className="previous-evaluations__generating-report">
                  <CircularProgress size={20} />
                  <span>Generando el informe ...</span>
                </span>
              )}
              {error && (
                <span className="previous-evaluations__report-error">
                  {error}
                </span>
              )}
            </div>
          </div>
          <div className="previous-evaluations__evaluation__progress">
            <ProgressComponent
              value={prevScore?.competences}
              color="competences"
              title="Competencias"
              widthDetail
              competencesScores={prevScore?.competencesScores}
            />
            <ProgressComponent
              value={prevScore?.objetives}
              color="objetives"
              title="Objetivos"
            />
            {(!userData.area?.hasOwnPonderations ||
              userData.area?.ponderations.assistance > 0) && (
              <ProgressComponent
                value={prevScore?.assistance}
                color="assistance"
                title="Presentismo"
              />
            )}
          </div>
          <TotalScore company={company} prevScore={prevScore} />
        </>
      ) : (
        <div className="previous-evaluations__no-data">
          {prevScore?.evaluationName && <p>{prevScore?.evaluationName}</p>}
          <h4>
            Para esta Evaluación no hay datos para mostrar ni generar reporte.
          </h4>
        </div>
      )}
    </div>
  );
};

export default Evaluation;
