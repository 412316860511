import CompetenceUserResponse from '../CompetenceUserResponse/CompetenceUserResponse';
import getAnswerLetter from '../EvaluationForm/helpers';
import UserComments from '../UserComments';

const CompetenceMultipleResponse = ({
  competence,
  currentType,
  affectedUsers,
  answers,
  showComments,
  setValue,
  done,
  reOpen,
}) => {
  return affectedUsers ? (
    !showComments ? (
      <div className="competence-response">
        <div className="competence-response__container">
          <div className="competence-response__questions">
            <div className="competence-response__label">
              1 - Elegí la opción que más lo identifique:
            </div>
            {competence.behaviourQuestionOptions.map((option, i) => (
              <div
                key={`${option.value}-${i}`}
                className="competence-response__option competence-response__option--disabled"
              >
                <div className="competence-response__option__label">
                  {getAnswerLetter(i)} -
                  {currentType === 'self'
                    ? ` ${option.label}`
                    : ` ${option.labelOthers}`}
                </div>
              </div>
            ))}

            <div className="competence-response__label">
              2 - Tenés un máximo de 5 puntos y un mínimo de 1 punto para
              asignarle en esta competencia.
            </div>
          </div>
        </div>
        <div className="competence-response__users">
          {affectedUsers.map((data) => (
            <CompetenceUserResponse
              key={data._id}
              data={data}
              competence={competence}
              answers={answers}
              setValue={setValue}
              done={done}
              reOpen={reOpen}
            />
          ))}
        </div>
      </div>
    ) : (
      affectedUsers.map((data, i) => (
        <UserComments
          key={`comment-${i}`}
          data={data}
          answers={answers}
          competence={competence}
          setValue={setValue}
          done={done}
          reOpen={reOpen}
        />
      ))
    )
  ) : (
    <div className="evaluation-form__empty">
      No hay roles asociados para realizar esta evaluación
    </div>
  );
};

export default CompetenceMultipleResponse;
