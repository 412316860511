import { Link } from 'react-router-dom';
import moment from 'moment';
import './Reminder.scss';

const Reminder = ({ reminder }) => {
  return (
    <div key={reminder._id} className="reminder">
      <div className="reminder__date">
        {moment(reminder.date).format('D MMM')}
      </div>
      <div className="reminder__text">
        {reminder.content} &nbsp;
        {reminder.redirect && (
          <Link to={reminder.redirectUrl} className="reminder__redirect">
            {<div className="reminder__label">{reminder.redirectLabel}</div>}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Reminder;
