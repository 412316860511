import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

const AddButton = ({ tooltip, onClick, withOptions, options, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    setAnchorEl(null);
    action && action();
  };

  return (
    <>
      <Tooltip title={tooltip} placement="top" arrow>
        <Button
          id="add-button"
          className="add-button"
          onClick={(e) => (withOptions ? handleClick(e) : onClick())}
          {...props}
        >
          <AddIcon fontSize="small" />
        </Button>
      </Tooltip>
      {withOptions && options && (
        <Menu
          id="add-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              transform: 'translateY(14px)',
            },
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {options.map((option, i) => (
            <MenuItem
              key={`${option?.label}-${i}`}
              onClick={() => handleActionClick(option?.action)}
            >
              {option?.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default AddButton;
