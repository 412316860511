import './GoalDetails.scss';

const GoalDetails = ({ goal }) => {
  const { title, toUserId, toUsersIds, group } = goal;
  return (
    <div className="goal__details">
      <p>
        <b>{title}</b>
      </p>
      {group ? (
        <div className="group__usernames">
          Asignado a:
          {toUsersIds.map((user) => (
            <b className="goal__user-name" key={user._id}>
              {user?.name} {user?.lastname}
            </b>
          ))}
        </div>
      ) : (
        <div className="single__username">
          Asignado a:
          <b className="goal__user-name">
            {toUserId?.name} {toUserId?.lastname}
          </b>
        </div>
      )}
    </div>
  );
};

export default GoalDetails;
