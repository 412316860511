import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../../actions/modelActions';
import { evaluationProcess } from '../../../constants/modelConstants';
import EvaluationForm from '../EvaluationForm';
import EvaluationPending from '../EvaluationPending';

import './OpenEvaluationProcess.scss';

const OpenEvaluationProcess = ({ hasPendingTask }) => {
  const dispatch = useDispatch();

  const { sending } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { pendingTasksToOpen } = useSelector(
    (store) => store.evaluationProcess
  );

  const [isEvaluationFormOpen, setIsEvaluationFormOpen] = useState(false);

  const handleAddButtonClick = () => {
    setIsEvaluationFormOpen(true);
  };

  useEffect(() => {
    dispatch(
      getAction(evaluationProcess.NAME, evaluationProcess.PENDING_TASKS_TO_OPEN)
    );
  }, [dispatch]);

  return pendingTasksToOpen ? (
    <>
      <div className="create-evaluation__no-evaluation">
        <p>No hay proceso de evaluaciones abierto</p>
        {userInfo.isSuperAdmin && (
          <Button
            onClick={handleAddButtonClick}
            variant="contained"
            size="large"
            disabled={
              !pendingTasksToOpen.isOpenActionEnabled ||
              sending ||
              hasPendingTask
            }
          >
            Comenzar Proceso de Evaluación
          </Button>
        )}
      </div>
      <EvaluationPending openProcess pendingTasks={pendingTasksToOpen} />
      {isEvaluationFormOpen && (
        <EvaluationForm setIsOpenForm={setIsEvaluationFormOpen} />
      )}
    </>
  ) : null;
};

export default OpenEvaluationProcess;
