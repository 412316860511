import { Button, Rating } from '@mui/material';
import { FormControl, TextField } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { demandModel } from '../../../constants/modelConstants';
import { postAction } from '../../../actions/modelActions';
import UserAutocomplete from '../../../components/UserAutocomplete/UserAutocomplete';
import CustomModal from '../../../components/CustomModal';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './DemandsForm.scss';

const DemandsForm = ({ setIsFormOpen }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);

  const initialForm = {
    toUserId: '',
    title: '',
    description: '',
    priority: 1,
    expiredDate: null,
  };
  const [formData, setFormData] = useState(initialForm);
  const { toUserId, title, description, priority, expiredDate } = formData;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnChange = (userId) => {
    setFormData({ ...formData, toUserId: userId });
  };

  const handleDateChange = (value, momentDate) => {
    const formatedDate = momentDate.format('YYYY-MM-DD');
    setFormData({ ...formData, [value]: formatedDate });
  };

  const handlePriorityChange = (_, priority) =>
    setFormData({ ...formData, priority });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postAction(demandModel.NAME, demandModel.URL, formData));
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Nueva Solicitud" setIsFormOpen={setIsFormOpen}>
      <div>
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <UserAutocomplete handleOnChange={handleOnChange} />
          </FormControl>
        </div>
        <TextField
          label="Título"
          variant="outlined"
          name="title"
          value={title}
          onChange={handleChange}
          fullWidth
        />
        <textarea
          className="demand-form-textarea"
          name="description"
          value={description}
          onChange={handleChange}
          placeholder="Descripción"
        />

        <div className="form-input-wrapper">
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Fecha vencimiento"
                inputFormat="DD/MM/YYYY"
                value={expiredDate}
                onChange={(momentDate) =>
                  handleDateChange('expiredDate', momentDate)
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="demand-form-stars-container">
          <span>Prioridad:</span>
          <Rating
            icon={<CircleIcon fontSize="small" />}
            emptyIcon={<CircleOutlinedIcon fontSize="small" />}
            name="priority"
            value={priority}
            max={3}
            onChange={handlePriorityChange}
          />
        </div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            sending || !toUserId || !title || !expiredDate || !description
          }
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default DemandsForm;
