import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRelatedUsers } from '../../../actions/innerStateActions';
import NineBox from '../NineBox';
import SurveyAverage from '../SurveyAverage';
import { alphabeticOrder } from '../../CompanyStatsPage/helpers';

import './AreaFilter.scss';

const AreaFilter = ({ availableAreas, areas }) => {
  const { evaluation } = useSelector((store) => store.generalData);

  const [areasSelectedIds, setAreasSelectedIds] = useState([
    availableAreas[0]._id,
  ]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [surveyAverage, setSurveyAverage] = useState(null);

  const handleChange = async (e) => setAreasSelectedIds(e.target.value);
  const getAreaLabel = (areaId) => {
    return areas.find((area) => area._id === areaId).name;
  };

  useEffect(() => {
    if (areasSelectedIds.length === 0) {
      setFilteredUsers([]);
      return;
    }
    const getAreasStatics = async () => {
      try {
        const response = await getRelatedUsers(areasSelectedIds);
        if (response?.areas.length > 0) {
          const newFilteredUsers = [];
          response.areas.forEach((area) =>
            newFilteredUsers.push(...area.employees, ...area.bosses)
          );
          setFilteredUsers(newFilteredUsers);
          setSurveyAverage(response.surveyAverage);
        }
      } catch (e) {
        //TODO manejar el error
      }
    };
    getAreasStatics();
  }, [areasSelectedIds]);

  return (
    <>
      <div className="area-filter">
        <h3>Nine Box</h3>
        <div className="area-filter__header-container">
          <FormControl className="area-filter__search-container">
            <InputLabel id="area-filter-select-label">Areas</InputLabel>
            <Select
              multiple
              name="area"
              label="Areas"
              value={areasSelectedIds}
              onChange={handleChange}
              disabled={availableAreas.length === 1}
              input={<OutlinedInput label="areas" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected?.map((value) => (
                    <Chip key={value} label={getAreaLabel(value)} />
                  ))}
                </Box>
              )}
            >
              {availableAreas.sort(alphabeticOrder).map((area) => (
                <MenuItem value={area._id} key={area._id}>
                  <Checkbox checked={areasSelectedIds.includes(area._id)} />
                  <ListItemText primary={area.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {surveyAverage ? (
            <SurveyAverage surveyAverage={surveyAverage} />
          ) : (
            <div className="survey-average-wrapper survey-average-wrapper--no-data">
              No hay datos para evaluar el promedio de satisfacción del área
            </div>
          )}
        </div>
        <NineBox evaluation={evaluation} filteredUsers={filteredUsers} />
      </div>
    </>
  );
};

export default AreaFilter;
