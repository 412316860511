import { useSelector } from 'react-redux';
import ServerError from '../../components/ServerError';
import CloseEvaluationProcess from './CloseEvaluationProcess/CloseEvaluationProcess';
import OpenEvaluationProcess from './OpenEvaluationProcess/OpenEvaluationProcess';

import './ManageGlobalEvaluationPage.scss';

const ManageGlobalEvaluationPage = () => {
  const { evaluation } = useSelector((store) => store.generalData);
  const { config } = useSelector((store) => store.generalData);

  const { pending } = config?.pendingTask || {};

  const { statusError } = useSelector((store) => store.app);

  if (statusError) return <ServerError />;

  return (
    <div className="create-evaluation">
      <h3>Administrar Proceso de Evaluaciones</h3>

      <div className="create-evaluation__warnings">
        <p>Recordá que durante el proceso de Evaluación no podrás:</p>
        <div className="create-evaluation__list">
          <ul>
            <li>Cargar usuarios nuevos en la aplicación.</li>
            <li>
              Cambiar usuarios de área, cambiarles el rol o cambiar su estado
              activo/inactivo.
            </li>
            <li>Crear objetivos.</li>
          </ul>
          <ul>
            <li>Crear, modificar o eliminar áreas.</li>
            <li>
              Cambiar los porcentajes de influencia de competencias, objetivos y
              presentismo.
            </li>
            <li>Cambiar las relaciones para las evaluaciones.</li>
          </ul>
        </div>
      </div>

      <div className="create-evaluation__actions">
        {evaluation ? (
          <CloseEvaluationProcess
            evaluation={evaluation}
            hasPendingTask={pending}
          />
        ) : (
          <OpenEvaluationProcess hasPendingTask={pending} />
        )}
      </div>
    </div>
  );
};

export default ManageGlobalEvaluationPage;
