const formValidation = (competenceId, answers) => {
  if (!answers) {
    return true;
  }
  const currentAnswers = answers[competenceId];

  let isFormInvalid = true;

  Object.keys(currentAnswers).forEach((userId) => {
    if (userId !== 'bonusPointValue') {
      if (
        !currentAnswers[userId].behaviour.value ||
        !currentAnswers[userId].score.value
      ) {
        isFormInvalid = true;
      } else {
        isFormInvalid = false;
      }
    }
  });

  return isFormInvalid;
};

export default formValidation;
