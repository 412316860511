export const getFilteredGoals = (goals, filter, myUserId, userInfo) => {
  const { source, selectedUserId, devolution, evaluation } = filter;
  let filteredGoals = goals || [];

  if (source !== 'all') {
    if (source === 'created-by-me') {
      filteredGoals = filteredGoals.filter((goal) =>
        userInfo.area?.bosses?.includes(goal.createUserId._id)
      );
    } else {
      filteredGoals = filteredGoals.filter(
        (goal) =>
          goal.toUserId?._id === myUserId ||
          goal.toUsersIds.some((user) => user._id === myUserId)
      );
    }
  }

  if (source === 'created-by-me') {
    if (selectedUserId) {
      filteredGoals = filteredGoals.filter(
        (goal) =>
          goal.toUserId?._id === selectedUserId ||
          goal.toUsersIds.some((user) => user._id === selectedUserId)
      );
    }
  }

  if (devolution && devolution !== 'all') {
    if (devolution === 'with-devolution') {
      filteredGoals = filteredGoals.filter((goal) => goal.note !== 0);
    } else {
      filteredGoals = filteredGoals.filter((goal) => goal.note === 0);
    }
  }

  if (evaluation && evaluation !== 'all') {
    filteredGoals = filteredGoals.filter(
      (goal) => goal.evaluationName === evaluation
    );
  }

  return filteredGoals;
};
