import { useEffect } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router';
import PrivateRoutes from './pages/PrivateRoutes';
import LoginPage from './pages/LoginPage/LoginPage';
import moment from 'moment';
import 'moment/locale/es';
import { io } from 'socket.io-client';
import MemoPage from './pages/MemoPage/MemoPage';
import SocketProvider from './context/socketContext';
moment.locale('es');

const socket = io(`${process.env.REACT_APP_API_URL}`);

const App = () => {
  return (
    <SocketProvider socket={socket}>
      <div className="app">
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/memo-game" component={MemoPage} />
              <Route path="/" component={PrivateRoutes} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </SocketProvider>
  );
};

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    const moveToTop = document.addEventListener('moveToTop', () =>
      window.scrollTo({ top: 0, behavior: 'smooth' }, { pasive: true })
    );
    return () => document.removeEventListener('moveToTop', moveToTop);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [location]);

  return <>{props.children}</>;
};

export default App;
