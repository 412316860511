import {
  Button,
  FormControl,
  TextField,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAction,
  postAction,
  putAction,
} from '../../../actions/modelActions';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CustomModal from '../../../components/CustomModal/CustomModal';
import './UserForm.scss';
import {
  allUsersModel,
  generalDataModel,
} from '../../../constants/modelConstants';

const UserForm = ({ selectedUser, selectedAreaId, setIsFormOpen }) => {
  const initialForm = {
    name: selectedUser?.name || '',
    lastname: selectedUser?.lastname || '',
    email: selectedUser?.email || '',
    password: '',
    isAdmin: selectedUser?.isAdmin || false,
    role: selectedUser?.role || 'employee',
    area: selectedUser?.area?._id || selectedAreaId || '',
    entry: selectedUser?.entry || null,
    profiles: selectedUser?.profiles || [],
    roleLabel: selectedUser?.roleLabel || '',
    range: selectedUser?.range || 1,
  };

  const [formData, setFormData] = useState(initialForm);
  const [filteredAreas, setFilteredAreas] = useState([]);
  const {
    name,
    lastname,
    email,
    password,
    isAdmin,
    role,
    area,
    profiles,
    entry,
    roleLabel,
    range,
  } = formData;
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { areas, evaluation, userProfiles } = useSelector(
    (store) => store.generalData
  );

  const isDisabled = !!evaluation && !!selectedUser;

  const handleChange = (e) => {
    if (e.target.name === 'role' && e.target.value === 'boss') {
      setFormData({
        ...formData,
        isAdmin: 'false',
        [e.target.name]: e.target.value,
      });
    } else if (
      e.target.name === 'area' &&
      areas.find((area) => area._id === e.target.value)?.withoutEmployees
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        role: 'boss',
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDateChange = (momentDate) => {
    const entryDate = momentDate.format('YYYY-MM-DD');
    setFormData({ ...formData, entry: entryDate });
  };

  const handleMultiChange = ({ target }) => {
    const profileId = target.value[target.value.length - 1];
    const isProfileAdded = profiles.some(
      (profile) => profile._id === profileId
    );

    if (isProfileAdded) {
      const newProfilesValues = profiles.filter(
        (profile) => profile._id !== profileId
      );
      setFormData({
        ...formData,
        profiles: newProfilesValues,
      });
    } else {
      const newProfile = userProfiles.find(
        (profile) => profile._id === profileId
      );
      setFormData({
        ...formData,
        profiles: [...profiles, newProfile],
      });
    }
  };

  const updateUser = (profilesIds) => {
    let data = { ...formData };
    const fullname = `${data.name} ${data.lastname}`;
    data.fullname = fullname;

    if (selectedUser) {
      delete data.password;
    }

    if (data.area?.length === 0) {
      data.area = null;
    }

    const callGeneralData = () => {
      dispatch(getAction(generalDataModel.NAME, generalDataModel.URL, false));
    };

    dispatch(
      putAction(
        allUsersModel.NAME,
        `${allUsersModel.URL}/${selectedUser._id}`,
        {
          ...data,
          profiles: profilesIds,
        },
        true,
        callGeneralData
      )
    );
  };

  const postUser = (profilesIds) => {
    let data = { ...formData };
    if (!areas.length || data.area?.length === 0) {
      data.area = null;
    }

    dispatch(
      postAction(
        allUsersModel.NAME,
        allUsersModel.REGISTER_URL,
        {
          ...data,
          profiles: profilesIds,
        },
        () => dispatch(getAction(generalDataModel.NAME, generalDataModel.URL))
      )
    );
  };

  const handleSubmit = () => {
    const profilesIds =
      isAdmin === true || isAdmin === 'true'
        ? profiles.map((profile) => profile._id)
        : [];
    selectedUser ? updateUser(profilesIds) : postUser(profilesIds);
  };

  const itemStyles = (currentProfile) => {
    const isProfileAdded = profiles.some(
      (profile) => profile._id === currentProfile._id
    );
    return isProfileAdded ? { color: 'silver' } : { color: 'inherit' };
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  useEffect(() => {
    if (formData.role === 'employee') {
      setFilteredAreas(areas);
    } else {
      setFilteredAreas(areas.filter((area) => !area.withoutBoss));
    }
  }, [areas, formData.role]);

  return (
    <CustomModal
      title={selectedUser ? 'Editar usuario' : 'Dar de alta un usuario'}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="register-form">
        <div className="register-form__container">
          <div className="register-form__container__side side-left">
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  label="Nombre"
                  name="name"
                  autoComplete="off"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  label="Apellido"
                  name="lastname"
                  autoComplete="off"
                  value={lastname}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  autoComplete="off"
                  value={email}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </div>
            {!selectedUser && (
              <div className="form-input-wrapper">
                <FormControl fullWidth>
                  <TextField
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </div>
            )}
            {filteredAreas.length > 0 && (
              <div className="form-input-wrapper">
                <FormControl fullWidth>
                  <InputLabel id="area">Area</InputLabel>
                  <Select
                    labelId="area"
                    id="area"
                    value={area}
                    label="Area"
                    name="area"
                    onChange={handleChange}
                    disabled={isDisabled}
                  >
                    {filteredAreas.map((area) => (
                      <MenuItem value={area._id} key={area._id}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
          <div className="register-form__container__side">
            <div className="form-input-wrapper register-form__role-control">
              <FormControl fullWidth>
                <FormLabel component="legend">Rol</FormLabel>
                <RadioGroup
                  row
                  aria-label="children"
                  name="role"
                  onChange={handleChange}
                  value={role}
                >
                  <FormControlLabel
                    value="boss"
                    control={<Radio color="primary" />}
                    label="Jefe"
                    disabled={
                      isDisabled ||
                      areas.find((a) => a._id.toString() === area.toString())
                        ?.withoutEmployees
                    }
                  />
                  <FormControlLabel
                    value="employee"
                    control={<Radio color="primary" />}
                    label="Empleado"
                    disabled={
                      isDisabled ||
                      areas.find((a) => a._id.toString() === area.toString())
                        ?.withoutEmployees
                    }
                  />
                </RadioGroup>
              </FormControl>
              {role === 'boss' && (
                <div className="register-form__range">
                  <FormControl fullWidth>
                    <TextField
                      label="Rango"
                      name="range"
                      type="number"
                      autoComplete="off"
                      inputProps={{ min: 1, max: 2 }}
                      value={range}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              )}
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <TextField
                  label="Puesto de trabajo"
                  name="roleLabel"
                  type="roleLabel"
                  autoComplete="off"
                  value={roleLabel}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Fecha de ingreso"
                    inputFormat="DD/MM/YYYY"
                    value={entry}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <FormLabel component="legend">¿Es administrador?</FormLabel>
                <RadioGroup
                  row
                  aria-label="children"
                  name="isAdmin"
                  onChange={handleChange}
                  value={isAdmin}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        {(isAdmin === 'true' || isAdmin === true) && (
          <div className="form-input-wrapper multi-select">
            <FormControl fullWidth>
              <InputLabel id="profiles">Perfiles</InputLabel>
              <Select
                labelId="profiles"
                id="profiles"
                multiple
                value={profiles}
                label="Perfiles"
                onChange={handleMultiChange}
                input={<OutlinedInput id="profiles" label="Perfiles" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value._id} label={value.label} />
                    ))}
                  </Box>
                )}
              >
                {userProfiles.map((userProfile) => (
                  <MenuItem
                    key={userProfile._id}
                    value={userProfile._id}
                    style={itemStyles(userProfile)}
                  >
                    {userProfile.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            sending ||
            !name ||
            !lastname ||
            !email ||
            (!selectedUser && !password)
          }
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default UserForm;
