import { Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAction, postAction } from '../../actions/modelActions';
import { currentEvaluationsModel } from '../../constants/modelConstants';
import CurrentEvaluation from './CurrentEvaluation';
import BackButton from '../../components/BackButton';

import './EvaluationsPage.scss';

const EvaluationsPage = () => {
  const dispatch = useDispatch();
  const { user, currentEvaluations } = useSelector(
    (store) => store.currentEvaluations
  );
  const { evaluation } = useSelector((store) => store.generalData);
  const { success } = useSelector((store) => store.app);

  const { userId } = useParams();

  const [selectedEvaluations, setSelectedEvaluations] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleAllCheck = (e) => {
    if (!isAllChecked) {
      setSelectedEvaluations(
        currentEvaluations
          .filter((e) => !e.done)
          .map((evaluation) => evaluation._id)
      );
    } else {
      setSelectedEvaluations([]);
    }
  };

  const handleClick = () => {
    dispatch(
      postAction(
        currentEvaluationsModel.NAME,
        `${currentEvaluationsModel.URL}/${evaluation._id}/${userId}`,
        { evaluationIds: selectedEvaluations }
      )
    );
  };

  useEffect(() => {
    if (evaluation) {
      dispatch(
        getAction(
          currentEvaluationsModel.NAME,
          `${currentEvaluationsModel.URL}/${evaluation._id}/${userId}`
        )
      );
    }
  }, [dispatch, userId, evaluation]);

  useEffect(() => {
    if (currentEvaluations) {
      setIsAllChecked(selectedEvaluations.length === currentEvaluations.length);
    } else {
      setIsAllChecked(false);
    }
  }, [currentEvaluations, selectedEvaluations]);

  useEffect(() => {
    if (success) {
      setSelectedEvaluations([]);
    }
  }, [success]);

  return user && currentEvaluations ? (
    <div className="current-evaluations-page">
      <h3 className="current-evaluations-page__title">
        Evaluaciones actuales <BackButton />
      </h3>
      <div className="current-evaluations-page__header">
        <div className="current-evaluations-page__user">
          <b>Usuario:</b> {user.fullname}
        </div>
        {currentEvaluations.length > 0 && (
          <div className="current-evaluations-page__actions">
            <Button
              variant="contained"
              disabled={selectedEvaluations.length === 0}
              onClick={handleClick}
            >
              Eliminar evaluaciones
            </Button>
            <div className="current-evaluations-page__evaluation-checkbox">
              <Checkbox
                checked={isAllChecked}
                onChange={handleAllCheck}
                disabled={currentEvaluations.every((ce) => ce.done)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="current-evaluations-page__evaluations">
        {currentEvaluations.length > 0 ? (
          currentEvaluations.map((evaluation) => (
            <CurrentEvaluation
              key={evaluation._id}
              evaluation={evaluation}
              selectedEvaluations={selectedEvaluations}
              setSelectedEvaluations={setSelectedEvaluations}
            />
          ))
        ) : (
          <div>No existen evaluaciones para este usuario.</div>
        )}
      </div>
    </div>
  ) : null;
};

export default EvaluationsPage;
