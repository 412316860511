export const conditionRiesgo = (user) =>
  user?.score?.competences < 40 && user?.score?.objetives < 40;

export const conditionDilema = (user) =>
  user?.score?.competences >= 40 &&
  user?.score?.competences < 80 &&
  user?.score?.objetives < 40;

export const conditionEnigma = (user) =>
  user?.score?.competences >= 80 && user?.score?.objetives < 40;

export const conditionEfectivo = (user) =>
  user?.score?.competences < 40 &&
  user?.score?.objetives >= 40 &&
  user?.score?.objetives < 80;

export const conditionClave = (user) =>
  user?.score?.competences >= 40 &&
  user?.score?.competences < 80 &&
  user?.score?.objetives >= 40 &&
  user?.score?.objetives < 80;

export const conditionDesarrollar = (user) =>
  user?.score?.competences >= 80 &&
  user?.score?.objetives >= 40 &&
  user?.score?.objetives < 80;

export const conditionConfiable = (user) =>
  user?.score?.competences < 40 && user?.score?.objetives >= 80;

export const conditionImpacto = (user) =>
  user?.score?.competences >= 40 &&
  user?.score?.competences < 80 &&
  user?.score?.objetives >= 80;

export const conditionEstrella = (user) =>
  user?.score?.competences >= 80 && user?.score?.objetives >= 80;
