import { useEffect, useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { generalDataModel } from '../../../constants/modelConstants';

import './CompetenceModalForm.scss';

const CompetenceModalForm = ({ competence, setIsFormOpen }) => {
  const dispatch = useDispatch();

  const initialData = {
    behaviourQuestionOptions: competence.behaviourQuestionOptions,
    bonusQuestionLabel: competence.bonusQuestionLabel,
    description: competence.description,
    name: competence.name,
  };

  const { sending, success } = useSelector((store) => store.app);

  const [formData, setFormData] = useState(initialData);

  const { behaviourQuestionOptions, bonusQuestionLabel, description, name } =
    formData;

  const options = ['Opción a', 'Opción b', 'Opción c', 'Opción d'];

  const handleChange = ({ target: { name, value } }, index, property) => {
    const newFormData = { ...formData };
    if (property) {
      const newValues = newFormData[name];
      newValues[index][property] = value;
      setFormData({ ...newFormData, [name]: newValues });
    } else {
      setFormData({ ...newFormData, [name]: value });
    }
  };

  const handleSave = () => {
    dispatch(
      putAction(
        generalDataModel.NAME,
        `${generalDataModel.UPDATE_COMPETENCE}/${competence._id}`,
        formData
      )
    );
  };

  useEffect(() => {
    if (success) setIsFormOpen(false);
  }, [setIsFormOpen, success]);

  return (
    <CustomModal title="Editar Competencia" setIsFormOpen={setIsFormOpen}>
      <div className="competence-modal-form">
        <div className="form-control">
          <TextField
            label="Nombre"
            value={name}
            name="name"
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div className="form-control">
          <TextField
            label="Descripción"
            value={description}
            name="description"
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
          />
        </div>
        <hr />
        <h4>Opciones pregunta comportamiento para autoevaluación</h4>
        {options.map((optionLabel, i) => (
          <div className="form-control" key={`option-${i}`}>
            <TextField
              label={optionLabel}
              value={behaviourQuestionOptions[i].label}
              name="behaviourQuestionOptions"
              onChange={(e) => handleChange(e, i, 'label')}
              multiline
              rows={3}
              fullWidth
            />
          </div>
        ))}
        <hr />
        <h4>Opciones pregunta comportamiento para evaluar a otros</h4>
        {options.map((optionLabel, i) => (
          <div className="form-control" key={`option-others-${i}`}>
            <TextField
              label={optionLabel}
              value={behaviourQuestionOptions[i].labelOthers}
              name="behaviourQuestionOptions"
              onChange={(e) => handleChange(e, i, 'labelOthers')}
              multiline
              rows={3}
              fullWidth
            />
          </div>
        ))}
        <hr />
        <div className="form-control">
          <TextField
            label="Pregunta punto bonus"
            value={bonusQuestionLabel}
            name="bonusQuestionLabel"
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
          />
        </div>
        <div className="competence-modal-form__actions">
          <Button variant="contained" onClick={handleSave} disabled={sending}>
            Guardar
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default CompetenceModalForm;
