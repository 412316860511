import CustomModal from '../CustomModal/CustomModal';
import { Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import './ConfirmActionModal.scss';

const ConfirmActionModal = ({
  title,
  message,
  setIsFormOpen,
  handleConfirmAction,
}) => {
  const { success } = useSelector((store) => store.app);

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="" setIsFormOpen={setIsFormOpen}>
      <div className="confirm-modal__warning-icon-container">
        <ErrorIcon
          fontSize="large"
          htmlColor="#dda312"
          className="warning-icon"
        />
      </div>
      <div className="confirm-modal__title">{title}</div>
      <div className="warning-message">{message}</div>

      <div className="confirm-modal__actions">
        <Button onClick={handleConfirmAction} variant="contained">
          Confirmar
        </Button>
        <Button onClick={() => setIsFormOpen(false)} variant="outlined">
          Cancelar
        </Button>
      </div>
    </CustomModal>
  );
};

export default ConfirmActionModal;
