import { useState } from 'react';
import AddUserModal from '../AddUserModal/AddUserModal';
import SugestedUsers from '../SugestedUsers/SugestedUsers';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

import './EvaluationType.scss';

export const EvaluationType = ({
  title,
  forRole,
  icon,
  type,
  selectedUser,
  affectedUsers,
  setAffectedUsers,
  setHasChanges,
  hideAddUserButton = false,
}) => {
  const { evaluation } = useSelector((store) => store.generalData);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  return (
    <div className="evaluation-type">
      <div className="evaluation-type__header">
        <h4>
          {title} {icon}
        </h4>
        {forRole === selectedUser.role && !hideAddUserButton && (
          <Tooltip title="Agregar Usuarios" placement="top" arrow>
            <span>
              <IconButton
                disabled={!!evaluation}
                onClick={() => setIsAddUserModalOpen(true)}
              >
                <PersonAddAltOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
      <div className="evaluation-type__content">
        <div className="evaluation-type__sugested-users">
          <SugestedUsers
            affectedUsers={affectedUsers}
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        </div>
      </div>
      {isAddUserModalOpen && (
        <AddUserModal
          setIsFormOpen={setIsAddUserModalOpen}
          title={title}
          forRole={forRole}
          type={type}
          affectedUsers={affectedUsers}
          setAffectedUsers={setAffectedUsers}
          setHasChanges={setHasChanges}
        />
      )}
    </div>
  );
};
