const paths = {
  '/reconocimientos': 'RECOGNITION',
  '/solicitudes': 'DEMAND',
  '/evaluaciones': 'EVALUATION',
  '/objetivos/actuales': 'GOAL',
};

// Valida que para la url actual haya un tipo de notificaciones relacionado
// Y ademas que haya notificaciones de ese tipo entre las notificaciones
// Si se cumplen ambas condiciones retorna el tipo para ser borradas las notificaciones
export const hasRelatedNotificationType = (pathname, notifications) => {
  if (
    paths[pathname] &&
    notifications.some((n) => n.type === paths[pathname])
  ) {
    return paths[pathname];
  }

  return null;
};
