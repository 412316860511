import './EvaluationsCompleted.scss';

const EvaluationsCompleted = () => {
  return (
    <div className="evaluations-completed-message">
      <p>
        ¡Felicitaciones! ¡Completaste todas las evaluaciones programadas hasta
        el momento! Te recomendamos que estés atento durante el periodo de
        evaluación, ya que existe la posibilidad de que se añada alguna
        evaluación adicional antes de que concluya el proceso.
      </p>
    </div>
  );
};

export default EvaluationsCompleted;
