import styled from '@emotion/styled';
import { Tooltip, tooltipClasses } from '@mui/material';

const ReactionsQtyTooltip = ({ reactions, children, userId }) => {
  return (
    <CustomTooltip
      title={
        <ReactionsQtyTooltipComponent reactions={reactions} userId={userId} />
      }
      placement="top"
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

const ReactionsQtyTooltipComponent = ({ reactions, userId }) => {
  return (
    <ul style={{ maxHeight: '200px', overflow: 'auto' }}>
      {reactions.map(
        (r, i) =>
          i < 5 && (
            <li key={r._id} style={{ margin: '3px 0', fontSize: '10px' }}>
              {r.userId === userId ? 'Vos' : r.fullname}
            </li>
          )
      )}
      {reactions.length > 5 && (
        <li style={{ margin: '3px 0', fontSize: '10px' }}>
          Y {reactions.length - 5} más...
        </li>
      )}
    </ul>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    padding: 5,
  },
}));

export default ReactionsQtyTooltip;
