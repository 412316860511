import { Checkbox } from '@mui/material';
import DirectionIcon from '../../../components/DirectionIcon';
import { formatDate } from '../../../helpers/enums';

const CurrentEvaluation = ({
  evaluation,
  selectedEvaluations,
  setSelectedEvaluations,
}) => {
  const isChecked = (evaluationId) => {
    return !!selectedEvaluations.find((evalId) => evalId === evaluationId);
  };

  const handleEvaluationCheck = (e, evaluationId) => {
    if (e.target.checked) {
      setSelectedEvaluations((selectedEvaluations) => [
        ...selectedEvaluations,
        evaluationId,
      ]);
    } else {
      setSelectedEvaluations((selectedEvaluations) =>
        selectedEvaluations.filter((evalId) => evalId !== evaluationId)
      );
    }
  };

  return (
    <div key={evaluation._id} className="current-evaluations-page__evaluation">
      <div className="current-evaluations-page__evaluation-header">
        <div className="current-evaluations-page__evaluation-data">
          <div className="current-evaluations-page__evaluation-label">
            {evaluation.label}
          </div>
          <div className="current-evaluations-page__evaluation-date">
            <b>Fecha de creación:</b> {formatDate(evaluation.createdAt)}
          </div>
          <div className="current-evaluations-page__evaluation-completed">
            {evaluation.done ? 'Completada' : 'No completada'}
          </div>
        </div>
        <div className="current-evaluations-page__evaluation-checkbox">
          <Checkbox
            onChange={(e) => handleEvaluationCheck(e, evaluation._id)}
            checked={isChecked(evaluation._id)}
            disabled={evaluation.done}
          />
        </div>
      </div>
      {!evaluation.isSelfEvaluation && (
        <div className="current-evaluations-page__affected-users">
          {evaluation.affectedUsers.map((affectedUser) => (
            <div
              key={affectedUser._id}
              className="current-evaluations-page__affected-user"
            >
              <div>{affectedUser.user.fullname}</div>
              <div>
                <DirectionIcon direction={affectedUser.evaluationType} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CurrentEvaluation;
