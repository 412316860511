import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAction, resetAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError';
import {
  allUsersModel,
  areaRelationshipsModel,
} from '../../constants/modelConstants';
import AreaUsersList from './AreaUsersList';
import SelectedUser from './SelectedUser';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

import './AreaRelationships.scss';

const AreaRelationships = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { users } = useSelector((store) => store.users);
  const { selectedArea } = useSelector((store) => store.areaRelationships);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { evaluation } = useSelector((store) => store.generalData);
  const { areaId } = useParams();

  const [selectedUser, setSelectedUser] = useState(null);
  const [relatedAreas, setRelatedAreas] = useState([]);

  const handleChange = ({ target: value }) => {
    dispatch(
      resetAction(
        areaRelationshipsModel.NAME,
        areaRelationshipsModel.INITIAL_STATE
      )
    );
    history.push(`/area/relaciones/${value.value}`);
  };

  useEffect(() => {
    dispatch(getAction(allUsersModel.NAME, allUsersModel.URL));
    dispatch(
      getAction(
        areaRelationshipsModel.NAME,
        `${areaRelationshipsModel.URL}/${areaId}`
      )
    );
  }, [areaId, dispatch]);

  useEffect(() => {
    setSelectedUser(null);
  }, [selectedArea?._id]);

  useEffect(() => {
    if (selectedArea) {
      let currentRelatedAreas = [];
      if (selectedArea.parentArea)
        currentRelatedAreas.push(selectedArea.parentArea);
      currentRelatedAreas = [
        ...currentRelatedAreas,
        ...selectedArea.dependentAreas,
      ];
      setRelatedAreas(currentRelatedAreas);
    }
  }, [selectedArea]);

  if (statusError) return <ServerError />;

  return pageLoaded && selectedArea && users ? (
    <div className="area-relationships-page">
      <h3 className="area-relationships-page__title">
        Relaciones de evaluaciones
      </h3>
      <div className="area-relationships-page__header">
        <div className="area-relationships-page__area-name">
          {selectedArea.name}
        </div>
        {userInfo.isSuperAdmin && relatedAreas.length > 0 && (
          <div className="area-relationships-page__related-areas">
            <FormControl fullWidth size="small">
              <InputLabel>Ver Area relacionada</InputLabel>
              <Select
                label="Ver Area relacionada"
                onChange={handleChange}
                defaultValue=""
              >
                {relatedAreas.map((area) => (
                  <MenuItem value={area._id} key={area._id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <Tooltip title="Ver Mapa de Areas" placement="top" arrow>
          <IconButton onClick={() => history.push('/empresa/mapa-de-areas')}>
            <AccountTreeIcon />
          </IconButton>
        </Tooltip>
      </div>
      {!!evaluation ? (
        <div className="area-relationships-page__message">
          Existe un proceso de evaluaciones abierto:
          <ul>
            <li>
              Las relaciones que fueron confirmadas antes de abrir dicho proceso
              no pueden ser eliminadas.
            </li>
            <li>
              Se pueden agregar nuevas relaciones y si las respectivas
              evaluaciones ya fueron creadas se agregarán los usuarios de las
              nuevas relaciones.
            </li>
            <li>
              Si se agregan nuevas relaciones y las respectivas evaluaciones
              fueron creadas y completadas, estas se reabrirán pudiendo sólo
              completarse las evaluaciones de los usurios que fueron agregados.
            </li>
          </ul>
        </div>
      ) : (
        <div className="area-relationships-page__message">
          No existe un proceso de evaluación abierto:
          <ul>
            <li>
              Todas las relaciones que sean agregadas, no podrán ser eliminadas
              una vez iniciado el proceso de evaluación.
            </li>
            <li>
              Recordá que para que las relaciones que agregues queden
              confirmadas deberás presionar en "Confirmar".
            </li>
          </ul>
        </div>
      )}
      <div className="area-relationships-page__content">
        <AreaUsersList
          selectedArea={selectedArea}
          bosses={selectedArea.bosses || []}
          employees={selectedArea.employees || []}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          evaluation={evaluation}
        />
        {selectedUser ? (
          <SelectedUser
            selectedArea={selectedArea}
            selectedUser={selectedUser}
            users={users}
          />
        ) : (
          <div className="area-relationships-page__empty-user">
            <ArrowBackOutlinedIcon />
            <span>
              Seleccioná un usuario para ver o modificar los usuarios a los que
              evalúa
            </span>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default AreaRelationships;
