import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError/ServerError';
import { benefitModel } from '../../constants/modelConstants';
import Benefits from './Benefits';
import ExcelIcon from '../../components/ExcelIcon';

import './BenefitsPage.scss';

const BenefitsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const {
    company,
    config: { viewBenefits },
  } = useSelector((store) => store.generalData);
  const { benefits } = useSelector((store) => store.benefits);

  if (!viewBenefits) history.replace('/');

  useEffect(() => {
    dispatch(getAction(benefitModel.NAME, benefitModel.URL));
  }, [dispatch]);

  if (statusError) return <ServerError />;

  return pageLoaded && company?.categories ? (
    <div className="benefits-page">
      <h3 className="benefits-page__title">
        <span>Beneficios</span>
        {userInfo.isSuperAdmin && benefits.length > 0 && (
          <ExcelIcon entity="benefit" title="solicitantes" />
        )}
      </h3>
      <Benefits categories={company.categories} benefits={benefits} />
    </div>
  ) : null;
};

export default BenefitsPage;
