export const groupNotifications = (notifications) => {
  let groupedNotifications = [];
  if (notifications) {
    notifications.forEach((notification) => {
      const groupedNotification = groupedNotifications.find(
        (n) => n.subType === notification.subType
      );
      if (groupedNotification) {
        groupedNotification.qty++;
      } else {
        groupedNotifications.push({ ...notification, qty: 1 });
      }
    });
  }

  return groupedNotifications;
};
