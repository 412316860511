import { useSelector } from 'react-redux';
import ProgressComponent from '../../../components/ProgressComponent';
import { verifyCurrentCategory } from '../../BenefitsPage/Benefits/verifyCurrentCategory';
import CupIcon from '@mui/icons-material/EmojiEvents';
import { categoriesEnum } from '../../../helpers/enums';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import EvaluationsCompleted from '../EvaluationsCompleted/EvaluationsCompleted';
import EvaluationsRemaining from '../EvaluationsRemaining/EvaluationsRemaining';

import './Progress.scss';

const Progress = ({ score = {}, user, isMyProgress = true }) => {
  const { competences, competencesScores, objetives, assistance, totalScore } =
    score;
  const { company, evaluation, floatingReminders } = useSelector(
    (store) => store.generalData
  );

  const category = verifyCurrentCategory(user, company.categories);
  const categoryColor =
    category?.value === 'Gold'
      ? '#ddad00'
      : category?.value === 'Silver'
      ? '#909ca7'
      : '#cd7f32';

  const showProgress = (key) => {
    return user.area?.hasOwnPonderations
      ? user.area?.ponderations?.[key] !== 0
      : company[key] > 0;
  };

  const evaluationInProgressMessages = () => {
    return isMyProgress ? (
      floatingReminders?.pendingEvaluations ? (
        <EvaluationsRemaining />
      ) : (
        <EvaluationsCompleted />
      )
    ) : (
      <div className="progress__not-evaluable">Evaluación en curso</div>
    );
  };

  return (
    <div className="progress">
      {score?.totalScore > 0 ? (
        <>
          <h3>Resultado de la evaluación</h3>
          {!evaluation ? (
            company.isFirstEvaluation ? (
              <EmptyComponentImage text="Acá vas a poder ver el resultado de tus evaluaciones." />
            ) : (
              <>
                <div className="progress-indicators__container">
                  {showProgress('competences') && (
                    <ProgressComponent
                      value={competences}
                      title="Competencias"
                      widthDetail
                      competencesScores={competencesScores}
                    />
                  )}
                  {showProgress('objetives') && (
                    <ProgressComponent value={objetives} title="Objetivos" />
                  )}
                  {showProgress('assistance') && (
                    <ProgressComponent value={assistance} title="Presentismo" />
                  )}
                </div>
                <div className="progress-total-score">
                  <span className="progress-total-score__total">
                    Puntaje Total: {Math.round(totalScore)}
                  </span>

                  {categoriesEnum[category?.value] ? (
                    <>
                      <CupIcon htmlColor={categoryColor} fontSize="large" />

                      <span
                        className="progress-total-score__category"
                        style={{
                          border: `1px solid ${categoryColor}`,
                          color: categoryColor,
                        }}
                      >
                        {categoriesEnum[category?.value]}
                      </span>
                    </>
                  ) : (
                    <span className="progress-total-score__no-category">
                      Sin categoría
                    </span>
                  )}
                </div>
              </>
            )
          ) : (
            evaluationInProgressMessages()
          )}
        </>
      ) : (
        <div className="progress__not-evaluable">Usuario no evaluado</div>
      )}
    </div>
  );
};

export default Progress;
