import { useRef, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import './PendingTask.scss';

const PendingTask = () => {
  const dispatch = useDispatch();
  const { config } = useSelector((store) => store.generalData);

  const wrapperRef = useRef(null);

  const { title, pending, success, error } = config?.pendingTask || {};

  const [isOpen, setIsOpen] = useState(success || error);
  const [quit, setQuit] = useState(false);

  const handleQuitClick = () => setQuit(true);

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        if (!pending) {
          setQuit(true);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dispatch, wrapperRef, pending]);

  return config?.pendingTask && !quit ? (
    <div
      ref={wrapperRef}
      className={`pending-task ${success ? 'pending-task--success' : ''} ${
        error ? 'pending-task--error' : ''
      }`}
    >
      {pending ? (
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="pending-task__button"
        >
          <IconButton color="primary" aria-label="open/close" component="label">
            {isOpen ? <ExpandMoreOutlinedIcon /> : <ExpandLessOutlinedIcon />}
          </IconButton>
        </div>
      ) : (
        <div onClick={handleQuitClick} className="pending-task__quit-button">
          <IconButton color="primary" aria-label="open/close" component="label">
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      )}
      {success && (
        <div className="pending-task__success-message">
          <CheckCircleOutlineOutlinedIcon />
          Proceso completado con éxito
        </div>
      )}
      {error && (
        <div className="pending-task__error-message">
          <ErrorOutlineOutlinedIcon />
          El proceso falló
        </div>
      )}
      {pending && <div className="pending-task__header">1 tarea pendiente</div>}
      {pending && <LinearProgress />}
      {isOpen && <div className="pending-task__title">{title} ...</div>}
    </div>
  ) : null;
};

export default PendingTask;
