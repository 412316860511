import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import SchemaIcon from '@mui/icons-material/Schema';
import { Avatar, IconButton } from '@mui/material';
import {
  categoriesEnum,
  formatDate,
  formatDNI,
  getAge,
  rolesEnum,
} from '../../helpers/enums';
import CropImageModal from './CropImageModal';
import { PhotoCamera } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { verifyCurrentCategory } from '../../pages/BenefitsPage/Benefits/verifyCurrentCategory';
import ActionPlanModal from './ActionPlanModal/ActionPlanModal';
import { getUsersTree } from '../../helpers/getAreaTree';

import './UserInfo.scss';

const UserInfo = ({
  userInfo,
  allowUpdate = true,
  showViewActionPlanButton,
  showDetailButtons = false,
}) => {
  const { success } = useSelector((store) => store.app);
  const { userInfo: userSignIn } = useSelector((store) => store.userSignIn);
  const { company, teamUsers, areas } = useSelector(
    (store) => store.generalData
  );
  const [showCropModal, setShowCropModal] = useState(false);
  const [showActionPlanModal, setShowActionPlanModal] = useState(false);
  const history = useHistory();

  const {
    _id,
    name,
    lastname,
    role,
    email,
    area,
    dni,
    entry,
    personalData,
    active,
    isNotEvaluable,
    roleLabel,
  } = userInfo;

  const category = verifyCurrentCategory(userInfo, company.categories);
  const myArea = areas.find((area) => area._id === userSignIn?.area?._id);

  const showButtons =
    showDetailButtons &&
    !isNotEvaluable &&
    (userSignIn.isSuperAdmin ||
      userInfo._id === userSignIn._id ||
      getUsersTree(areas, myArea).some((user) => user._id === userInfo._id));

  const showCreateActionPlanButton =
    showDetailButtons &&
    !isNotEvaluable &&
    (userSignIn.isSuperAdmin ||
      teamUsers.some((user) => user._id === userInfo._id));

  useEffect(() => {
    if (success) {
      setShowCropModal(false);
    }
  }, [success, setShowCropModal]);

  return userInfo ? (
    <div className="user-info">
      <div className="category">
        {!userInfo?.isSuperAdmin
          ? category
            ? categoriesEnum[category.value]
            : 'Sin Categoría'
          : 'Súper Administrador'}
      </div>
      <div
        className="img-container"
        style={
          userInfo.isSuperAdmin
            ? { width: '100px', height: '100px' }
            : { width: '150px', height: '150px' }
        }
      >
        <Avatar src={userInfo?.avatar} />
      </div>
      {allowUpdate && (
        <div style={{ marginTop: '-50px' }}>
          <IconButton
            color="primary"
            aria-label="Cambiar Avatar"
            component="span"
            onClick={() => setShowCropModal(true)}
          >
            <PhotoCamera />
          </IconButton>
        </div>
      )}
      <div className="user-info_data-container">
        <h4 className="user-info_data-container__name">
          {name} {lastname}
        </h4>

        {!isNotEvaluable && (
          <div>
            {personalData?.birthDate && (
              <p>
                {getAge(personalData?.birthDate, 'years')} - {rolesEnum[role]}
              </p>
            )}
            {entry && <p>Ingresó el {formatDate(entry)}</p>}
            <p>
              <span className="icon-wrapper">
                <Tooltip title="Email" placement="top" arrow>
                  <MailOutlineIcon />
                </Tooltip>
              </span>
              {email}
            </p>
            {area ? (
              <p>
                <span className="icon-wrapper">
                  <Tooltip title="Área" placement="top" arrow>
                    <BusinessIcon />
                  </Tooltip>
                </span>

                {area?.name}
              </p>
            ) : active ? (
              <p className="no-area">Sin área informada</p>
            ) : (
              <p className="inactive">Perfil inactivo</p>
            )}
            {roleLabel && (
              <p>
                <span className="icon-wrapper">
                  <Tooltip title="Puesto de Trabajo" placement="top" arrow>
                    <SchemaIcon />
                  </Tooltip>
                </span>
                {roleLabel}
              </p>
            )}
            {personalData?.birthDate && (
              <p>
                <span className="icon-wrapper">
                  <Tooltip title="Fecha de nacimiento" placement="top" arrow>
                    <CalendarTodayIcon />
                  </Tooltip>
                </span>

                {formatDate(personalData?.birthDate)}
              </p>
            )}
            {dni && (
              <p>
                <span className="icon-wrapper">
                  <Tooltip title="DNI" placement="top" arrow>
                    <FeaturedPlayListOutlinedIcon />
                  </Tooltip>
                </span>
                {formatDNI(dni)}
              </p>
            )}
          </div>
        )}

        {isNotEvaluable && (
          <div className="user-info__super-admin-message">
            <p>
              Los usuarios <b>Súper Administradores</b> no son visibles a los
              demás usuarios de la plataforma, no tienen área, ni son
              evaluables.
            </p>
          </div>
        )}
      </div>

      {showButtons && (
        <>
          <Button
            className="evaluations-history"
            size="medium"
            variant="contained"
            onClick={() => history.push(`/historial-evaluaciones/${_id}`)}
          >
            Historial de Evaluaciones
          </Button>

          {showViewActionPlanButton ? (
            <div className="action-plan__wrapper">
              {userSignIn.actionPlan.text && (
                <span
                  className={`action-plan__badge ${
                    userSignIn.actionPlan?.viewed ? 'read' : 'unread'
                  }`}
                >
                  {userSignIn.actionPlan?.viewed ? 'Leído' : 'No Leído'}
                </span>
              )}
              <Button
                className="action-plan"
                disabled={!userSignIn.actionPlan.text}
                size="medium"
                variant="contained"
                onClick={() => setShowActionPlanModal(true)}
              >
                Ver Plan de Acción
              </Button>
            </div>
          ) : (
            showCreateActionPlanButton && (
              <Button
                className="action-plan"
                size="medium"
                variant="contained"
                onClick={() => setShowActionPlanModal(true)}
              >
                {userInfo.actionPlan?.text ? 'Editar' : 'Crear'} Plan de Acción
              </Button>
            )
          )}
        </>
      )}

      {showCropModal && (
        <CropImageModal
          setIsFormOpen={setShowCropModal}
          userId={userInfo._id}
        />
      )}

      {showActionPlanModal && (
        <ActionPlanModal
          setIsFormOpen={setShowActionPlanModal}
          toUserId={userInfo._id}
          currentActionPlan={userInfo.actionPlan}
        />
      )}
    </div>
  ) : null;
};

export default UserInfo;
