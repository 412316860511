import { Button, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import Competence from '../Competence';
import CompetencesTemplates from '../CompetencesTemplates/CompetencesTemplates';
import CompetenceTemplateForm from '../../ManageCompetencesPage/CompetenceTemplateForm/CompetenceTemplateForm';
import './CompetenceAssign.scss';
import { useSelector } from 'react-redux';

const CompetenceAssign = ({
  assignedCompetences,
  unassignedCompetences,
  handleCompetenceClick,
  role,
  quantity,
  handleTemplateAssign,
}) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { competencesTemplates } = useSelector((store) => store.generalData);

  const [isTemplateFormOpen, setIsTemplateFormOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredUnassignedCompetences, setFilteredUnassignedCompetences] =
    useState(unassignedCompetences);

  const handleFilterChange = ({ target: { value } }) => {
    setFilterText(value.toLowerCase());
    setFilteredUnassignedCompetences(
      unassignedCompetences.filter((c) =>
        c.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleSaveTemplateClick = () => setIsTemplateFormOpen(true);
  const getForRole = userInfo.isSuperAdmin ? 'boss' : 'employee';

  const alreadyExists = () => {
    // Filtro mis templates
    const myTemplates = competencesTemplates.filter(
      (ct) => ct.createUserId === userInfo._id
    );

    // armo un array de arrays con las competencias que tiene cada template y ordeno cada uno
    const sortedCompetencesTemplates = myTemplates.map((ct) =>
      ct.competences.map((c) => c._id).sort()
    );

    // Ordeno las seleccoinadas
    const sortedCompetencesIds = assignedCompetences.map((ac) => ac._id).sort();

    // me fijo si ya existe un template con mi seleccion
    return sortedCompetencesTemplates.some((ct) =>
      ct.every((el, i) => el === sortedCompetencesIds[i])
    );
  };

  useEffect(() => {
    setFilteredUnassignedCompetences(
      unassignedCompetences.filter((c) =>
        c.name.toLowerCase().includes(filterText)
      )
    );
  }, [unassignedCompetences, filterText]);

  return (
    <div className="competence-assign">
      <div className="competence-assign__header">
        <h4>Asignar {quantity} competencias</h4>
        <div className="competence-assign__filter">
          <TextField
            onChange={handleFilterChange}
            placeholder="Filtrar Competencias por nombre"
            fullWidth
            variant="standard"
            className="filter_input"
          />
        </div>
      </div>

      <div className="competence-assign__wrapper">
        <div className="competence-assign__assigned">
          {assignedCompetences.length > 0 &&
            assignedCompetences.map((competence) => (
              <Competence
                key={competence._id}
                competence={competence}
                assigned
                handleCompetenceClick={handleCompetenceClick}
              />
            ))}
          {assignedCompetences.length === 0 && (
            <div className="competence-assign__assigned__empty">
              <div className="competence-assign__assigned__empty__title">
                No hay competencias agregadas
              </div>
              <div className="competence-assign__assigned__empty__message">
                Clickeá las competencias que quieras agregar a la evaluación
              </div>
            </div>
          )}
        </div>
        <div className="competence-assign__all">
          {unassignedCompetences.length > 0 ? (
            <div>
              {filteredUnassignedCompetences.map((competence) => (
                <Competence
                  key={competence._id}
                  competence={competence}
                  assigned={false}
                  handleCompetenceClick={handleCompetenceClick}
                />
              ))}
            </div>
          ) : (
            <p> No quedan competencias por asignar </p>
          )}
        </div>
      </div>

      {assignedCompetences.length === 5 && (
        <Tooltip
          arrow
          placement="right"
          title={
            alreadyExists()
              ? 'Ya generaste un template con estas competencias'
              : null
          }
        >
          <span>
            <Button
              variant="contained"
              onClick={handleSaveTemplateClick}
              disabled={alreadyExists()}
            >
              Guardar Template
            </Button>
          </span>
        </Tooltip>
      )}

      <CompetencesTemplates
        handleTemplateAssign={handleTemplateAssign}
        role={role}
      />

      {isTemplateFormOpen && (
        <CompetenceTemplateForm
          setIsFormOpen={setIsTemplateFormOpen}
          assignedForRole={getForRole}
          assignedCompetencesIds={assignedCompetences.map((ac) => ac._id)}
          callingFromEvaluations={true}
        />
      )}
    </div>
  );
};

export default CompetenceAssign;
