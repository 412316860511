import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/system';
import { alphabeticOrder } from './helpers';

const UsersSelect = ({ usersIds, handleUsersChange, stats, users }) => {
  const getUserLabel = (userId) => {
    return users.find((c) => c._id === userId).fullname;
  };

  const itemStyles = (currentUserId) => {
    const isAddedUser = usersIds.includes(currentUserId);
    return isAddedUser ? { color: 'silver' } : { color: 'inherit' };
  };

  return (
    <div className="form-input-wrapper">
      <FormControl fullWidth>
        <InputLabel>Usuarios a Evaluar</InputLabel>
        <Select
          multiple
          name="usersIds"
          value={usersIds}
          label="Usuarios a Evaluar"
          onChange={handleUsersChange}
          disabled={!stats}
          input={<OutlinedInput label="Usuarios a evaluar" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={getUserLabel(value)} />
              ))}
            </Box>
          )}
        >
          {users.sort(alphabeticOrder).map((user) => (
            <MenuItem key={user._id} value={user._id} style={itemStyles(user)}>
              <Checkbox checked={usersIds.includes(user._id)} />
              <ListItemText primary={user.fullname} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UsersSelect;
