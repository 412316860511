import { CircularProgress } from '@mui/material';
import './LoadingModal.scss';

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <CircularProgress color="info" />
    </div>
  );
};

export default LoadingModal;
