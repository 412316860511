import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { recognitionModel } from '../../constants/modelConstants';
import Recognitions from './Recognitions';
import RecognitionsRanking from './RecognitionsRanking';
import ServerError from '../../components/ServerError';
import AddButton from '../../components/AddButton/AddButton';
import recognitionImage from '../../assets/recognition-image.png';
import RecognitionForm from './RecognitionForm';
import confetti from 'canvas-confetti';

import './RecognitionsPage.scss';

const RecognitionsPage = () => {
  const { pageLoading, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { recognitions, ranking } = useSelector((store) => store.recognitions);
  const dispatch = useDispatch();

  const [isFormOpen, setIsFormOpen] = useState(false);

  const isAddButtonDisabled =
    (userInfo.role === 'boss' && userInfo.recognitionsMade === 5) ||
    (userInfo.role === 'employee' && userInfo.recognitionsMade === 3);

  useEffect(() => {
    dispatch(getAction(recognitionModel.NAME, recognitionModel.URL));
  }, [dispatch]);

  useEffect(() => {
    if (!pageLoading && recognitions?.length > 0) confetti({ spread: 180 });
  }, [pageLoading, recognitions]);

  if (statusError) return <ServerError />;
  if (pageLoading) return null;

  return (
    <div className="recognitions-page">
      <h3>
        Reconocimientos
        <AddButton
          disabled={isAddButtonDisabled}
          tooltip="Realizar un reconocimiento"
          onClick={() => setIsFormOpen(true)}
        />
      </h3>
      <div className="recognitions-page__container">
        <div className="recognitions-page__manifest">
          <div className="recognitions-page__image">
            <img width="100%" src={recognitionImage} alt="" />
          </div>
          <p>
            Reconocer a un compañero significa llevar a cabo acciones que ayuden
            a la persona a saber que su trabajo y contribuciones son reconocidos
            y apreciados.
          </p>
          <p>
            Un reconocimiento nos motiva al mismo tiempo que nos permite conocer
            si estamos generando un impacto positivo y si estamos en el camino
            correcto hacia el éxito laboral que buscamos.
          </p>
          <RecognitionsRanking ranking={ranking} myId={userInfo._id} />
        </div>
        <div className="recognitions-page__content">
          <Recognitions recognitions={recognitions} myId={userInfo._id} />
        </div>
      </div>
      {isFormOpen && <RecognitionForm setIsFormOpen={setIsFormOpen} />}
    </div>
  );
};

export default RecognitionsPage;
