export const subtitles = {
  confiable:
    'Cumple objetivos excepcionalmente, pero las competencias requieren fortalecimiento; recomendado centrarse en desarrollo para mejorar habilidades clave',
  impacto:
    'Se destaca en el logro de objetivos y exhibición de competencias; contribuyente de alto rendimiento que impacta positivamente en la organización',
  estrella:
    'Genera impulso, muestra cualidades notables y contribuye significativamente al éxito organizacional; se espera que mantenga su rendimiento',
  excepcional: '',
  efectivo:
    'Logra objetivos con eficacia a pesar de no comprender completamente las competencias; necesita trabajar en el desarrollo de estas para un desempeño más completo',
  clave:
    'Individuo esencial bien valorado, con objetivos y competencias sólidas; desafío consiste en elevar aún más sus contribuciones y resultados',
  desarrollar:
    'Comprende competencias, pero necesita mayor impulso en perseguir objetivos para lograr cumplirlos con excelencia; oportunidad de desarrollo adicional',
  riesgo:
    'Persona con dificultades tanto en comprender las competencias como en cumplir objetivos; requiere mejorar su compromiso con la organización',
  dilema:
    'Individuo que comprende las competencias, pero no logra la valoración adecuada; necesita trabajar en el cumplimiento de objetivos para mejorar su rendimiento sectorial',
  enigma:
    'Posee sólido entendimiento de competencias, pero los resultados en objetivos no alcanzan niveles deseados; el desafío radica en traducir habilidades en un cumplimiento óptimo',
};
