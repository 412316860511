import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import UsersInChargeTooltip from '../../../components/UsersInChargeTooltip/UsersInChargeTooltip';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getFilteredAreas, getUsersInCharge } from '../helpers';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import './EvaluationPending.scss';

const Task = ({ label, pending, linkTo = false }) => {
  const history = useHistory();
  return (
    <div
      className={`evaluation-pending__task ${
        pending
          ? 'evaluation-pending__task--pending'
          : 'evaluation-pending__task--done'
      }`}
      style={linkTo ? { cursor: 'pointer' } : {}}
      onClick={() => linkTo && history.push(linkTo)}
    >
      {label}
      {pending ? (
        <HighlightOffOutlinedIcon sx={{ marginLeft: '10px' }} />
      ) : (
        <CheckCircleOutlineOutlinedIcon sx={{ marginLeft: '10px' }} />
      )}
    </div>
  );
};

const defaultFilter = {
  searchValue: '',
  pendings: '',
  userInCharge: '',
};

const EvaluationPending = ({ openProcess, pendingTasks }) => {
  const history = useHistory();

  const [filter, setFilter] = useState(defaultFilter);
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [usersInCharge] = useState(getUsersInCharge(pendingTasks.areas));

  const { searchValue, pendings, userInCharge } = filter;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const handleAreaClick = (managementArea) => {
    localStorage.setItem('managementArea', managementArea);
    window.scrollTo({ top: 0 });
    history.push('/empresa/mapa-de-areas');
  };

  useEffect(() => {
    setFilteredAreas(getFilteredAreas(pendingTasks.areas, filter, openProcess));
  }, [filter, pendingTasks.areas, openProcess]);

  return (
    <div className="evaluation-pending">
      <h2>Tareas relacionadas a la evaluación por área</h2>
      <div className="evaluation-pending__filters">
        <div className="evaluation-pending__form-control">
          <FormControl fullWidth>
            <TextField
              label="Nombre del area"
              value={searchValue}
              name="searchValue"
              onChange={handleChange}
              size="small"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </FormControl>
        </div>
        <div className="evaluation-pending__form-control">
          <FormControl fullWidth size="small">
            <InputLabel id="pendings">Estado</InputLabel>
            <Select
              labelId="pendings"
              id="pendings"
              value={pendings}
              label="Estado"
              name="pendings"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todas</MenuItem>
              <MenuItem value="with">Con tareas pendientes</MenuItem>
              <MenuItem value="without">Sin tareas pendientes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="evaluation-pending__form-control">
          <FormControl fullWidth size="small">
            <InputLabel id="userInCharge">Usuario a cargo</InputLabel>
            <Select
              labelId="userInCharge"
              id="userInCharge"
              value={userInCharge}
              label="Usuario a cargo"
              name="userInCharge"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem value="">Todos</MenuItem>
              {usersInCharge.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Tooltip title="Limpiar filtros" placement="top" arrow>
            <IconButton
              aria-label="delete"
              onClick={() => setFilter(defaultFilter)}
            >
              <FilterAltOffOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="close evaluation process">
          <TableBody>
            <TableRow>
              <TableCell>
                <div className="evaluation-pending__name">Admin</div>
              </TableCell>
              <TableCell>
                {openProcess ? (
                  <div className="evaluation-pending__tasks">
                    <Task
                      label="Asignar objetivos"
                      pending={pendingTasks.hasAdminGoalsPendingToAssign}
                    />
                  </div>
                ) : (
                  <div className="evaluation-pending__tasks">
                    <Task
                      label="Dar devolución a objetivos"
                      pending={pendingTasks.hasAdminGoalsPendingToGiveFeedback}
                    />
                    <Task
                      label="Crear evaluaciones para jefes"
                      pending={pendingTasks.hasEvaluationsPendingToCreate}
                    />
                    <Task
                      label="Cargar presentismo"
                      pending={pendingTasks.hasPendingAssistance}
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
          {filteredAreas.map((area) => (
            <TableBody key={area._id}>
              <TableRow>
                <TableCell>
                  <div className="evaluation-pending__name evaluation-pending__name--area">
                    <div onClick={() => handleAreaClick(area._id)}>
                      {area.name}
                    </div>
                    <div className="evaluation-pending__users">
                      <UsersInChargeTooltip users={area.usersInCharge}>
                        <GroupOutlinedIcon />
                      </UsersInChargeTooltip>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  {openProcess ? (
                    <div className="evaluation-pending__tasks">
                      <Task
                        label="Mínimo de usuarios"
                        pending={area.hasMissingUsers}
                      />
                      <Task
                        label="Relaciones para evaluaciones"
                        pending={area.hasRelationshipsPendings}
                        linkTo={`area/relaciones/${area._id}`}
                      />
                      <Task
                        label="Asignar objetivos"
                        pending={area.hasGoalsPendingToAssign}
                      />
                    </div>
                  ) : (
                    <div className="evaluation-pending__tasks">
                      <Task
                        label="Dar devolución a objetivos"
                        pending={area.hasGoalsPendingToGiveFeedback}
                      />
                      {!area.withoutEmployees && (
                        <Task
                          label="Crear evaluaciones para empleados"
                          pending={area.hasEvaluationsPendingToCreate}
                        />
                      )}
                      <Task
                        label="Completar evaluaciones"
                        pending={area.hasEvaluationsPendingToDo}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </div>
  );
};

export default EvaluationPending;
