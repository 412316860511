import {
  NOTIFICATIONS_FAIL,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_DELETE_SUCCESS,
} from '../constants/notificationsConstants';
import httpClient from '../http-client';

export const getNotificationsDataAction = () => async (dispatch) => {
  dispatch({ type: NOTIFICATIONS_REQUEST });

  try {
    const { data } = await httpClient.get('notification');
    dispatch({ type: NOTIFICATIONS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: NOTIFICATIONS_FAIL,
      payload: err.response?.data.message || err.message,
    });
  }
};

export const deleteNotificationAction = (type) => async (dispatch) => {
  try {
    const { data } = await httpClient.delete(`notification/${type}`);
    dispatch({ type: NOTIFICATIONS_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: NOTIFICATIONS_FAIL,
      payload: err.response?.data.message || err.message,
    });
  }
};
