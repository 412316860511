import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Evaluation360 from '../../../assets/Evaluation360.png';
import { formatDate } from '../../../helpers/enums';
import './EvaluationsRemaining.scss';

const EvaluationsRemaining = () => {
  const { evaluation } = useSelector((store) => store.generalData);
  return (
    <div className="evaluations-remaining-message">
      <img src={Evaluation360} alt="" />
      <div>
        <p className="message-title">Proceso de Evaluación en curso.</p>
        <p>
          No dejes de completar las <Link to="/evaluaciones">evaluaciones</Link>{' '}
          que te han sido asignadas, tenés tiempo hasta el{' '}
          {formatDate(evaluation?.dateTo)}.
        </p>
        <p>Una vez finalizado el proceso, vas a poder ver tus resultados</p>
      </div>
    </div>
  );
};

export default EvaluationsRemaining;
