import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction } from '../../../actions/userActions';

const ResetPasswordForm = ({ history, setView, rpat }) => {
  const [form, setForm] = useState({
    password: '',
    password2: '',
  });
  const { loading, error, success } = useSelector((store) => store.userSignIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success || error) {
      setView('login');
      history.push('/login');
    }
  }, [setView, success, error, history]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { password, password2 } = form;

    if (password !== password2) {
      return;
    }

    dispatch(resetPasswordAction({ password, token: rpat }));
  };

  return (
    <>
      <h3 className="login-page__title">Cambiar contraseña</h3>
      <form onSubmit={handleSubmit}>
        <div className="login-page_inputs-wrapper">
          <TextField
            type="password"
            name="password"
            label="Password"
            onChange={handleInputChange}
            value={form.password}
            className={`login-page_input ${error && 'error'}`}
          />
          <TextField
            type="password"
            name="password2"
            label="Reingresar Password"
            onChange={handleInputChange}
            value={form.password2}
            className={`login-page_input ${error && 'error'}`}
          />
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? 'Guardando...' : 'Cambiar Contraseña'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ResetPasswordForm;
