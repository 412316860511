import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './MissingTasks.scss';

const MissingTasks = () => {
  const history = useHistory();
  const { evaluation } = useSelector((store) => store.generalData);
  const { openPendingTasks, closePendingTasks } = useSelector(
    (store) => store.dashboard
  );

  const getTitle = evaluation ? 'cierre' : 'apertura';
  const handleMissingTaskClick = () => {
    localStorage.setItem('config', 3);
    history.push('/configuraciones');
  };

  return (
    <div className="missing-tasks">
      <h3>Pendientes {getTitle} evaluación</h3>
      <div className="missing-tasks__container">
        {evaluation
          ? closePendingTasks?.map(({ title, pending }) => (
              <div
                className={`task ${pending ? 'pending' : ''}`}
                key={title}
                onClick={handleMissingTaskClick}
              >
                {title}
              </div>
            ))
          : openPendingTasks?.map(({ title, pending }) => (
              <div
                className={`task ${pending ? 'pending' : ''}`}
                key={title}
                onClick={handleMissingTaskClick}
              >
                {title}
              </div>
            ))}
      </div>
    </div>
  );
};

export default MissingTasks;
