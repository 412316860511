import { useRef, useState, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import './CompetenceItem.scss';
import CompetenceModalForm from '../CompetenceModalForm/CompetenceModalForm';

const CompetenceItem = ({ competence }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const comptenceRef = useRef();

  const {
    name,
    roles,
    description,
    bonusQuestionLabel,
    behaviourQuestionOptions,
  } = competence;

  const transformedRole = (role) => (role === 'boss' ? 'Jefes' : 'Empleados');

  useEffect(() => {
    if (showDetail) {
      window.scrollTo({
        top: comptenceRef.current.offsetTop - 60,
        behavior: 'smooth',
      });
    }
  }, [showDetail]);

  return (
    <div
      ref={comptenceRef}
      className={`competence-item ${
        showDetail ? 'competence-item--selected' : ''
      }`}
    >
      <div
        onClick={() => setShowDetail(!showDetail)}
        className="competence-item__name-container"
      >
        <div className="competence-item__name">{name}</div>
        <div className="competence-item__roles">
          {roles.map((role) => (
            <span key={role}>{transformedRole(role)}</span>
          ))}
        </div>
        {showDetail ? (
          <ArrowDropUpIcon fontSize="medium" />
        ) : (
          <ArrowDropDownIcon fontSize="medium" />
        )}
      </div>
      {showDetail && (
        <div className="competence-item__detail">
          <div className="competence-item__edit-button">
            <IconButton onClick={() => setIsFormOpen(true)}>
              <EditOutlinedIcon />
            </IconButton>
          </div>
          <div>
            <b>Descripción:</b>
            <p>{description}</p>
          </div>
          <div>
            <b>Opciones:</b>
            <ul className="competence-item__options">
              {behaviourQuestionOptions.map((option, i) => (
                <li key={`competence-option-${i + 1}`}>
                  <div>
                    <b>{option.labelSelect} - (hacia mi):</b>
                    <p>{option.label}</p>
                  </div>
                  <div>
                    <b>{option.labelSelect} - (hacia otros):</b>
                    <p>{option.labelOthers}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <b>Pregunta punto bonus:</b>
            <p>{bonusQuestionLabel}</p>
          </div>
          {isFormOpen && (
            <CompetenceModalForm
              setIsFormOpen={setIsFormOpen}
              competence={competence}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CompetenceItem;
