import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import LoadingModal from '../../components/LoadingModal';
import ServerError from '../../components/ServerError/ServerError';
import { goalsModel } from '../../constants/modelConstants';
import Filters from './Filters/Filters';
import Goals from './Goals/Goals';
import { getFilteredGoals } from './helpers';
import GoalsPageHeader from './GoalsPageHeader/GoalsPageHeader';
import GoalForm from './GoalForm/GoalForm';
import AssignedGoalsUsers from './AssignedGoalsUsers/AssignedGoalsUsers';
import UsersWithoutGoals from './UsersWithoutGoals/UsersWithoutGoals';
import UsersWithoutGoalsWarning from './UsersWithoutGoals/UsersWithoutGoalsWarning';
import './CurrentGoalsPage.scss';

const defaultFilters = {
  source: 'all',
  selectedUserId: '',
  devolution: 'all',
  evaluation: 'all',
};

const CurrentGoalsPage = () => {
  const dispatch = useDispatch();
  const { pageLoaded, sending, success, statusError } = useSelector(
    (store) => store.app
  );
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { goals } = useSelector((store) => store.goals);
  const { evaluation, teamUsers } = useSelector((store) => store.generalData);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [filteredGoals, setFilteredGoals] = useState(goals);
  const [usersWithoutGoals, setUsersWithoutGoals] = useState([]);
  const [showUsersWithoutGoals, setShowUsersWithoutGoals] = useState(false);

  const { source, selectedUserId } = filters;

  const showUsersWithoutGoalsWarning =
    !evaluation && userInfo.role === 'boss' && usersWithoutGoals.length > 0;

  useEffect(() => {
    if (teamUsers) {
      let updatedUsersWithoutGoals = [];
      teamUsers.forEach((user) => {
        if (!goals.some((goal) => goal.toUserId?._id === user._id)) {
          updatedUsersWithoutGoals.push(user);
        }
      });

      setUsersWithoutGoals(updatedUsersWithoutGoals);
    }
  }, [goals, teamUsers]);

  useEffect(() => {
    if (filters.source === 'users-without-goals') {
      setShowUsersWithoutGoals(true);
    } else {
      setFilteredGoals(
        getFilteredGoals(goals, filters, userInfo._id, userInfo)
      );
    }
  }, [goals, filters, userInfo]);

  useEffect(() => {
    dispatch(getAction(goalsModel.NAME, `${goalsModel.URL}/current`));
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success]);

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    <div className="current-goals-page">
      {sending && <LoadingModal />}
      <GoalsPageHeader setIsFormOpen={setIsFormOpen} />
      {showUsersWithoutGoalsWarning && (
        <UsersWithoutGoalsWarning
          setFilters={setFilters}
          setShowUsersWithoutGoals={setShowUsersWithoutGoals}
          showUsersWithoutGoals={showUsersWithoutGoals}
        />
      )}
      <Filters
        filters={filters}
        setFilters={setFilters}
        setShowUsersWithoutGoals={setShowUsersWithoutGoals}
        current={true}
        usersWithoutGoals={usersWithoutGoals}
      />
      {source === 'created-by-me' && (
        <AssignedGoalsUsers
          goals={filteredGoals}
          teamUsers={teamUsers}
          selectedUserId={selectedUserId}
          setFilters={setFilters}
        />
      )}

      {showUsersWithoutGoals ? (
        <UsersWithoutGoals usersWithoutGoals={usersWithoutGoals} />
      ) : (
        <Goals goals={filteredGoals} current />
      )}

      {isFormOpen && <GoalForm setIsFormOpen={setIsFormOpen} />}
    </div>
  ) : null;
};

export default CurrentGoalsPage;
