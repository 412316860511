import Calendar from './Calendar';

import './CalendarPage.scss';

const CalendarPage = () => {
  return (
    <div className="calendar-page">
      <h3 className="calendar-page__title">Calendario</h3>
      <Calendar />
    </div>
  );
};

export default CalendarPage;
