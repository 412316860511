import DefaultAvatar from '../../../images/default-avatar2.png';
import Confetti from '../../../assets/confetti.png';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import RecognitionReactions from '../RecognitionReactions';
import './Recognitions.scss';

const Recognitions = ({ recognitions = [], myId }) => {
  return (
    <div className="recognitions">
      <h4>Últimos Reconocimientos</h4>
      <div className="recognitions__container">
        {recognitions.length ? (
          recognitions.map((recognition) => (
            <div key={recognition._id} className="recognition-wrapper">
              <div
                className={`recognition ${
                  recognition.recognizedUserId._id === myId
                    ? 'recognition--own'
                    : ''
                }`}
              >
                <div
                  className="recognition__image"
                  style={{
                    backgroundImage: `url(${
                      recognition.recognizedUserId.avatar || DefaultAvatar
                    })`,
                  }}
                ></div>
                <div className="recognition__confetti">
                  <img src={Confetti} alt="Confetti" width="100%" />
                </div>
                <div className="recognition__reason">{recognition.reason}</div>
                <RecognitionReactions
                  recognition={recognition}
                  recognitions={recognitions}
                />
                <div className="recognition__congratulations">
                  Felicitaciones {recognition.recognizedUserId.name}
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyComponentImage text="Acá vas a poder ver los últimos reconocimientos otorgados hacia vos y tus compañeros." />
        )}
      </div>
    </div>
  );
};

export default Recognitions;
