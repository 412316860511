import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import { searchModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal/CustomModal';

import './SearchForm.scss';

const SearchForm = ({ setIsFormOpen }) => {
  const initialForm = {
    area: '',
    job: '',
    requirements: '',
    tasks: '',
  };

  const [formData, setFormData] = useState(initialForm);
  const { area, job, tasks, requirements } = formData;

  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { areas } = useSelector((store) => store.generalData);

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = () => {
    dispatch(postAction(searchModel.NAME, searchModel.URL, formData));
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Crear una busqueda" setIsFormOpen={setIsFormOpen}>
      <div className="search-form">
        {areas.length > 0 ? (
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <InputLabel id="area">Área</InputLabel>
              <Select
                labelId="area"
                id="area"
                value={area}
                label="Área"
                b
                name="area"
                onChange={handleChange}
              >
                {areas.map(
                  (area) =>
                    !area.management && (
                      <MenuItem value={area._id} key={area._id}>
                        {area.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </div>
        ) : (
          <p className="search-form__empty-message">
            No hay Areas cargadas. No es posible crear una búsqueda
          </p>
        )}
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <TextField
              label="Puesto"
              name="job"
              autoComplete="off"
              value={job}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <textarea
          className="search-form-textarea"
          name="tasks"
          value={tasks}
          onChange={handleChange}
          placeholder="Tareas a realizar..."
        />

        <textarea
          className="search-form-textarea"
          name="requirements"
          value={requirements}
          onChange={handleChange}
          placeholder="Requermientos para el puesto..."
        />

        <Button
          variant="contained"
          className="search-form-button"
          onClick={handleSubmit}
          disabled={sending || !area || !job || !tasks || !requirements}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default SearchForm;
