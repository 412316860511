import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress } from '@mui/material';
import CompetenceAssign from '../CompetenceAssign';
import { postUsersEvaluationAction } from '../../../actions/modelActions';
import { generalDataModel } from '../../../constants/modelConstants';
import { useHistory } from 'react-router-dom';
import AreaAssign from '../AreaAssign';
import BackButton from '../../../components/BackButton';

import './CreateEvaluation.scss';

const CreateEvaluation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { competences, evaluation } = useSelector((store) => store.generalData);
  const { userInfo } = useSelector((store) => store.userSignIn);

  const initialForm = {
    evaluation: evaluation?._id || null,
    bossCompetences: [],
    employeeCompetences: [],
    areas: [],
  };

  const [formData, setFormData] = useState(initialForm);
  const [unassignedBossCompetences, setUnassignedBossCompetences] = useState(
    competences.filter((competence) =>
      competence.roles.some((role) => role === 'boss')
    )
  );
  const [unassignedEmployeeCompetences, setUnassignedEmployeeCompetences] =
    useState(
      competences.filter((competence) =>
        competence.roles.some((role) => role === 'employee')
      )
    );

  const [evaluationCreated, setEvaluationCreated] = useState(false);

  const handleBossCompetenceClick = (competence, addCompetence) => {
    if (addCompetence) {
      if (formData.bossCompetences.length < 5) {
        setFormData({
          ...formData,
          bossCompetences: [...formData.bossCompetences, competence],
        });
        const updatedUnassignedBossCompetences =
          unassignedBossCompetences.filter(
            (unasignedCompetence) => unasignedCompetence._id !== competence._id
          );
        setUnassignedBossCompetences(updatedUnassignedBossCompetences);
      }
    } else {
      setUnassignedBossCompetences([...unassignedBossCompetences, competence]);
      const updatedCompetences = formData.bossCompetences.filter(
        (asignedCompetence) => asignedCompetence._id !== competence._id
      );
      setFormData({
        ...formData,
        bossCompetences: updatedCompetences,
      });
    }
  };

  const handleEmployeeCompetenceClick = (competence, addCompetence) => {
    if (addCompetence) {
      if (formData.employeeCompetences.length < 5) {
        setFormData({
          ...formData,
          employeeCompetences: [...formData.employeeCompetences, competence],
        });
        const updatedUnassignedEmployeeCompetences =
          unassignedEmployeeCompetences.filter(
            (unasignedCompetence) => unasignedCompetence._id !== competence._id
          );
        setUnassignedEmployeeCompetences(updatedUnassignedEmployeeCompetences);
      }
    } else {
      setUnassignedEmployeeCompetences([
        ...unassignedEmployeeCompetences,
        competence,
      ]);
      const updatedCompetences = formData.employeeCompetences.filter(
        (asignedCompetence) => asignedCompetence._id !== competence._id
      );
      setFormData({
        ...formData,
        employeeCompetences: updatedCompetences,
      });
    }
  };

  const handleTemplateAssign = (template, key, role) => {
    setFormData({
      ...formData,
      [key]: template.competences,
    });

    const competencesIds = template.competences.map((c) => c._id);

    if (role === 'boss') {
      const updatedUnassignedBossCompetences = unassignedBossCompetences.filter(
        (uc) => !competencesIds.includes(uc._id)
      );
      setUnassignedBossCompetences(updatedUnassignedBossCompetences);
    } else {
      const updatedUnassignedEmployeeCompetences =
        unassignedEmployeeCompetences.filter(
          (uc) => !competencesIds.includes(uc._id)
        );
      setUnassignedEmployeeCompetences(updatedUnassignedEmployeeCompetences);
    }
  };

  const handleSubmit = () => {
    let forRole;

    if (userInfo.isSuperAdmin) {
      forRole = 'boss';
    } else if (userInfo.role === 'boss' && userInfo.area) {
      forRole = 'employee';
    }

    const bossCompetenceIds = formData.bossCompetences.map(
      (competence) => competence._id
    );

    const employeeCompetenceIds = formData.employeeCompetences.map(
      (competence) => competence._id
    );

    if (forRole) {
      dispatch(
        postUsersEvaluationAction(
          generalDataModel.NAME,
          `${generalDataModel.CREATE_USER_EVALUATION_URL}?forRole=${forRole}`,
          {
            ...formData,
            competences: [...bossCompetenceIds, ...employeeCompetenceIds],
            evaluation: evaluation?._id,
          }
        )
      );
      setEvaluationCreated(true);
    }
  };

  const isButtonDisabled = () => {
    return (
      formData.bossCompetences.length + formData.employeeCompetences.length !==
        5 ||
      (!userInfo.isSuperAdmin && formData.areas.length === 0) ||
      sending ||
      success
    );
  };

  useEffect(() => {
    let timeout;
    let url;

    if (userInfo.isSuperAdmin) {
      url = '/configuraciones';
    } else {
      url = '/evaluaciones';
    }

    if (success && evaluationCreated) {
      timeout = setTimeout(() => {
        history.push(url);
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [success, history, evaluationCreated, userInfo]);

  return (
    evaluation && (
      <>
        <div className="create-evaluation">
          <h3>
            Crear Evaluación
            <BackButton />
          </h3>
          <div className="create-evaluation_form-container">
            <div>
              <b>Evaluación general: </b> {evaluation?.name}
            </div>
            {userInfo.isSuperAdmin && (
              <div className="create-evaluation_step">
                <h4>Crear Evaluaciones orientadas a Gerentes y Jefes:</h4>
                <p className="create-evaluation___text">
                  Se crearán <b>Autoevaluaciones</b> para Jefes y
                  <b> Evaluaciones</b> para todos los usuarios a sus superiores.
                </p>
                <CompetenceAssign
                  assignedCompetences={formData.bossCompetences}
                  unassignedCompetences={unassignedBossCompetences}
                  handleCompetenceClick={handleBossCompetenceClick}
                  role="boss"
                  quantity={5}
                  handleTemplateAssign={handleTemplateAssign}
                />
              </div>
            )}
            {userInfo.role === 'boss' && !userInfo.isSuperAdmin && (
              <div className="create-evaluation_step">
                <h4>Crear Evaluaciones orientadas a Empleados:</h4>
                <p className="create-evaluation___text">
                  Se crearán <b> Evaluaciones</b> para los empleados del área/s
                  que asignes, sus <b> Autoevaluaciones</b> y las
                  <b> Evaluaciones </b>a sus compañeros.
                </p>
                <AreaAssign setFormData={setFormData} formData={formData} />
                <CompetenceAssign
                  assignedCompetences={formData.employeeCompetences}
                  unassignedCompetences={unassignedEmployeeCompetences}
                  handleCompetenceClick={handleEmployeeCompetenceClick}
                  role="employee"
                  quantity={5}
                  handleTemplateAssign={handleTemplateAssign}
                />
              </div>
            )}
          </div>
          {sending && (
            <div className="sending-message">
              <p>Aguarde. Este proceso puede demorar ...</p>
              <LinearProgress />
            </div>
          )}
          <div className="create-evaluation_actions-container">
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isButtonDisabled()}
            >
              {sending ? 'Guardando...' : 'Crear Evaluación'}
            </Button>
          </div>
        </div>
      </>
    )
  );
};

export default CreateEvaluation;
