export const permissions = {
  CREATE_COMMUNICATION: 'CREATE_COMMUNICATION',
  ADMIN_ASSISTANCE: 'ADMIN_ASSISTANCE',
  ADMIN_BENEFITS: 'ADMIN_BENEFITS',
  ADMIN_SEARCHES: 'ADMIN_SEARCHES',
  ADMIN_GENERAL: 'ADMIN_GENERAL',
};

export const permissionsTranslated = {
  CREATE_COMMUNICATION: 'Crear Comunicación',
  ADMIN_ASSISTANCE: 'Administrar Presentismo',
  ADMIN_BENEFITS: 'Administrar Beneficios',
  ADMIN_SEARCHES: 'Administrar Búsquedas internas',
  ADMIN_GENERAL: 'Administrar Usuarios, Areas y datos de Empresa',
};
