export const verifyCurrentCategory = (userInfo, categories) => {
  const { score } = userInfo;

  if (categories && score) {
    const categoriesByPoints = categories.filter(
      (category) => category.exchangeType === 'points'
    );

    const currentCategory = categoriesByPoints.find(
      (category) =>
        category.minPoints <= Math.round(score.totalScore) &&
        category.maxPoints >= Math.round(score.totalScore)
    );

    return currentCategory ? currentCategory : null;
  }

  return null;
};
