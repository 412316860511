import { useSelector } from 'react-redux';
import EmptyComponentImage from '../../components/EmptyComponentImage';
import ServerError from '../../components/ServerError';
import NotAuthorized from '../ErrorPage/NotAuthorized';
import AreaFilter from './AreaFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAreaTree } from '../../helpers/getAreaTree';

import './AreaPage.scss';

const AreaPage = () => {
  const history = useHistory();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const {
    areas,
    config: { viewNineBox },
  } = useSelector((store) => store.generalData);
  const { pageLoaded, statusError } = useSelector((store) => store.app);

  const isSuperAdmin = userInfo.isSuperAdmin;
  const isEmployee = userInfo.role === 'employee';
  const myArea = areas.find((area) => area._id === userInfo.area?._id);
  const availableAreas = isSuperAdmin ? areas : getAreaTree(areas, myArea);

  if (!viewNineBox) history.replace('/');
  if (statusError) return <ServerError />;

  return isEmployee ? (
    <NotAuthorized />
  ) : pageLoaded ? (
    <div className="area-page">
      <div className="area-page__container">
        {availableAreas?.length ? (
          <AreaFilter availableAreas={availableAreas} areas={areas} />
        ) : (
          <EmptyComponentImage text="Acá vas a poder visualizar información del área" />
        )}
      </div>
    </div>
  ) : null;
};

export default AreaPage;
