import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useUpdateFilteredDemands = (
  filter,
  demands,
  userInfo,
  setFilteredDemands,
  setIsDropDisabled
  // isDoneState,
  // setIsDoneState,
  // setShowCongratsModal
) => {
  const { success, message } = useSelector((store) => store.app);

  useEffect(() => {
    switch (filter) {
      case 'made':
        const madeDemands = demands.filter(
          (d) =>
            d.createUserId._id === userInfo._id &&
            d.createUserId._id !== d.toUserId._id
        );
        return setFilteredDemands(madeDemands);

      case 'received':
        const receivedDemands = demands.filter(
          (d) =>
            d.toUserId._id === userInfo._id &&
            d.createUserId._id !== d.toUserId._id
        );
        return setFilteredDemands(receivedDemands);

      case 'selfAssignment':
        const selfAssignmentDemands = demands.filter(
          (d) => d.createUserId._id === d.toUserId._id
        );
        return setFilteredDemands(selfAssignmentDemands);
      default:
        return setFilteredDemands(demands);
    }
  }, [filter, setFilteredDemands, demands, userInfo._id]);

  useEffect(() => {
    if (success) {
      // if (isDoneState) {
      //   setIsDoneState(false);
      //   setShowCongratsModal(true);
      // }
      const timer = setTimeout(() => setIsDropDisabled(false), 2500);
      return () => clearTimeout(timer);
    }

    if (!success && message) {
      const timer = setTimeout(() => window.location.reload(), 2500);
      return () => clearTimeout(timer);
    }
  }, [
    success,
    setIsDropDisabled,
    message,
    // isDoneState,
    // setIsDoneState,
    // setShowCongratsModal,
  ]);
};

export default useUpdateFilteredDemands;
