import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { dashboardModel } from '../../constants/modelConstants';
import CategoriesRanking from './CategoriesRanking';
import UserInfo from '../../components/UserInfo';
import Survey from './Survey';
import Progress from './Progress';
import Communications from './Comunications';
import ServerError from '../../components/ServerError';
import SuperAdminDashboard from './SuperAdminDashboard';
import AreaUsersData from './AreaUsersData';

import './DashboardPage.scss';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { userScore } = useSelector((store) => store.generalData);

  const [showSurvey, setShowSurvey] = useState(userInfo.showSurvey);

  useEffect(() => {
    dispatch(getAction(dashboardModel.NAME, dashboardModel.URL));
  }, [dispatch]);

  if (statusError) return <ServerError />;

  return pageLoaded ? (
    userInfo.isSuperAdmin ? (
      <SuperAdminDashboard />
    ) : (
      <div className="dashboard-page">
        <UserInfo
          userInfo={userInfo}
          showViewActionPlanButton
          showDetailButtons
        />

        <div className="dashboard-page__data">
          <div className="dashboard-page__data--top">
            <Progress score={userScore} user={userInfo} />
            {userInfo.role === 'boss' && <AreaUsersData />}
          </div>
          <div className="dashboard-page__data--bottom">
            <CategoriesRanking myId={userInfo._id} />
            <Communications userInfo={userInfo} />
          </div>
        </div>
        {showSurvey && <Survey setIsFormOpen={setShowSurvey} />}
      </div>
    )
  ) : null;
};

export default DashboardPage;
