import {
  READ_ERROR,
  READ_REQUEST,
  READ_SUCCESS,
  WRITE_ERROR,
  WRITE_REQUEST,
  WRITE_RESET,
  WRITE_SUCCESS,
} from '../constants/actionConstants';

export const appReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case READ_REQUEST:
      return { pageLoading: true, pageLoaded: false, statusError: null };

    case READ_SUCCESS:
      return { ...state, pageLoading: false, pageLoaded: true };

    case READ_ERROR:
      return {
        ...state,
        pageLoading: false,
        pageLoaded: false,
        statusError: payload,
      };

    case WRITE_REQUEST:
      return { ...state, sending: true, message: null };

    case WRITE_SUCCESS:
      return {
        ...state,
        sending: false,
        success: true,
        message: payload,
      };

    case WRITE_ERROR:
      return {
        ...state,
        sending: false,
        success: false,
        message: payload,
      };

    case WRITE_RESET:
      return { ...state, sending: false, success: false, message: null };

    default:
      return state;
  }
};
