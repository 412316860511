import CustomModal from '../CustomModal/CustomModal';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import './ConfirmModal.scss';
import { useState } from 'react';
import { motives } from '../../constants/inactivateMotives';

const ConfirmModal = ({ setIsFormOpen, handleConfirm, username, action }) => {
  const userAction = action ? 'DESACTIVAR' : 'ACTIVAR';
  const [motive, setMotive] = useState('');

  return (
    <CustomModal
      title="Activación/Desactivación de usuario"
      setIsFormOpen={setIsFormOpen}
    >
      <div className="confirm-modal__warning-icon-container">
        <ErrorIcon
          fontSize="large"
          htmlColor="#dda312"
          className="warning-icon"
        />
      </div>
      <div className="confirm-modal__title">
        {`¿Estás seguro de ${userAction} a ${username} ?`}
      </div>
      {userAction === 'DESACTIVAR' && (
        <>
          <p className="select-motive__title">
            Por favor seleccioná un motivo por el cual querés desactivar a
            <span style={{ marginLeft: '5px' }}>{username}</span>
          </p>
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <InputLabel>Motivo</InputLabel>
              <Select
                value={motive}
                label="Motivo"
                onChange={(e) => setMotive(e.target.value)}
                defaultValue=""
              >
                {motives.map((motive) => (
                  <MenuItem value={motive.value} key={motive.value}>
                    {motive.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <p className="warning-message">
            * Tené en cuenta que se desasociará del área que tenga asignada.
          </p>
        </>
      )}
      {userAction === 'ACTIVAR' && (
        <>
          <p className="warning-message">
            * Se reiniciarán todos los puntos y monedas que tenía.
          </p>
          <p className="warning-message">* Recordá asociarle un área.</p>
        </>
      )}

      <div className="confirm-modal__actions">
        <Button
          onClick={() => handleConfirm(motive)}
          disabled={userAction === 'DESACTIVAR' && !motive}
          variant="contained"
        >
          Confirmar
        </Button>
        <Button onClick={() => setIsFormOpen(false)} variant="outlined">
          Cancelar
        </Button>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
