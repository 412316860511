import AreaUser from '../AreaUser';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import './AreaUsersList.scss';

const AreaUsersList = ({
  bosses,
  employees,
  selectedUser,
  setSelectedUser,
  selectedArea,
  evaluation,
}) => {
  const byRange = (a, b) => {
    if (a.range < b.range) return -1;
    if (a.range > b.range) return 1;
    return 0;
  };

  return (
    <div className="area-users-list">
      {!!evaluation && selectedArea.evaluationCreated && (
        <div className="area-users-list__evaluation-status area-users-list__evaluation-status--created">
          <CheckCircleOutlinedIcon />
          Se han creado las evaluaciones para empleados para esta área.
        </div>
      )}
      {!!evaluation && !selectedArea.evaluationCreated && (
        <div className="area-users-list__evaluation-status area-users-list__evaluation-status--not-created">
          <CancelOutlinedIcon />
          No se han creado las evaluaciones para empleados para esta área.
        </div>
      )}
      <div className="area-users-list__users">
        <p>Jefes</p>
        {selectedArea.withoutBoss ? (
          <div className="area-users-list__widthout-boss">
            Área sin jefes (responde al nivel superior)
          </div>
        ) : bosses.length > 0 ? (
          bosses
            .sort(byRange)
            .map((user) => (
              <AreaUser
                key={user._id}
                user={user}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            ))
        ) : (
          <div className="area-users-list__no-boss">Sin jefes asignados</div>
        )}
      </div>
      <div className="area-users-list__users">
        <p>Empleados</p>
        {selectedArea.withoutEmployees ? (
          <div className="area-users-list__without-employees">
            Área definida como "área sin empleados"
          </div>
        ) : employees.length > 0 ? (
          employees.map((user) => (
            <AreaUser
              key={user._id}
              user={user}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          ))
        ) : (
          <div className="area-users-list__no-employees">
            Sin empleados asignados
          </div>
        )}
      </div>
    </div>
  );
};

export default AreaUsersList;
