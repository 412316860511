import React, { useEffect } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import { getAction } from '../../actions/modelActions';
import { statusModel } from '../../constants/modelConstants';
import { useHistory } from 'react-router-dom';
import './StatusPage.scss';

const StatusPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector((store) => store.status);

  useEffect(() => {
    dispatch(getAction(statusModel.NAME, statusModel.URL));
  }, [dispatch]);

  const getColor = (value) => {
    if (value >= 80) return 'high';
    if (value >= 60) return 'medium';
    if (value >= 40) return 'low';
    return 'sublow';
  };

  const handleRedirectClick = (redirectUrl) => {
    if (redirectUrl) {
      return history.push(redirectUrl);
    }
  };

  return (
    <div className="status-page">
      <h3>Status de la empresa</h3>
      <div className="stats-container">
        {status.map(({ title, data, percentage, redirectUrl }) => (
          <div className="stat" key={title}>
            <h2 className="stat__title">{title}</h2>
            <span className="stat__data">{data}</span>
            {percentage >= 0 ? (
              <span className="stat__info">
                <CircularProgress
                  variant="determinate"
                  value={Number(percentage || 0)}
                  color={getColor(percentage)}
                  size={30}
                />
                {percentage}%
              </span>
            ) : (
              <span></span>
            )}
            {redirectUrl && (
              <IconButton
                className="stat__redirect-btn"
                onClick={() => handleRedirectClick(redirectUrl)}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusPage;
