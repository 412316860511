import { useEffect, useState } from 'react';
import { Button, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import { dashboardModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal';
import UserAutocomplete from '../../../components/UserAutocomplete/UserAutocomplete';
import confetti from 'canvas-confetti';

import './CongratulationForm.scss';

const CongratulationForm = ({ setIsFormOpen }) => {
  const { sending, success } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const dispatch = useDispatch();

  const [congratulation, setCongratulation] = useState({
    toUserId: '',
    content: '',
  });

  const handleSubmit = () => {
    dispatch(
      postAction(
        dashboardModel.NAME,
        dashboardModel.CONGRATULATION_URL,
        congratulation
      )
    );
  };

  const handleContentChange = ({ target: { value } }) =>
    setCongratulation((prevState) => ({ ...prevState, content: value }));

  const handleUserChange = (toUserId) =>
    setCongratulation((prevState) => ({ ...prevState, toUserId }));

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
      confetti({ spread: 180 });
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Crear Felicitación" setIsFormOpen={setIsFormOpen}>
      <div className="congratulation-form__header">Felicito a:</div>
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <UserAutocomplete
            handleOnChange={handleUserChange}
            excludeId={userInfo._id}
          />
        </FormControl>
      </div>
      <div>
        <textarea
          className="congratulation-form__textarea"
          name="congratulation"
          value={congratulation.content}
          onChange={handleContentChange}
          placeholder="Motivo..."
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            sending || !congratulation.content || !congratulation.toUserId
          }
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default CongratulationForm;
