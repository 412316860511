import ServerErrorImage from '../../images/server-error.png';
import './ServerError.scss';

const ServerError = () => {
  return (
    <div className="server-error">
      <div className="server-error__message">
        <img width="150px" src={ServerErrorImage} alt="Server Error" />
        <div className="server-error__text">
          <h3>Ha ocurrido un error</h3>
          <p>Inténtelo de nuevo más tarde.</p>
        </div>
      </div>
    </div>
  );
};

export default ServerError;
