import { FormControl, TextField } from '@mui/material';
const Ponderations = ({
  competences,
  objetives,
  assistance,
  evaluation,
  handlePercentageChange,
  showtitle,
}) => {
  return (
    <>
      {showtitle && <h3>Ponderaciones</h3>}
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Competencias %"
            name="competences"
            value={competences || 1}
            onChange={handlePercentageChange}
            disabled={!!evaluation}
          />
        </FormControl>
      </div>
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Objetivos %"
            name="objetives"
            value={objetives || 1}
            onChange={handlePercentageChange}
            disabled={!!evaluation}
          />
        </FormControl>
      </div>
      <div className="form-input-wrapper">
        <FormControl fullWidth>
          <TextField
            type="number"
            label="Presentismo %"
            name="assistance"
            value={assistance}
            onChange={handlePercentageChange}
            disabled={!!evaluation}
          />
        </FormControl>
      </div>
    </>
  );
};

export default Ponderations;
