import { useSelector } from 'react-redux';
import UserInfo from '../../../components/UserInfo';
import CategoriesRanking from '../CategoriesRanking';
import Communications from '../Comunications';
import DashboardStatus from '../DashboardStatus';
import MissingTasks from '../MissingTasks';
import './SuperAdminDashboard.scss';

const SuperAdminDashboard = () => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  return (
    <div className="super-admin-dashboard">
      <div className="super-admin-dashboard__top">
        <UserInfo
          userInfo={userInfo}
          showViewActionPlanButton
          showDetailButtons
        />
        <CategoriesRanking />
        <MissingTasks />
        <Communications userInfo={userInfo} />
      </div>
      <div className="super-admin-dashboard__bottom">
        <DashboardStatus />
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
