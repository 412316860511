import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAction, putAction } from '../../../actions/modelActions';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { goalsModel } from '../../../constants/modelConstants';
import GroupInput from './GroupInput';
import TitleInput from './TitleInput';
import DetailInput from './DetailInput';
import UsersInput from './UsersInput';

import './GoalForm.scss';

const GoalForm = ({ setIsFormOpen, selectedUser, editingGoal }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { teamUsers } = useSelector((store) => store.generalData);

  const initialForm = {
    title: editingGoal?.title || '',
    detail: editingGoal?.detail || '',
    toUserId: editingGoal?.toUserId || selectedUser || null,
    group: editingGoal?.group || false,
    toUsersIds: editingGoal?.toUsersIds || [],
  };
  const [formData, setFormData] = useState(initialForm);
  const { title, detail, toUserId, group, toUsersIds } = formData;

  const handleChange = (e) => {
    if (
      (e.target.name !== 'title' && e.target.name !== 'detail') ||
      (e.target.name === 'title' &&
        (title.length <= 39 || e.target.value.length <= 40)) ||
      (e.target.name === 'detail' &&
        (detail.length <= 219 || e.target.value.length <= 220))
    ) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = () => {
    const updatedData = {
      ...formData,
      toUserId: formData.toUserId?._id || null,
      toUsersIds: formData.toUsersIds?.map((u) => u._id),
    };

    editingGoal
      ? dispatch(
          putAction(
            goalsModel.NAME,
            `${goalsModel.URL}/${editingGoal._id}`,
            updatedData
          )
        )
      : dispatch(postAction(goalsModel.NAME, goalsModel.URL, updatedData));
  };

  const handleAutocompleteChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleGroupChange = ({ target: { value } }) => {
    setFormData({ ...formData, toUserId: null, toUsersIds: [], group: value });
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal
      title={editingGoal ? 'Editar Objetivo' : 'Agregar Objetivo'}
      setIsFormOpen={setIsFormOpen}
    >
      {teamUsers.length ? (
        <div>
          <GroupInput
            group={group}
            handleGroupChange={handleGroupChange}
            editingGoal={editingGoal}
            selectedUser={selectedUser}
          />
          <UsersInput
            handleAutocompleteChange={handleAutocompleteChange}
            selectedUser={selectedUser}
            toUserId={toUserId}
            toUsersIds={toUsersIds}
            group={group}
            editingGoal={editingGoal}
          />
          <TitleInput title={title} handleChange={handleChange} />
          <DetailInput detail={detail} handleChange={handleChange} />
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              sending || !title || !detail || (!toUserId && !toUsersIds)
            }
          >
            {sending ? 'Guardando' : 'Guardar'}
          </Button>
        </div>
      ) : (
        <div>
          <h3>Ya asignaste el máximo número posible de objetivos.</h3>
        </div>
      )}
    </CustomModal>
  );
};

export default GoalForm;
