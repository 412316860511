export const getUserOptions = (
  forRole,
  selectedArea,
  affectedUsers,
  users,
  type,
  areas
) => {
  let userOptions = [];

  if (type === 'BOSS_PAIR') {
    selectedArea.parentArea?.dependentAreas.forEach((areaId) => {
      if (areaId !== selectedArea._id) {
        const currentArea = areas.find((mapedArea) => mapedArea._id === areaId);
        currentArea.bosses.forEach((boss) => {
          const currentBoss = users.find(
            (mapedUser) => mapedUser._id === boss._id
          );
          userOptions.push(currentBoss);
        });
      }
    });
  }

  if (type === 'EMPLOYEE_PAIR') {
    selectedArea.parentArea?.dependentAreas.forEach((areaId) => {
      if (areaId !== selectedArea._id) {
        const currentArea = areas.find((mapedArea) => mapedArea._id === areaId);
        currentArea.employees.forEach((employee) => {
          const currentEmployee = users.find(
            (mapedUser) => mapedUser._id === employee._id
          );
          userOptions.push(currentEmployee);
        });
      }
    });
  }

  return userOptions.filter(
    (user) =>
      !affectedUsers.find((affectedUser) => affectedUser.user === user._id) &&
      user.active
  );

  /* users.filter(
    (user) =>
      user.role === forRole &&
      user.area?._id !== selectedArea._id &&
      !affectedUsers.find((affectedUser) => affectedUser.user === user._id)
  ); */
};
