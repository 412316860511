import { useSelector } from 'react-redux';
import ServerError from '../../components/ServerError/ServerError';
// import SouthEastIcon from '@mui/icons-material/SouthEast';
import companyLogo from '../../assets/company-logo.jpeg';

import './CompanyPage.scss';

const CompanyPage = () => {
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { company } = useSelector((store) => store.generalData);

  if (statusError) return <ServerError />;

  return company && (pageLoaded || !statusError) ? (
    <div className="company-page">
      <h3>Sobre la Empresa</h3>
      <div className="company-page__content">
        <div className="company-page__logo">
          <img src={companyLogo} alt="Logo de la empresa" />
        </div>
        <div className="company-page__data">
          <h1>{company.name ? company.name : 'Nombre no imformado'}</h1>
          <div className="company-page__description">
            {company.description ? (
              <div
                className="rich-text rich-text__view"
                dangerouslySetInnerHTML={{ __html: company.description }}
              ></div>
            ) : (
              'Descripción no imformada'
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CompanyPage;
