import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import { dashboardModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal';

import './NoveltyForm.scss';

const NoveltyForm = ({ setIsFormOpen }) => {
  const [novelty, setNovelty] = useState('');

  const { sending, success } = useSelector((store) => store.app);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      postAction(dashboardModel.NAME, dashboardModel.NOVELTY_URL, {
        content: novelty,
      })
    );
  };

  const handleChange = (e) => setNovelty(e.target.value);

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Crear Novedad" setIsFormOpen={setIsFormOpen}>
      <div>
        <textarea
          className="novelty-form-textarea"
          name="novelty"
          value={novelty}
          onChange={handleChange}
          placeholder="Novedad..."
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={sending || !novelty}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default NoveltyForm;
