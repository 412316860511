import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postAction, putAction } from '../../../actions/modelActions';
import { benefitModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal/CustomModal';
import './BenefitsForm.scss';

const BenefitsForm = ({ setIsFormOpen, editingBenefit = null, categories }) => {
  const { sending, success } = useSelector((store) => store.app);
  const dispatch = useDispatch();

  const initialBenefit = {
    title: editingBenefit?.title || '',
    description: editingBenefit?.description || '',
    exchangeType: editingBenefit?.exchangeType || 'points',
    category: editingBenefit?.category?._id || '',
    coins: editingBenefit?.coins || 0,
    internal: editingBenefit?.internal.toString() || true,
    isActive: editingBenefit?.isActive.toString() || true,
    field: editingBenefit?.field || '',
  };
  const [benefit, setBenefit] = useState(initialBenefit);
  const {
    title,
    description,
    exchangeType,
    category,
    coins,
    internal,
    isActive,
    field,
  } = benefit;

  const isFormDisabled = () => {
    if (exchangeType === 'points') {
      return sending || !title || !description || !category || !field;
    }

    return sending || !title || !description /* || coins < 1 */ || !field;
  };

  const handleChange = ({ target: { name, value } }) => {
    setBenefit({ ...benefit, [name]: value });
  };

  const handleRadioChange = ({ target: { value } }, name) => {
    setBenefit({ ...benefit, [name]: Boolean(value === 'true') });
  };

  const handleSubmit = () => {
    // const [coinsCategory] = categories.filter(
    //   (category) => category.exchangeType === 'coins'
    // );

    editingBenefit
      ? dispatch(
          putAction(benefitModel.NAME, benefitModel.URL, {
            ...benefit,
            category:
              exchangeType === 'points'
                ? category
                : /* coinsCategory._id */ null,
            id: editingBenefit._id,
          })
        )
      : dispatch(
          postAction(benefitModel.NAME, benefitModel.URL, {
            ...benefit,
            category:
              exchangeType === 'points'
                ? category
                : /* coinsCategory._id */ null,
          })
        );
  };

  useEffect(() => {
    if (exchangeType === 'no-exchange') {
      setBenefit((benefit) => ({ ...benefit, category: null }));
    }
  }, [exchangeType]);

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal
      title={`${editingBenefit ? 'Editar' : 'Crear un'} beneficio`}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="benefits-form">
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <TextField
              label="Título"
              name="title"
              autoComplete="off"
              value={title}
              required
              onChange={handleChange}
              placeholder="-10%"
            />
          </FormControl>
        </div>

        <textarea
          className="benefits-form-textarea"
          name="description"
          value={description}
          required
          onChange={handleChange}
          placeholder="Descripcion del beneficio..."
        />

        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <FormLabel component="legend">Canjeable por</FormLabel>
            <RadioGroup
              row
              aria-label="exchangeType"
              name="exchangeType"
              onChange={handleChange}
              value={exchangeType}
            >
              <FormControlLabel
                value="points"
                control={<Radio color="primary" />}
                label="Puntos"
              />
              <FormControlLabel
                value="no-exchange"
                control={<Radio color="primary" />}
                label="Sin categoría"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {exchangeType === 'points' && (
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <InputLabel id="title">Categoría</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={category}
                required
                label="Categoría"
                name="category"
                onChange={handleChange}
              >
                {categories
                  .filter((category) => category.exchangeType === 'points')
                  .map((category) => (
                    <MenuItem value={category._id} key={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}

        {exchangeType === 'coins' && (
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Monedas"
                name="coins"
                value={coins}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        )}

        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <TextField
              label="Rubro"
              name="field"
              autoComplete="off"
              value={field}
              required
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="benefits-form__row">
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <FormLabel component="legend">Tipo de Beneficio</FormLabel>
              <RadioGroup
                row
                aria-label="internal"
                name="internal"
                onChange={(e) => handleRadioChange(e, 'internal')}
                value={internal}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Interno"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Externo"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="form-input-wrapper">
            <FormControl fullWidth>
              <FormLabel component="legend">Activo</FormLabel>
              <RadioGroup
                row
                aria-label="isActive"
                name="isActive"
                onChange={(e) => handleRadioChange(e, 'isActive')}
                value={isActive}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="SI"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isFormDisabled()}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default BenefitsForm;
