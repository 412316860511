import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../actions/modelActions';
import { benefitModel } from '../../constants/modelConstants';
import BenefitsTable from './BenefitsTable';
import BenefitsForm from './BenefitsForm';
import { useHistory } from 'react-router-dom';

import './BenefitsAdminPage.scss';
import ServerError from '../../components/ServerError';

const BenefitsAdminPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { benefits } = useSelector((store) => store.benefits);
  const {
    company,
    config: { viewBenefits },
  } = useSelector((store) => store.generalData);

  const [editingBenefit, setEditingBenefit] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  if (!viewBenefits) history.replace('/');

  useEffect(() => {
    dispatch(getAction(benefitModel.NAME, benefitModel.URL));
  }, [dispatch]);

  const handleEdit = (benefit) => {
    setEditingBenefit(benefit);
    setIsFormOpen(true);
  };

  if (statusError) return <ServerError />;

  return pageLoaded && company?.categories ? (
    <div className="benefit-admin-page">
      <BenefitsTable
        benefits={benefits}
        handleEdit={handleEdit}
        categories={company.categories}
      />
      {isFormOpen && (
        <BenefitsForm
          setIsFormOpen={setIsFormOpen}
          editingBenefit={editingBenefit}
          categories={company.categories}
        />
      )}
    </div>
  ) : null;
};

export default BenefitsAdminPage;
