import { Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BusinessIcon from '@mui/icons-material/Business';

import './TeamEvaluations.scss';

const TeamEvaluations = ({ teamEvaluations }) => {
  const history = useHistory();

  return (
    <div className="team-evaluations">
      {teamEvaluations.map(({ user, evaluations }, i) => (
        <div className="team-evaluations__user" key={user._id}>
          <div
            className="team-evaluations__user-header"
            onClick={() => history.push(`/usuario/${user._id}`)}
          >
            <Avatar src={user.avatar} />
            <div>
              {user.fullname}
              <div className="team-evaluations__user-area">
                <BusinessIcon fontSize="inherit" />
                {user.area.name}
              </div>
            </div>
          </div>

          <div className="team-evaluations__user-evaluations">
            {evaluations?.length === 0 && (
              <p className="team-evaluations__no-evaluations">
                No se le crearon evaluaciones.
              </p>
            )}
            {evaluations.map((ev) => (
              <p key={ev._id} className="team-evaluations__user-evaluation">
                <span>{ev.label}</span>
                <span className="icon">
                  {ev.done ? (
                    <CheckCircleOutlineOutlinedIcon htmlColor="#36b29a" />
                  ) : (
                    <HighlightOffOutlinedIcon htmlColor="#df8932" />
                  )}
                </span>
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamEvaluations;
