import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CompetenceTooltip from '../../../components/CompetenceTooltip/CompetenceTooltip';
import './Competence.scss';

const Competence = ({ competence, handleCompetenceClick, assigned }) => {
  const { name } = competence;

  return (
    <div
      className="competence"
      onClick={() => handleCompetenceClick(competence, !assigned)}
    >
      {!assigned && (
        <div className="competence__arrow">
          <ArrowBackIosIcon size="small" />
        </div>
      )}
      <div className="competence__name">
        {name} <span className="competence__detail-icon"></span>
        <CompetenceTooltip competence={competence}>
          <HelpOutlineIcon fontSize="14px" />
        </CompetenceTooltip>
      </div>
      {assigned && (
        <div className="competence__arrow">
          <ArrowForwardIosIcon size="small" />
        </div>
      )}
    </div>
  );
};

export default Competence;
