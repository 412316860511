import { getQueryString } from '../helpers/getQueryString';
import httpClient from '../http-client/httpClient';

export const getUsersByArea = async (area) => {
  const { data } = await httpClient.get(`area/${area}`);
  return data;
};

export const getRelatedUsers = async (areasIds) => {
  const { data } = await httpClient.get(`area/multiple?areasIds=${areasIds}`);
  return data;
};

export const getEvaluationsImpacts = async (userId) => {
  const { data } = await httpClient.get(`evaluation/impacts/${userId}`);
  return data;
};

export const getFilteredUsers = async (filter, page) => {
  const queryString = getQueryString(filter);
  const { data } = await httpClient.get(
    `user/search?${queryString}&page=${page}`
  );
  return data;
};

export const fetchRandomUsers = async () => {
  const { data } = await httpClient.get('user/game/random');
  return data;
};

export const fetchRandomQuestions = async () => {
  const { data } = await httpClient.get('user/game/quiz');
  return data;
};

export const getFilteredGoals = async (userId) => {
  const { data } = await httpClient.get(`goal/user/${userId}`);
  return data;
};
