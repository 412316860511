export const getAreaTree = (areas, myArea) => {
  const areasToShow = [];

  const getDependentAreas = (currentArea) => {
    const currentAreaData = areas?.find(
      (area) => area._id === currentArea?._id
    );
    areasToShow.push({
      _id: currentAreaData?._id,
      name: currentAreaData?.name,
      bosses: currentAreaData?.bosses,
      employees: currentAreaData?.employees,
    });
    if (currentAreaData?.dependentAreas?.length > 0) {
      currentAreaData.dependentAreas?.forEach((dependentArea) => {
        getDependentAreas(dependentArea);
      });
    }
  };

  getDependentAreas(myArea);

  return areasToShow;
};

export const getUsersTree = (areas, myArea) => {
  if (!myArea) return [];
  const usersTree = [];
  getAreaTree(areas, myArea)?.forEach((area) =>
    usersTree.push(...area.employees, ...area.bosses)
  );
  return usersTree;
};
