import Tooltip from '@mui/material/Tooltip';
import './UserScoresTooltip.scss';

const UserScoresTooltip = ({ user, children }) => {
  return (
    <Tooltip
      title={<UserScoresTooltipComponent user={user} />}
      arrow
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

const UserScoresTooltipComponent = ({ user }) => {
  const {
    score: { objetives, competences },
    fullname,
  } = user;

  return (
    <div className="user-score-tooltip">
      <p className="name"> {fullname}: </p>
      <p>Objetivos: {Math.round(objetives)} pts</p>
      <p>Competencias: {Math.round(competences)} pts</p>
    </div>
  );
};

export default UserScoresTooltip;
