import { Avatar } from '@mui/material';
import Coins from '../../../assets/coins.png';

const Category = ({ category, isCurrent, isSelected, setCategory }) => {
  return (
    <div
      key={category.value}
      className={`benefits__categories__category ${category.value} ${isCurrent(
        category.value
      )} ${isSelected(category.value)}`}
      onClick={() => setCategory(category)}
    >
      {category.exchangeType === 'coins' && (
        <span className="benefits__categories__coins">
          <Avatar src={Coins} sx={{ width: 60, height: 60 }} />
        </span>
      )}
      <span className="benefits__categories__category__name">
        {category.name}
      </span>
      {category.exchangeType === 'points' && (
        <span className="benefits__categories__category__min-points">
          Puntaje mínimo: {category.minPoints}
        </span>
      )}
      {category.exchangeType === 'coins' && (
        <span className="benefits__categories__category__min-points">
          Canjes por monedas
        </span>
      )}
      <span className={`${category.value}-background`}></span>
    </div>
  );
};

export default Category;
