import { finalEvaluationTypes } from './evaluationRelationships';

const getCurrentTypes = (evaluationsToImpact, companyImpacts) => {
  let missingTypes = [];
  let existingTypes = [];
  Object.keys(companyImpacts).forEach((type) => {
    if (!evaluationsToImpact.find((impact) => impact.evaluationType === type)) {
      missingTypes.push(type);
    } else {
      existingTypes.push(type);
    }
  });
  return { missingTypes, existingTypes };
};

const getCurrentImpact = (
  type,
  typeQty,
  missingTypes,
  existingTypes,
  companyImpacts
) => {
  let originalPonderation = companyImpacts[type] / 100;
  let ponderationAdded = 0;
  let totalExistingPonderations = 0;

  existingTypes.forEach((type) => {
    totalExistingPonderations += companyImpacts[type] / 100;
  });

  missingTypes.forEach((type) => {
    const missingPonderation = companyImpacts[type] / 100;
    const rate = (originalPonderation * 1) / totalExistingPonderations;
    ponderationAdded += missingPonderation * rate;
  });

  const finalType = finalEvaluationTypes.find((evType) => evType.key === type);

  return {
    evaluationType: type,
    impact: (originalPonderation + ponderationAdded) * 100,
    qty: typeQty,
    label: finalType.label,
  };
};

const getFinalEvaluationTypes = (evaluationsToImpact) => {
  return evaluationsToImpact.map((data) => {
    let finalType = finalEvaluationTypes.find((type) =>
      type.relatedTo.includes(data.evaluationType)
    );
    return { ...data, evaluationType: finalType.key };
  });
};

export const calculateImpacts = (evaluationsToImpact, companyImpacts) => {
  const evaluationTypes = getFinalEvaluationTypes(evaluationsToImpact);
  const { missingTypes, existingTypes } = getCurrentTypes(
    [...evaluationTypes, { evaluationType: 'SELF' }],
    companyImpacts
  );
  const impacts = existingTypes.map((type) => {
    const typeQty = evaluationTypes.filter(
      (evaluation) => evaluation.evaluationType === type
    ).length;
    return getCurrentImpact(
      type,
      typeQty,
      missingTypes,
      existingTypes,
      companyImpacts
    );
  });
  return impacts;
};
