import { Button, FormControl, LinearProgress, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState, useEffect } from 'react';
import CustomModal from '../../../components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { postGlobalEvaluationAction } from '../../../actions/modelActions';
import { generalDataModel } from '../../../constants/modelConstants';
import moment from 'moment';

const EvaluationForm = ({ setIsOpenForm }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const initialForm = {
    name: '',
    dateTo: null,
  };

  const [formData, setFormData] = useState(initialForm);

  const { name, dateTo } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (value, momentDate) => {
    const formatedDate = momentDate.format('YYYY-MM-DD');
    setFormData({ ...formData, [value]: formatedDate });
  };

  const handleSubmit = () => {
    dispatch(
      postGlobalEvaluationAction(
        generalDataModel.NAME,
        generalDataModel.CREATE_EVALUATION_URL,
        formData
      )
    );
  };

  useEffect(() => {
    if (success) {
      setIsOpenForm(false);
    }
  }, [success, setIsOpenForm]);

  return (
    <CustomModal title="Generar una Evaluación" setIsFormOpen={setIsOpenForm}>
      <div className="evaluation-data">
        <div className="form-input-wrapper">
          <FormControl fullWidth>
            <TextField
              label="Nombre"
              name="name"
              autoComplete="off"
              value={name}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="form-input-wrapper">
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Fecha hasta"
                inputFormat="DD/MM/YYYY"
                minDate={moment().add(7, 'days')}
                value={dateTo}
                onChange={(momentDate) =>
                  handleDateChange('dateTo', momentDate)
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        {sending && (
          <div className="sending-message">
            <p>Aguarde. Este proceso puede demorar ...</p>
            <LinearProgress />
          </div>
        )}
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={sending || !name || !dateTo}
        >
          {sending ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </CustomModal>
  );
};

export default EvaluationForm;
