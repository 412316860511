import { useSelector } from 'react-redux';
import ServerError from '../../components/ServerError/ServerError';
import CompanyForm from './CompanyForm';

import './ManageCompanyPage.scss';

const ManageCompanyPage = () => {
  const { company } = useSelector((store) => store.generalData);
  const { pageLoaded, statusError } = useSelector((store) => store.app);

  if (statusError) return <ServerError />;

  return company && pageLoaded ? (
    <div className="mange-company-page">
      <h3 className="mange-company-page__title">
        Administrar datos de la Empresa
      </h3>

      <CompanyForm company={company} />
    </div>
  ) : null;
};

export default ManageCompanyPage;
