const failedNotes = [
  { label: 'Deficiente', points: 1 },
  { label: 'Debajo de las expectativas', points: 2 },
  { label: 'Regular', points: 4 },
];
const doneNotes = [
  { label: 'Bueno', points: 6 },
  { label: 'Muy Bueno', points: 8 },
  { label: 'Sobresaliente', points: 10 },
];

const getNoteText = (note) => {
  if (note > 5) {
    return doneNotes.find((n) => n.points === note).label;
  }
  return failedNotes.find((n) => n.points === note).label;
};

const getGoalState = (state) => {
  switch (state) {
    case 'wip':
      return 'En Proceso';
    case 'done':
      return 'Cumplido';
    case 'failed':
      return 'No Cumplido';
    default:
      return '';
  }
};

export { failedNotes, doneNotes, getNoteText, getGoalState };
