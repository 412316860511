import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getAction, putAction } from '../../actions/modelActions';
import ServerError from '../../components/ServerError/ServerError';
import { evaluationModel } from '../../constants/modelConstants';
import EvaluationForm from './EvaluationForm';

import './EvaluationResponsePage.scss';
import BackButton from '../../components/BackButton';

const EvaluationResponsePage = ({ history }) => {
  const dispatch = useDispatch();
  const { pageLoaded, statusError } = useSelector((store) => store.app);
  const { evaluationResponse } = useSelector((store) => store.evaluations);
  const { userEvaluationId } = useParams();
  const { search } = useLocation();

  const sendResponse = (answers) => {
    if (!evaluationResponse.done) {
      dispatch(
        putAction(
          evaluationModel.NAME,
          `${evaluationModel.EVALUATION_RESPONSE}/${userEvaluationId}`,
          answers
        )
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAction(
        evaluationModel.NAME,
        `${evaluationModel.EVALUATION_RESPONSE}/${userEvaluationId}`
      )
    );
  }, [dispatch, userEvaluationId, search]);

  if (statusError) return <ServerError />;

  return pageLoaded && evaluationResponse ? (
    <div className="evaluation-response-page">
      <h3 className="evaluation-response-page__title">
        <div>{evaluationResponse.label}</div>
        <BackButton label="Salir de la evaluación" />
      </h3>

      <p>{evaluationResponse.name}</p>

      <EvaluationForm sendResponse={sendResponse} {...evaluationResponse} />
    </div>
  ) : null;
};

export default EvaluationResponsePage;
