import { useState, useEffect } from 'react';
import UserEvaluation from '../UserEvaluation';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

import './MultipleEvaluations.scss';

const MultipleEvaluations = ({ evaluations }) => {
  const [page, setPage] = useState(1);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [itemWidth, setItemWidth] = useState(0);
  const [movement, setMovement] = useState(0);

  const pendings = evaluations.filter((evaluation) => !evaluation.done).length;
  const offset = 25;

  const dots = evaluations.map((_, i) => ({
    key: `dot-${i + 1}`,
    active: page === i + 1,
  }));

  useEffect(() => {
    let shouldSkip = false;
    evaluations.forEach((evaluation, i) => {
      if (!evaluation.done && !shouldSkip) {
        shouldSkip = true;
        setPage(i + 1);
      }
    });
  }, [evaluations]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize, { pasive: true });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const wrapper = document
      .getElementById('multiple-evaluations-wrapper')
      .getBoundingClientRect();
    setItemWidth(wrapper.width - 40);
  }, [windowSize, setItemWidth]);

  useEffect(() => {
    const newMovement = page === 1 ? 0 : -itemWidth * (page - 1) - offset;
    setMovement(newMovement);
  }, [page, itemWidth]);

  return (
    <div id="multiple-evaluations-wrapper" className="multiple-evaluations">
      <p className="multiple-evaluations__title">Evaluaciones a empleados</p>

      <p
        className={`multiple-evaluations__sub-title ${
          pendings ? 'multiple-evaluations__sub-title--pendings' : ''
        }`}
      >
        {`${pendings} pendiente/s de ${evaluations.length}`}
      </p>
      <div
        className="multiple-evaluations__carousel"
        style={{ transform: `translateX(${movement}px)` }}
      >
        {evaluations.map((evaluation) => (
          <div
            key={evaluation._id}
            style={{ width: itemWidth, marginRight: offset }}
            className="multiple-evaluations__carousel__item"
          >
            <UserEvaluation userEvaluation={evaluation} />
          </div>
        ))}
      </div>
      <button
        className="changege-page changege-page--prev"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        <ArrowLeftOutlinedIcon />
      </button>
      <button
        className="changege-page changege-page--next"
        onClick={() => setPage(page + 1)}
        disabled={page === evaluations.length}
      >
        <ArrowRightOutlinedIcon />
      </button>
      <div className="multiple-evaluations__dots">
        {dots.map((dot) => (
          <div
            key={dot.key}
            className={`dot ${dot.active ? 'dot--active' : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default MultipleEvaluations;
