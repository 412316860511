import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SEND_MAIL_FAIL,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  USER_SIGN_IN_FAIL,
  USER_SIGN_IN_REQUEST,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_OUT,
  UPDATE_USER_SUCCESS,
} from '../constants/userConstants';

export const userSignInReducer = (state = {}, action) => {
  const { type, payload } = action;
  const { userInfo } = state;
  switch (type) {
    case USER_SIGN_IN_REQUEST:
      return { loading: true };

    case USER_SIGN_IN_SUCCESS:
      return { loading: false, userInfo: payload };

    case USER_SIGN_IN_FAIL:
      return { loading: false, error: payload };

    case SEND_MAIL_REQUEST:
      return { loading: true };

    case SEND_MAIL_SUCCESS:
      return { loading: false, message: payload };

    case SEND_MAIL_FAIL:
      return { loading: false, error: payload };

    case RESET_PASSWORD_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_SUCCESS:
      return { loading: false, message: payload, success: true };

    case RESET_PASSWORD_FAIL:
      return { loading: false, error: payload };

    case USER_SIGN_OUT:
      return {};

    case UPDATE_USER_SUCCESS:
      return { userInfo: { ...userInfo, ...payload } };

    default:
      return state;
  }
};
