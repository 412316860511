import { FormControl, Rating, TextField } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const DemandEditMode = ({
  handlePriorityChange,
  formData,
  handleDateChange,
  handleChange,
}) => {
  return (
    <>
      <div>
        <b>Prioridad:</b>
        <Rating
          name="priority"
          value={formData.priority}
          icon={<CircleIcon fontSize="small" />}
          emptyIcon={<CircleOutlinedIcon fontSize="small" />}
          max={3}
          onChange={(_, newValue) => handlePriorityChange(newValue)}
          readOnly={false}
        />
      </div>
      <div className="form-input-wrapper">
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Fecha vencimiento"
              inputFormat="DD/MM/YYYY"
              value={formData.expiredDate}
              onChange={(momentDate) =>
                handleDateChange('expiredDate', momentDate)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <TextField
        label="Título"
        variant="outlined"
        name="title"
        value={formData.title}
        onChange={handleChange}
        fullWidth
      />
      <textarea
        className="demand-form-textarea"
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Descripción"
      />
    </>
  );
};

export default DemandEditMode;
