import { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Header from '../components/Header';
import SubHeader from '../components/SubHeader';
import PrivateRoute from '../components/PrivateRoute';
import Message from '../components/SnackBar';
// Routes
import routes from './routes';
import { ThemeContextProvider } from '../theme/ThemeContextProvider';
import { getGeneralDataAction } from '../actions/modelActions';
import { generalDataModel } from '../constants/modelConstants';
import { signOutAction } from '../actions/userActions';
import useListenSocketActions from './useListenSocketActions';
import InitialLoading from '../components/InitialLoading';
// import FloatingReminder from '../components/FloatingReminder/FloatingReminder';

import './Layout.scss';

const PrivateRoutes = ({ location }) => {
  useListenSocketActions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { success, message, statusError, pageLoaded } = useSelector(
    (store) => store.app
  );
  const [stickNavbar, setStickNavar] = useState(false);

  // Get general data
  useEffect(() => {
    dispatch(getGeneralDataAction(generalDataModel.NAME, generalDataModel.URL));
  }, [dispatch, history]);

  useEffect(() => {
    const listenerStickNavbar = () => {
      if (window !== undefined) {
        window.scrollY > 80 ? setStickNavar(true) : setStickNavar(false);
      }
    };

    window.addEventListener('scroll', listenerStickNavbar, { pasive: true });

    return () => {
      window.removeEventListener('scroll', listenerStickNavbar);
    };
  }, [setStickNavar]);

  useEffect(() => {
    if (statusError === 403 || statusError === 401) {
      const pathName = window.location.href.split('#/')[1];
      localStorage.setItem('path-name', pathName);
      dispatch(signOutAction());
      history.replace('/login');
      window.location.reload();
    }
  }, [statusError, dispatch, history]);

  return (
    <ThemeContextProvider>
      <div className="layout">
        <Header stickNavbar={stickNavbar} />
        <SubHeader location={location} stickNavbar={stickNavbar} />
        {userInfo && (
          <div className="layout__section">
            <Switch>
              {routes.map((route) => (
                <PrivateRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </div>
        )}

        <InitialLoading
          userInfo={userInfo}
          pageLoaded={pageLoaded}
          statusError={statusError}
        />
        {message && (
          <Message msg={message} severity={success ? 'success' : 'error'} />
        )}
        {/* <FloatingReminder /> */}
      </div>
    </ThemeContextProvider>
  );
};

export default PrivateRoutes;
