import { useSelector } from 'react-redux';
import ServerError from '../../components/ServerError';
import CreateEvaluation from './CreateEvaluation/CreateEvaluation';

import './ManageEvaluationsPage.scss';

const ManageEvaluationsPage = () => {
  const { statusError, pageLoading } = useSelector((store) => store.app);

  if (statusError) return <ServerError />;
  if (pageLoading) return null;

  return (
    <div className="manage-evaluations-page">
      <CreateEvaluation />
    </div>
  );
};

export default ManageEvaluationsPage;
