import { useState, useEffect } from 'react';
import { fetchRandomUsers } from '../../actions/innerStateActions';
import Cards from './Cards';
import Back from '../../assets/volver.png';
import AttemptsImages from './AttemptsImages';
import Counter from './Counter';
import './MemoPage.scss';
import GameModal from './GameModal';

const MemoPage = () => {
  const [play, setPlay] = useState(true);
  const [cards, setCards] = useState([]);
  const [firstSelectedCard, setFirstSelectedCard] = useState({});
  const [secondSelectedCard, setSecondSelectedCard] = useState({});
  const [correctCards, setCorrectCards] = useState([]);
  const [remainingAttempts, setRemainingAttempts] = useState(3);
  const [compareCards, setCompareCards] = useState(false);
  const [win, setWin] = useState(false);
  const [endGame, setEndGame] = useState(false);

  const handleClick = (c) => {
    //Si toco en una card que ya está como correcta, no hago nada
    if (correctCards.includes(c._id) || remainingAttempts === 0) {
      return null;
    }

    // Al seleccionar una card, me fijo . Si ya hay una seleccionada
    if (firstSelectedCard._id) {
      if (c._id === firstSelectedCard._id) {
        // si toco la misma, no hago nada
        return null;
        // si toco la misma, la devuelvo al estdo inicial
        // const updatedCards = cards.map((card) =>
        //   card._id === c._id ? { ...card, rotate: !card.rotate } : card
        // );
        // setCards(updatedCards);
        // setFirstSelectedCard({});
      } else {
        // Si es otra, la seteo como segunda, y comparo resultado
        const updatedCards = cards.map((card) =>
          card._id === c._id ? { ...card, rotate: !card.rotate } : card
        );
        setCards(updatedCards);
        setSecondSelectedCard(c);
        setCompareCards(true);
      }
    } else {
      // Si no hay una seleccionada, doy vuelta la primera y la seteo
      setFirstSelectedCard(c);
      const updatedCards = cards.map((card) =>
        card._id === c._id ? { ...card, rotate: !card.rotate } : card
      );
      setCards(updatedCards);
    }
  };

  const flipWrongCards = () => {
    const updatedCards = cards.map((card) =>
      card._id === firstSelectedCard._id || card._id === secondSelectedCard._id
        ? { ...card, rotate: !card.rotate }
        : card
    );
    setCards(updatedCards);
  };

  const compare = () => {
    //Si son las mismas, las pongo en correctas
    if (firstSelectedCard.fullname === secondSelectedCard.fullname) {
      setTimeout(() => {
        setCorrectCards([
          ...correctCards,
          firstSelectedCard._id,
          secondSelectedCard._id,
        ]);
      }, 2000);
      reset();
    } else {
      // Sino, las vuelvo a dar vuelta, sumo un intento fallido y seteo las seleccionadas a cero de nuevo
      setTimeout(() => {
        flipWrongCards();
        setRemainingAttempts(remainingAttempts - 1);
      }, 2000);
      reset();
    }
  };

  const reset = () => {
    setFirstSelectedCard({});
    setSecondSelectedCard({});
    setCompareCards(false);
  };

  useEffect(() => {
    if (remainingAttempts === 0) {
      setWin(false);
      setEndGame(true);
    }
  }, [remainingAttempts]);

  useEffect(() => {
    if (cards.length > 0 && correctCards.length === cards.length) {
      setWin(true);
      setEndGame(true);
    }
  }, [correctCards, cards.length]);

  useEffect(() => {
    const fetchUsers = async () => {
      const randomUsers = await fetchRandomUsers();
      const doubleUsers = [...randomUsers, ...randomUsers];
      const mappedRandomUsers = doubleUsers.map((u, i) => ({
        ...u,
        rotate: false,
        _id: Math.random(),
      }));

      setCards(
        mappedRandomUsers.sort(function () {
          return Math.random() - 0.5;
        })
      );
    };

    fetchUsers();
  }, []);

  if (compareCards) {
    compare();
  }

  if (!play) {
    return <button onClick={() => setPlay(true)}> JUGAR </button>;
  }

  return cards.length > 0 ? (
    <div className="game-page">
      {endGame ? (
        <GameModal win={win} />
      ) : (
        <>
          <div className="left-side">
            <AttemptsImages remainingAttempts={remainingAttempts} />
          </div>
          <div className="board">
            <Cards cards={cards} handleClick={handleClick} />
          </div>
          <div className="right-side">
            <Counter setEndGame={setEndGame} />
            <div
              className="back-button"
              onClick={() => (window.location.href = '/')}
            >
              <img src={Back} alt="" />
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default MemoPage;
