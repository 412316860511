import ScrollContainer from 'react-indiana-drag-scroll';
import RecursiveArea from '../RecursiveArea';

import './CompanyChart.scss';

const CompanyChart = ({ managements, areasExpanded, areas }) => {
  return (
    <ScrollContainer className="scroll-container">
      <div className="company-chart">
        {managements.map((management, i) => (
          <RecursiveArea
            key={management._id}
            area={management}
            areasExpanded={areasExpanded}
            totalAreas={managements.length}
            index={i}
            areas={areas}
          />
        ))}
      </div>
    </ScrollContainer>
  );
};

export default CompanyChart;
