import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { postAction } from '../../../actions/modelActions';
import CustomModal from '../../../components/CustomModal';
import { WRITE_SUCCESS } from '../../../constants/actionConstants';
import { generalDataModel } from '../../../constants/modelConstants';
import './CompetenceTemplateForm.scss';

const CompetenceTemplateForm = ({
  setIsFormOpen,
  assignedForRole,
  assignedCompetencesIds,
  callingFromEvaluations,
}) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { competences } = useSelector((store) => store.generalData);

  const [template, setTemplate] = useState({
    forRole: assignedForRole || '',
    name: '',
    competencesIds: assignedCompetencesIds || [],
  });
  const [filteredCompetences, setFilteredCompetences] = useState([]);

  const { forRole, name, competencesIds } = template;

  const handleChange = ({ target }) =>
    setTemplate({ ...template, [target.name]: target.value });

  const handleSubmit = () => {
    const aditionalFc = (payload) =>
      dispatch({
        type: `${generalDataModel.NAME}${WRITE_SUCCESS}`,
        payload,
      });

    dispatch(
      postAction(
        generalDataModel.NAME,
        generalDataModel.CREATE_COMPETENCE_TEMPLATE,
        { name, forRole, competences: template.competencesIds },
        aditionalFc
      )
    );
  };

  const itemStyles = (currentCompetence) => {
    const isProfileAdded = competencesIds.includes(currentCompetence);
    return isProfileAdded ? { color: 'silver' } : { color: 'inherit' };
  };

  const getLabel = (competenceId) => {
    return competences.find((c) => c._id === competenceId).name;
  };

  useEffect(() => {
    if (!callingFromEvaluations) {
      setTemplate((prevState) => ({ ...prevState, competencesIds: [] }));
      setFilteredCompetences(
        competences.filter((competence) => competence.roles.includes(forRole))
      );
    }
  }, [forRole, competences, callingFromEvaluations]);

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal
      title="Crear Template de Competencias"
      setIsFormOpen={setIsFormOpen}
    >
      <div className="competences-templates-form">
        <FormControl className="form-input-wrapper">
          <InputLabel>Template para:</InputLabel>
          <Select
            value={forRole}
            label="Tempalte Para"
            name="forRole"
            onChange={handleChange}
            disabled={callingFromEvaluations}
          >
            <MenuItem value="boss">Jefes</MenuItem>
            <MenuItem value="employee">Empleados</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="name"
          label="Nombre"
          value={name}
          onChange={handleChange}
          placeholder="Nombre..."
          className="form-input-wrapper"
        />

        <FormControl fullWidth className="form-input-wrapper">
          <InputLabel>Competencias</InputLabel>
          <Select
            multiple
            name="competencesIds"
            value={competencesIds}
            onChange={handleChange}
            disabled={!forRole || callingFromEvaluations}
            input={<OutlinedInput label="Competencias" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={getLabel(value)} />
                ))}
              </Box>
            )}
          >
            {filteredCompetences.map((competence) => (
              <MenuItem
                key={competence._id}
                value={competence._id}
                style={itemStyles(competence)}
              >
                {competence.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              sending || !name || !forRole || competencesIds.length !== 5
            }
          >
            {sending ? 'Guardando' : 'Guardar'}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default CompetenceTemplateForm;
