import { Avatar, Button, IconButton, Tooltip } from '@mui/material';
import {
  evaluationTypes,
  getSugestedUsers,
} from '../../../helpers/evaluationRelationships';
import EvaluationType from '../EvaluationType';
import NorthOutlinedIcon from '@mui/icons-material/NorthOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { areaRelationshipsModel } from '../../../constants/modelConstants';
import ShowImpactsModal from '../ShowImpactsModal';
import { useHistory } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import './SelectedUser.scss';

export const SelectedUser = ({ selectedUser, selectedArea }) => {
  const dispatch = useDispatch();
  const { sending, success } = useSelector((store) => store.app);
  const { evaluation, areas, company } = useSelector(
    (store) => store.generalData
  );
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { evaluations } = useSelector((store) => store.areaRelationships);

  const history = useHistory();

  const [hasChanges, setHasChanges] = useState(false);
  const [affectedUsers, setAffectedUsers] = useState([]);
  const [isShowImpactsModalOpen, setIsShowImpactsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const show360ImpactsButton =
    company && evaluations.length > 0 && userInfo.isSuperAdmin;

  const handleViewImpacts = () => setIsShowImpactsModalOpen(true);

  const handleViewEvaluations = () =>
    history.push(`/evaluaciones-actuales/${selectedUser._id}`);

  const handleView360 = (evaluationId) =>
    history.push(`/impacto360/${evaluationId}/${selectedUser._id}`);

  const handleClick = () => {
    dispatch(
      putAction(areaRelationshipsModel.NAME, areaRelationshipsModel.CONFIRM, {
        userId: selectedUser._id,
        affectedUsers,
        areaId: selectedArea._id,
      })
    );
  };

  const handlePostEvaluationClick = () => {
    dispatch(
      putAction(
        areaRelationshipsModel.NAME,
        areaRelationshipsModel.POST_EVALUATION_CONFIRM,
        {
          userId: selectedUser._id,
          affectedUsers,
          areaId: selectedArea._id,
          evaluationId: evaluation._id,
        }
      )
    );
  };

  useEffect(() => {
    const result = getSugestedUsers(areas, selectedUser);
    const addedUsers =
      selectedUser.evaluationRelationships.affectedUsers.filter(
        (affected) => affected.isAddedUser
      );
    setAffectedUsers([...result, ...addedUsers]);
  }, [areas, selectedUser]);

  useEffect(() => {
    if (success) {
      setHasChanges(false);
    }
  }, [success]);

  return (
    <div className="selected-user">
      <div className="selected-user__header">
        <div className="selected-user__user">
          <Avatar sx={{ width: 60, height: 60 }} src={selectedUser.avatar} />
          <div className="selected-user__user-data">
            <div className="selected-user__user-name">
              {selectedUser.fullname}
            </div>
            <div className="selected-user__user-role-label">
              {selectedUser.roleLabel}
            </div>
          </div>
        </div>
        {userInfo.isSuperAdmin && (
          <Tooltip title="Afectaciones" placement="top" arrow>
            <IconButton onClick={handleViewImpacts}>
              <PercentOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {evaluation && userInfo.isSuperAdmin && (
          <Tooltip title="Evaluaciones actuales" placement="top" arrow>
            <IconButton onClick={handleViewEvaluations}>
              <InventoryOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {show360ImpactsButton && (
          <>
            <Tooltip title="Impacto 360 por evaluación" placement="top" arrow>
              <IconButton
                id="evaluations-list-button"
                aria-controls={open ? 'evaluations-list-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuButtonClick}
              >
                <RefreshOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="evaluations-list-menu"
              aria-labelledby="evaluations-list-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {evaluations.map((evaluation) => (
                <MenuItem
                  onClick={() => handleView360(evaluation._id)}
                  key={evaluation._id}
                >
                  {evaluation.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {evaluation ? (
          <Button
            variant="contained"
            onClick={handlePostEvaluationClick}
            disabled={sending || !hasChanges}
          >
            {sending ? 'Confirmando' : 'Confirmar'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={sending || !hasChanges}
          >
            {sending ? 'Confirmando' : 'Confirmar'}
          </Button>
        )}
      </div>
      <div className="selected-user__content">
        {selectedUser.role === 'boss' && !selectedArea.management && (
          <EvaluationType
            title="Jefes"
            forRole="boss"
            icon={<NorthOutlinedIcon fontSize="small" />}
            type={evaluationTypes.BOSS_UP}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.BOSS_UP
            )}
            hideAddUserButton
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
        {selectedUser.role === 'employee' && (
          <EvaluationType
            title="Jefes"
            forRole="boss"
            icon={<NorthOutlinedIcon fontSize="small" />}
            type={evaluationTypes.EMPLOYEE_BOSS}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.EMPLOYEE_BOSS
            )}
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
        {selectedUser.role === 'boss' && (
          <EvaluationType
            title="Pares"
            forRole="boss"
            icon={<SyncAltOutlinedIcon fontSize="small" />}
            type={evaluationTypes.BOSS_PAIR}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.BOSS_PAIR
            )}
            hideAddUserButton={selectedArea.management}
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
        {selectedUser.role === 'employee' && (
          <EvaluationType
            title="Pares"
            forRole="employee"
            icon={<SyncAltOutlinedIcon fontSize="small" />}
            type={evaluationTypes.EMPLOYEE_PAIR}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.EMPLOYEE_PAIR
            )}
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
        {selectedUser.role === 'boss' && (
          <EvaluationType
            title="Jefes"
            forRole="boss"
            icon={<SouthOutlinedIcon fontSize="small" />}
            type={evaluationTypes.BOSS_DOWN}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.BOSS_DOWN
            )}
            hideAddUserButton
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
        {selectedUser.role === 'boss' && (
          <EvaluationType
            title="Empleados"
            forRole="employee"
            icon={<SouthOutlinedIcon fontSize="small" />}
            type={evaluationTypes.BOSS_EMPLOYEE}
            selectedUser={selectedUser}
            affectedUsers={affectedUsers.filter(
              (user) => user.evaluationType === evaluationTypes.BOSS_EMPLOYEE
            )}
            setAffectedUsers={setAffectedUsers}
            setHasChanges={setHasChanges}
          />
        )}
      </div>
      {isShowImpactsModalOpen && (
        <ShowImpactsModal
          setIsFormOpen={setIsShowImpactsModalOpen}
          user={selectedUser}
        />
      )}
    </div>
  );
};
