import { LinearProgress } from '@mui/material';

import './EvaluationProgress.scss';

const EvaluationProgress = ({ done, timeElapsed, timeToExpire }) => {
  const range = timeElapsed + timeToExpire;
  const normalise = (value) => (value * 100) / range;

  const getrogressLabel = () => {
    return done ? (
      <span></span>
    ) : timeToExpire > 1 ? (
      <p>
        Tenés <b>{timeToExpire} días</b> para completar la evaluación{' '}
      </p>
    ) : timeToExpire === 1 ? (
      <span className="evaluation-progress__error">Último día</span>
    ) : (
      <p>Evaluación vencida</p>
    );
  };

  const linearProgressColor = () => {
    switch (true) {
      case timeToExpire > 5:
        return 'success';
      case timeToExpire > 3:
        return 'warning';
      default:
        return 'error';
    }
  };

  return (
    <div style={{ width: '400px' }}>
      {getrogressLabel()}
      {!done && timeToExpire > 1 && (
        <div
          className={`evaluation-progress ${
            done ? 'evaluation-progress-done' : 'evaluation-progress-pending'
          }`}
        >
          <LinearProgress
            value={normalise(timeElapsed)}
            color={linearProgressColor()}
            variant="determinate"
          />
        </div>
      )}
    </div>
  );
};

export default EvaluationProgress;
