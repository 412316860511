import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const GroupInput = ({
  group,
  handleGroupChange,
  editingGoal,
  selectedUser,
}) => {
  return (
    <div className="form-input-wrapper">
      <FormControl fullWidth>
        <InputLabel>Tipo de Objetivo</InputLabel>
        <Select
          value={group}
          label="Tipo de Objetivo"
          name="group"
          onChange={handleGroupChange}
          disabled={!!editingGoal?._id || selectedUser}
        >
          <MenuItem value={true}>Grupal</MenuItem>
          <MenuItem value={false}>Individual</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default GroupInput;
