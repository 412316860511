import EmptyComponentImage from '../../../components/EmptyComponentImage';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EvaluationResults from '../../../assets/evaluationResults.png';
import './CategoriesRanking.scss';

const CategoriesRanking = ({ myId }) => {
  const { evaluation, company } = useSelector((store) => store.generalData);
  const { categoriesRanking } = useSelector((store) => store.dashboard);
  const history = useHistory();

  return (
    <div className="categories-ranking">
      <h3>Ranking de puntajes de evaluación</h3>

      <div className="categories-ranking-container">
        {!company.isFirstEvaluation &&
          !evaluation &&
          categoriesRanking.map((user, index) => (
            <div
              className={`user ${user._id === myId && 'own'}`}
              key={user._id}
              onClick={() => history.push(`/usuario/${user._id}`)}
            >
              <div className="user-row">
                <div className="user-position">{index + 1}</div>
                <Avatar src={user.avatar} />
                <div className="user-data">
                  <div className="user-name">
                    {user.name} {user.lastname}
                  </div>

                  <div className="user-area">{user.area?.name}</div>
                </div>

                <div className="user-points">
                  {Math.round(user.score.totalScore)} Pts.
                </div>
              </div>
            </div>
          ))}
        {!company.isFirstEvaluation && evaluation && (
          <div className="categories-ranking__evaluation-process-message">
            <img src={EvaluationResults} alt="Ranking de resultados" />
            <p>
              Una vez finalizado el Proceso de Evaluación vas a poder ver los
              usuarios mejor puntuados de toda la empresa.
            </p>
            <p>
              El puntaje es únicamente el correspondiente a la última evaluación
              y no se acumula a puntajes de evaluaciones anteriores.
            </p>
          </div>
        )}
        {company.isFirstEvaluation && !evaluation && (
          <EmptyComponentImage text="Acá vas a poder ver los mejores puntajes de la empresa." />
        )}
      </div>
    </div>
  );
};

export default CategoriesRanking;
