import React from 'react';

const DetailInput = ({ detail, handleChange }) => {
  return (
    <>
      <textarea
        className="goal-form-textarea"
        name="detail"
        value={detail}
        onChange={handleChange}
        placeholder="Detalle"
      />
      <p className="condition-masssage">
        Máximo 220 caractéres ({detail.length}/220)
      </p>
    </>
  );
};

export default DetailInput;
