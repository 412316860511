import StarIcon from '@mui/icons-material/Star';
import EmptyComponentImage from '../../../components/EmptyComponentImage';
import './RecognitionsRanking.scss';

const RecognitionsRanking = ({ ranking, myId }) => {
  return (
    <div className="recognitions-ranking-container">
      <div className="recognitions-ranking">
        <h4> Ranking de Reconocimientos </h4>
        <div style={{ overflow: 'auto', height: '100%' }}>
          {ranking.length ? (
            ranking.map((user) => (
              <div
                className={`reco ${myId === user._id && 'own'}`}
                key={user._id}
              >
                <span className="reco-name">
                  <span className="reco-star">
                    <StarIcon htmlColor="#36b29a" />
                  </span>
                  <span>
                    {user.name} {user.lastname}
                  </span>
                </span>
                <span>
                  <span className="reco-qty">{user.numberOfRecognitions}</span>
                </span>
              </div>
            ))
          ) : (
            <EmptyComponentImage text="Acá vas a poder ver el ranking de los empleados más reconocidos." />
          )}
        </div>
      </div>
    </div>
  );
};

export default RecognitionsRanking;
