import { Avatar, Badge, Tooltip } from '@mui/material';
import './AssignedGoalsUsers.scss';

const AssignedGoalsUsers = ({
  goals,
  teamUsers,
  selectedUserId,
  setFilters,
}) => {
  const getGoalsQty = (userId) => {
    const userGoals = goals.filter(
      (goal) =>
        goal.toUserId?._id === userId ||
        goal.toUsersIds.some((user) => user._id === userId)
    );
    return userGoals.length.toString();
  };

  const handleAvatarClick = (user) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedUserId: user._id,
    }));
  };

  const removeSelectedUserFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedUserId: '',
    }));
  };

  return teamUsers?.length > 0 ? (
    <>
      <div className="assigned-goals-users">
        <div className="assigned-goals-users__users">
          {teamUsers.map((user) => (
            <div
              key={user._id}
              className={`avatar ${
                selectedUserId === user._id ? 'user-selected' : ''
              }`}
            >
              <Tooltip title={user.name + ' ' + user.lastname}>
                <Badge
                  badgeContent={getGoalsQty(user._id)}
                  color="primary"
                  sx={{
                    color: 'white',
                  }}
                >
                  <Avatar
                    src={user.avatar}
                    onClick={() => handleAvatarClick(user)}
                  />
                </Badge>
              </Tooltip>
            </div>
          ))}
        </div>
        {selectedUserId && (
          <div
            onClick={removeSelectedUserFilter}
            className="assigned-goals-users__remove-filter"
          >
            Quitar filtros de usuario
          </div>
        )}
      </div>
    </>
  ) : (
    <p> No tenes usuarios a cargo</p>
  );
};

export default AssignedGoalsUsers;
