import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAction } from '../../../actions/userActions';
import moment from 'moment';

import './PersonalData.scss';
import { userModel } from '../../../constants/modelConstants';
import PasswordModal from '../../../components/PasswordModal';

const PersonalData = ({ data }) => {
  const maxDate = moment().subtract(18, 'years');
  const initialState = {
    birthDate: null,
    civilStatus: '',
    children: false,
    childrenQuantity: 0,
    childrenComing: false,
    studies: false,
    studiesList: [],
    hobbies: '',
  };

  const [formData, setFormData] = useState({ ...initialState, ...data });
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const [customControlAdd, setCustomControlAdd] = useState({
    value: '',
    show: false,
  });

  const { sending } = useSelector((store) => store.app);

  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (momentDate) => {
    const birthDate = momentDate.format('YYYY-MM-DD');
    setFormData({ ...formData, birthDate });
  };

  const handleRadioChange = ({ target: { value } }, name) => {
    const reseteData =
      value !== 'false' && name === 'children' ? { childrenQuantity: 0 } : {};
    setFormData({ ...formData, [name]: value, ...reseteData });
  };

  const handleClick = () => {
    dispatch(updateUserAction(formData, userModel.UPDATE_PERSONAL_DATA));
  };

  const addControl = () => {
    setCustomControlAdd({ ...customControlAdd, show: true });
  };

  const customControlSave = () => {
    const { value } = customControlAdd;
    const studiesList = formData.studiesList;
    studiesList.push(value);
    setFormData({ ...formData, studiesList });
    setCustomControlAdd({ value: '', show: false });
  };

  const cancelCustomControlAdd = () => {
    setCustomControlAdd({ ...customControlAdd, show: false });
  };

  const deleteControl = (i) => {
    formData.studiesList.splice(i, 1);
    setFormData({ ...formData });
  };

  const handleCustomControlAddChange = ({ target: { value } }) => {
    setCustomControlAdd({ ...customControlAdd, value });
  };

  return (
    data && (
      <div className="personal-data">
        <div className="personal-data__form">
          <div className="personal-data__form__side">
            <div className="form-input-wrapper">
              <Button
                variant="contained"
                onClick={() => setIsPasswordModalOpen(true)}
              >
                Cambiar contraseña
              </Button>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Fecha de nacimiento"
                    inputFormat="DD/MM/YYYY"
                    maxDate={maxDate}
                    value={formData.birthDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <InputLabel id="civil-status">Estado Civil</InputLabel>
                <Select
                  labelId="civil-status"
                  id="civil-status"
                  value={formData.civilStatus}
                  label="Estado Civil"
                  onChange={handleChange}
                  name="civilStatus"
                  defaultValue=""
                >
                  <MenuItem
                    value="Soltero"
                    selected={formData.civilStatus === 'Soltero'}
                  >
                    Soltero
                  </MenuItem>
                  <MenuItem
                    value="Casado"
                    selected={formData.civilStatus === 'Casado'}
                  >
                    Casado
                  </MenuItem>
                  <MenuItem
                    value="Divorciado"
                    selected={formData.civilStatus === 'Divorciado'}
                  >
                    Divorciado
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="form-input-wrapper">
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">¿Tenés hijos?</FormLabel>
                <RadioGroup
                  row
                  aria-label="children"
                  name="children"
                  onChange={(e) => handleRadioChange(e, 'children')}
                  value={formData.children}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {(formData.children === 'true' || formData.children === true) && (
              <div className="form-input-wrapper">
                <FormControl fullWidth>
                  <InputLabel htmlFor="component-outlined">
                    Cantidad de hijos
                  </InputLabel>
                  <OutlinedInput
                    id="component-outlined"
                    type="number"
                    value={formData.childrenQuantity}
                    onChange={handleChange}
                    label="Cantidad de hijos"
                    name="childrenQuantity"
                  />
                </FormControl>
              </div>
            )}

            <div className="form-input-wrapper">
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  ¿Tenés hijos en camino?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="childrenComing"
                  name="childrenComing"
                  onChange={(e) => handleRadioChange(e, 'childrenComing')}
                  value={formData.childrenComing}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="form-input-wrapper">
              <FormControl fullWidth>
                <FormLabel component="legend">
                  ¿Qué te gusta hacer en tu tiempo libre?
                </FormLabel>
                <textarea
                  name="hobbies"
                  className="personal-data__form__side__textarea"
                  placeholder="Contanos ..."
                  value={formData.hobbies}
                  onChange={handleChange}
                  rows="4"
                ></textarea>
              </FormControl>
            </div>
          </div>
          <div className="personal-data__form__side">
            <div className="form-input-wrapper">
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">¿Tenés estudios?</FormLabel>
                <RadioGroup
                  row
                  aria-label="studies"
                  name="studies"
                  onChange={(e) => handleRadioChange(e, 'studies')}
                  value={formData.studies}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {(formData.studies === 'true' || formData.studies === true) && (
              <div className="form-input-wrapper">
                <FormControl fullWidth>
                  <div className="personal-data__form__side__label-control">
                    <FormLabel component="legend">¿Cuáles?</FormLabel>
                    <Button
                      variant="contained"
                      className="add-button"
                      onClick={addControl}
                      disabled={customControlAdd.show}
                    >
                      <AddIcon />
                    </Button>
                  </div>

                  {customControlAdd.show && (
                    <div className="personal-data__form__side__custom-control">
                      <TextField
                        fullWidth
                        value={customControlAdd.value}
                        onChange={handleCustomControlAddChange}
                        focused
                        autoFocus
                      />
                      <Button
                        onClick={customControlSave}
                        className="doneButton"
                        disabled={customControlAdd.value.length < 10}
                      >
                        <DoneIcon />
                      </Button>
                      <IconButton
                        onClick={cancelCustomControlAdd}
                        color="primary"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  )}

                  {formData.studiesList.map((study, i) => (
                    <div
                      key={`${study}-${i}`}
                      className="personal-data__form__side__custom-control"
                    >
                      <TextField fullWidth value={study} disabled />
                      <IconButton
                        onClick={() => deleteControl(i)}
                        color="error"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </div>
                  ))}

                  {!formData.studiesList.length && (
                    <h4 className="personal-data__form__no-data">
                      Agregá algún estudio
                    </h4>
                  )}
                </FormControl>
              </div>
            )}
          </div>
        </div>
        <div className="personal-data__actions">
          <Button variant="contained" onClick={handleClick} disabled={sending}>
            {sending ? 'Guardando...' : 'Guardar'}
          </Button>
        </div>
        {isPasswordModalOpen && (
          <PasswordModal setIsFormOpen={setIsPasswordModalOpen} />
        )}
      </div>
    )
  );
};

export default PersonalData;
