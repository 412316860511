import { useSelector } from 'react-redux';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CompetenceItem from './CompetenceItem';
import AddButton from '../../components/AddButton';
import CompetenceTemplateForm from './CompetenceTemplateForm/CompetenceTemplateForm';
import { rolesEnum } from '../../helpers/enums';
import ServerError from '../../components/ServerError';
import './ManageCompetencesPage.scss';

const ManageCompetencesPage = () => {
  const { competences, competencesTemplates } = useSelector(
    (store) => store.generalData
  );
  const { statusError } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);

  const [tab, setTab] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleChange = (_, newTab) => setTab(newTab);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <div style={{ marginTop: '10px' }}>{children}</div>}
      </div>
    );
  };

  if (statusError) return <ServerError />;

  return competences ? (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Listado de Competencias" />
            <Tab label="Templates" />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <div className="manage-compentences">
            <h3>Listado de competencias</h3>
            <div className="manage-compentences__list">
              {competences.map((competence) => (
                <CompetenceItem key={competence._id} competence={competence} />
              ))}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className="manage-competences__templates">
            <h3>
              Templates de Competencias
              <AddButton
                tooltip="Agregar un template"
                onClick={() => setIsFormOpen(true)}
              />
            </h3>
            <div className="manage-competences__templates-container">
              {competencesTemplates?.map(
                (template) =>
                  template.createUserId === userInfo._id && (
                    <div className="competence-template" key={template.name}>
                      <div className="competence-template__name">
                        {template.name}
                      </div>
                      <div className="competence-template__for-role">
                        {rolesEnum[template.forRole]}
                      </div>
                      <ul>
                        {template.competences.map((competence) => (
                          <li
                            key={competence._id}
                            className="competence-template__competence"
                          >
                            {competence.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
              {competencesTemplates.length === 0 && (
                <p>No existen templates creados por el momento</p>
              )}
            </div>
          </div>
        </TabPanel>
      </Box>
      {isFormOpen && <CompetenceTemplateForm setIsFormOpen={setIsFormOpen} />}
    </>
  ) : null;
};

export default ManageCompetencesPage;
