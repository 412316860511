import styled from '@emotion/styled';
import { Avatar, Chip, Tooltip, tooltipClasses } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './PostulatedUsersTooltip.scss';

const PostulatedUsersTooltip = ({ postulatedUsers, children }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  if (!userInfo.isSuperAdmin) {
    return <>{children}</>;
  }

  return (
    <CustomTooltip
      title={
        <PostulatedUsersTooltipComponent postulatedUsers={postulatedUsers} />
      }
      placement="bottom"
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

const PostulatedUsersTooltipComponent = ({ postulatedUsers }) => {
  const history = useHistory();

  return postulatedUsers.length ? (
    <>
      <p className="postulated-users__title">Postulados</p>
      <ul className="postulated-users__list">
        {postulatedUsers.map((user) => (
          <li key={user.fullname}>
            <Chip
              avatar={<Avatar src={user.avatar} />}
              label={user.fullname}
              onClick={() => history.push(`/usuario/${user._id}`)}
            />
          </li>
        ))}
      </ul>
    </>
  ) : (
    <p className="postulated-users__title--empty">Ningún postulante.</p>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid #cdcdcd',
    borderRadius: '4px',
    padding: 10,
  },
}));

export default PostulatedUsersTooltip;
