export const sourceOptions = [
  {
    _id: 'created-by-me',
    label: 'Creados por mi o por un compañero',
  },
  {
    _id: 'created-to-me',
    label: 'Creados para mi',
  },
];

export const devolutionOptions = [
  {
    _id: 'with-devolution',
    label: 'Con Devolución',
  },
  {
    _id: 'without-devolution',
    label: 'Sin Devolución',
  },
];
