import Attempts3 from '../../assets/intentos3.png';
import Attempts2 from '../../assets/intentos2.png';
import Attempts1 from '../../assets/intentos1.png';

const AttemptsImages = ({ remainingAttempts }) => {
  return (
    <div className="images-wrapper">
      <img
        src={Attempts3}
        alt=""
        className={remainingAttempts !== 3 ? 'hide' : 'show'}
      />
      <img
        src={Attempts2}
        alt=""
        className={remainingAttempts !== 2 ? 'hide' : 'show'}
      />
      <img
        src={Attempts1}
        alt=""
        className={remainingAttempts !== 1 ? 'hide' : 'show'}
      />
    </div>
  );
};

export default AttemptsImages;
