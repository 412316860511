const buildNewInitialState = (prevAnswers, postEvaluationUsers) => {
  postEvaluationUsers.forEach((data) => {
    Object.keys(prevAnswers).forEach((competenceId) => {
      prevAnswers[competenceId][data.user._id] = {
        evaluationType: data.evaluationType,
        isAddedUser: data.isAddedUser,
        reason: data.reason,
        behaviour: {
          value: null,
          points: 0,
        },
        score: {
          value: null,
          points: 0,
        },
        comments: '',
      };
    });
  });

  return prevAnswers;
};

export default buildNewInitialState;
