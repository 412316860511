import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useSelector } from 'react-redux';

import './AreaAssign.scss';
import { useEffect, useState } from 'react';

const AreaAssign = ({ formData, setFormData }) => {
  const { areas } = formData;
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { areas: areasDB } = useSelector((store) => store.generalData);
  const { success } = useSelector((store) => store.app);

  const myArea = areasDB.find((area) => area._id === userInfo?.area?._id);

  const [availableAreas, setAvailableAreas] = useState([]);

  const itemStyles = (currentArea) => {
    const isAreaAdded = areas.some((area) => area._id === currentArea._id);
    return isAreaAdded ? { color: 'silver' } : { color: 'inherit' };
  };

  const handleAreaChange = ({ target: { value } }) => {
    setFormData({
      ...formData,
      areas: typeof value === 'string' ? value.split(',') : value,
    });
  };

  useEffect(() => {
    const newAvailableAreas = [];
    const areasWithoutBoss = myArea?.dependentAreas.filter(
      (dependentArea) =>
        dependentArea.withoutBoss && !dependentArea.evaluationCreated
    );
    newAvailableAreas.push(...areasWithoutBoss);

    if (!myArea.withoutEmployees && !myArea.evaluationCreated) {
      newAvailableAreas.push(myArea);
    }
    setAvailableAreas(newAvailableAreas);
  }, [myArea]);

  useEffect(() => {
    if (success) setFormData((formData) => ({ ...formData, areas: [] }));
  }, [success, setFormData]);

  return (
    <div className="areas-assign">
      <h4 className="areas-assign__header">Seleccionar Área/s:</h4>
      <div className="form-input-wrapper multi-select">
        <FormControl fullWidth>
          <InputLabel id="profiles">Área/s</InputLabel>
          <Select
            labelId="profiles"
            id="profiles"
            multiple
            value={areas}
            onChange={handleAreaChange}
            disabled={success}
            input={<OutlinedInput id="profiles" label="Perfiles" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      availableAreas.find((area) => area._id === value)?.name
                    }
                  />
                ))}
              </Box>
            )}
          >
            {availableAreas.map((area) => (
              <MenuItem
                key={area._id}
                value={area._id}
                style={itemStyles(area)}
              >
                {area.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default AreaAssign;
