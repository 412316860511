import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddButton from '../../../components/AddButton';
import { permissions } from '../../../helpers/permissions';
import { verifyPermission } from '../../../helpers/verifyPermission';
import Congratulations from '../Congratulations';
import Novelties from '../Novelties';
import Reminders from '../Reminders';
import './Communications.scss';

const Communications = ({ userInfo }) => {
  const { novelties, reminders, congratulations } = useSelector(
    (store) => store.dashboard
  );

  const [currentTab, setCurrentTab] = useState('novelties');
  const [isNoveltiesFormOpen, setIsNoveltiesFormOpen] = useState(false);
  const [isRemindersFormOpen, setIsRemindersFormOpen] = useState(false);
  const [isCongratulationsFormOpen, setIsCongratulationsFormOpen] =
    useState(false);

  const tabsList = [
    { value: 'novelties', label: 'Novedades' },
    { value: 'reminders', label: 'Recordatorios' },
    { value: 'congratulations', label: 'Felicitaciones' },
  ];

  const options = [
    {
      label: 'Novedad',
      action: () => {
        setCurrentTab('novelties');
        setIsNoveltiesFormOpen(true);
      },
    },
    {
      label: 'Recordatorio',
      action: () => {
        setCurrentTab('reminders');
        setIsRemindersFormOpen(true);
      },
    },
    {
      label: 'Felicitación',
      action: () => {
        setCurrentTab('congratulations');
        setIsCongratulationsFormOpen(true);
      },
    },
  ];

  const renderComponent = {
    novelties: (
      <Novelties
        novelties={novelties}
        isFormOpen={isNoveltiesFormOpen}
        setIsFormOpen={setIsNoveltiesFormOpen}
      />
    ),
    reminders: (
      <Reminders
        reminders={reminders}
        isFormOpen={isRemindersFormOpen}
        setIsFormOpen={setIsRemindersFormOpen}
      />
    ),
    congratulations: (
      <Congratulations
        congratulations={congratulations}
        isFormOpen={isCongratulationsFormOpen}
        setIsFormOpen={setIsCongratulationsFormOpen}
      />
    ),
  };

  return (
    <div className="communications">
      <h3>
        Comunicaciones
        {verifyPermission(userInfo, permissions.CREATE_COMMUNICATION) && (
          <AddButton
            tooltip="Crear comunicación"
            withOptions
            options={options}
          />
        )}
      </h3>
      <div className="communications__tabs">
        <ul>
          {tabsList.map((tab) => (
            <li
              key={tab.value}
              onClick={() => setCurrentTab(tab.value)}
              className={`communications__tabs--tab ${
                currentTab === tab.value && 'current'
              }`}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      {renderComponent[currentTab]}
    </div>
  );
};

export default Communications;
