import CustomModal from '../CustomModal/CustomModal';
import { Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import './ConfirmModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAction } from '../../actions/modelActions';
import { goalsModel } from '../../constants/modelConstants';
import { useEffect } from 'react';

const ConfirmModal = ({ setIsFormOpen, currentGoal }) => {
  const dispatch = useDispatch();
  const { success } = useSelector((store) => store.app);

  const handleConfirm = () => {
    dispatch(
      deleteAction(goalsModel.NAME, `${goalsModel.URL}/${currentGoal._id}`)
    );
  };

  useEffect(() => {
    if (success) {
      setIsFormOpen(false);
    }
  }, [success, setIsFormOpen]);

  return (
    <CustomModal title="Eliminación de Objetivo" setIsFormOpen={setIsFormOpen}>
      <div className="confirm-modal__warning-icon-container">
        <ErrorIcon
          fontSize="large"
          htmlColor="#dda312"
          className="warning-icon"
        />
      </div>
      <div className="confirm-modal__title">
        {currentGoal.group
          ? '¿Estás seguro de eliminar el objetivo grupal?'
          : `¿Estás seguro de eliminar el objetivo de ${currentGoal.toUserId.name} ${currentGoal.toUserId.lastname}?`}
      </div>
      <div className="warning-message">"{currentGoal.title}"</div>

      <div className="confirm-modal__actions">
        <Button onClick={handleConfirm} variant="contained">
          Confirmar
        </Button>
        <Button onClick={() => setIsFormOpen(false)} variant="outlined">
          Cancelar
        </Button>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
