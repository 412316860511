import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { userSignInReducer } from '../reducers/userReducer';
import { notificationsReducer } from '../reducers/notificationsReducer';
import { modelReducer } from '../reducers/modelReducer';
import { appReducer } from '../reducers/appReducer';
import {
  benefitModel,
  dashboardModel,
  demandModel,
  evaluationModel,
  generalDataModel,
  goalsModel,
  recognitionModel,
  searchModel,
  allUsersModel,
  userDetailModel,
  evaluationProcess,
  chartsModel,
  areaRelationshipsModel,
  competencesScoreDetailModel,
  currentEvaluationsModel,
  publicationsModel,
  statusModel,
} from '../constants/modelConstants';

const initialState = {};

const reducer = combineReducers({
  areaRelationships: modelReducer(areaRelationshipsModel),
  app: appReducer,
  benefits: modelReducer(benefitModel),
  chartsData: modelReducer(chartsModel),
  competencesScoreDetail: modelReducer(competencesScoreDetailModel),
  currentEvaluations: modelReducer(currentEvaluationsModel),
  dashboard: modelReducer(dashboardModel),
  demands: modelReducer(demandModel),
  evaluationProcess: modelReducer(evaluationProcess),
  evaluations: modelReducer(evaluationModel),
  generalData: modelReducer(generalDataModel),
  goals: modelReducer(goalsModel),
  notifications: notificationsReducer,
  publications: modelReducer(publicationsModel),
  recognitions: modelReducer(recognitionModel),
  searches: modelReducer(searchModel),
  userDetail: modelReducer(userDetailModel),
  userSignIn: userSignInReducer,
  users: modelReducer(allUsersModel),
  status: modelReducer(statusModel),
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
