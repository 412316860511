import moment from 'moment';
import { useEffect, useState } from 'react';
import { getDaysToView } from '../Calendar/helpers';
import Day from '../Day';
import data from '../events.json';

const WeekMode = ({
  referenceDay,
  setReferenceDay,
  selectedDay,
  setSelectedDay,
}) => {
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const handlePrev = () => {
    const daysOfCurrentMonth = daysOfWeek.filter((day) => day.currentMonth);
    const newReferenceDay = moment(daysOfCurrentMonth[0].isoString).subtract(
      1,
      'days'
    );
    setReferenceDay(newReferenceDay);
  };

  const handleNext = () => {
    const daysOfCurrentMonth = daysOfWeek.filter((day) => day.currentMonth);
    const newReferenceDay = moment(
      daysOfCurrentMonth[daysOfCurrentMonth.length - 1].isoString
    ).add(1, 'days');
    setReferenceDay(newReferenceDay);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setDaysOfWeek(
      daysOfWeek.map((dayOfWeek) =>
        dayOfWeek.isoString === day.isoString
          ? { ...dayOfWeek, selected: true }
          : { ...dayOfWeek, selected: false }
      )
    );
  };

  useEffect(() => {
    setDaysOfWeek(
      getDaysToView(
        'week',
        referenceDay.toISOString(),
        selectedDay,
        data.events
      )
    );
  }, [referenceDay, selectedDay]);

  return (
    <>
      <div className="calendar__buttons">
        <span onClick={handlePrev} variant="contained" size="small">
          {'<'}
        </span>
        <h4 className="calendar__month">
          {`${referenceDay
            .format('MMMM')
            .toUpperCase()} de ${referenceDay.format('Y')}`}
        </h4>
        <span onClick={handleNext} variant="contained" size="small">
          {'>'}
        </span>
      </div>
      <div className="calendar__days-labels calendar__days-labels--week-mode">
        <div>Lun</div>
        <div>Mar</div>
        <div>Miér</div>
        <div>Jue</div>
        <div>Vie</div>
        <div>Sáb</div>
        <div>Dom</div>
      </div>
      <div className="calendar__days-container">
        {daysOfWeek.map((day, i) => (
          <Day
            key={`${day.number}-${i}`}
            day={day}
            index={i}
            handleDayClick={handleDayClick}
            showLargeEvents
            showEvents
            weekMode
          />
        ))}
      </div>
    </>
  );
};

export default WeekMode;
