import { useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import './CompetencesTooltip.scss';

const CompetencesTooltip = ({ competencesScores, children }) => {
  return (
    <CustomTooltip
      title={
        <CompetencesTooltipComponent competencesScores={competencesScores} />
      }
      arrow
      placement="right"
    >
      {children}
    </CustomTooltip>
  );
};

const CompetencesTooltipComponent = ({ competencesScores }) => {
  const { competences } = useSelector((store) => store.generalData);

  const getCompetenceName = (competenceId) => {
    const currentCompetence = competences.find(
      (competence) => competence._id === competenceId
    );
    return currentCompetence?.name || 'Competencia desconocida.';
  };

  return competencesScores ? (
    <div className="competences-tooltip">
      {competencesScores.map((score) => (
        <p key={score.competenceId}>
          {getCompetenceName(score.competenceId)}:
          <span className="competences-tooltip__points">
            {Math.round(score.competencePoints)} %.
          </span>
        </p>
      ))}
    </div>
  ) : null;
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid gray',
    borderRadius: '4px',
    padding: 0,
  },
}));

export default CompetencesTooltip;
