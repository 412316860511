import { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useSelector } from 'react-redux';
import CustomModal from '../../../components/CustomModal/CustomModal';
import SearchGoalsModal from '../SearchGoalsModal';
import './DashboardStatus.scss';

const DashboardStatus = () => {
  const history = useHistory();
  const { status } = useSelector((store) => store.dashboard);
  const [isSearchGoalsModalOpen, setIsSearchGoalsModalOpen] = useState(false);

  const getColor = (value) => {
    if (value >= 80) return 'high';
    if (value >= 60) return 'medium';
    if (value >= 40) return 'low';
    return 'sublow';
  };

  const handleRedirectClick = (redirectUrl) => {
    if (redirectUrl) {
      return history.push(redirectUrl);
    }
  };

  const handleSearchClick = () => setIsSearchGoalsModalOpen(true);

  return (
    <>
      <div className="dashboard-status">
        <h3>Status</h3>
        <div className="dashboard-status__wrapper">
          {status?.map(
            ({ title, data, percentage, redirectUrl, searchIcon }) => (
              <div className="stat" key={title}>
                <span className="stat__title">{title}</span>
                <span className="stat__data">{data}</span>
                {percentage >= 0 ? (
                  <span className="stat__info">
                    <CircularProgress
                      variant="determinate"
                      value={Number(percentage || 0)}
                      color={getColor(percentage)}
                      size={20}
                    />
                    {percentage} %
                  </span>
                ) : (
                  <span></span>
                )}
                {redirectUrl && (
                  <IconButton
                    className="stat__redirect-btn"
                    onClick={() => handleRedirectClick(redirectUrl)}
                    size="small"
                  >
                    <ReplyIcon />
                  </IconButton>
                )}
                {searchIcon && (
                  <IconButton
                    className="stat__search-btn"
                    onClick={() => handleSearchClick()}
                    size="small"
                  >
                    <SearchOutlinedIcon />
                  </IconButton>
                )}
              </div>
            )
          )}
        </div>
      </div>
      {isSearchGoalsModalOpen && (
        <CustomModal
          title="Búsqueda de objetivos"
          setIsFormOpen={setIsSearchGoalsModalOpen}
          fullWidth={true}
        >
          <SearchGoalsModal />
        </CustomModal>
      )}
    </>
  );
};

export default DashboardStatus;
