export const buildChildren = (area, areas, property) => {
  if (area.dependentAreas.length > 0) {
    const children = areas.filter(
      (mapedArea) => area._id === mapedArea.parentArea?._id
    );
    area[property] = children.map((child) =>
      buildChildren(child, areas, property)
    );
    return area;
  } else {
    return area;
  }
};

const buildAreaTree = (areas) => {
  const managements = areas.filter((area) => area.management);
  return managements.map((management) =>
    buildChildren(management, areas, 'children')
  );
};

export const getIsAreaInPath = (areas, areaId, userAreaId, connectorY) => {
  if (areaId === userAreaId && !connectorY) return true;

  const currentArea = areas?.find((area) => area._id === areaId);

  // Verificar si el área actual coincide con el área del usuario o si alguna de sus áreas dependientes coincide
  if (
    currentArea._id === userAreaId ||
    currentArea.dependentAreas.some((dependentArea) =>
      getIsAreaInPath(areas, dependentArea._id, userAreaId, connectorY)
    )
  ) {
    return true;
  }
  return false;
};

export default buildAreaTree;
