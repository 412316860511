import Button from '@mui/material/Button';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import { Tooltip } from '@mui/material';

import './Filter.scss';

const Filter = ({ setFilter, filter }) => {
  return (
    <div className="demands_filter">
      <Tooltip title="Ver Todas" placement="top" arrow>
        <Button
          size="small"
          onClick={() => setFilter('all')}
          variant={filter === 'all' ? 'contained' : 'outlined'}
        >
          <ListAltOutlinedIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Ver las asignadas a mi" placement="top" arrow>
        <Button
          size="small"
          onClick={() => setFilter('received')}
          variant={filter === 'received' ? 'contained' : 'outlined'}
        >
          <AccountBoxOutlinedIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Ver las emitidas por mi" placement="top" arrow>
        <Button
          size="small"
          onClick={() => setFilter('made')}
          variant={filter === 'made' ? 'contained' : 'outlined'}
        >
          <PeopleAltOutlinedIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Ver las auto asignadas" placement="top" arrow>
        <Button
          size="small"
          onClick={() => setFilter('selfAssignment')}
          variant={filter === 'selfAssignment' ? 'contained' : 'outlined'}
        >
          <FlipCameraAndroidOutlinedIcon />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Filter;
