import { Autocomplete, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomModal from '../../../components/CustomModal';

import './AddUserModal.scss';
import { getUserOptions } from './helpers';

const AddUserModal = ({
  setIsFormOpen,
  title,
  forRole,
  type,
  affectedUsers,
  setAffectedUsers,
  setHasChanges,
}) => {
  const { users } = useSelector((store) => store.users);
  const { selectedArea } = useSelector((store) => store.areaRelationships);
  const { areas } = useSelector((store) => store.generalData);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);

  const handleChange = (_, values) => {
    let userAdded = false;
    values.forEach((value) => {
      if (
        affectedUsers.find((affectedUser) => affectedUser.user === value._id)
      ) {
        userAdded = true;
        return;
      }
    });
    if (userAdded) {
      setWarningMessage('El usuario ya está seleccionado.');
      return;
    }
    setWarningMessage(null);
    setSelectedUsers(values);
  };

  const handleAddClick = () => {
    const newRelationships = selectedUsers.map((selectedUser) => ({
      forRole,
      evaluationType: type,
      user: selectedUser._id,
      isAddedUser: true,
      reason: '',
      selected: true,
      userArea: selectedUser.area._id,
    }));
    setAffectedUsers((prev) => [...prev, ...newRelationships]);
    setIsFormOpen(false);
    setHasChanges(true);
  };

  useEffect(() => {
    if (users) {
      setUserOptions(
        getUserOptions(forRole, selectedArea, affectedUsers, users, type, areas)
      );
    }
  }, [
    affectedUsers,
    areas,
    forRole,
    selectedArea,
    selectedArea._id,
    type,
    users,
  ]);

  return (
    <CustomModal
      title={`Agregar usuarios a la evaluación de ${title}`}
      setIsFormOpen={setIsFormOpen}
    >
      <div className="add-user-modal">
        <div className="add-user-modal__content">
          {userOptions && (
            <Autocomplete
              multiple
              id="tags-outlined"
              options={userOptions}
              value={selectedUsers}
              getOptionLabel={(option) =>
                `${option.fullname} (${option.area.name})`
              }
              filterSelectedOptions
              onChange={handleChange}
              noOptionsText={'No se encontraron resultados'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipear nombre y/o apellido de Usuario o Area para buscar..."
                  placeholder="Tipear nombre y/o apellido de Usuario o Area para buscar..."
                />
              )}
            />
          )}
        </div>
        <div className="add-user-modal__warning-message">
          {warningMessage && <p>{warningMessage}</p>}
        </div>
        <div className="add-user-modal__actions">
          <Button
            variant="contained"
            onClick={handleAddClick}
            disabled={!!warningMessage || selectedUsers.length === 0}
          >
            Agregar
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddUserModal;
