import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import BusinessIcon from '@mui/icons-material/Business';
import { deleteAction, postAction } from '../../../actions/modelActions';
import { searchModel } from '../../../constants/modelConstants';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import PostulatedUsersTooltip from '../../../components/PostulatedUsersTooltip/PostulatedUsersTooltip';

import './Search.scss';

const Search = ({ search }) => {
  const [currentSearchId, setCurrentSearchId] = useState(null);
  const { area, job, tasks, requirements, _id, postulatedUsers } = search;
  const { sending } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const dispatch = useDispatch();

  const handleClick = (searchId) => {
    setCurrentSearchId(searchId);
    dispatch(
      postAction(searchModel.NAME, searchModel.POSTULATE_URL, {
        searchId,
      })
    );
  };

  const handleCloseSearch = (searchId) => {
    dispatch(deleteAction(searchModel.NAME, `${searchModel.URL}/${searchId}`));
  };

  const isPostulated = search?.postulatedUsers?.some(
    (user) => user._id === userInfo._id
  );
  const isCurrentSending = currentSearchId === _id && sending;

  return (
    <>
      {sending && <LoadingModal />}
      <div className="search">
        <div className="search-container">
          <h4 className="search-container__job">{job}</h4>
          <div>
            <Tooltip title="Área" placement="top" arrow>
              <div className="search-container__area">
                <BusinessIcon />
                {area.name}
              </div>
            </Tooltip>
          </div>
          <p>
            <b> Tareas: </b> <span> {tasks} </span>
          </p>
          <p>
            <b> Requisitos: </b> <span> {requirements} </span>
          </p>
          <div className="search-container__footer">
            {!userInfo.isSuperAdmin && (
              <Button
                onClick={() => handleClick(_id)}
                disabled={isPostulated || isCurrentSending}
                variant="contained"
                className="postulate"
              >
                {isPostulated
                  ? 'Postulado'
                  : isCurrentSending
                  ? 'Guardando...'
                  : 'Postular'}
              </Button>
            )}
            {userInfo.isAdmin && (
              <Button
                onClick={() => handleCloseSearch(_id)}
                variant="contained"
                color="primary"
              >
                Finalizar
              </Button>
            )}
          </div>
        </div>
        <PostulatedUsersTooltip postulatedUsers={postulatedUsers}>
          <div className="search__posulated-users-quantity">
            {postulatedUsers.length}
          </div>
        </PostulatedUsersTooltip>
      </div>
    </>
  );
};

export default Search;
