import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, IconButton, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { putAction } from '../../../actions/modelActions';
import { goalsModel } from '../../../constants/modelConstants';
import CustomModal from '../../../components/CustomModal/CustomModal';

import './TodoForm.scss';

const TodoForm = ({ goal, setShowTodos, todos }) => {
  const { sending, success } = useSelector((store) => store.app);
  const { userInfo } = useSelector((store) => store.userSignIn);

  const dispatch = useDispatch();

  const [goalTodos, setGoalTodos] = useState(todos);
  const todoRef = useRef();

  const belongsToMe =
    goal.toUserId?._id === userInfo._id ||
    goal.toUsersIds.some((user) => user._id === userInfo._id);

  const doneTodosQty = goalTodos.filter((todo) => todo.done).length;
  const progress = Math.round((doneTodosQty * 100) / goalTodos.length) || 0;

  const handleCheckboxClick = (i) => {
    const updatedGoalTodos = goalTodos.map((goalTodo, index) =>
      index === i ? { ...goalTodo, done: !goalTodo.done } : goalTodo
    );
    setGoalTodos(updatedGoalTodos);
  };

  const handleDeleteClick = (i) => {
    const updatedGoalTodos = goalTodos.filter((_, index) => index !== i);
    setGoalTodos(updatedGoalTodos);
  };

  const handleSaveClick = () => {
    dispatch(
      putAction(goalsModel.NAME, `${goalsModel.URL}/todos/${goal._id}`, {
        todos: goalTodos,
      })
    );
  };

  const addTodo = (e) => {
    e.preventDefault();
    if (todoRef.current.value.length === 0) return;
    setGoalTodos([...goalTodos, { text: todoRef.current.value, done: false }]);
    todoRef.current.value = '';
  };

  useEffect(() => {
    if (success) {
      setShowTodos(false);
    }
  }, [success, setShowTodos]);

  return (
    <CustomModal title="Listado de tareas" setIsFormOpen={setShowTodos}>
      <div className="goal-todo-form">
        {!goal.processed && belongsToMe && goal.state === 'wip' && (
          <form onSubmit={addTodo} className="goal-todo-form__form">
            <input
              type="text"
              placeholder="Agregar Tarea"
              ref={todoRef}
              className="goal-todo-form__input"
              disabled={!belongsToMe || goal.note !== 0}
            />
            <span className="goal-todo-form__text">
              * Presioná ENTER para confirmar la tarea
            </span>
          </form>
        )}
        {goalTodos.length ? (
          <ul className="goal-todo-form__todo-list">
            {goalTodos.map((todo, i) => (
              <li
                key={todo.text}
                className={`goal-todo goal-todo${todo.done ? '-done' : ''}`}
              >
                <div
                  style={{
                    width: '90%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={todo.done}
                    onChange={() => handleCheckboxClick(i)}
                    disabled={!belongsToMe || goal.processed || goal.note !== 0}
                    className={todo.done ? 'checked' : ''}
                  />
                  <span>{todo.text}</span>
                </div>
                {!goal.processed && belongsToMe && (
                  <IconButton
                    onClick={() => handleDeleteClick(i)}
                    disabled={!belongsToMe || goal.note !== 0}
                    className="delete-todo-icon"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className="goal-todo-form__empty-list">
            No existen tareas registradas para este objetivo
          </p>
        )}
        <div className="goal-todo-form__progress">
          <Box sx={{ width: '50%', textAlign: 'center' }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              className="progress-line"
              color="high"
            />
            <span>{progress} % (Tareas Completadas)</span>
          </Box>

          {!goal.processed && goal.note === 0 && belongsToMe && (
            <Button
              onClick={handleSaveClick}
              className="goal-todo-form__save-button"
              variant="contained"
              disabled={sending || !belongsToMe}
            >
              {sending ? 'Guardando..' : 'Guardar'}
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default TodoForm;
