import { useSelector } from 'react-redux';
import UserSection from '../UserSection';
import LogoHorizontal from '../../assets/logo-horizontal.png';
import UsersSearch from '../../pages/DashboardPage/UsersSearch';
import { useHistory } from 'react-router-dom';

import './Header.scss';

const Header = ({ stickNavbar }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { applicationName } = useSelector((store) => store.generalData);
  const stickNavbarClass = stickNavbar ? 'header--hide' : '';

  const history = useHistory();

  return (
    <div className={`header ${stickNavbarClass}`}>
      <div className="header__logo">
        <img
          src={LogoHorizontal}
          alt="logo"
          onClick={() => history.push('/')}
        />
        <div className="header__app-name">{applicationName}</div>
      </div>
      <div className="header__users-search">
        <UsersSearch />
      </div>
      {userInfo && <UserSection />}
    </div>
  );
};

export default Header;
