import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import './UserEvaluation.scss';

const UserEvaluation = ({ userEvaluation }) => {
  const history = useHistory();

  const { _id, label, done, competences, areaName, reOpen } = userEvaluation;

  return (
    <div className={`user-evaluation ${done ? 'user-evaluation--done' : ''}`}>
      {areaName && <div className="user-evaluation__area-name">{areaName}</div>}
      <div className="user-evaluation__label">{label}</div>
      <div className="user-evaluation__competences-title">
        Competencias a evaluar
      </div>
      <div className="user-evaluation__competences">
        {competences.map((competence) => (
          <p className="user-evaluation__competence" key={competence._id}>
            {competence.name}
          </p>
        ))}
      </div>
      <Button
        sx={{
          width: 'fit-content',
          margin: '4px auto 0',
          padding: '6px 15px',
        }}
        color="primary"
        variant={done ? 'text' : 'contained'}
        onClick={() => history.push(`/evaluaciones/${_id}`)}
      >
        {done ? 'Ver respuesta' : 'Completar'}
      </Button>
      {reOpen && <div className="user-evaluation__reopen-flag">Reabierta</div>}
    </div>
  );
};

export default UserEvaluation;
