import moment from 'moment';

export const rolesEnum = {
  employee: 'Empleado',
  boss: 'Jefe',
};

export const categoriesEnum = {
  Gold: 'Categoría Oro',
  Silver: 'Categoría Plata',
  Bronze: 'Categoría Bronce',
};

export const booleansEnum = (value) => (value ? 'Si' : 'No');

export const getAge = (date) => {
  return `${moment().diff(date, 'years')} años`;
};

export const formatDate = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : '';
};

export const formatToLongDate = (date) => {
  return date ? moment(date).format('D [de] MMMM [a las] HH:mm') : '';
};

export const getDateHour = (date) => {
  return date ? moment(date).format('HH:mm') : '';
};

export const formatDNI = (dni) => {
  return dni; /* .toLocaleString('ES-es'); */
};
