import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import './CompetencesTemplates.scss';

const CompetencesTemplates = ({ handleTemplateAssign, role }) => {
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { competencesTemplates } = useSelector((store) => store.generalData);

  const filteredTemplatesByRole = competencesTemplates
    .filter((ct) => ct.createUserId === userInfo._id) // valida que haya sido creado por mi
    .filter((template) => template.forRole === role); // muestra solo los que corresponden al role por el que se esta creando la evaluacion

  const competencesByRole =
    role === 'boss' ? 'bossCompetences' : 'employeeCompetences';

  return (
    <>
      <h4 className="competences-templates__title">
        Si querés podés hacer uso de tus templates de competencias
        pre-generados.
      </h4>
      <div className="competences-templates">
        {filteredTemplatesByRole.length > 0 ? (
          filteredTemplatesByRole?.map((template) => (
            <div className="competence-template" key={template._id}>
              <div className="competence-template__name">{template.name}</div>
              {template.competences.map((competence) => (
                <div
                  key={competence._id}
                  className="competence-template__competence"
                >
                  {competence.name}
                </div>
              ))}
              <Button
                variant="contained"
                className="competence-template__assign-button"
                onClick={() =>
                  handleTemplateAssign(template, competencesByRole, role)
                }
              >
                Asignar
              </Button>
            </div>
          ))
        ) : (
          <div className="competences-templates">
            <p>No hay templates pre-generados.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CompetencesTemplates;
