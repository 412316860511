import React from 'react';
import { CircularProgress } from '@mui/material';
import './UserGoalStatus.scss';

const UserGoalStatus = ({ userGoalStatus }) => {
  const getColor = (value) => {
    if (value >= 80) return 'high';
    if (value >= 60) return 'medium';
    if (value >= 40) return 'low';
    return 'sublow';
  };

  return (
    <div className="user-goal-status">
      <h3>Estado de objetivos actuales</h3>
      <div className="stats-container">
        {userGoalStatus?.map(({ title, data, percentage }) => (
          <div className="stat" key={title}>
            <h2 className="stat__title">{title}</h2>
            <span className="stat__data">{data}</span>
            {percentage >= 0 ? (
              <span className="stat__info">
                <CircularProgress
                  variant="determinate"
                  value={Number(percentage || 0)}
                  color={getColor(percentage)}
                  size={30}
                />
                {percentage}%
              </span>
            ) : (
              <span></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserGoalStatus;
