import { IconButton, TextField } from '@mui/material';
import { formatDate, getDateHour } from '../../../helpers/enums';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const DemandComments = ({
  comments,
  newComment,
  handleChange,
  handleKeyDown,
  handleSendNewComment,
  state,
}) => {
  const bottomRef = useRef(null);
  const { userInfo } = useSelector((store) => store.userSignIn);

  const unreadCommentClass = (comment) =>
    comment.userId !== userInfo._id && !comment.read
      ? 'demand-modal__comment--unread'
      : '';

  useEffect(() => {
    const scrollDownToBottom = () => {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    };
    scrollDownToBottom();
  }, [comments]);

  return (
    <div className="demand-modal__comments">
      <h3>Comentarios</h3>
      <div className="demand-modal__comments-container">
        {comments.length > 0 ? (
          comments.map((comment, i) => (
            <div
              className={`demand-modal__comment ${unreadCommentClass(comment)}`}
              key={`${i}-${comment.userId}`}
            >
              <div className="demand-modal__comment-content">
                <p className="demand-modal__comment-data">
                  <span>{comment.fullname} -</span>
                  <span className="date">{formatDate(comment.date)}</span>
                  <span className="hour">{getDateHour(comment.date)} hs.</span>
                </p>
                <p className="demand-modal__comment-text">{comment.content}</p>
              </div>
            </div>
          ))
        ) : (
          <span>Esta solicitud no posee comentarios.</span>
        )}
        <div ref={bottomRef} />
      </div>
      {state !== 'done' && (
        <div className="demand-modal__new-comment">
          <TextField
            type="text"
            label="Escribe un comentario"
            name="newComment"
            value={newComment}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
          <IconButton
            color="primary"
            component="label"
            disabled={!newComment.length}
            onClick={handleSendNewComment}
          >
            <SendIcon color="inherit" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default DemandComments;
