import { useSelector } from 'react-redux';
import NotificationsMenu from '../NotificationsMenu';
// import UserCoins from '../UserCoins';
import UserMenu from '../UserMenu';

import './UserSection.scss';

const UserSection = () => {
  const { userInfo } = useSelector((store) => store.userSignIn);

  return (
    <div className="user-section">
      {/* {!userInfo?.isNotEvaluable && <UserCoins />} */}
      {!userInfo?.isNotEvaluable && <NotificationsMenu />}
      <UserMenu />
    </div>
  );
};

export default UserSection;
