import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import OutlinedFlagOutlinedIcon from '@mui/icons-material/OutlinedFlagOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import {
  benefitModel,
  dashboardModel,
  demandModel,
  evaluationModel,
  goalsModel,
  recognitionModel,
  searchModel,
} from '../../constants/modelConstants';
import { verifyPermission } from '../../helpers/verifyPermission';
import { permissions } from '../../helpers/permissions';

const menu = [
  {
    name: 'Dashboard',
    url: '/',
    icon: <DashboardOutlinedIcon color="inherit" />,
    model: dashboardModel,
  },
  {
    name: 'Evaluaciones',
    url: '/evaluaciones',
    icon: <InventoryOutlinedIcon color="inherit" />,
    model: evaluationModel,
    userInfoValidation: (userInfo) => !userInfo?.isNotEvaluable,
  },
  {
    name: 'Objetivos',
    url: '/objetivos',
    icon: <OutlinedFlagOutlinedIcon color="inherit" />,
    model: goalsModel,
    children: [
      {
        name: 'Actuales',
        url: '/objetivos/actuales',
      },
      {
        name: 'Anteriores',
        url: '/objetivos/anteriores',
      },
    ],
  },
  {
    name: 'Solicitudes',
    url: '/solicitudes',
    icon: <DriveFileRenameOutlineOutlinedIcon color="inherit" />,
    model: demandModel,
    userInfoValidation: (userInfo) => !userInfo?.isSuperAdmin,
    configValidation: (config) => config,
  },
  {
    name: 'Reconocimientos',
    url: '/reconocimientos',
    icon: <StarPurple500Icon color="inherit" />,
    model: recognitionModel,
  },
  {
    name: 'Beneficios',
    url: '/beneficios',
    icon: <EmojiEventsOutlinedIcon color="inherit" />,
    configValidation: (config) => config,
    children: [
      {
        name: 'Ver Beneficios',
        url: '/beneficios',
        model: benefitModel,
      },
      {
        name: 'Administrar Beneficios',
        url: '/beneficios/adm',
        model: null,
        userInfoValidation: (userInfo) =>
          verifyPermission(userInfo, permissions.ADMIN_BENEFITS),
      },
    ],
  },
  {
    name: 'Empresa',
    icon: <BusinessIcon color="inherit" />,
    children: [
      {
        name: 'Conocenos',
        url: '/empresa/conocenos',
        model: null,
      },
      {
        name: 'Mapa de Areas',
        url: '/empresa/mapa-de-areas',
        model: null,
      },
      /* {
        name: 'Comunicaciones',
        url: '/posts',
        model: null,
      }, */
      {
        name: 'Estado de las Areas',
        url: '/empresa/area',
        model: null,
        userInfoValidation: (userInfo) =>
          userInfo?.isSuperAdmin ||
          (userInfo?.area && userInfo?.role === 'boss'),
        configValidation: (config) => config,
      },
      {
        name: 'Estadísticas',
        url: '/empresa/estadisticas',
        model: null,
        userInfoValidation: (userInfo) =>
          userInfo?.isSuperAdmin ||
          (userInfo?.area && userInfo?.role === 'boss'),
        configValidation: (config) => config,
      },
      {
        name: 'Status',
        url: '/empresa/status',
        model: null,
        userInfoValidation: (userInfo) => userInfo?.isSuperAdmin,
      },
      {
        name: 'Búsquedas',
        url: '/empresa/busquedas',
        model: searchModel,
        // userInfoValidation: (userInfo) => !userInfo?.isSuperAdmin,
        configValidation: (config) => config,
      },
    ],
  },
];

export default menu;
