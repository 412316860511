import { Tooltip } from '@mui/material';
import excelLogo from '../../assets/excel.png';
import axios from 'axios';

import './ExcelIcon.scss';

const ExcelIcon = ({ entity, title }) => {
  const handleDownloadExcel = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/excel/generate/${entity}`,
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Tooltip title={`Descargar ${title} en una planilla`} arrow placement="top">
      <span className="excel-icon" onClick={handleDownloadExcel}>
        <img src={excelLogo} alt="Descargar planilla" />
      </span>
    </Tooltip>
  );
};

export default ExcelIcon;
