const Cards = ({ cards, handleClick }) => {
  return (
    <>
      {cards.map((card, i) => (
        <div
          className={`memo-user-card ${card.rotate && 'clicked'}`}
          onClick={() => handleClick(card)}
          key={`${card._id} + ${i}`}
        >
          <div className="memo-user-card__side memo-user-card__side--front"></div>
          <div className="memo-user-card__side memo-user-card__side--back">
            <img src={card.avatar} alt={card._id} />
            <span>{card.fullname}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default Cards;
