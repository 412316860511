import EmptyComponentImage from '../../../components/EmptyComponentImage';
import Congratulation from '../Congratulation/Congratulation';
import CongratulationForm from '../CongratulationForm';

import './Congratulations.scss';

const Congratulations = ({ congratulations, isFormOpen, setIsFormOpen }) => {
  return (
    <div className="congratulations">
      {congratulations.length ? (
        congratulations.map((congratulation) => (
          <Congratulation
            congratulation={congratulation}
            key={congratulation._id}
          />
        ))
      ) : (
        <EmptyComponentImage text="Acá vas a poder estar al tanto de todas las felicitaciones." />
      )}
      {isFormOpen && <CongratulationForm setIsFormOpen={setIsFormOpen} />}
    </div>
  );
};

export default Congratulations;
