import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import moment from 'moment';
import { putAction } from '../../../actions/modelActions';
import { goalsModel } from '../../../constants/modelConstants';
import { useDispatch, useSelector } from 'react-redux';

import './FeedbackForm.scss';

const FeedbackForm = ({ goal, setCreateFeedback, setShowFeedback }) => {
  const dispatch = useDispatch();
  const { success } = useSelector((store) => store.app);
  const [feedbackText, setFeedbackText] = useState('');

  const date = moment().format('L');

  const handleSendFeedback = () => {
    const newFeedback = { text: feedbackText, date };
    const updatedFeedbacks = [...goal.feedbacks, newFeedback];
    dispatch(
      putAction(goalsModel.NAME, `${goalsModel.URL}/feedbacks/${goal._id}`, {
        feedbacks: updatedFeedbacks,
      })
    );
  };

  useEffect(() => {
    if (success) {
      setCreateFeedback(false);
      setShowFeedback(false);
    }
  }, [success, setCreateFeedback, setShowFeedback]);

  return (
    <form>
      <span>{date}</span>
      <div className="feedback-form__input">
        <TextField
          multiline
          value={feedbackText}
          onChange={({ target }) => setFeedbackText(target.value)}
        />
      </div>
      <div className="feedback-form__actions">
        <Button
          variant="contained"
          onClick={handleSendFeedback}
          disabled={!feedbackText}
        >
          Enviar
        </Button>
        <Button onClick={() => setCreateFeedback(false)}>Cancelar</Button>
      </div>
    </form>
  );
};

export default FeedbackForm;
