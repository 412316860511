import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  deleteNotificationAction,
  getNotificationsDataAction,
} from '../../actions/notificationsActions';
import { CheckCircleOutline } from '@mui/icons-material';
// import { NOTIFICATIONS_ADD_NEW } from '../../constants/notificationsConstants';
// import { io } from 'socket.io-client';
import { groupNotifications } from '../../helpers/groupNotifications';
import { hasRelatedNotificationType } from '../../helpers/hasRelatedNotificationType';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAction } from '../../actions/modelActions';
import {
  demandModel,
  evaluationModel,
  goalsModel,
  recognitionModel,
} from '../../constants/modelConstants';
// import confetti from 'canvas-confetti';

const NotificationsMenu = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((store) => store.notifications);
  const { userInfo } = useSelector((store) => store.userSignIn);
  const { pathname } = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [currentType, setCurrentType] = useState(null);

  const open = Boolean(anchorEl);

  const handleNotificationClick = (notification) => {
    if (currentType !== notification.type) {
      history.push(notification.url);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data) setNotifications(groupNotifications(data));
  }, [data]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationsDataAction());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (currentType) {
      dispatch(deleteNotificationAction(currentType));
      setAnchorEl(null);
    }
  }, [currentType, dispatch]);

  useEffect(() => {
    const typeToDelete = hasRelatedNotificationType(pathname, data);
    if (typeToDelete === currentType) {
      switch (currentType) {
        case 'RECOGNITION':
          dispatch(
            getAction(recognitionModel.NAME, recognitionModel.URL, false)
          );
          break;
        case 'EVALUATION':
          dispatch(
            getAction(
              evaluationModel.NAME,
              `${evaluationModel.USER_EVALUATION}/${userInfo._id}`,
              false
            )
          );
          break;
        case 'GOAL':
          dispatch(
            getAction(goalsModel.NAME, `${goalsModel.URL}/current`, false)
          );
          break;
        case 'DEMAND':
          dispatch(getAction(demandModel.NAME, demandModel.URL, false));
          break;
        default:
          break;
      }
    }
    setCurrentType(typeToDelete);
  }, [currentType, data, dispatch, pathname, userInfo._id]);

  return (
    <div className="user-section__notifications-menu">
      <Button
        id="notifications-menu"
        size="small"
        aria-controls="notifications-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Badge
          badgeContent={data?.length || 0}
          className="user-section__notifications"
          color="primary"
          sx={{
            color: 'white',
          }}
        >
          <NotificationsOutlinedIcon htmlColor="#fff" fontSize="large" />
        </Badge>
      </Button>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateY(6px)',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {notifications?.length === 0 ? (
          <MenuItem onClick={handleClose}>
            <ListItemText>No tenés ninguna notificación</ListItemText>
          </MenuItem>
        ) : (
          notifications?.map((notification, i) => (
            <MenuItem key={`${notification.type}-${i}`}>
              <ListItemIcon>
                <CheckCircleOutline fontSize="small" color="success" />
              </ListItemIcon>
              <div
                className="link"
                onClick={() => handleNotificationClick(notification)}
              >
                <ListItemText>
                  {notification.message}
                  {notification.qty > 1 && (
                    <span className="user-section__notification-qty">
                      ({notification.qty})
                    </span>
                  )}
                </ListItemText>
              </div>
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
};

export default NotificationsMenu;
