import { Avatar, Tooltip } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import './AreaUser.scss';

const AreaUser = ({ user, selectedUser, setSelectedUser }) => {
  const {
    evaluationRelationships: { confirmed, affectedUsers },
  } = user;
  const getModifierUserClass = (user) =>
    selectedUser?._id === user._id ? 'area-user--selected' : '';

  const handleUserClick = () => setSelectedUser(user);

  return (
    <div
      key={user._id}
      className={`area-user ${getModifierUserClass(user)}`}
      onClick={handleUserClick}
    >
      <Avatar src={user.avatar} />
      <div className="area-user__user-data">
        <div className="area-user__user-name">{user.fullname}</div>
        <div className="area-user__user-role-label">
          {user.roleLabel}
          {user.role === 'boss' ? (
            <span className="area-user__range">R-{user.range}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      {confirmed && affectedUsers.some((user) => user.selected) ? (
        <div className="area-user__confirmed">
          <Tooltip title="Confirmado" placement="top" arrow>
            <CheckCircleOutlinedIcon color="inherit" fontSize="small" />
          </Tooltip>
        </div>
      ) : (
        <div className="area-user__need-confirmation">
          <Tooltip title="Necesita confirmación" placement="top" arrow>
            <WarningAmberOutlinedIcon color="inherit" fontSize="small" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default AreaUser;
