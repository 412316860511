export const getFilteredAreas = (areas, filter) => {
  const { searchValue, level, parentArea } = filter;
  let newAreas = areas || [];

  if (searchValue !== '') {
    newAreas = newAreas.filter((area) =>
      area.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  if (level !== '') {
    switch (level) {
      case 'l1':
        newAreas = newAreas.filter((area) => area.management);
        break;
      case 'l2':
        newAreas = newAreas.filter((area) => !area.management);
        break;
      default:
        newAreas = newAreas.filter((area) => area.withoutBoss);
    }
  }

  if (parentArea !== '') {
    newAreas = newAreas.filter((area) => area.parentArea?._id === parentArea);
  }

  return newAreas;
};
