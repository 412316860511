import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import './CompetenceTooltip.scss';

const CompetenceTooltip = ({ competence, children }) => {
  return (
    <CustomTooltip
      title={<CompetenceTooltipComponent competence={competence} />}
      arrow
      placement="right"
    >
      {children}
    </CustomTooltip>
  );
};

const CompetenceTooltipComponent = ({ competence }) => {
  const { description /* behaviourQuestionOptions */ } = competence;

  return (
    <div className="competence-tooltip">
      <p className="competence-tooltip__description">{description}</p>

      {/* <div>
        <p className="competence-tooltip__options__label">Opciones:</p>
        <div className="competence-tooltip__options__options">
          {behaviourQuestionOptions.map((option, i) => (
            <p
              key={`${i}-${option.label}`}
              className="competence-tooltip__option"
            >
              {option.label}
            </p>
          ))}
        </div>
      </div> */}
    </div>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid gray',
    borderRadius: '4px',
    padding: 0,
    width: '500px',
  },
}));
export default CompetenceTooltip;
